<template>
<div>
  <div :style="fullscreen?``:`height:${height}px`" :class="{fullscreen:fullscreen,mapBlock:!fullscreen}">
    <div v-if="!fullscreen" style="width: 30px" class="abs hand btnFull" @click="full(true,14)"><img src="../../assets/smart/home/full.png" width="100%"/></div>
    <div v-if="fullscreen" style="width: 60px" class="abs hand btnFull" @click="full(false,13)"><img src="../../assets/smart/home/full2.png" width="100%"/></div>
    <div class="search flex">
      <div class="searchBar">
        <el-autocomplete
            class="skey"
            v-model="keyword"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
            placeholder="请输入企业名称"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-autocomplete>
      </div>
    </div>
    <div class="baidumap" :style="`height:100%`">
      <div class="abs" :class="{dazi:fullscreen,xiao:!fullscreen}" style="right: 10px;z-index: 100;color: white;top:90px;line-height: 150%">
        <!-- <div v-if="type==1">
          <div class="legend">
          <div class="flex_vc hand item" @click="$refs.childDialog.openMessage()"><img :src="ico[0]" />预警通知</div>
          <div class="flex_vc hand item" @click="$refs.childDialog.openTan(0)"><img :src="ico[1]" />自主整改</div>
          <div class="flex_vc hand item" @click="$refs.childDialog.openZlzg(10)"><img :src="ico[2]" />责令整改</div>
          <div class="flex_vc hand item" @click="$refs.childDialog.openZlzg(11)"><img :src="ico[3]" />现场检查</div>
          <div class="flex_vc hand item" @click="$refs.childDialog.openTan(3)"><img :src="ico[4]"/>联动处置</div>
          <div class="flex_vc hand item" @click="$refs.childDialog.openTan(2,0)"><img :src="ico[5]"/>未处置</div>
        </div>
        </div> -->
        <div v-if="type==2">
          <div class="flex_vc"><img src="../../assets/smart/ico/1.png" width="15"/>找药人群分布</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/2.png" width="15"/>响应药店分布</div>
        </div>
        <div v-if="type==3">
          <div class="flex_vc"><img src="../../assets/smart/ico/1.png" width="15"/>丰收驿站点</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/2.png" width="15"/>上山进岛点</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/3.png" width="15"/>签约医生分布</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/4.png" width="15"/>问诊人群分布</div>
        </div>
        <div v-if="type==4">
          <div class="flex_vc"><img src="../../assets/smart/ico/1.png" width="15"/>药店分布</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/2.png" width="15"/>取消医保</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/3.png" width="15"/>暂停医保</div>
          <div class="flex_vc"><img src="../../assets/smart/ico/4.png" width="15"/>信用降级</div>
        </div>


      </div>
     <bd-map ref="children" :znz="1" :type="type"/>
    </div>
  </div>
  <yun-dialog ref="childDialog"/>
</div>
</template>

<script>
import axios from "axios";
import BdMap from '../BaiduMap'
import yunDialog from "@/views/yunDialog";
import {storeList} from "@/api/yaojian";
export default {
  name: "baidumap",
  components: { BdMap,yunDialog},
  props: {
    height: {
      type: Number,
      default: 470
    },
    type: {
      type: Number,
      default: 1
    },
  },
  data(){
    return {
      ico:[
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1828051103.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_359700814.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_350535907.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_943563961.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1363424593.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1910063012.png",
      ],
      fullscreen:false,
      keyword:"",
      restaurants:[],
      region:this.$id,
    }
  },
  created() {
    this.loadAll()//获取店铺
    },
  methods:{
    full(b,num){
      this.fullscreen=b
      this.$refs.children.zoom({lng:119.92528,lat:29.454017},num)
    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
   async loadAll() {
      let arr
      let that=this
     let post={
        lai:"baiduSearch",
       region:this.region
     }
      let rs=await storeList(post)
     if(rs.code==200){
       arr=rs.data
       for(let i in arr){
         arr[i].value=arr[i].name
       }
       that.restaurants=arr
     }


    },
    handleSelect(item) {
      let url=this.$route.path
      this.$router.push(`/yunStore?id=${item.id}&name=${item.name}&new=1&url=${url}`)
      //this.$refs.children.handleSelect(item)
    },
    //搜素代码----------------------------------------end
  }
}
</script>
<style lang="less" scoped>
.legend{
  right: -7px;
  top: -36px;
  position: absolute;
  width: 114px;
  height: 180px;
  background: rgba(0, 53, 103, 0.5);
  opacity: 0.85;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  line-height: 140%;
  .item{
    img{width: 18px;margin-right: 5px}
  }
}
</style>
<style scoped>
.dazi{font-size: 20px;line-height: 31px}
.dazi img{width: 23px}
.xiao{font-size: 12px}
.btnFull{
  z-index: 101;
  bottom: 10px;
  right: 10px;
}
.hand{cursor: pointer}
.flex{display: flex}
.flex_vc{display: flex;align-items: center}
.mapBlock{position: relative;width: 646px;transition: all 0.1s ease-out}
.fullscreen{position: absolute;top:0;left:0;width: 1920px;height: 1080px;z-index: 99;transition: all 0.1s ease-out}
.search{position: absolute;  left: calc(50% - 175px);top: 10px;z-index: 101}
.map2{
  height: 464px;
  width: 646px;
  position: absolute;
  z-index: 0;

}
.searchBar{margin-left: 20px;margin-top: 5px}
>>>.el-input__icon{line-height: 30px}
.skey>>>.el-input__inner{
  border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 351px;
  color: #28C0EA;
  font-size: 16px;
  opacity: 0.7;
  height: 30px;
  line-height: 30px;
}
.abs{
  position: absolute;
}
</style>
