<template>
    <div id="medicine" style="width: 1920px; height:1080px; overflow: hidden">
        <div class="medicine-content">
            <yun-header />
            <div class="medicine-body">
                <div class="left">
                    <!-- 响应率及找到率分析 -->
                    <div class="analyse common-style">
                        <!-- 头部标题 -->
                        <div class="analyse-header common-header">
                            <div style="display:flex;align-items: center;">
                                <span style="margin-right:15px">响应率及找到率分析</span>
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div id="chart" style="position: relative">
                            <div class="crystalBox" style="left:285px;top:60px" @click="$refs.childDialog.openSatisfaction('threeLevel')">
                                <div
                                    id="chartCrystal"
                                    :style="{ width: '250px', height: '250px' }"
                                ></div>
                            </div>
                            <div class="abs" style="left:282px;top:0px"  @click="$refs.childDialog.openSatisfaction('responsivity')">
                                <div
                                    id="chart_ydxyl"
                                    :style="{ width: '250px', height: '250px' }"
                                ></div>
                            </div>
                            <div class="abs" style="top:218px;left:63px;color: white">
                                三级找药找到率
                            </div>
                            <div class="abs" style="top:218px;left:371px;color: white">
                                药店响应率
                            </div>
                        </div>
                        <!-- 柱状图 -->
                        <!--                        <div class="analyse-echart" ref="analyse"></div>-->
                    </div>
                    <!-- 药店参与响应报价情况分析 -->
                    <div class="bidding-analysis">
                        <!-- 头部标题 -->
                        <div class="bidding-analysis-header common-header">
                            <div style="display:flex;align-items: center;">
                                <span style="margin-right:15px">药店参与响应报价情况分析</span>
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 饼图 -->
                        <div class="bidding-analysis-pie">
                            <div class="bidding-analysis-echart" ref="iddingAnalysis"></div>
                        </div>
                        <!-- 药店响应排行 -->
                        <div class="ranking">
                            药店响应排行
                        </div>
                        <!-- 横向柱状图 -->
                        <div class="bidding-analysisBar" ref="analysisBar"></div>
                    </div>
                </div>
                <div class="center">
                    <!-- 今日找药发布局数 -->
                    <div class="fund-medicine">
                        <div class="fund-medicine-one">
                            <div class="fund-medicine-title">
                                <span style="font-size:26px;line-height:20px;margin-right:28px"
                                    >1</span
                                >
                                <span style="font-size:26px;line-height:26px">今日找药发布数</span>
                            </div>
                            <div
                                class="fund-medicine-number"
                                @click="$refs.childDialog.openFabu(1)"
                            >
                                <div
                                    class="fund-medicine-list"
                                    style="margin-left:8px"
                                    v-for="(item, index) in numberList"
                                    :key="index"
                                >
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                        <div class="fund-medicine-two">
                            <div class="fund-medicine-title">
                                <span style="font-size:26px;line-height:20px;margin-right:28px"
                                    >2</span
                                >
                                <span style="font-size:26px;line-height:26px">找到药品数量</span>
                            </div>
                            <div
                                class="fund-medicine-number"
                                @click="$refs.childDialog.openFabu(2)"
                            >
                                <div class="fund-medicine-list" style="margin-right: 8px">6</div>
                                <div class="fund-medicine-list">5</div>
                            </div>
                        </div>
                    </div>
                    <!-- 丰收驿站服务次数 -->
                    <div class="serve">
                        <!-- 头部标题 -->
                        <div class="serve-header common-header" style="padding:20px 20px 0 20px">
                            <span>丰收驿站服务总数</span>
                            <span>
                                <!-- {{ fengshouci }} -->
                                968

                            </span>
                        </div>
                        <!-- 内容 -->
                        <div class="serve-content" style="padding:0 15px 0 15px">
                            <table
                                width="100%"
                                height="100%"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                class="table-header"
                            >
                                <tr>
                                    <td>站点名称</td>
                                    <td>负责人</td>
                                    <td>服务总数</td>
                                </tr>
                            </table>

                            <!-- <div class="serve-content-title">
                                <span
                                    style="width:173px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                    >驿站名称</span
                                >
                                <span
                                    style="width:178px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                    >负责人</span
                                >
                                <span
                                    style="flex:1;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                    >服务次数</span
                                >
                            </div> -->
                            <div class="serve-content-info">
                                <vue-seamless-scroll
                                    :data="severList"
                                    :class-option="defaultOption"
                                >
                                    <div class="serve-content-list">
                                        <table
                                            class="table-from"
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            border="0"
                                        >
                                            <tr v-for="(item, index) in severList" :key="index">
                                                <td style="width:207px">{{ item.a1 }}</td>
                                                <td style="width:172px">{{ item.a3 }}</td>
                                                <td>{{ item.a6 }}</td>
                                            </tr>
                                        </table>

                                        <!-- <span
                                            style="width:173px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                            >{{ item.a1 }}</span
                                        >
                                        <span
                                            style="width:178px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                            >{{ item.a2 }}</span
                                        >
                                        <span
                                            style="width:125px;margin-left: 12px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap"
                                            >{{ item.a3 }}</span
                                        >
                                        <span
                                            style="flex:1;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;"
                                            >{{ item.a4 }}</span
                                        > -->
                                    </div>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                    </div>
                    <!-- 送药上山笔数 -->
                    <div class="stroke-count">
                        <!-- 头部标题 -->
                        <div
                            class="stroke-count-header common-header"
                            style="padding:20px 20px 0 20px"
                        >
                            <span>送药上山总数</span>
                            <span>{{ shangshanci }}</span>
                        </div>
                        <!-- 内容 -->
                        <div class="stroke-count-content" style="padding:0 15px 0 15px">
                            <div>
                                <table
                                    class="table-header"
                                    width="100%"
                                    height="100%"
                                    cellspacing="0"
                                    cellpadding="0"
                                    border="0"
                                >
                                    <tr>
                                        <td style="width:260px">站点名称</td>
                                        <td style="width:88px">负责人</td>
                                        <td>服务次数</td>
                                        <td>服务对象人次</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="stroke-count-content-info">
                                <vue-seamless-scroll
                                    :data="strokeCountlist"
                                    :class-option="defaultOption"
                                >
                                    <table
                                        class="table-from"
                                        width="100%"
                                        height="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                        border="0"
                                    >
                                        <tr v-for="(item, index) in strokeCountlist" :key="index">
                                            <td style="width:260px">
                                                {{ item.a1 }}
                                            </td>
                                            <td style="width:97px">{{ item.a2 }}</td>
                                            <td>{{ item.a4 }}</td>
                                            <td>{{ item.a5 }}</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- 三级找药趋势分析 -->
                    <div class="trend">
                        <!-- 标题 -->
                        <div class="trend-header common-header">
                            <span>三级找药趋势分析</span>
                        </div>
                        <!-- 线图 -->
                        <!--                        <div class="trend-echart" ref="trendechart"></div>-->
                        <div style="height: 50px"></div>
                        <div id="myChart_sjzy" :style="{ width: '600px', height: '400px' }"></div>
                    </div>
                    <!-- 三级未找到药品品种分析 -->
                    <div class="nofoud">
                        <div class="nofoud-header common-header">
                            <div style="display:flex;align-items: center;">
                                <span style="margin-right:15px">三级未找到药品品种分析</span>
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 柱状图 -->
                        <div class="nofound-echart" ref="nofoundechart"></div>
                    </div>
                </div>
            </div>
        </div>
        <yun-dialog ref="childDialog" />
    </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill"; //水晶图组件
import vueSeamlessScroll from "vue-seamless-scroll";
import yunHeader from "@/views/yunHeader";
import yunDialog from "@/views/yunDialog";
import fengshou from "../assets/data/fengshou.json";
import shangshan from "../assets/data/shangshan.json";
import moment from "moment";
import { prescriptionTotal } from "@/api/yaojian";
import {mul} from "@/utils/jsj"
export default {
    components: {
        vueSeamlessScroll,
        yunHeader,
        yunDialog,
    },
    data() {
        return {
            today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
            riqi: [],
            cdate: [
                moment()
                    .add(-4, "day")
                    .format("MM-DD"),
                moment()
                    .add(-3, "day")
                    .format("MM-DD"),
                moment()
                    .add(-2, "day")
                    .format("MM-DD"),
                moment()
                    .add(-1, "day")
                    .format("MM-DD"),
                moment().format("MM-DD"),
            ],
            severList: fengshou || [],
            strokeCountlist: shangshan || [],
            numberList: ["6", "5"],
            analyse: null,
            iddingAnalysis: null,
            trend: null,
            nofound: null,
            analysisBar: null,
            iddingAnalysisList: [
                {
                    name: "5分钟内",
                    value: 77.3,
                },
                {
                    name: "5-15分钟",
                    value: 17.2,
                },
                {
                    name: "15-30分钟",
                    value: 3.5,
                },
                {
                    name: "30分钟",
                    value: 2,
                },
            ],
        };
    },
    created() {
        for (let i = 0; i < 7; i++) {
            this.riqi.push(
                moment()
                    .add(-i, "day")
                    .format("MM-DD")
            );
        }
        this.riqi.sort();
    },
    mounted() {
        //this.analyseEchart();
        this.iddingAnalysisEchart();
        //this.trendEchart();三级找药趋势分析
        this.drawChart_sjzy("myChart_sjzy");
        this.nofoundEchart();
        this.analysisBarEchart();

        this.draw_ysfwxyl("chart_ydxyl", "99.85"); //药店响应率

        let arr = ["", ""];
        let varr = [0.98, 0.9976];
        this.drawChartCrystal("chartCrystal", arr, varr);
    },
    computed: {
        fengshouci() {
            let num = 0;
            this.severList.forEach((x) => {
                num += parseInt(x.a6);
            });
            return num;
        },
        shangshanci() {
            let num = 0;
            this.strokeCountlist.forEach((x) => {
                num += parseInt(x.a4);
            });
            return num;
        },
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    methods: {
        draw_ysfwxyl(id, data) {
            let angle = 0; // 角度
            let dataValue = data;

            let option = {
                backgroundColor: "",
                title: {
                    text: `{v|${dataValue}}{unit|%}`,
                    x: "center",
                    y: "center",
                    textStyle: {
                        rich: {
                            v: { fontSize: 35, color: "#28edf2" },
                            unit: { fontSize: 16, color: "#28edf2" },
                            t: { fontSize: 30, color: "#28edf2" },
                        },
                    },
                },
                series: [
                    /** 绘制内部圆弧-1 <right-top> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            return {
                                type: "arc",
                                shape: {
                                    cx: api.getWidth() / 2,
                                    cy: api.getHeight() / 2,
                                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                                    startAngle: ((270 + angle) * Math.PI) / 180,
                                    endAngle: ((360 + angle) * Math.PI) / 180,
                                },
                                style: {
                                    fill: "transparent",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                    lineWidth: 2,
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 绘制内部圆弧-2 <left-bottom> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            return {
                                type: "arc",
                                shape: {
                                    cx: api.getWidth() / 2,
                                    cy: api.getHeight() / 2,
                                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.6,
                                    startAngle: ((90 + angle) * Math.PI) / 180,
                                    endAngle: ((180 + angle) * Math.PI) / 180,
                                },
                                style: {
                                    fill: "transparent",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                    lineWidth: 2,
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 绘制外部圆弧-1 <right-bottom> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            return {
                                type: "arc",
                                shape: {
                                    cx: api.getWidth() / 2,
                                    cy: api.getHeight() / 2,
                                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.66,
                                    startAngle: ((355 + -angle) * Math.PI) / 180,
                                    endAngle: ((120 + -angle) * Math.PI) / 180,
                                },
                                style: {
                                    fill: "transparent",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                    lineWidth: 2.6,
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 绘制外部圆弧-2 <left-top> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            return {
                                type: "arc",
                                shape: {
                                    cx: api.getWidth() / 2,
                                    cy: api.getHeight() / 2,
                                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.66,
                                    startAngle: ((175 + -angle) * Math.PI) / 180,
                                    endAngle: ((300 + -angle) * Math.PI) / 180,
                                },
                                style: {
                                    fill: "transparent",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                    lineWidth: 2.6,
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 绘制外部圆弧-1-开始圆点 <right-bottom> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            let x0 = api.getWidth() / 2;
                            let y0 = api.getHeight() / 2;
                            let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.66;
                            return {
                                type: "circle",
                                shape: {
                                    /** 角度355° 外弧1开始角度 */
                                    cx: x0 + r * Math.cos(((355 + -angle) * Math.PI) / 180),
                                    cy: y0 + r * Math.sin(((355 + -angle) * Math.PI) / 180),
                                    r: 4,
                                },
                                style: {
                                    fill: "rgba(1, 248, 68, 0.4)",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 绘制外部圆弧-2-开始圆点 <left-top> */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            let x0 = api.getWidth() / 2;
                            let y0 = api.getHeight() / 2;
                            let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.66;
                            return {
                                type: "circle",
                                shape: {
                                    /** 角度175° 外弧2开始角度 */
                                    cx: x0 + r * Math.cos(((175 + -angle) * Math.PI) / 180),
                                    cy: y0 + r * Math.sin(((175 + -angle) * Math.PI) / 180),
                                    r: 4,
                                },
                                style: {
                                    fill: "rgba(1, 248, 68, 0.4)",
                                    stroke: "rgba(1, 248, 68, 0.4)",
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 刻度仪表盘 */
                    {
                        type: "gauge",
                        center: ["50%", "50%"],
                        radius: "68.5%", // 错位调整此处
                        startAngle: 0,
                        endAngle: 360,
                        axisLine: { show: false },
                        splitLine: { show: false },
                        axisTick: {
                            splitNumber: 10,
                            // length: 8,  // 刻度长度
                            length: "4%",
                            lineStyle: {
                                color: "rgba(1, 248, 68, 0.4)",
                                width: 1.5,
                            },
                        },
                        axisLabel: { show: false },
                    },
                    /** 内心圆 */
                    {
                        type: "custom",
                        coordinateSystem: "none",
                        renderItem: (params, api) => {
                            return {
                                type: "circle",
                                shape: {
                                    cx: api.getWidth() / 2,
                                    cy: api.getHeight() / 2,
                                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.38,
                                    startAngle: ((175 + angle) * Math.PI) / 180,
                                    endAngle: ((300 + angle) * Math.PI) / 180,
                                },
                                style: {
                                    fill: "transparent",
                                    stroke: "#00374C80",
                                    lineWidth: 2.6,
                                },
                                silent: true,
                            };
                        },
                        data: [0],
                    },
                    /** 饼图 */
                    {
                        name: "已完成",
                        type: "pie",
                        startAngle: 90,
                        z: 0,
                        label: {
                            position: "center",
                        },
                        radius: ["56%", "44%"],
                        silent: true,
                        animation: false, // 关闭饼图动画
                        data: [
                            {
                                value: dataValue,
                                itemStyle: {
                                    color: {
                                        type: "linear",
                                        x: 0,
                                        y: 0.2,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [
                                            { offset: 0, color: "#01f74420" },
                                            { offset: 1, color: "#01f744" },
                                        ],
                                    },
                                },
                            },
                            {
                                name: "未完成",
                                value: 100 - dataValue,
                                label: { show: false },
                                itemStyle: { color: "#00374C" },
                            },
                        ],
                    },
                    /** 饼图上刻度 */
                    {
                        type: "gauge",
                        center: ["50%", "50%"],
                        radius: "63%", // 错位调整此处
                        startAngle: 0,
                        endAngle: 360,
                        splitNumber: 12,
                        axisLine: { show: false },
                        splitLine: {
                            // length: 39,
                            length: "24%",
                            lineStyle: {
                                width: 10,
                                color: "#002837",
                            },
                        },
                        axisTick: { show: false },
                        axisLabel: { show: false },
                    },
                ],
            };

            setInterval(() => {
                angle = angle + 2;
                myChart.setOption(option, true);
            }, 100);

            /**
         * 圆弧角度
         ◜    270°   ◝
         180°    ↻      360°
         ◟    90°    ◞
         *

         */

            let myChart = echarts.init(document.getElementById(id));
            myChart.setOption(option);
        },
        drawChartCrystal(id, arrTitle, v = [0.5, 0.5]) {
            let value = 0;
            let v1 = v[0];
            let v2 = v[1];
            var data = [v1, v1];
            var data2 = [v2, v2];
            var option = {
                title: [
                    {
                        text: mul(v2,100) + "%",
                        left: "50%",
                        top: "45%",
                        textAlign: "center",
                        textStyle: {
                            fontSize: "35",
                            fontWeight: "300",
                            color: "#02456d",
                            textAlign: "center",
                            textBorderColor: "rgba(0, 0, 0, 0)",
                            textShadowColor: "#fff",
                            textShadowBlur: "0",
                            textShadowOffsetX: 0,
                            textShadowOffsetY: 1,
                        },
                    },
                ],
                series: [
                    {
                        type: "liquidFill",
                        radius: "50%",
                        z: 6,
                        center: ["50%", "50%"],
                        color: [
                            {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 1,
                                        color: "rgba(0, 155, 219, 0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(0, 155, 219, .2)",
                                    },
                                    {
                                        offset: 0,
                                        color: "rgba(0, 155, 219, 1)",
                                    },
                                ],
                                globalCoord: false,
                            },
                        ],
                        data: data2,
                        backgroundStyle: {
                            borderWidth: 1,
                            color: "transparent",
                        },
                        label: {
                            normal: {
                                formatter: "",
                            },
                        },
                        outline: {
                            show: true,
                            itemStyle: {
                                borderWidth: 0,
                            },
                            borderDistance: 0,
                        },
                    },
                    {
                        name: "第二层白边",
                        type: "pie",
                        z: 3,
                        radius: ["0%", "55%"],
                        center: ["50%", "50%"],
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                },
                            },
                        },
                        data: [
                            {
                                value: 100,
                                itemStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {
                                                offset: 0,
                                                color: "#fefefe",
                                            },
                                            {
                                                offset: 1,
                                                color: "#e7e8ea",
                                            },
                                        ]),
                                    },
                                },
                            },
                            {
                                value: 0,
                                itemStyle: {
                                    normal: {
                                        color: "transparent",
                                    },
                                },
                            },
                        ],
                    },
                    {
                        name: "最外蓝边",
                        type: "pie",
                        z: 1,
                        radius: ["0%", "58%"],
                        center: ["50%", "50%"],
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                },
                            },
                        },
                        data: [
                            {
                                value: 100,
                                itemStyle: {
                                    color: "#07a2e3",
                                },
                            },
                            {
                                value: 0,
                                itemStyle: {
                                    normal: {
                                        color: "transparent",
                                    },
                                },
                            },
                        ],
                    },
                ],
            };

            let myChart = echarts.init(document.getElementById(id));
            myChart.setOption(option);
        },
        fabu() {
            let post = {
                startTime: this.today[0],
                endTime: this.today[1],
            };
            prescriptionTotal(post).then((rs) => {
                console.log(rs);
            });
        },
        drawChart_sjzy(id) {
            //三级找药趋势分析
            let xLabel = this.riqi;
            let data = ["70", "75", "81", "79", "76", "83", "85"];
            let data2 = ["10", "12", "21", "15", "22", "23", "25"];
            let data3 = ["5", "6", "8", "7", "9", "8", "10"];
            let data4 = ["1", "1", "2", "1", "1", "2", "1"];
            let color = ["#0277FA", "#FBC902", "#C41DF3", "#00D7FE"];
            let option = {
                backgroundColor: "",
                tooltip: {
                    trigger: "axis",
                    backgroundColor: "transparent",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(126,199,255,0)", // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(126,199,255,1)", // 100% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(126,199,255,0)", // 100% 处的颜色
                                    },
                                ],
                                global: false, // 缺省为 false
                            },
                        },
                    },
                    formatter(res) {
                        // console.log(res)
                        return "";
                    },
                },
                legend: {
                    // data: ['示范', '一级', '二级'],
                    orient: "horizontal", // 水平
                    //type: 'plain',          // 普通图例
                    icon: "circle",
                    top: 0,
                    right: 30,
                    // right: '5%',
                    // bottom: '15%',
                    //width:280,              // 宽度
                    itemGap: 2, // 间隔
                    itemWidth: 10, // 图形宽度。
                    itemHeight: 10, // 图形高度。
                    borderWidth: 0,
                    textStyle: {
                        fontSize: "18px",
                        color: "#4ca9ff",
                    },
                },
                grid: {
                    top: "15%",
                    left: "10%",
                    right: "10%",
                    bottom: "15%",
                    // containLabel: true
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLine: {
                            //坐标轴轴线相关设置。数学上的x轴
                            show: false,
                            lineStyle: {
                                color: "#233653",
                            },
                        },
                        axisLabel: {
                            //坐标轴刻度标签的相关设置
                            textStyle: {
                                color: "#7ec7ff",
                                padding: 16,
                                fontSize: 18,
                            },
                            formatter: function(data) {
                                return data;
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "#192a44",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        data: xLabel,
                    },
                ],
                yAxis: [
                    {
                        name: "",
                        nameTextStyle: {
                            color: "#7ec7ff",
                            fontSize: 16,
                            padding: 10,
                        },
                        min: 0,
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "#192a44",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#233653",
                            },
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#7ec7ff",
                                padding: 16,
                            },
                            formatter: function(value) {
                                if (value === 0) {
                                    return value;
                                }
                                return value;
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: "一级(县域)",
                        type: "line",
                        symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 8,
                        smooth: true,
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                color: color[0],
                            },
                        },
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: color[0], // 线条颜色
                            },
                            borderColor: "rgba(0,0,0,.4)",
                        },
                        itemStyle: {
                            color: color[0],
                            borderColor: "#646ace",
                            borderWidth: 2,
                        },

                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(25,163,223,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(25,163,223, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                        data: data,
                    },
                    {
                        name: "二级(市域)",
                        type: "line",
                        symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 8,
                        smooth: true,
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                color: color[1],
                            },
                        },
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: color[1], // 线条颜色
                            },
                            borderColor: "rgba(0,0,0,.4)",
                        },
                        itemStyle: {
                            color: color[1],
                            borderColor: "#646ace",
                            borderWidth: 2,
                        },

                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(10,219,250,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(10,219,250, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                        data: data2,
                    },
                    {
                        name: "三级(省域)",
                        type: "line",
                        symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 8,
                        smooth: true,
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                color: color[2],
                            },
                        },
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: color[2], // 线条颜色
                            },
                            borderColor: "rgba(0,0,0,.4)",
                        },
                        itemStyle: {
                            color: color[2],
                            borderColor: "#646ace",
                            borderWidth: 2,
                        },

                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(10,219,250,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(10,219,250, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                        data: data3,
                    },
                    {
                        name: "未找到",
                        type: "line",
                        symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 8,
                        smooth: true,
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                color: color[3],
                            },
                        },
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: color[3], // 线条颜色
                            },
                            borderColor: "rgba(0,0,0,.4)",
                        },
                        itemStyle: {
                            color: color[3],
                            borderColor: "#646ace",
                            borderWidth: 2,
                        },

                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(10,219,250,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(10,219,250, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                        data: data4,
                    },
                ],
            };
            let myChart = echarts.init(document.getElementById(id));
            myChart.setOption(option);
        },
        //响应率及找到率分析柱状图
        analyseEchart() {
            this.analyse = echarts.init(this.$refs.analyse);
            const analyseOption = {
                tooltip: {},
                grid: {
                    left: "10%",
                    right: "4%",
                    bottom: "10%",
                    height: "80%",
                },

                xAxis: [
                    {
                        type: "value",
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "category",
                        data: this.cdate,
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                legend: {
                    right: 0,
                    textStyle: { color: "#fff" },
                },
                series: [
                    {
                        barWidth: 10,
                        name: "响应率",
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#01d6ff",
                            borderWidth: 15,
                        },

                        data: [99, 100, 100, 98, 100],
                    },
                    {
                        barWidth: 10,
                        name: "找到率",
                        type: "bar",
                        itemStyle: {
                            color: "#0277FA",
                            borderWidth: 15,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [93, 96, 99, 95, 99],
                    },
                ],
            };
            this.analyse.setOption(analyseOption);
        },
        // 药店参数响应报价情况饼图
        iddingAnalysisEchart() {
            this.iddingAnalysis = echarts.init(this.$refs.iddingAnalysis);
            const iddingAnalysisOption = {
                color: ["#0277FA", "#FBC902", "#C41DF3", "#00D7FE"],
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "20%",
                    left: "50%",
                    itemGap: 15,
                    itemWidth: 20,
                    itemHeight: 15,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 160,
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            b: {
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.iddingAnalysisList.findIndex(
                            (item) => item.name == name
                        );
                        return `{a|${name}}{b|${this.iddingAnalysisList[currentIndex].value}%}`;
                    },
                },
                series: [
                    {
                        name: "风险处置分析",
                        type: "pie",
                        radius: ["40%", "70%"],
                        center: ["20%", "55%"],
                        label: {
                            show: false,
                            position: "center",
                        },
                        data: this.iddingAnalysisList,
                    },
                ],
            };
            this.iddingAnalysis.setOption(iddingAnalysisOption);
        },
        // 三级找药趋势分析
        trendEchart() {
            this.trend = echarts.init(this.$refs.trendechart);
            const trendOption = {
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },
                    itemGap: 28,
                    icon: "rect",
                    right: "0%",
                },
                xAxis: {
                    type: "category",
                    data: this.cdate,
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        name: "一级(县域)",
                        data: [30, 40, 50, 100, 50],
                        type: "line",
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#0096FF",
                            },
                        },
                    },
                    {
                        name: "二级(市域)",
                        data: [30, 40, 50, 100, 50],
                        type: "line",
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#0096FF",
                            },
                        },
                    },
                    {
                        name: "三级(省域)",
                        data: [30, 40, 50, 100, 50],
                        type: "line",
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#0096FF",
                            },
                        },
                    },
                    {
                        name: "未找到",
                        data: [30, 40, 50, 100, 50],
                        type: "line",
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 0, color: "#00D7FE  " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#0096FF",
                            },
                        },
                    },
                ],
            };
            this.trend.setOption(trendOption);
        },
        //三级未找到
        nofoundEchart() {
            this.nofound = echarts.init(this.$refs.nofoundechart);
            const nofoundOption = {
                // legend: {
                //     itemWidth: 12,
                //     itemHeight: 12,
                //     textStyle: {
                //         color: "#fff",
                //         fontSize: 18,
                //     },
                //     itemGap: 28,
                //     top: "3%",
                // },
                grid: {
                    left: "10%",
                    right: "5%",
                    bottom: "10%",
                    top: "5%",
                    height: "80%",
                },
                tooltip: {
                    show: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: [
                            "艾司唑仑片",
                            "氯硝西泮片",
                            "地西泮片",
                            "氟西泮片",
                            "Jardiance",
                            "糖尿病治疗药",
                            "阿普唑仑片",
                        ],
                        axisLabel: {
                            fontSize: 12,
                            color: "#EEEEEE",
                            fontWeight: 400,
                            interval: 0,
                            formatter: function(params) {
                                var newParamsName = ""; // 最终拼接成的字符串
                                var paramsNameNumber = params.length; // 实际标签的个数
                                var provideNumber = 4; // 每行能显示的字的个数
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                                /**
                                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                                 */
                                // 条件等同于rowNumber>1
                                if (paramsNameNumber > provideNumber) {
                                    /** 循环每一行,p表示行 */
                                    for (var p = 0; p < rowNumber; p++) {
                                        var tempStr = ""; // 表示每一次截取的字符串
                                        var start = p * provideNumber; // 开始截取的位置
                                        var end = start + provideNumber; // 结束截取的位置
                                        // 此处特殊处理最后一行的索引值
                                        if (p == rowNumber - 1) {
                                            // 最后一次不换行
                                            tempStr = params.substring(start, paramsNameNumber);
                                        } else {
                                            // 每一次拼接字符串并换行
                                            tempStr = params.substring(start, end) + "\n";
                                        }
                                        newParamsName += tempStr; // 最终拼成的字符串
                                    }
                                } else {
                                    // 将旧标签的值赋给新标签
                                    newParamsName = params;
                                }
                                //将最终的字符串返回
                                return newParamsName;
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#081d4b",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "",
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#0277FA",
                        },
                        data: [4, 1, 1, 1, 1, 1, 1, ],
                        barWidth: 15,
                        label: {
                            show: true,
                            position: "top",
                        },
                    },
                    // {
                    //     name: "需求量",
                    //     barWidth: 15,
                    //     type: "bar",
                    //     itemStyle: {
                    //         color: "#00D7FE",
                    //     },
                    //     emphasis: {
                    //         focus: "series",
                    //     },
                    //     data: [120, 132, 101, 134, 90],
                    // },
                    // {
                    //     name: "未找到",
                    //     barWidth: 15,
                    //     type: "bar",
                    //     itemStyle: {
                    //         color: "#FBC902",
                    //     },
                    //     emphasis: {
                    //         focus: "series",
                    //     },
                    //     data: [ 182, 191, 234, 2, 3],
                    // },
                ],
            };
            this.nofound.setOption(nofoundOption);
        },
        // 药店响应排行
        analysisBarEchart() {
            this.analysisBar = echarts.init(this.$refs.analysisBar);
            const option = {
                title: {
                    textStyle: {
                        align: "center",
                        color: "#fff",
                        fontSize: 28,
                    },
                    top: "5%",
                    left: "center",
                },
                grid: [
                    {
                        top: "15%",
                        bottom: "5%",
                        // width: '50%',
                        left: "3%",
                        containLabel: false,
                    },
                ],
                angleAxis: {
                    polarIndex: 0,
                    min: 0,
                    max: 100,
                    show: false,
                    boundaryGap: ["60%", "60%"],
                    startAngle: 90,
                },
                radiusAxis: {
                    type: "category",
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                polar: [
                    {
                        center: ["20%", "50%"], //中心点位置
                        radius: ["60%", "75%"],
                    },
                ],
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisTick: "none",
                        axisLine: "none",
                        axisLabel: {
                            show: false,
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: [
                            "浦江县恒生药房连锁有限公司亚太大道店",
                            "浙江康佰家大药房有限公司浦江亚太路店",
                            "金华市江南药都医药连锁有限公司浦江亚太路药店",
                            "金华瑞人堂保济堂医药连锁有限公司浦江亚太大道",
                            "浦江丰安药品销售有限公司",
                        ],
                    },
                    {
                        type: "category",
                        inverse: true,
                        axisTick: "none",
                        axisLine: "none",
                        show: true,
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                                fontSize: "20",
                            },
                        },
                        data: [213, 151, 123, 117, 111],
                    },
                ],
                series: [
                    {
                        name: "完成率",
                        type: "bar",
                        zlevel: 1,
                        silent: false,
                        label: {
                            normal: {
                                color: "#fff",
                                show: true,
                                position: ["0", "-24px"],
                                textStyle: {
                                    fontSize: 20,
                                },
                                formatter: function(a, b) {
                                    return a.name;
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: 30,
                                color: "#0277FA",
                            },
                        },
                        barWidth: 10,
                        data: [80.5, 30, 20, 11, 8],
                    },
                    {
                        name: "背景",
                        type: "bar",
                        barWidth: 10,
                        barGap: "-100%",
                        data: [100, 100, 100, 100, 100],
                        itemStyle: {
                            normal: {
                                color: "#071c4c",
                                barBorderRadius: 30,
                            },
                        },
                    },
                ],
            };

            this.analysisBar.setOption(option);
            let that = this;
            this.analysisBar.on("click", function(params) {
                console.log(params);
                //此处的value值为饼状图里 data的name 值
                let arr = [
                    {
                        id: "1",
                        name: "浦江县恒生药房连锁有限公司亚太大道店",
                    },
                    {
                        id: "1381845942424559617",
                        name: "浙江康佰家大药房有限公司浦江亚太路店",
                    },
                    {
                        id: "1379362632492290050",
                        name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    },
                    {
                        id: "1381306888962347010",
                        name: "金华瑞人堂保济堂医药连锁有限公司浦江亚太大道",
                    },
                    {
                        id: "1381519298602070018",
                        name: "浦江丰安药品销售有限公司",
                    },
                ];
                var value = params.name;
                let index = params.dataIndex;
                that.$router.push(
                    `/yunStore?id=${arr[index].id}&name=${arr[index].name}&url=/yunMedicine`
                );
            });
        },
    },
};
</script>

<style scoped>
.abs {
    position: absolute;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#medicine {
    background: url("../assets/medicine/bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#medicine .medicine-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.medicine-content .medicine-header {
    height: 51px;
    width: 100%;
    background: url("../assets/medicine/bt.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    line-height: 26px;
}
.medicine-header span {
    line-height: 38px;
}
.medicine-content .medicine-body {
    flex: 1;
    display: flex;
    gap: 15px;
    padding: 0 20px;
}
.medicine-body .left {
    flex: 1;
}
.medicine-body .center {
    flex: 1;
}
.medicine-body .right {
    flex: 1;
}
.medicine-body .left .analyse {
    display: flex;
    flex-direction: column;
    background: url("../assets/medicine/公共背景.png");
    width: 100%;
    height: 365px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
}
.medicine-body .left .analyse .analyse-header,
.common-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    line-height: 27px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 6px;
    display: flex;
    align-content: center;
}
.medicine-body .common-header span:nth-child(2) {
    height: 21px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #f2b739;
    letter-spacing: 0;
}
.medicine-body .left .analyse .analyse-echart {
    flex: 1;
    width: 100%;
}
.medicine-body .left .bidding-analysis {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    height: 605px;
    width: 100%;
    background: pink;
    padding: 20px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .left .bidding-analysis .bidding-analysisBar {
    flex: 1;
}
.medicine-body .left .bidding-analysis .bidding-analysis-pie {
    height: 181px;
    width: 100%;
    position: relative;
}
.medicine-body .left .bidding-analysis .bidding-analysis-echart {
    width: 100%;
    height: 100%;
}
.medicine-body .left .bidding-analysis .bidding-analysis-list {
    position: absolute;
    right: 42px;
    top: 19%;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 20px;
}
.medicine-body .left .bidding-analysis .bidding-analysis-list span {
    padding-bottom: 15px;
    line-height: 15px;
}
.medicine-body .left .bidding-analysis .ranking {
    margin-top: 15px;
    height: 39px;
    width: 100%;
    background: url("../assets/medicine/jb.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    padding-left: 16px;
    display: flex;
    align-items: center;
    line-height: 23px;
}
.medicine-body .center .fund-medicine {
    width: 100%;
    height: 136px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 21px;
}
.medicine-body .center .fund-medicine .fund-medicine-number {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.medicine-body .center .fund-medicine .fund-medicine-number .fund-medicine-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    background: url("../assets/medicine/数字背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 29px;
    color: #ffc038;
}
.medicine-body .center .fund-medicine .fund-medicine-title {
    margin-right: 10px;
}
.medicine-body .center .fund-medicine .fund-medicine-title span {
    color: #fff;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}
.medicine-body .center .fund-medicine .fund-medicine-one,
.medicine-body .center .fund-medicine .fund-medicine-two {
    padding-left: 21px;
    background: url("../assets/medicine/中1标题.png");
    height: 57px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.medicine-body .center .serve {
    width: 100%;
    height: 410px;
    background: url("../assets/medicine/右2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .center .serve .serve-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.medicine-body .center .serve .serve-content .table-header tr {
    height: 39px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .center .serve .serve-content .table-header tr td {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
    padding: 0 30px;
    vertical-align: middle;
}
.medicine-body .center .serve .serve-content .serve-content-info {
    height: 302px;
    overflow: hidden;
    width: 100%;
}
.medicine-body .center .serve .serve-content .serve-content-info .table-from tr {
    height: 54px;
}
.medicine-body .center .serve .serve-content .serve-content-info .table-from tr td {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
    padding: 0 30px;
    vertical-align: middle;
}
.medicine-body .center .serve .serve-content .serve-content-info .table-from tr:nth-child(2n) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .center .serve .serve-content .serve-content-info .serve-content-list {
    display: flex;
    height: 100%;
    align-items: center;
}
.medicine-body .center .serve .serve-content .serve-content-title {
    display: flex;
    height: 40px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    padding: 0 15px 0 14px;
}
.medicine-body .center .serve .serve-content .serve-content-title span {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
}
.medicine-body .center .stroke-count {
    margin-top: 15px;
    height: 403px;
    width: 100%;
    background: url("../assets/medicine/右2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}
.medicine-body .center .stroke-count .stroke-count-content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.medicine-body .center .stroke-count .stroke-count-content .table-header tr {
    height: 39px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
}
.medicine-body .center .stroke-count .stroke-count-content .table-header tr td {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
    padding-left: 10px;
    vertical-align: middle;
}
.medicine-body .center .stroke-count .stroke-count-content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;
    padding: 0 15px 0 14px;
}
.medicine-body .center .stroke-count .stroke-count-content-title span {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
}
.medicine-body .center .stroke-count .stroke-count-content-info {
    flex: 1;
    overflow: hidden;
}
.medicine-body .center .stroke-count .stroke-count-content-info .table-from tr {
    height: 54px;
}
.medicine-body .center .stroke-count .stroke-count-content-info .table-from tr:nth-child(2n) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .center .stroke-count .stroke-count-content-info .table-from tr td {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
    padding-left: 10px;
    vertical-align: middle;
}
.medicine-body .center .stroke-count .stroke-count-content-info .stroke-count-content-list {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 14px;
    height: 55px;
}
.medicine-body
    .center
    .stroke-count
    .stroke-count-content-info
    .stroke-count-content-list:nth-child(2n) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
}
.medicine-body .right .trend {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 487px;
    width: 100%;
    background: url("../assets/medicine/右2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.medicine-body .right .trend .trend-echart {
    flex: 1;
    width: 100%;
    margin-top: 15px;
}
.medicine-body .right .nofoud {
    height: 482px;
    margin-top: 15px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}
.nofoud-header {
    padding: 20px;
}
.medicine-body .right .nofoud .nofound-echart {
    flex: 1;
    width: 100%;
    margin-top: 15px;
}
</style>
