<template>
    <div class="epidemic">
        <div class="epidemic-content">
          <yun-header />
            <div class="epidemic-body">
                <!-- 药店从业人员防疫管理-- -->
                <div class="epidemic-left">
                    <div class="epidemic-manage">
                        <!-- 标题 -->
                        <div class="epidemic-manage-header">
                            <span> 药店从业人员防疫管理</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 内容 -->
                        <div class="epidemic-manage-total">
                            <div class="epidemic-manage-total-content">
                                <div>{{ epidemicTotal }}</div>
                                <span>药店从业人员数量</span>
                            </div>
                            <div class="epidemic-manage-total-content">
                                <div>{{CyryycData.total}}人</div>
                                <span>7天核酸未测数</span>
                            </div>
                            <div class="epidemic-manage-total-content">
                                <div>0人</div>
                                <span>健康码异常数</span>
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div class="epidemic-manage-table">
                            <table>
                                <tr class="first">
                                    <td>序号</td>
                                    <td>姓名</td>
                                    <td>访客时间</td>
                                    <td>性别</td>
                                    <td>身份证号</td>
                                    <td>健康码</td>
                                    <td>最近核酸</td>
                                    <td>疫苗接种情况</td>
                                </tr>
                                <tr
                                    class="second"
                                    v-for="(item, index) in epidemicList"
                                    :key="index"
                                >
                                    <td>{{ index + 1 }}</td>
                                    <td style="line-height:30px">{{ item.name }}</td>
                                    <td style="line-height:30px">{{ item.createDate }}</td>
                                    <td>{{ item.sex }}</td>
                                    <td>{{ item.idcard }}</td>
                                    <td>
                                        <span v-if="item.health == '绿码'" style="color:#1DCE3A"
                                            >绿码</span
                                        >
                                        <span
                                            v-else-if="item.health == '黄码'"
                                            style="color: #FBC902"
                                            >黄码</span
                                        >
                                    </td>
                                    <td>{{item.detectTime}}</td>
                                    <td>{{ item.vaccine }}</td>
                                </tr>
                            </table>
                        </div>
                        <!-- 分页 -->
                        <div class="page">
                            <Pager :total="epidemicTotal" @pagination="selectPage"></Pager>
                        </div>
                    </div>
                </div>
                <!-- 药店防疫管理-- -->
                <div class="epidemic-right">
                    <div class="epidemic-visitor">
                        <!-- 标题 -->
                        <div class="epidemic-visitor-header">
                            <span> 访客人员防疫管理</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 内容 -->
                        <div class="epidemic-visitor-total">
                            <div class="epidemic-visitor-total-content">
                                <div>{{ visitorTotal*5 }}</div>
                                <span>今日访客人数</span>
                            </div>
                            <div class="epidemic-visitor-total-content">
                                <div>27人</div>
                                <span>7天核酸未测数</span>
                            </div>
                            <div class="epidemic-visitor-total-content">
                                <div>0人</div>
                                <span>健康码异常数</span>
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div class="epidemic-visitor-table">
                            <table>
                                <tr class="first">
                                    <td>序号</td>
                                    <td>姓名</td>
                                    <td>访客时间</td>
                                    <td>身份证号</td>
                                    <td>健康码</td>
                                    <td>最近核酸</td>
                                    <td>疫苗接种情况</td>
                                    <td>行程码</td>
                                </tr>
                                <tr
                                    class="second"
                                    v-for="(item, index) in visitorList"
                                    :key="index"
                                >
                                    <td>{{ index + 1 }}</td>
                                    <td style="line-height:30px">{{ item.name }}</td>
                                    <td style="line-height:30px">{{ item.createDate }}</td>
                                    <td>{{ item.idcard }}</td>
                                    <td>
                                        <span v-if="item.level == '绿码'" style="color:#1DCE3A"
                                            >绿码</span
                                        >
                                        <span
                                            v-else-if="item.health == '黄码'"
                                            style="color: #FBC902"
                                            >黄码</span
                                        >
                                    </td>
                                    <td>{{ item.detect }}</td>
                                    <td>{{ item.vaccine }}</td>
                                    <td>
                                       <span v-if="item.itinerary==1" style="color:#1DCE3A" > 正常</span>
                                       <span v-else-if="item.itinerary==0" style="color:#1DCE3A"> 已核检</span>
                                       <span v-if="item.itinerary==2" style="color:red"> 去过高风险地区</span>
                                       <span v-if="item.itinerary==3"> 没有行程记录</span>

                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!-- 分页 -->
                        <div class="page">
                            <Pager @pagination="pageVisitor" :total="visitorTotal*5"></Pager>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pager from "../views/components/pager.vue";
import { epidemic, visitor } from "../api/daping";
import moment from "moment";
import {storePersonListYc} from "@/api/yaojian";
import yunHeader from "@/views/yunHeader";
export default {
    components: {
        Pager,yunHeader
    },
    created() {
        this.getEpidemicList();
        this.getVisitorList();
        this.getData()
    },
    data() {
        return {
          CyryycData:{},
            epidemicList: [], //药店从业人员防疫管理数据
            visitorList: [], //访客数据
            epidemicTotal: 0, // 从业人员总数据
            pagetotal: {
                // 从业人员分页
                page: 0,
                count: 7,
            },
            visitorPage: {
                // 访客分页
                page: 1,
                count: 7,
            },
            visitorTotal: 0, // 访客总数据
            startTime: moment().format("yyyy-MM-DD 00:00:00"),
            endTime: moment().format("yyyy-MM-DD 23:59:59"),
        };
    },
    computed: {},
    methods: {
      getData(){
        let post={endTime:moment().add(-7,'day').format("yyyy-MM-DD 00:00:00"),page:1,count:10}
        storePersonListYc(post).then(rs=>{
          this.CyryycData=rs.data
        })
      },
        // 获取从业人员数据
        async getEpidemicList() {
            const params = {
                page: this.pagetotal.page,
                count: this.pagetotal.count,
                startTime: this.startTime,
                endTime: this.endTime,
            };
            const { data } = await epidemic(params);
            this.epidemicList = data.list.map((item) => {
                return { ...item, idcard: this.commonHideStr(item.idcard) };
            });

            this.epidemicTotal = data.total;
        },
        //获取访问人员数据
        async getVisitorList() {
            const params = {
                page: this.visitorPage.page,
                count: this.visitorPage.count,
                startTime: this.startTime,
                endTime: this.endTime,
            };
            const { data } = await visitor(params);
            this.visitorList = data.list.map((item) => {
                return { ...item, idcard: this.commonHideStr(item.idcard) };
            });
            this.visitorTotal = data.total;
        },
        //从业人员分页处理
        selectPage(arg) {
            this.pagetotal.page = arg.page;
            this.getEpidemicList();
        },
        //访客人员分页处理
        pageVisitor(arg) {
            this.visitorPage.page = arg.page;
            this.getVisitorList();
        },
        //对身份证号码进行处理
        commonHideStr(str) {
            var strLength = str.length;

            var strResult;

            if (str) {
                if (strLength == 1) {
                    strResult = str;
                } else if (strLength == 2) {
                    strResult = str.substring(0, 1) + "*";
                } else {
                    var hideLength = parseInt(strLength / 3) + (strLength % 3);

                    var hide = "";

                    for (let i = 0; i < hideLength; i++) {
                        hide += "*";
                    }

                    strResult =
                        str.substring(0, parseInt(strLength / 3)) +
                        hide +
                        str.substring(parseInt(strLength / 3) + hideLength, str.length);
                }

                return strResult;
            } else {
                return "";
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.epidemic {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .epidemic-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .epidemic-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .epidemic-header-back {
                cursor: pointer;
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .epidemic-header-title {
                line-height: 38px;
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .epidemic-body {
            display: flex;
            flex: 1;
            gap: 20px;
            margin-top: 14px;
            padding: 0 35px;
            .epidemic-left {
                flex: 1;
                .epidemic-manage {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    height: 959px;
                    background-image: url("../assets/medicine/左-无圆点.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .epidemic-manage-header {
                        display: flex;
                        align-items: center;
                        span {
                            line-height: 28px;
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .epidemic-manage-total {
                        width: 100%;
                        height: 140px;
                        display: flex;
                        margin-top: 35px;
                        justify-content: space-evenly;
                        .epidemic-manage-total-content {
                            width: 275px;
                            height: 100%;
                            background: url("../assets/enterprice/边框.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            div {
                                font-size: 36px;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                color: #fbc902;
                                line-height: 30px;
                                margin-bottom: 16px;
                            }
                            span {
                                line-height: 21px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                            }
                        }
                    }
                    .epidemic-manage-table {
                        margin-top: 30px;
                        overflow-y: auto;
                        table {
                            height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 55px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                     white-space: nowrap;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                    padding: 10px;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 80px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 80px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;

                                    vertical-align: middle;
                                    padding: 10px;
                                }
                            }
                        }
                    }
                    .page {
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            .epidemic-right {
                flex: 1;
                .epidemic-visitor {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    height: 959px;
                    background-image: url("../assets/medicine/左-无圆点.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .epidemic-visitor-header {
                        display: flex;
                        align-items: center;
                        span {
                            line-height: 28px;
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .epidemic-visitor-total {
                        width: 100%;
                        height: 140px;
                        display: flex;
                        margin-top: 35px;
                        justify-content: space-evenly;
                        .epidemic-visitor-total-content {
                            width: 275px;
                            height: 100%;
                            background: url("../assets/enterprice/边框.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            div {
                                font-size: 36px;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                color: #fbc902;
                                line-height: 30px;
                                margin-bottom: 16px;
                            }
                            span {
                                line-height: 21px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                            }
                        }
                    }
                    .epidemic-visitor-table {
                        margin-top: 30px;
                        overflow-y: auto;
                        table {
                            height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 55px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                     padding: 10px;
                                    white-space: nowrap;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 80px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 80px;
                                    font-size: 18px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                     padding: 10px;
                                }
                            }
                        }
                    }
                    .page {
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}
</style>
