export function get_message(call_back_fun){
  var ws = new WebSocket("wss://api.yaojian.xusos.com/api/message");
  //var ws = new WebSocket("ws://47.111.107.34:28062/api/message");
  console.log('readyState', ws.readyState)
  ws.onopen = function() {
    console.log('onopen')
	}

  ws.onmessage = function(evt) {
    console.log('onmessage')
	call_back_fun(evt)
  };

  ws.onclose = function(evt) {
    console.log('onclose', evt)
    ws = new WebSocket("wss://api.yaojian.xusos.com/api/message");
  };

  ws.onerror = function(evt) {
    console.info(`onerror`, evt)
    // call_error_func()
  }

  setInterval(() => {
    console.log('重试链接')
    let data = {
      lng: '',
      lat: '',
      type: 0
    }
    ws.send(JSON.stringify(data))
  }, 30000)
}
