<template>
<div class="dialog">
  <el-dialog title="" :visible.sync="dialogTableVisible" width="1537px" top="5vh">
    <div class="box">
      <div style="padding: 50px">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 10px"><img src="../../assets/home/4.png" ></div>
          <div class="title">控制规则添加</div>
          <div style="margin-left: auto"><img src="../../assets/home/cha.png" @click="dialogTableVisible=false" class="hand"></div>
        </div>
        <div class="search">
          <el-form ref="form" :model="form" :inline="true" :rules="rules">
            <el-form-item label="控制名称：" prop="name">
              <el-input v-model="form.name" placeholder="" />
            </el-form-item>
            <!--- <el-form-item label="控制区域：">
              <el-input v-model="form.region" placeholder="" />
            </el-form-item>
            <el-form-item label="控制对象：">
              <el-radio-group v-model="form.type">
                <el-radio label="1">药店</el-radio>
                <el-radio label="2">个人</el-radio>
              </el-radio-group>
            </el-form-item>-->
            <el-form-item label="控制力度：">
              <el-radio-group v-model="form.way">
                <el-radio :label="1">禁售</el-radio>
                <el-radio :label="2">限售</el-radio>
                <el-radio :label="3">提示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="控制原因：" prop="reason">
              <el-input v-model="form.reason" placeholder="" />
            </el-form-item>
            <br>
            <!-- <el-form-item label="商品属性" /> -->
            <el-form-item label="日期" prop="date">
              <el-date-picker
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              />
            </el-form-item>
            <el-form-item v-if="form.way === 2" label="设置规则：" prop="days">
              时间周期:<el-input v-model="form.days" size="mini" placeholder="" style="width: 100px" />(天)
              销售上限:<el-input v-model="form.count" size="mini" placeholder="" style="width: 100px" />(基本单位)
            </el-form-item>
            <br>
            <el-form-item label="控制类型：">
              <el-radio-group v-model="form.catetory">
                <el-radio :label="1">以药品通用名</el-radio>
                <el-radio :label="2">以药品批号及其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <br>
            <el-form-item v-if="form.catetory === 1" label="添加商品名称">
              <el-link type="primary" href="http://dl.jisogo.com/yaopin_name_template.xlsx">下载excel模板</el-link>
              <el-upload
                  class="upload-demo"
                  drag
                  :show-file-list="false"
                  :action="$api + '/control/file/upload'"
                  :on-success="handleSuccess"
                  :before-upload="handleBeforeUpload"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </el-upload>
              <el-tag
                  v-for="(tag, index) in dynamicTags"
                  :key="index"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-autocomplete
                  v-if="inputVisible"
                  ref="saveTagInput"
                  v-model="inputValue"
                  class="input-new-tag"
                  size="small"
                  value-key="name"
                  placeholder="请输入药品名称或首字母查询"
                  :fetch-suggestions="querySearchAsync"
                  @select="handleSelect"
              />
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加商品名称</el-button>
            </el-form-item>
            <el-form-item v-if="form.catetory == 2" label="商品检索：">
              <el-select
                  v-model="keyword"
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  placeholder="请输入关键词"
                  popper-class="popper-class"
                  :remote-method="remoteMethod"
                  @change="handleChange"
              >
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name + ' ' + item.spec + ' ' + item.unit + '(' + item.approval + ')'"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div class="biao">
            <el-table v-if="form.catetory == 2" :data="tableData">
              <el-table-column type="index" label="序号" />
              <el-table-column label="商品名" prop="name" width="120px" />
              <!-- <el-table-column label="通用名称" prop="default_name" width="120px" /> -->
              <el-table-column label="规格" prop="spec" width="100px" />
              <el-table-column label="单位" prop="unit" width="60px" />
              <el-table-column label="生产企业" prop="company" width="150px" />
              <el-table-column label="批号范围">
                <template slot-scope="scope">
                  <el-tag v-for="(item, index) in scope.row.batch" :key="index" style="margin: 0px 5px 5px 0px;" closable @close="handleTagClose(scope.row, index, scope.$index)">{{ item }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" @click="handleBatch(scope.row, scope.$index)">添加批号</el-button>
                  <el-button type="danger" size="mini" @click="handleRemove(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="footer">
            <el-button @click="close">返回</el-button>
            <el-button type="primary" @click="handleSave()">保存</el-button>
          </div>
        </div>
         </div>
    </div>
  </el-dialog>
</div>
</template>
<style scoped>
.footer{position: absolute;bottom:100px;right: 100px}
.btnSearch{
  width: 100px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #1AC9FF;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
}
.search{
  padding: 0px 20px;
  margin-top: 20px;
  color: #1AC9FF;
  font-size: 18px;

}
>>>.el-button{background-color: transparent;color: #1AC9FF}
.search >>>.el-upload-dragger{background-color: transparent}
.search >>>.el-date-editor .el-range-input{background-color: transparent;color: white}
.search >>>.el-form-item__label,.search >>>.el-radio__label{color:#1AC9FF;font-size:16px }
.search >>>.el-input__inner{
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #1AC9FF;
  border-radius: 6px;
}
.content{ padding:0px 20px}
.box{
  background-image: url('../../assets/home/dialog.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 931px;
  position: relative;
}
.title{
  font-size: 24px;
  font-weight: 500;
  color: #1AC9FF;
}
.hand{cursor: pointer}

>>>.el-pager li{background-color: transparent !important;}
>>>.el-pagination .btn-next, >>>.el-pagination .btn-prev{
  background-color: transparent !important;
}
/*el-table样式------------------------------------start*/
.biao>>> .el-table,
.biao >>> .el-table__expanded-cell {
  background-color: transparent !important;
}

.biao >>> .el-table th,>>>.el-table tr {
  border: 0 !important;
  background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>>.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row>td{
  color: #F5FDFF;
  border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
  height: 0px;
}

/*el-table样式------------------------------------end*/

/*el-dialog样式------------------------------------start*/
.dialog >>>.el-dialog{background-color: transparent}
.dialog >>>.el-dialog__header{display: none}
.dialog >>>.el-dialog__wrapper .el-dialog .el-dialog__body{padding: 0;}
/*el-dialog样式------------------------------------end*/

</style>
<script>
import { parseTime } from '@/utils/date'
import {search,searchWord,addData} from "@/api/yaojian";
export default {
  name: "controlAdd",
  data(){
    return {
      form: {
        name: '',
        region: '',
        type: 1,
        way: 1,
        reason: '',
        date: [],
        days: 0,
        count: 0,
        catetory: 1
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '名称不能为空' }],
        reason: [{ required: true, trigger: 'blur', message: '原因不能为空' }],
        date: [{ required: true, trigger: 'blur', message: '日期不能为空' }],
        days: [{ required: true, trigger: 'blur', message: '周期不能为空' }]
        // count: [{ required: true, trigger: 'blur', message: '数量不能为空' }]
      },
      keyword: '',
      options: [],
      tableData: [],
      dynamicTags: [],
      tempTag: [],
      inputVisible: false,
      inputValue: '',
      restaurants: [],
      dialogTableVisible: false,
    }
  },
  created() {

    },
  methods:{
    handleOpen() {
      this.options = []
    },
    loadDrugData() {
      search({ keyword: '' }).then(res => {
        this.restaurants = res.data.map(x => x.name)
      })
    },
    handleBeforeUpload(file) {
      if (file.name.indexOf('.xlsx') === -1 && file.name.indexOf('.xls') === -1) {
        this.$message.warning('请上传excel文件')
        return false
      }
      this.$confirm('上传改文件会替换掉所有的药品名称, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dynamicTags = this.tempTag
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上传'
        })
        return false
      })
    },
    handleSuccess(response, file, fileList) {
      this.tempTag = response.data
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    remoteMethod(query) {
      search({ keyword: query }).then(res => {
        this.options = res.data
      })
    },
    querySearchAsync(queryString, cb) {
      if (queryString.length <= 1) {
        cb([])
      } else {
        searchWord({ keyword: queryString }).then(res => {
          const results = res.data
          cb(results)
        })
      }
    },
    handleSelect(item) {
      const inputValue = item.name
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleQuery() {
      search({ keyword: this.keyword }).then(res => {
        this.tableData = res.data
      })
    },
    handleChange(val) {
      const item = this.options.find(x => {
        return x.id === val
      })
      item.batch = []
      this.tableData.push(item)
    },
    handleRemove(id) {
      this.tableData = this.tableData.filter(x => x.id !== id)
    },
    handleBatch(row, index) {
      this.$prompt('请输入批次号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (value === '') {
          this.$message.warning('请输入批次号')
          return false
        }
        const param = row
        const arr = param.batch
        arr.push(value)
        param.batch = arr
        this.tableData.splice(index, 1, param)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    handleTagClose(row, index, tableIndex) {
      const param = row
      const arr = param.batch
      arr.splice(index, 1)
      param.batch = arr
      this.tableData.splice(tableIndex, 1, param)
    },
    handleSave() {
      this.$refs['form'].validate((valid) => {
        const param = this.form
        param.startTime = parseTime(this.form.date[0])
        param.endTime = parseTime(this.form.date[1])
        if (this.tableData.length === 0 && this.form.catetory === 2) {
          this.$message.warning('请选择需要操作的药品')
          return false
        }
        if (this.dynamicTags.length === 0 && this.form.catetory === 1) {
          this.$message.warning('请添加需要操作的药品')
          return false
        }
        if (this.form.catetory === 1) {
          param.names = JSON.stringify(this.dynamicTags)
        } else {
          param.ids = JSON.stringify(this.tableData)
        }
        addData(this.form).then(res => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.form = {
              name: '',
              region: '',
              type: 1,
              way: 1,
              reason: '',
              date: [],
              days: 0,
              count: 0,
              catetory: 1
            }
            this.close()
            this.$emit('update')
          }
        })
      })
    },
    close(){
      this.dialogTableVisible=false
    },
    open(){
      this.dialogTableVisible=true
    }
  }
}
</script>

<style scoped>

</style>
