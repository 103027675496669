<template>
  <div id="main" style="width: 1920px">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">找好药</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px;position: relative">
            <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px" @click="go('/cloudHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
            <div class="findleft1">
                   <div class="title">找药年龄分布</div>
              <div class="chart abs" style="bottom:30px">
                <div id="myChart" :style="{width: '600px', height: '200px'}"></div>
              </div>

            </div>
            <div class="findleft2" style="margin-top: 20px">
              <div class="title">找药人群结构分布</div>
              <div class="chart "  style="margin-top: 20px">
                <div id="myChart2" :style="{width: '600px', height: '300px'}"></div>
              </div>
              <div class="title" style="padding-left: 32px"><img  src="../assets/home/4.png"/> 找药人群区域分布</div>
              <div class="chart "  style="margin-top: 0px">
                <div id="myChartCake_1" :style="{width: '600px', height: '250px'}"></div>
              </div>
            </div>
          </div>
          <div style="width: 671px;margin-left: 18px" class="centerblock">
            <div style="position: relative">
              <div class="total">
                <div class="lan24" style="padding-left: 64px;padding-top: 23px">
                  <div>今日发布量</div>
                  <div style="margin-top: 37px">累计发布总量</div>
                </div>
              </div>
              <div class="totalnum">

                  <div class="nums">
                    <span class="num">5</span><span class="num">7</span><span class="num">3</span>
                  </div>
                  <div class="nums" style="margin-top: 30px">

                    <span class="num">2</span><span class="num">4</span><span class="num">5</span><span class="num">8</span><span class="num">7</span>
                  </div>
                </div>
            </div>
<div style="position: relative;height: 150px">
  <div class="crystalBox" style="left:25px;top:20px">
    <div id="chartCrystal" :style="{width: '262px', height: '150px'}"></div>
  </div>
<!--  <div class="crystalBox" style="left:45px;top:42px">-->
<!--    <div class="crystal" >-->
<!--      <div class="item">95%</div>-->
<!--    </div>-->
<!--    <div class="c lan16">找药成交率</div>-->
<!--  </div>-->

<!--  <div class="crystalBox" style="left:182px;top:42px">-->
<!--    <div class="crystal2" >-->
<!--      <div class="item">92%</div>-->
<!--    </div>-->
<!--    <div class="c lan16">药房参与率</div>-->
<!--  </div>-->
  <div class="chart abs"  style="left:250px">
    <div id="myChartCake_2" :style="{width: '401px', height: '210px'}"></div>
  </div>
  <div class="abs lan14" style="left:410px;top:180px;">费用结构分析</div>
</div>
<div class="keymap" style="margin-top: 70px">
  <div class="title">关键词因子图</div>
  <div class="chart abs" style="top:50px">
    <div class="chart " >
      <div id="myChartKey" :style="{width: '600px', height: '500px'}"></div>
    </div>
  </div>

</div>

          </div>
          <div style="width: 606px" class="rightblock">
            <div class="findright1">
              <div class="title">一周找药趋势</div>
              <div class="chart abs" style="top:50px">
                <div class="chart " >
                  <div id="myChart5" :style="{width: '600px', height: '250px'}"></div>
                </div>
              </div>
              <div class="abs lan14" style="left:86px;top:350px">今日好药精准推荐率</div>
              <div class="abs lan14" style="left:387px;top:350px">三级找药率分析</div>
              <div class="chart abs"  style="left:10px;top:350px">
                <div id="myChartCake_3" :style="{width: '301px', height: '150px'}"></div>
              </div>
              <div class="chart abs"  style="left:270px;top:350px">
                <div id="myChartCake_4" :style="{width: '351px', height: '150px'}"></div>
              </div>
            </div>
            <div class="findright2" style="margin-top: 20px">
              <div class="title">药品品种分析</div>
              <div class="chart abs" style="top:60px">
                <div id="myChart3" :style="{width: '600px', height: '230px'}"></div>
              </div>

            </div>
          </div>
        </div>

      </div>



  </div>
</template>
<style scoped>
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.hand{cursor: pointer}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.title{
  padding-top: 20px;
  padding-left: 55px;
font-size: 24px;
  line-height: 23px;
  color: #1AC9FF;
}
.findright1{
  position: relative;
  background-image: url("../assets/home/findright1.png");
  width: 601px;
  height: 539px;
}
.findright2{
  position: relative;
  background-image: url("../assets/home/findright2.png");
  width: 601px;
  height: 416px;
}
.keymap{
  position: relative;
  background-image: url("../assets/home/findcenter.png");
  width: 646px;
  height: 634px;
}
.findleft1{
  position: relative;
  background-image: url("../assets/home/findleft1.png");
  width: 594px;
  height: 325px;
}
.findleft2{
  position: relative;
  background-image: url("../assets/home/findleft2.png");
  width: 594px;
  height: 639px;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import 'echarts-wordcloud'
import axios from "axios";
import {mul} from "@/utils/jsj";
export default {
  name: 'zhuye',
  components: { BdMap},
  data(){
    return {
      keyword:"",
      region:330726,
      ypxsData:[],
      storeAge:[]
    }
  },
  created() {
    if(!this.$Cookies.get("AuthorizationToken")){
      //window.location.href='/#/login?url=/cloudHome'
    }
  },
  mounted() {
    this.getData()
    },
  methods:{
go(url){
  this.$router.push(url)
},
    drawChartCrystal(id,arrTitle,v=[0.5,0.8]){
      var value = v[0];
      var value2 = v[1];
      var data = [value, value, value];
      var data2 = [value2, value2, value2];
      var option = {
        title: [
          {
            text: arrTitle[0],
            x: '25%',
            y: 120,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: arrTitle[1],
            x: '75%',
            y: 120,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: (value * 100).toFixed(0) + '%',
            left: '25%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#a06a0a',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
          {
            text: (value2 * 100).toFixed(0) + '%',
            left: '75%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#02456d',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['25%', '50%'],
            // color:[ {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{
            //         offset: 1,
            //         color: 'rgba(2, 159, 200, 1)'
            //     }, {
            //         offset: 0.5,
            //         color: 'rgba(0, 186, 211, .5)'
            //     }, {
            //         offset: 0,
            //         color: 'rgba(0, 230, 220, 1)'
            //     }],
            //     globalCoord: false
            // }],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(251, 173, 23, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(251, 173, 23, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(251, 173, 23, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外黄边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#fdc56e'
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['75%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(0, 155, 219, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(0, 155, 219, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(0, 155, 219, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data2,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外蓝边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#07a2e3'

                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
        ],
      };

      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChartKey(){
      let myChart = echarts.init(document.getElementById('myChartKey'))
      const list = [
        {
          value: '50',
          name: '防疫',
          textStyle: {
            shadowBlur: 4,
            shadowColor: '#ECEFFF',
            shadowOffsetY: 14,
            color: '#73DDFF',
          },
        }, // 50
        { value: '30', name: '维生素C' },
        { value: '29', name: '恒生药房' },
        { value: '28', name: '兴奋剂药品' },
        { value: '27', name: '花桥乡' },
        { value: '26', name: '特殊药品' },
        { value: '25', name: '未找到' },
        { value: '30', name: '中医' },
        { value: '23', name: '利福昔明片' },
        { value: '22', name: '高血压' },
        { value: '21', name: '疫情管控' },
        { value: '20', name: '配送费' },
        { value: '16', name: '老年人' },
        { value: '15', name: '中药' },
        { value: '14', name: '慢病' },
        { value: '13', name: '医保' },
        { value: '12', name: '康柏家' },
      ]
     let option = {
        tooltip: {
          show: true,
          borderColor: '#fe9a8bb3',
          borderWidth: 1,
          padding: [10, 15, 10, 15],
          confine: true,
          backgroundColor: 'rgba(255, 255, 255, .9)',
          textStyle: {
            color: 'hotpink',
            lineHeight: 22
          },
          extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },
        series: [
          {
            type: 'wordCloud',
            // The shape of the "cloud" to draw. Can be any polar equation represented as a
            // callback function, or a keyword present. Available presents are circle (default),
            // cardioid (apple or heart shape curve, the most known polar equation), diamond (
            // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

            shape: 'pentagon',

            // A silhouette image which the white area will be excluded from drawing texts.
            // The shape option will continue to apply as the shape of the cloud to grow.

            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.

            left: 'center',
            top: 'center',
            width: '100%',
            height: '100%',
            right: null,
            bottom: null,

            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.

            sizeRange: [14, 50],

            // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

            rotationRange: [0, 0],
            rotationStep: 0,

            // size of the grid in pixels for marking the availability of the canvas
            // the larger the grid size, the bigger the gap between words.

            gridSize: 25,

            // set to true to allow word being draw partly outside of the canvas.
            // Allow word bigger than the size of the canvas to be drawn
            drawOutOfBound: false,

            // If perform layout animation.
            // NOTE disable it will lead to UI blocking when there is lots of words.
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'PingFangSC-Semibold',
              fontWeight: 600,
              color: function (params) {
                let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3', '#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3', '#fe9a8bb3', '#73DDFF', '#58D5FF']
                return colors[parseInt(Math.random() * 10)];
              },
            },
            emphasis: {
              focus: 'none',
            },

            // Data is an array. Each array item must have name and value property.
            data: list,
          },
        ],
      };

      myChart.setOption(option);
    },
    drawChart(){
      this.ypxsData[0]=['1-10岁', '11-20岁', '21-30岁', '31-40岁', '41-50岁', '50-60岁','60岁以上']
      this.ypxsData[1]=this.storeAge
      let myChart = echarts.init(document.getElementById('myChart'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 16
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              },
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart2(){
      let data=this.storeAge
      let data2=['青年', '老年', '幼儿', '青少年', '儿童', '中年']
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        grid: {
          top: '0%',
          left: '10%',
          bottom: '0%',
          right: '20%',
          containLabel: true,
        },
        title: {
          text: '',
        },
        //backgroundColor: 'rgb(24, 3, 98)',
        yAxis: {
          type: 'category',
          inverse: true,
          offset: 10,
          axisLabel: {
            show: true,
            interval: 'auto',
            color: 'rgb(255,255,255)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: data2,
        },
        xAxis: {
          show: false,
          type: 'value',
          offset: 30,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(106, 148, 255)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            inside: true,
            length: 10,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%',
          },
        },
        series: [
          {
            //下半截柱子
            name: '人群',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,
              formatter: '{c}%'
            },
            itemStyle: {
              //lenged文本
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0, 111, 255)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(29, 227, 255)', // 100% 处的颜色
                      },
                    ],
                    false
                );
              },
            },
            data: data,
          },
          {
            //数据圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [6, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(27, 140, 255)'
            },
            symbolPosition: 'end',
            data: data,

          },
          {
            //最左圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [-5, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(0, 111, 255)'
              //color: '#000'
            },
            symbolPosition: 'end',
            data: [1, 1, 1, 1, 1,1],
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      this.ypxsData[0]=["中成药","西药","处方药","OTC","进口药","中药饮片","其它"]
      this.ypxsData[1]=[25,63,3,97,5,12,20]
      let myChart = echarts.init(document.getElementById('myChart3'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 16
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              },
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart5(){
      let xLabel = ['03-14', '03-15', '03-16', '03-17', '03-18', '03-19','03-20']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '已发布',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '已找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },

        ]
      };
      let myChart = echarts.init(document.getElementById('myChart5'))
      myChart.setOption(option);
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    getData(){
  let that=this
      //年龄段销售数据
      axios.get('/drug/store/age?region='+this.region, {})
          .then(function (response) {
            let age=response.data.data
            that.storeAge[0]=mul(parseInt((age.young/age.total)*1000),0.1)
            that.storeAge[1]=mul(parseInt((age.elderly/age.total)*1000),0.1)
            that.storeAge[2]=mul(parseInt((age.infant/age.total)*1000),0.1)
            that.storeAge[3]=mul(parseInt((age.youngster/age.total)*1000),0.1)
            that.storeAge[4]=mul(parseInt((age.child/age.total)*1000),0.1)
            that.storeAge[5]=mul(parseInt((age.middleAged/age.total)*1000),0.1)
            that.drawChart();
            that.drawChart2();
          })

      let arr=["药房参与","找药成交率"]
      this.drawChartCrystal('chartCrystal',arr,[0.1,0.8]);
      this.drawChart();
      this.drawChartKey();
      this.drawChart2();
      this.drawChart3();
      this.drawChart5();
      let arrCake=[
        {
          name: '城市人口',
          value: 20,
        },
        {
          name: '农村人口',
          value: 10,
        },
        {
          name: '外来人口',
          value: 30,
        },
      ]
      this.drawChartCake("myChartCake_1",arrCake);
      let arrCake2=[
        {
          name: '自费',
          value: 20,
        },
        {
          name: '医保',
          value: 10,
        },
      ]
      this.drawChartCake("myChartCake_2",arrCake2);
      let arrCake3=[
        {
          name: '药品',
          value: 20,
        },
        {
          name: '药房',
          value: 10,
        },
      ]
      this.drawChartCake("myChartCake_3",arrCake3);
      let arrCake4=[
        {
          name: '一级找药',
          value: 20,
        },
        {
          name: '二级找药',
          value: 10,
        },
        {
          name: '三级找药',
          value: 30,
        },
      ]
      this.drawChartCake("myChartCake_4",arrCake4);

          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
