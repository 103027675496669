<template>
  <div id="main" style="width: 1920px;overflow: hidden">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">智慧管</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px" class="blockLeft">
            <div style="position: relative">
              <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px;cursor: pointer" @click="go('/smartHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
            </div>
            <div class="witleft1" >
              <div class="titleBox">
                <div class="title hand" @click="go('/smartRisk')">日常分析预警</div>
              </div>
              <div class="jryj_content" style="height: 353px">
                <div class="chart abs" style="top:50px;left:50px">
                  <div class="yuan abs" style="border:1px solid #F4F4F4;background-color: rgba(0,0,255,0);width: 46px;height:46px;left:243px;top:51px"></div>
                                  <div @click="$refs.childDialog.openMessage()" id="myChart_rcfx" :style="{width: '650px', height: '150px'}"></div>
                </div>
                <div class="ctitle">
                  <div class="abs" style="top:220px;left:74px">群众举报</div>
                  <div class="abs" style="top:220px;left:260px">抽查发现</div>
                  <div class="abs" style="top:220px;left:451px">上级交办</div>
                </div>

                <div class="chart abs" style="top:200px">
                  <div id="myChart_qzjb" :style="{width: '200px', height: '200px'}"></div>
                </div>
                <div class="chart abs" style="top:200px;left:185px">
                  <div id="myChart_ccfx" :style="{width: '200px', height: '200px'}"></div>
                </div>
                <div class="chart abs" style="top:200px;left:373px">
                  <div id="myChart_sjjb" :style="{width: '200px', height: '200px'}"></div>
                </div>
              </div>
            <div>
              <div class="titleBox">
                <div class="title">风险处置</div>
              </div>
              <div class="fxcz_content" style="height:250px;position: relative">

                <div class="chart abs" style="top:20px;left:-50px">
                  <div id="myChart_jrzl" :style="{width: '300px', height: '100px'}"></div>
                </div>
                <div class="chart abs" style="top:20px;left:115px">
                  <div id="myChart_jrcz" :style="{width: '350px', height: '100px'}"></div>
                </div>
                <div class="chart abs" style="top:20px;left:353px">
                  <div id="myChart_czfx" :style="{width: '300px', height: '100px'}"></div>
                </div>
                <div class="ctitle">
                  <div class="abs" style="top:180px;left:74px">今日指令</div>
                  <div class="abs" style="top:180px;left:260px">今日处置</div>
                  <div class="abs" style="top:180px;left:451px">处置分析</div>
                </div>
              </div>
            </div>
              <div style="padding-left: 20px;position: relative">
                <div class="lan16">处置督办数据分析</div>
                <div class="chart abs" style="top:20px;left:3px">
                  <div id="myChart_2" :style="{width: '600px', height: '260px'}"></div>
                </div>
              </div>

            </div>


          </div>
<div class="blockCenter" style="padding-left: 18px">
  <div style="position: relative;height: 59px;overflow: hidden">
    <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px;cursor: pointer" @click="go('/cloudHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
    <div class="total">
      <div class="lan24" style="padding-left: 64px;padding-top: 23px">
        <div>今日预警数量</div>
      </div>
    </div>
    <div class="totalnum">
      <div class="nums">
        <span class="num" v-for="(x,i) in yjsToday" :key="i">{{x}}</span>
      </div>
    </div>
  </div>
  <div style="height: 557px;margin-top: 10px">
    <baidumap :height="537"/>
  </div>
  <div class="blockYj">
    <div class="titleBox">
      <div style="width: 80px;float: right;padding-top: 10px" class="bai14 hand" @click="open('http://www.yaojian.xusos.com/#/title/more')">详情</div>
      <div class="title">预警信息</div>
    </div>
    <div  class="zdyj" style="margin-top: 10px">
      <table width="100%" cellspacing="0" cellpadding="0">
        <tr style="background-color:#001B3A">
          <td style="width:30px">序号</td>
          <td style="width:330px">企业名称</td>
          <td style="width:141px">预警时间</td>
          <td style="width:141px">预警类型</td>
        </tr>
      </table>
      <div style="height:243px;overflow: hidden;">
        <vue-seamless-scroll :data="listData" :class-option="defaultOption" >
          <table width="100%" cellspacing="0" cellpadding="0" class="table">
            <tr v-for="(x,i) in listData" :key="x.id">
              <td style="width: 30px">{{i+1}}</td>
              <td style="width: 330px;" ><div >{{x.storeName}}</div></td>
              <td style="width: 141px">{{x.createDate}}</td>
              <td style="width: 141px">{{["","无执业药师","销售预警","特殊药品","处方预警","药品禁售","跨店销售","超售预警","温湿度超标","温湿度离线"][x.type]}}</td>
            </tr>

          </table>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</div>
          <div class="blockRight" style="padding-left: 18px">
            <div class="blockRight1">
              <div class="titleBox">
                <div class="title hand" @click="go('/smartDynamic')">进销存动态分析</div>
              </div>
<div style="height: 150px"></div>
              <div class="chart abs" style="top:49px;left:20px">
                <div class="yuan abs" style="border:1px solid #F4F4F4;background-color: rgba(0,0,255,0);width: 46px;height:46px;left:243px;top:51px"></div>
                <div @click="$refs.childDialog.openJxc()" id="myChart_jxcdtfx" :style="{width: '650px', height: '150px'}"></div>
              </div>

              <div class="titleBox">
                <div class="title">异常预警趋势分析</div>
              </div>
              <div style="position: relative">

                <div class="chart abs" style="top:6px">
                  <div id="myChart_czsjqs" :style="{width: '600px', height: '250px'}"></div>
                </div>
                <div class="chart abs" style="top:355px">
                  <div id="myChart_jrcz2" :style="{width: '230px', height: '200px'}"></div>
                </div>
                <div class="chart abs" style="top:300px;left:281px">
                  <div id="myChart_czfx2" :style="{width: '300px', height: '200px'}"></div>
                </div>
                <div class="ctitle">
                  <div class="abs" style="top:300px;left:76px">今日处置</div>
                  <div class="abs" style="top:300px;left:426px">处置分析</div>
                </div>

              </div>
            </div>
            <div class="blockRight3" style="position: relative">
              <div class="bai14 abs" style="top:17px;left:467px"><span style="cursor: pointer" @click="$refs.childDialog.openControl()">一键锁定</span></div>
              <div class="titleBox">
                <div class="title">药品管控情况</div>
              </div>
<div>
  <div id="myChart_ydgk" :style="{width: '600px', height: '180px'}"></div>
</div>
            </div>
          </div>
        </div>

      </div>

    <chuan ref="childDialog"/>

  </div>
</template>

<style scoped>
.table tr td{ text-align: center;font-size: 14px}
.yuan{
  /* length为正方形边长的的一般 */
  border-radius: 50%; }
.overflow{

  width:220px;

  overflow:hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  -o-text-overflow:ellipsis;

}
.ctitle{color: white;font-size: 16px}
.zdyj table{font-size: 12px}
.zdyj tr td{text-align: center;color: white;padding: 5px;line-height: 150%}
.hand{cursor: pointer}
/*滚动条*/
/*.stockAlert::-webkit-scrollbar{*/
/*  width: 10px;*/
/*  height: 1px;*/
/*}*/
/*.stockAlert::-webkit-scrollbar-thumb{*/
/*  border-radius: 10px;*/
/*  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0);*/
/*  background: #13c2f8;*/
/*}*/
/*.stockAlert::-webkit-scrollbar-track{*/
/*  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0);*/
/*  border-radius: 10px*/
/*;*/
/*  background: #010324;*/
/*}*/

.lue{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.parent {display: table;height: 30px; padding-top: 10px }
.controlAlert{
  vertical-align: middle;
  display: table-cell;
  width: 94px;
  height: 24px;
  line-height: 24px;
  background: rgba(209,255,231, 0.3);

    border-radius: 12px;
  color: #00D898;
}
.stockAlertHeader{font-size: 14px}
.stockAlertRow{line-height: 300%;font-size: 16px}
.stockAlert{color: white;padding-left: 20px;padding-right: 20px;text-align: center;
  margin-top: 30px}
.line{width: 1px;
  height: 84px;
  background: #283FEA;
  opacity: 0.5; }
.dynamic{}
.dynamic .item{text-align: center;width: 220px}
.dynamic .item1{padding-top: 20px}
.dynamic .item2{margin-top: 15px}
.witZong{line-height: 150%;margin-top: 10px}
.zong{color: white;font-size: 14px;opacity: 0.8;}
.blockRight3{
  position: relative;
  background-image: url("../assets/smart/wit/right3.png");
  width: 601px;
  height: 218px;
  margin-top: 16px;
}
/*.blockRight2{*/
/*  position: relative;*/
/*  background-image: url("../assets/smart/wit/right2.png");*/
/*  width: 601px;*/
/*  height: 520px;*/
/*  margin-top: 16px;*/
/*}*/
.blockRight1{
  position: relative;
  background-image: url("../assets/smart/wit/right1.png");
  width: 601px;
  height: 753px;
}
.blockYj{
  position: relative;
  background-image: url("../assets/smart/wit/center.png");
  width: 646px;
  height: 368px;
}
.blockCenter{
 width: 646px;
}

.witleft1{
  position: relative;
  background-image: url("../assets/smart/wit/left.png");
  background-size: 100% 100%;
  width: 602px;
  height: 994px;
}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.lan20{color: #5BFCF4;
  font-size: 20px}
.total{
  background-image: url("../assets/smart/home/num.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  left:262px

}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.bai12{
  color: white;
  font-size: 14px;
}
.lan12{
  color: #1AC9FF;
  font-size: 12px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import axios from "axios";
import {mul} from "@/utils/jsj";
import baidumap from "@/views/components/baidumap";
import vueSeamlessScroll from 'vue-seamless-scroll'
import moment from 'moment'
import chuan from "./components/chuan"
export default {
  name: 'zhuye',
  components: {baidumap,vueSeamlessScroll,chuan},
  data(){
    return {
      jxc:{},
      risk:{},
      riskTotal:0,
      yjsToday:[],
      listData:[],
      keyword:"",
      region:330726,
      ypxsData:[],
      stock:0,
      saleToday:0,
      businessLicense:{},
      pharmacistLicense:{},
      health:{},
      drugCategory:[],
      drugRate:[],
      drugRateStock:[],
      message:[],
      smartType:{},
      wsdData:{},
      stockWarningData: [
        {
          index: 1,
          type: '抗感染类',
          count: 15,
          region: '浦阳街道',
          result: '缺货',
          num: '10%',
          status: '提醒控制'
        },
        {
          index: 2,
          type: '呼吸道类',
          count: 65,
          region: '全域',
          result: '缺货',
          num: '15%',
          status: '提醒控制'
        },
        {
          index: 3,
          type: '消化系统类',
          count: 53,
          region: '全域',
          result: '溢货',
          num: '5.8%',
          status: '提醒控制'
        },
        {
          index: 4,
          type: '心血管类',
          count: 20,
          region: '浦阳',
          result: '溢货',
          num: '7.9%',
          status: '提醒控制'
        },
        {
          index: 5,
          type: '肝胆类',
          count: 5,
          region: '黄宅',
          result: '缺货',
          num: '8.7%',
          status: '提醒控制'
        }
      ]
    }
  },
  created() {

  },
  mounted() {
    this.getData()
    },
  computed: {
    defaultOption () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }

  },
  methods:{
    open(url){
      window.location.href=url
    },
    go(url){
      this.$router.push(url)
    },
    draw_rcfx(){//日常分析预警分析
      let myChart = echarts.init(document.getElementById('myChart_rcfx'))
     let option = {
        tooltip: {
          trigger: "item",
          formatter: "数据统计 <br/>{b} : {c} ({d}%)"
        },
        legend: {
          show:false,
          right: 40,
          top: 40,
          itemGap: 20,
          itemWidth: 14,
          orient: "vertical",
          textStyle: { fontSize: 14, color: "#333333" }
        },
        title: {
          show:true,
          text: this.riskTotal,
          top: "42%",
          left: "40%",
          subtext: "",
          itemGap: 5,
          textStyle: {
            color: "#45a0e8",
            fontSize: 24
          },
          subtextStyle: {
            color: "#61bbff",
            fontSize: 20
          },
          textAlign: "center"
        },
        color: [
          "#5dcafd",
          "#73e363",
          "#05eca7",
          "#fdbd2c",
          "#f27b72",
          "#7464fb",
          "#4091fe",
          "#9a60b4",
          "#ea7ccc"
        ],
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: ["30%", "70%"],
            center: ["41%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 0
            },
            data: [
                      {
                value: this.risk.crossStoreSaleSize,
                name: "跨店销售特殊药品",
                label: {
                  color: "#73e363"
                },
                color: "#73e363"
              },
              {
                value: this.risk.crossStorePurchaseSize,
                name: "跨店购买特殊药品",
                label: {
                  color: "#05eca7"
                },
                color: "#05eca7"
              },
              {
                value: this.risk.saleEpidemicSize,
                name: "违规销售疫情药品",
                label: {
                  color: "#fdbd2c"
                },
                color: "#fdbd2c"
              },
              {
                value: this.risk.visitorSize,
                name: "访客其它异常",
                label: {
                  color: "#f27b72"
                },
                color: "#f27b72"
              },
              {
                value: this.risk.personSize,
                name: "药店从业人员异常",
                label: {
                  color: "#7464fb"
                },
                color: "#7464fb"
              },
              {
                value: this.risk.prescriptionSize,
                name: "未凭处方销售处方药",
                label: {
                  color: "#4091fe"
                },
                color: "#4091fe"
              }
              ,
              {
                value: 0,
                name: "执业药师未审方销售处方药",
                label: {
                  color: "#4091fe"
                },
                color: "#4091fe"
              }
              ,
              {
                value: this.risk.thSize,
                name: "温湿度超标",
                label: {
                  color: "#4091fe"
                },
                color: "#4091fe"
              }
            ],
            label: {
              position: 'outside',
              formatter: function(params) {
                return `{normal|${params.name}：}\r\r\n{value|${params.data.value} (${params.percent})%}`
              },
              rich: {
                normal: {
                  //   color: "#8A8A8A",
                  fontSize: 12,
                  align: "left"
                },
                value: {
                  fontSize: 10,
                  //   color: "#8A8A8A",
                  fontWeight: 600,
                  padding: [5, 0, 0, 0]
                }
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    draw_jxcdtfx(){//进销存动态分析预警
      let myChart = echarts.init(document.getElementById('myChart_jxcdtfx'))
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "数据统计 <br/>{b} : {c} ({d}%)"
        },
        legend: {
          show:false,
          right: 40,
          top: 40,
          itemGap: 20,
          itemWidth: 14,
          orient: "vertical",
          textStyle: { fontSize: 14, color: "#333333" }
        },
        title: {
          show:true,
          text: 0,
          top: "42%",
          left: "40%",
          subtext: "",
          itemGap: 5,
          textStyle: {
            color: "#45a0e8",
            fontSize: 24
          },
          subtextStyle: {
            color: "#61bbff",
            fontSize: 20
          },
          textAlign: "center"
        },
        color: [
          "#5dcafd",
          "#73e363",
          "#05eca7",
          "#fdbd2c",
          "#f27b72",
          "#7464fb",
          "#4091fe",
          "#9a60b4",
          "#ea7ccc"
        ],
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            radius: ["30%", "70%"],
            center: ["41%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 0
            },
            data: [
              {
                value: 0,
                name: "盗刷医保嫌疑",
                label: {
                  color: "#5dcafd",
                }
              },
              {
                value: 0,
                name: "药品近效期超临界",
                label: {
                  color: "#73e363"
                },
                color: "#73e363"
              },
              {
                value: 0,
                name: "药品过期率超临界",
                label: {
                  color: "#05eca7"
                },
                color: "#05eca7"
              },
              {
                value: 0,
                name: "药品滞销超临界",
                label: {
                  color: "#fdbd2c"
                },
                color: "#fdbd2c"
              },
              {
                value: 0,
                name: "进口药品购进数据异常",
                label: {
                  color: "#f27b72"
                },
                color: "#f27b72"
              },
              {
                value: 0,
                name: "销售数据异常",
                label: {
                  color: "#7464fb"
                },
                color: "#7464fb"
              },
              {
                value: 0,
                name: "库存数据异常",
                label: {
                  color: "#4091fe"
                },
                color: "#4091fe"
              }
              ,
              {
                value: 0,
                name: "全县药品储备异常",
                label: {
                  color: "#4091fe"
                },
                color: "#4091fe"
              }

            ],
            label: {
              position: 'outside',
              formatter: function(params) {
                return `{normal|${params.name}：}\r\r\n{value|${params.data.value} (${params.percent})%}`
              },
              rich: {
                normal: {
                  //   color: "#8A8A8A",
                  fontSize: 12,
                  align: "left"
                },
                value: {
                  fontSize: 10,
                  //   color: "#8A8A8A",
                  fontWeight: 600,
                  padding: [5, 0, 0, 0]
                }
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    drawChart_ydgk(){
      let myChart = echarts.init(document.getElementById('myChart_ydgk'))
      var datas = [
        {
          "value": this.smartType.epidemic,
          "name": "疫情药品"
        },
        {
          "value": this.smartType.special,
          "name": "特殊药品"
        },
        {
          "value": this.smartType.failed,
          "name": "不合格药品"
        },
        {
          "value": this.smartType.cordial,
          "name": "兴奋剂类药品"
        },
      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let option = {
        backgroundColor: "",
        legend: {
          show:false,
          borderColor:'#d73622',
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 10,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          },
        },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [
            {
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
            color:"#fff"
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          label: {
            color:"#ffffff",
            show: true,
            position: 'right',
            distance: 10,

          },
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChart_qzjb(id,title,arr){//群众举报

      let data = arr
    let  option = {
        backgroundColor:"",
        color: ["#A96FFF", "#FFDE4C", "#23C3FF", "#7BE09A"],
        grid: {
          left: -100,
          top: 50,
          bottom: 10,
          right: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: "{b} : {c} ({d}%)"
        },
        title: {
          show:true,
          //text: '{a|'+ '0' +'}{c|'+ '' +'}',
          text: '{a|'+ '0' +'}',
          top:'center',
         left:'center',
          textStyle: {
            rich:{
              a: {
                fontSize: 26,
                color: '#23C3FF'
              },
              c: {
                fontSize: 16,
                color: '#EEEEEE',
                padding: [5,5]
              }
            }
          }
        },
        calculable: true,
        series: [{
          stack: 'a',
          type: 'pie',
          radius: ['20%', '40%'],
          roseType: 'area',
          zlevel:10,
          label: {
            show: true,
            position: 'outside',
            formatter: function(params, ticket, callback) {
              return '{value|' + params.value +'%'+ '}\n{name|' + params.name + '}';
            },
            padding: [0, 0],
            rich: {
              value: {
                color:"#fff",
                fontSize: 10,
                padding: [5, 0],
                align: 'left'
              },
              name: {
                color: '#eeeeee',
                align: 'left',
                fontSize: 10,
                padding: [5, 0],
              },
            }
          },
          labelLine: {
            normal: {
              show: true,
              length: 10,
              length2: 15,
              lineStyle:{
                width:1
              }
            },
            emphasis: {
              show: false
            }
          },
          data: data
        }, ]
      }
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_czsjqs(){//处置数据趋势分析
      let xLabel = ['1月', '2月', '3月', '4月', '5月']
      let data = ["50", "60", "22", "75", "50", "40",'33']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          show:false,
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '预警趋势',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },


        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_czsjqs'))
      myChart.setOption(option);
    },
    drawChart_2(){//处置督办数据分析
      let xLabel = ['1月', '2月', '3月', '4月', '5月']
      let data = ["50", "70", "60", "90", "80"]
      let data2 = ["40", "50", "12", "55", "30", "60",'76']
      let data3 = ["30", "40", "32", "45", "30", "60",'76']
      let data4 = ["60", "70", "32", "85", "60", "50",'46']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '自主整改完成',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '处置完成',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '未完成',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },

        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_2'))
      myChart.setOption(option);
    },
    drawChart_sjjb(){//上级交办
      var color = ['#02CDFF', '#62FBE7', '#7930FF','#E148EB']

     let option = {
        color: color,
        title: {
          show:true,
          text: '0',
          left: 'center',
          top: '44%',
          textStyle: {
            fontSize: 22,
            color: '#23C3FF',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          right: 20,
          top: '50%',
          textStyle: {
            fontSize: 14,
            color: '#333',
            fontWeight: 'normal'
          }
        },
        series: [{
          type: 'pie',
          roseType: 'radius',
          radius: ['20%', '40%'],
          data: [{
            value: 0,
            name: '不合格禁售'
          }
          ],
          label: {
            normal: {
              formatter: '{font|{c}}',
              rich: {
                font: {
                  fontSize: 10,
                  padding: [5, 0],
                  color: '#fff'
                },
                hr: {
                  height: 0,
                  borderWidth: 1,
                  width: '100%',
                  borderColor: '#fff'
                }
              }
            },
          },
          labelLine: {
            normal: {
              show: true,
              length: 10,
              length2: 15,
              lineStyle:{
                width:1
              }
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0,0,0,0.5)'
            }
          }
        }]
      };
      let myChart = echarts.init(document.getElementById('myChart_sjjb'))
      myChart.setOption(option);
    },
draw_jrzl(id,arr){
  var sData = arr;
  var legendData1 = [];
  var legendData2 = [];
  for (var i = 0; i < sData.length; i++) {
    var halfLength = Math.ceil(sData.length / 2);
    var itemName = sData[i].name;
    if (i < halfLength) {
      legendData1.push(itemName)
    } else {
      legendData2.push(itemName)
    }
  }
  var colorList = ['#0066FF', '#2E2EE6', '#4400CC', '#00AACC', '#9BBF30', '#E60000', '#92368D', '#BF9926'];
  let option = {
    backgroundColor: "",
    title: {
      show:false,
      text: '完成率占比',
      x: '55%',
      y: 'center',
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'item',
      borderColor: 'rgba(255,255,255,.3)',
      backgroundColor: 'rgba(13,5,30,.6)',
      borderWidth: 1,
      padding: 5,
      formatter: function(parms) {
        var str = parms.marker + "" + parms.data.name + "</br>" +
            "占比：" + parms.percent + "%";
        return str;
      }
    },
    series: [
        {
      type: 'pie',
      z: 3,
      center: ['60%', '50%'],
      radius: ['20%', '35%'],
      clockwise: true,
      avoidLabelOverlap: true,
      itemStyle: {
        normal: {
          color: function(params) {
            return colorList[params.dataIndex]
          }
        }
      },
      label: {
        show: true,
        position: 'outside',
        formatter: '{b}：\n{d}%',
    color:"#fff"
      },
      labelLine: {
        normal: {
          length: 10,
          length2: 10,
          lineStyle: {
            width: 1
          }
        }
      },
      data: sData
    },
      {
      name: '第一层环',
      type: 'pie',
      z: 2,
      tooltip: {
        show: false
      },
      center: ['60%', '50%'],
      radius: ['45%', '58%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          shadowBlur: 1,
          shadowColor: 'rgba(53,139,255,.3)',
          color: 'rgba(1,15,80,.95)',
        },
        emphasis: {
          color: 'rgba(1,15,80,.95)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    },
      {
      name: '第二层环',
      type: 'pie',
      z: 1,
      tooltip: {
        show: false
      },
      center: ['60%', '50%'],
      radius: ['58%', '76%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          shadowBlur: 1,
          shadowColor: 'rgba(53,139,255,.3)',
          color: 'rgba(0,15,69,.95)',
        },
        emphasis: {
          color: 'rgba(0,15,69,.95)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    }]
  };
  let myChart = echarts.init(document.getElementById(id))
  myChart.setOption(option);
},
   draw_czfx(){//处置分析
      let    data=[
        {value:83, name:'自主完成'},
        {value:17, name:'部门督办'},
        {value:0, name:'联动处置'},
        {value:0, name:'未处置'}
      ]
     var colorList =['#228c38', '#1a57b2', '#b04b07', '#af8108'];
     var colorListSub =['rgba(35,143,56,.5)', 'rgba(26,87,178,.5)', 'rgba(176,75,7,.5)', 'rgba(175,129,8,.5)'];
    let option = {
       backgroundColor: '',
       tooltip: {
         trigger: 'item',
         formatter: "{b}: {c}度 ({d}%)"
       },
       legend: {
         show:false,
         orient: 'vertical',
         textStyle:{
           color:'#fff'
         },
         x: 'right',
         data:data
       },
       series: [
         {
           type:'pie',
           radius: ['10%', '55%'],
           roseType: 'radius',
           clockwise :false,
           z:10,
           itemStyle: {
             normal: {
               color: function(params) {
                 // build a color map as your need.
                 return colorList[params.dataIndex]
               },
               shadowBlur: 20,
               shadowColor: 'rgba(0, 0, 0, 0.3)'
             }
           },
           label: {
             show: true,
             position: 'outside',
             formatter: '{b}\n{d}%',
             color:"#fff",
             fontsize:20
           },
           labelLine: {
             normal: {
               length: 10,
               length2: 10,
               lineStyle:{
                 width:1
               }
             }
           },
           data:data
         },

         // 中心的圆圈
         {
           radius: ['60%', '60.3%'],
           type: 'pie',
           label: {
             normal: {
               show: false
             },
             emphasis: {
               show: false
             }
           },
           labelLine: {
             normal: {
               show: false
             },
             emphasis: {
               show: false
             }
           },
           tooltip: {
             show: false
           },
           data: [{
             value: 100,
             name: '',
             itemStyle: {
               normal: {
                 color: '#5A96FF',
               }
             }
           }],
           animation: false
         }
       ]
     };
     let myChart = echarts.init(document.getElementById("myChart_czfx"))
     myChart.setOption(option);
   },
    drawChart_jrcz2(){//今日处置右
      let color = ["rgba(91, 143, 249, 0.85)","rgba(90, 216, 166, 0.85)"];

// 圆环图各环节的名称和值(系列中各数据项的名称和值)
      let data = [{
        name: '完成',
        value: 390,
        per: 390
      }, {
        name: '未完成',
        value: 815,
        per: 815
      }];
// 指定图表的配置项和数据
      let  option = {
        legend: [{
          orient: 'vertical',
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '15%',
          right: '0%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        }],
        backgroundColor : '',
        title: [{
          text: '',
          bottom: '2%',
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        }],
        //  center: ['60%', '50%'],
        // 系列列表
        series: [
          {
            name: '内圆和外圆', // 系列名称
            type: 'pie',
            center: ['50%', '30%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
            radius: ['20%', '55%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
            hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
            color: color, // 圆环图的颜色
            label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
              normal: {
                show: true, // 是否显示标签[ default: false ]
                fontSize: 12,
                position: 'inside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: (b) => {
                  return b.data.per + ''; // 标签内容
                }
              }
            },
            labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
              normal: {
                show: true, // 是否显示视觉引导线。
                length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
                length2: 10 // 视觉引导项第二段的长度。
              }
            },
            data: data // 系列中的数据内容数组。
          }]
      };
      let myChart = echarts.init(document.getElementById('myChart_jrcz2'))
      myChart.setOption(option);
    },
    draw_czfx2(){
      const center = ['80%', '50%'];
      const color = [
        '#09aabe',
        '#86c200',
        '#ffbb00',
        '#ff6f00',
        '#e31404'
      ];
      let data = [{
        name: '通知提醒',
        value: 1542
      },
        {
          name: '通知整改',
          value: 1765
        },
        {
          name: '下发检查',
          value: 1988
        },
        {
          name: '联动分析',
          value: 2160
        }
      ];
      const dataArcStyle = {
        label: {
          color:"#fff",
          show: true
        },
        labelLine: {
          show: true,
          length: 0,
          length2: 100
        },
        emphasis: {
          labelLine: {
            show: true
          }
        }
      };
      data = data.map(d => {
        d = Object.assign(d, dataArcStyle);
        return d;
      });
      const originDataLen = data.length;
      const spanAngle = 90;
      const repeatedMultiple = 180 / spanAngle;
      const addDataLen = parseInt((repeatedMultiple - 1) * originDataLen);
      for (let index = 0; index < addDataLen; index++) {
        data.push({
          name: null,
          value: 0,
          itemStyle: {
            color: 'rgba(0,0,0,0)',
          },
          tooltip: {
            show: false
          }
        });
      }
      const dataArc = {
        type: 'pie',
        roseType: 'area',
        clockwise: false,
        center,
        radius: ['30%', '70%'],
        data,
        label: {
          show: false
        }
      };
      const backgroundArc0 = {
        type: 'pie',
        radius: ['0%', '0%'],
        center,
        silent: true,
        clockwise: false,
        label: {
          show: false
        },
        data: [{
          name: null,
          value: spanAngle,
          itemStyle: {
            color: 'rgba(220,220,220,0)',
            borderColor:'rgba(255,255,255,.3)',
          }
        },
          {
            name: null,
            value: 360 - spanAngle,
            itemStyle: {
              color: 'rgba(220,220,220,0)',
            }
          }
        ]
      };
      const backgroundArc1 = JSON.parse(JSON.stringify(backgroundArc0));
     let option = {
        backgroundColor: '',
        color,
        tooltip: {
          show: true
        },
        series: [backgroundArc0, backgroundArc1, dataArc]
      };
      let myChart = echarts.init(document.getElementById('myChart_czfx2'))
      myChart.setOption(option);
    },
    getData(){
      this.drawChart_jrcz2()//今日处置2右
      this.draw_czfx2()//处置分析2右
      //this.draw_jxcdtfx()//进销存动态分析预警

     this.drawChart_2()//处置督办数据分析
     this.drawChart_czsjqs()//处置数据趋势分析
     this.draw_czfx()//处置分析
      let arr=[
        {name:"服务",value:12},
        {name:"价格",value:57},
        {name:"质量",value:89},
      ]
      this.drawChart_qzjb("myChart_qzjb","群众举报",arr)
      arr=[
        {name:"日常检查",value:12},
        {name:"有因检查",value:57},
        {name:"专项检查",value:89},
        {name:"飞行检查",value:89},
      ]
      this.drawChart_qzjb("myChart_ccfx","抽查发现",arr)
      this.drawChart_sjjb()//上级交办
      arr=[{
        name: "前日指令",
        value: 0
      }, {
        name: "今日新增",
        value: 89
      }]
      this.draw_jrzl("myChart_jrzl",arr)//今日指令
      arr=[{
        name: "未完成",
        value: 0
      }, {
        name: "今日完成",
        value: 75
      }]
      this.draw_jrzl("myChart_jrcz",arr)//今日处置

      let that=this
      //获取进销存
      let beforeDay = moment().add(-1,'day').format('YYYY-MM-DD');
      let time1=beforeDay+" 00:00:00"
      let time2=beforeDay+" 23:59:59"
      axios.get('/screen/lot/num?&region='+this.region, {params:{startTime:time1,endTime:time2}})
          .then(function (response) {
            that.jxc=response.data.data
           that.draw_jxcdtfx()
          })
      //疫情管控药品
      axios.get('/drug/store/smart/tube/type?&region='+this.region, {})
          .then(function (response) {
            that.smartType=response.data.data
            that.drawChart_ydgk()//药品管控情况
          })
      //日常分析预警
      axios.get('/screen/v3/day/rusk', {})
          .then(function (response) {
            let data=response.data
            that.risk=data.data
            for(let o in that.risk){
              that.riskTotal+=that.risk[o]
            }
            let numToday=that.riskTotal
            that.yjsToday=numToday.toString().split("")
            that.draw_rcfx()
          })
      //今日预警数
      axios.get('/screen/v3/message/size', {params:{today:1}})
          .then(function (response) {
            let data=response.data
            let num=data.data
           // that.yjsToday=num.toString().split("")
          })
      //预警信息列表
      axios.get('/screen/v3/violation/message/list', {})
          .then(function (response) {
            let data=response.data
            that.listData=data.data
          }),
      //温湿度监控------start
      axios.get('/screen/device/status?region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.wsdData=obj
            let arrCake=[
              {
                name: '正常',
                value: that.wsdData.normal,
              },
              {
                name: '超标',
                value: that.wsdData.excerpt,
              },
              {
                name: '故障',
                value: that.wsdData.fault,
              },
              {
                name: '报停',
                value: that.wsdData.stop,
              },
            ]

          })
      //温湿度监控------end
      //药品种类占比
      axios.get('/drug/store/drug/rate?region='+this.region, {})
          .then(function (response) {
            let drugRate=response.data.data
            that.drugRate[0]=mul(parseInt((drugRate.prescriptionCount/drugRate.totalCount)*1000),0.001)
            that.drugRate[1]=mul(parseInt((drugRate.validateCount/drugRate.totalCount)*1000),0.1)
            that.drugRate[2]=mul(parseInt((drugRate.entranceCount/drugRate.totalCount)*1000),0.1)
            that.drugRate[3]=0
            that.drugRate[4]=0

            that.drugRateStock[0]=mul(parseInt((drugRate.prescriptionStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[1]=mul(parseInt((drugRate.validateStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[2]=mul(parseInt((drugRate.entranceStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[3]=mul(parseInt((drugRate.expiredStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[4]=mul(parseInt((drugRate.expiredStock/drugRate.totalStock)*1000),0.1)
            let arr=["处方药占比","过期药占比","近效期药占比","滞销药占比"]




          })
      //获取库存药品数量

      axios.get('/drug/store/stock/count?region='+this.region, {})
          .then(function (response) {
            that.stock=response.data.data.total
            that.stock=parseInt(that.stock)
            that.stock=that.stock.toString().split("")
          })
      //获取今日销售总量
      axios.get('/drug/store/today/sale/number?region='+this.region, {})
          .then(function (response) {
            that.saleToday=response.data.data.total
            that.saleToday=parseInt(that.saleToday)
            that.saleToday=that.saleToday.toString().split("")
          })

      //经营许可证统计数量
      axios.get('/drug/store/business/license?region='+this.region, {})
          .then(function (response) {
            that.businessLicense=response.data.data
            that.businessLicense.bai=mul(parseInt((that.businessLicense.normal/that.businessLicense.all)*100),0.01)



          })
      //执业药师注册证 量
      axios.get('/drug/store/pharmacist/license?region='+this.region, {})
          .then(function (response) {
            that.pharmacistLicense=response.data.data
            that.pharmacistLicense.bai=mul(parseInt((that.pharmacistLicense.normal/that.pharmacistLicense.all)*100),0.01)

          })
      //健康证
      axios.get('/drug/store/health/data?region='+this.region, {})
          .then(function (response) {
            that.health=response.data.data
            that.health.bai=mul(parseInt((that.health.normal/that.health.all)*100),0.01)

          })

      //药品类型数据
      let startTime=this.getRiqi(6)[0];
      axios.get('/drug/store/category/count?endTime='+that.today()+' 23:59:59&startTime='+startTime+' 00:00:00&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.drugCategory[0]=Object.keys(obj.special).map(x=>that.shortDay(x)) //日期
            that.drugCategory[1]=Object.values(obj.special)//特殊药品
            that.drugCategory[2]=Object.values(obj.prescription)//处方药品
            that.drugCategory[3]=Object.values(obj.epidemic)//疫情药品

          })

      //首页-智慧管-右下角 分类品种统计
      axios.get('/drug/store/smart/tube/type?&region='+this.region, {})
          .then(function (response) {
            that.smartType=response.data.data
          })

      //预警列表数据（今日）
      axios.get('/drug/store/message?region='+this.region, {})
          .then(function (response) {
            that.message=response.data.data
          })

          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    shortDay(d){
      let a=d.slice(-5)
      return a
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},
    //     fun() {
//       let index = 0;
//       let myChart = echarts.init(document.getElementById('myChart_rcfx'))
//       var timer = setInterval(function() {
//         myChart.dispatchAction({
//           type: 'hideTip',
//           seriesIndex: 0,
//           dataIndex: index
//         });
//         // 显示提示框
//         myChart.dispatchAction({
//           type: 'showTip',
//           seriesIndex: 0,
//           dataIndex: index
//         });
//         // 取消高亮指定的数据图形
//         myChart.dispatchAction({
//           type: 'downplay',
//           seriesIndex: 0,
//           dataIndex: index == 0? 5 : index -1
//         });
//         myChart.dispatchAction({
//           type: 'highlight',
//           seriesIndex: 0,
//           dataIndex: index
//         });
//         index++;
//         if (index > 5) {
//           index = 0;
//         }
//       },3000)
// },
//     drawChart_rcfx(){
//       let datas=[
//         {
//           'name': '经营许可证近效期',
//           'value': 1.45
//         }, {
//           'name': '跨店销售特殊药品',
//           'value': 2.93
//         }, {
//           'name': '跨店购买特殊药品',
//           'value': 3.15
//         }, {
//           'name': '违规销售疫情药品',
//           'value': 4.78
//         }, {
//           'name': '访客其它异常',
//           'value': 5.93
//         }, {
//           'name': '药店从业人员异常',
//           'value': 5.73
//         }, {
//           'name': '没有处方销售处方药',
//           'value': 5.73
//         }, {
//           'name': '执业药师未审方销售处方药',
//           'value': 5.73
//         }, {
//           'name': '没有执业药师销售处方药',
//           'value': 5.73
//         }, {
//           'name': '温湿度超标',
//           'value': 5.73
//         }
//       ]
//       let datas1 = datas.slice(0,4)
//       let datas2 = datas.slice(5,9);
//       var colorList = ['#E8684A', '#6DC8EC', '#5B8FF9', '#5AD8A6', '#5D7092', '#E8684A', '#fed816', '#fed816', '#5B8FF9', '#5D7092']
//      this.fun()
//      //setTimeout(function() {this.fun()}, 5000);
//      let option = {
//        legend: [{
//          data: datas1,
//          orient: 'vertical',
//          icon: "circle",
//          itemHeight: 7, //修改icon图形大小
//          itemWidth: 7, //修改icon图形大小
//          show: true,
//          top: 'center',
//          right: '30%',
//          textStyle: {
//            color: '#fff',
//          },
//          itemGap: 5,
//          formatter: (name) => {
//            return name;
//          },
//        },{
//          data: datas2,
//          orient: 'vertical',
//          icon: "circle",
//          itemHeight: 7, //修改icon图形大小
//          itemWidth: 7, //修改icon图形大小
//          show: true,
//          top: 'center',
//          right: '0%',
//          textStyle: {
//            color: '#fff',
//          },
//          itemGap: 5,
//          formatter: (name) => {
//            return name;
//          },
//        }],
//        title: {
//          text: '',
//          x: 'center',
//          y: 'center',
//          textStyle: {
//            fontSize: 20
//          }
//        },
//        tooltip: {
//          trigger: 'item'
//        },
//        series: [
//            {
//          type: 'pie',
//          center: ['30%', '50%'],
//          radius: ['25%', '55%'],
//          clockwise: true,
//          avoidLabelOverlap: true,
//          itemStyle: {
//            normal: {
//              color: function(params) {
//                return colorList[params.dataIndex]
//              }
//            }
//          },
//          label: {
//            //color:'inherit',颜色继承
//            show: true,
//            position: 'inside',
//            //formatter: '{a|{b}：{d}%}\n{hr|}',
//            formatter: '{d}%',
//            rich: {
//              hr: {
//                backgroundColor: 't',
//                borderRadius: 3,
//                width: 3,
//                height: 3,
//                padding: [3, 3, 0, -12]
//              },
//              a: {
//                padding: [-30, 15, -20, 15]
//              }
//            }
//          },
//          labelLine: {
//            normal: {
//              length: 20,
//              length2: 30,
//              lineStyle: {
//                width: 1
//              }
//            }
//          },
//          data: datas,
//        }]
//      };
//       let myChart = echarts.init(document.getElementById('myChart_rcfx'))
//       myChart.setOption(option);
//     },

  },

}
</script>
