<template>
<span>{{times}}</span>
</template>

<script>
export default {
  name: "timer",
  props: {
     end: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
    times_start:0,
      times:0,
  }},
  created() {
    this.start()
    if(this.type==1){
      this.start2()
    }
  },
  methods:{
    start() {
      if(this.end<0){return}
      this.times_start=0
      this.timer = setInterval(()=>{
        this.times=this.times+1
        if(this.times>this.end||this.times>50){
          this.times=this.end
          clearInterval(this.timer)
        }
      },1)
    },
    start2() {
      let timer = setInterval(()=>{
        this.times=this.times+1
         },5000)
    },
  }
}
</script>

<style scoped>

</style>
