<template>
  <div class="daping">
    <div :class="isAll?'map':'map2'">
      <bd-map ref="children"/>
    </div>
    <div class="lanBg" v-if="isAll"></div>
  <div class="quan">

    <div style="height: 29px"></div>
    <div class="header" >
      <div style="z-index:10;cursor:pointer;color: #0895EE;position: absolute;left:50px;top:60px" @click="go('/#/cloudHome')" v-if="lai==1">返回</div>
      <div class="you_btn"  v-if="isAll" style="">
        <span @click="gohome()" style="margin-right: 20px">首页</span>
        <span @click="isAll=false">隐藏</span>
      </div>
      <div class="you_btn" @click="quanju" v-if="!isAll">
        返回全局
      </div>
      <div style="height: 19px"></div>
      <div class="dabiaoti">数字监管</div>
    </div>
    <div class="sosuo">
      <div class="search">
        <div style="padding-left: 20px; padding-top: 8px;"><div style="position: absolute;margin-left: 241px;margin-top: 5px;font-size: 30px;color: #0895ee" v-if="zoom"><img src="../assets/zoom.png"/>搜索企业名称</div>
          <el-autocomplete
              @focus="jujiao()"
              class="search_input"
              v-model="keyword"
              :fetch-suggestions="querySearch"
              @select="handleSelect"
          ></el-autocomplete>
        </div>
      </div>
    </div>
    <div class="main_content" v-if="isAll">
<div class="main_content_right">
  <div class="kuang_s">
    <div class="kuang_tit">执业药师</div>
    <div style="width: 420px; margin: 0 auto">
      <div id="myChartZyys" :style="{width: '420px', height: '190px'}"></div>
    </div>
  </div>
  <div class="kuang_b">
    <div class="kuang_tit">证件近效期预警通知</div>
    <div style="width: 400px; margin: 0 auto">
      <div id="myChartZjyj" :style="{width: '400px', height: '620px'}"></div>
    </div>
  </div>
</div>
<div class="main_content_left">
  <div class="kuang_m">
    <div class="kuang_tit">疫情管控药品销售</div>
    <div style="width: 400px; margin: 0 auto">
      <div id="myChart" :style="{width: '400px', height: '248px'}"></div>
    </div>
  </div>
  <div class="kuang_m">
    <div class="kuang_tit">温湿度监控</div>
    <div style="width: 400px; margin: 0 auto">
      <div id="myChartWsdjk" :style="{width: '400px', height: '248px'}"></div>
    </div>
  </div>
  <div class="kuang_m">
    <div class="kuang_tit">特殊药品复方制剂销售</div>
    <div style="width: 400px; margin: 0 auto">
      <div id="myChartFfxs" :style="{width: '400px', height: '250px'}"></div>
    </div>
  </div>
</div>
<div class="main_content_center">
  <div style="height: 22px"></div>

  <div style="height: 525px">
  </div>
  <div>
    <div class="yjtz">预警通知</div>
    <div style="width: 820px; margin: 0 auto">
      <div id="myChartYjtz" :style="{width: '820px', height: '300px'}"></div>
    </div>
  </div>

</div>
    </div>
  </div>
  </div>
</template>
<script>
import BdMap from './BaiduMap'
import axios from 'axios';
import * as echarts from 'echarts'

export default {
  name: 'szjg',
  components: { BdMap},
  data () {
    return {
      region:0,//地区
      keyword:"",
      ypxsData:[],
      ffzjData:[],
      yjtzData:[],
      zyysData:[],
      wsdData:{},
      zjyjData:{},
      isAll: true,
      zoom: true,
      restaurants: [],
      selectData: {},
      lai:0,
    }
  },
  mounted(){
    this.lai=this.$route.query.new
    if(!this.$Cookies.get("AuthorizationToken")&&this.$login){
      window.location.href='/#/login'
    }
    this.region=localStorage.id
    this.getData()
    this.loadAll()
    },
  methods: {
    gohome(){
      if(this.lai==1){
        this.go('/#/cloudHome?region='+this.region)
      }else{
        this.go('/#/zhuye?region='+this.region)
      }

    },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
             }
            that.restaurants=arr
          })

    },
    handleSelect(item) {
      console.log(item,"kkk");
      this.isAll=false
      this.$refs.children.handleSelect(item)
    },
    jujiao(){
      this.zoom=false
    },
    quanju(){
      this.isAll=true
      this.getData();
    },
    //搜素代码----------------------------------------end

       getData(){

      //疫情管控药品销售------start
      let otime=this.getRiqi(4);
      var that=this
      that.ypxsData[0]=[]
      that.ypxsData[1]=[]
      axios.get('/screen/epidemic/sale?startDate='+otime[1]+'&endDate='+that.today()+'&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data.data
            for(let key in obj){
              that.ypxsData[0].push(key)
              that.ypxsData[1].push(obj[key])
            }
            that.drawLine();
          })
      //疫情管控药品销售------end

      //温湿度监控------start
      axios.get('/screen/device/status?region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.wsdData=obj
            that.drawWsdjk()
          })
      //温湿度监控------end

      //特殊药品复方制剂销售------start
     // let otime=this.getRiqi(4);
      that.ffzjData[0]=[]
      that.ffzjData[1]=[]
      axios.get('/screen/special/sale?startDate='+otime[1]+'&endDate='+that.today()+'&region='+this.region, {})
          .then(function (response) {
           // console.log(response)
            let obj=response.data.data.data
            for(let key in obj){
              that.ffzjData[0].push(key)
              that.ffzjData[1].push(obj[key])
            }
            that.drawFfzj()
          })
      //特殊药品复方制剂销售------end
      //预警通知------start
      let otimeYjtz=this.getRiqi(7);
      that.yjtzData[0]=[]
      that.yjtzData[1]=[]
      that.yjtzData[1][0]=[]
      that.yjtzData[1][1]=[]
      that.yjtzData[1][2]=[]
      axios.get('/screen/warning?startTime='+otimeYjtz[0]+'&endTime='+otimeYjtz[6]+'&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.yjtzData[0] =  Object.keys(obj);
            let arr = Object.keys(obj).map(function(i){return obj[i]});
            for(let i in arr){
              that.yjtzData[1][0].push(arr[i].disposal)
              that.yjtzData[1][1].push(arr[i].warn)
              that.yjtzData[1][2].push(arr[i].prescription)
            }
             that.drawYjtz()
          })
      //预警通知------end
      //执业药师------start
       let otimeZyys=this.getRiqi(3);
      that.zyysData[0]=[]
      that.zyysData[1]=[]
      that.zyysData[1][0]=[]
      that.zyysData[1][1]=[]
      that.zyysData[1][2]=[]
      axios.get('/screen/pharmacist/duty?startTime='+otimeZyys[0]+'&endTime='+this.today()+'&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.zyysData[0] =  Object.keys(obj);
            for(let i in that.zyysData[0]){
              that.zyysData[0][i]=that.zyysData[0][i].substring(5,10)
            }
            let arr = Object.keys(obj).map(function(i){return obj[i]});
            for(let i in arr){
              that.zyysData[1][0].push(arr[i].sign)
              that.zyysData[1][1].push(arr[i]["no-sign"])
            }

           that.drawZyys()
          })
      //执业药师------end

      //证件近效期预警通知------start
      axios.get('/screen/paperwork/info?region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.zjyjData=obj
            that.drawZjyj()
          })
      //证件近效期预警通知------end


    },
    search(){
      this.isAll=false
    },
    go(url){
      //this.$router.push(url)
      window.location.href=url
    },
    drawLine(){
      //疫情管控药品销售
      let myChart = echarts.init(document.getElementById('myChart'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '10%',
          left: '-5%',
          bottom: '5%',
          right: '5%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 30,
            },
            interval: 1,
          },
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, 7],
            z: 12,
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
          {
            name: '内层波浪',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [50, 15],
            symbolOffset: [0, 12],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: '#5BFCF4',
                borderType: 'solid',
                borderWidth: 8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '外层波浪',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [70, 20],
            symbolOffset: [0, 18],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: 'rgba(91,252,244,0.5)',
                borderType: 'solid',
                borderWidth: 5,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '疫情管控药品销售',
            type: 'bar',
            barWidth: '40',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: 'rgba(210,210,210,0.3)',
                  },
                  {
                    offset: 1,
                    color: '#5BFCF4',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },

          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, 7],
            z: 12,
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawFfzj(){
      var xdata = this.ffzjData[0];
      var data = this.ffzjData[1];
      var maxArr = [Math.max(...this.ffzjData[1]), Math.max(...this.ffzjData[1]), Math.max(...this.ffzjData[1])];

      let myChart = echarts.init(document.getElementById('myChartFfxs'))
    var option = {
        backgroundColor: "", //背景色
        tooltip: {
          trigger: "axis",
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function(params) { // params[0].data.nameCode
            return (
                params[0].name +
                "<br>" +
                "特殊药品复方制剂销售 ：" +
                params[0].value
            );
          }
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "10%",
          top: "15%",
          containLabel: true
        },
        xAxis: {
          data: xdata,
          triggerEvent: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            rotate: 0,
            interval: 0,
            textStyle: {
              padding: [3,0,0,0],
              fontSize: 14,
              color: "rgba(255,255,255,1)"
            }
          }
        },
        yAxis: {
          show:false,
          name: "单位：个数",
          triggerEvent: true,
          nameTextStyle: {
            color: "rgba(255,255,255,1)",
            fontSize: 16,
            padding: [0,0,10,-20]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgb(148,159,167)",
              fontSize: 16
            }
          }
        },
        // color: ["#e54035"],
        series: [{
          name: "数量",
          barMinHeight: 10,
          type: "pictorialBar",
          barCategoryGap: "60%",
          // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
          symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
          itemStyle: {
            normal: {
              //barBorderRadius: 5,
              //渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, .5, [{
                offset: 0,
                color: "#01EAED"//塔尖
              },
                {
                  offset: 0.5,//塔中
                  color: "#02C4DD"
                },
                {
                  offset: 1,//塔底
                  color: "#029ED9",
                }
              ])
            }
          },
          label: {
            normal: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize:20
              }
            }
          },
          data: data,
          z: 10
        },
          {//矩形样式
            name: "hill",
            type: "bar",
            barWidth: '20%',
            symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              normal: {
                color: "rgba(11,47,68,.2)"
              }
            },
            data: maxArr,
            z: 9
          }
        ]
      }
      myChart.setOption(option);
    },
    drawYjtz(){
      let myChart = echarts.init(document.getElementById('myChartYjtz'))
      var option = {
        backgroundColor: '',
        // tooltip: { //提示框组件
        //   trigger: 'axis',
        //   formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
        //   axisPointer: {
        //     type: 'shadow',
        //     label: {
        //       backgroundColor: '#6a7985'
        //     }
        //   },
        //   textStyle: {
        //     color: '#fff',
        //     fontStyle: 'normal',
        //     fontFamily: '微软雅黑',
        //     fontSize: 12,
        //   }
        // },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '10%',
          top:'40%',
          //	padding:'0 0 10 0',
          containLabel: true,
        },
        legend: {//图例组件，颜色和名字
          right:'10%',
          top:'10%',
          itemGap: 16,
          itemWidth: 18,
          itemHeight: 10,
          data:[{
            name:'温湿度预警',
            //icon:'image://../wwwroot/js/url2.png', //路径
          },
            {
              name:'特殊复方超量销售预警',
            },
            {
              name:'未凭处方销售预警',
            }],
          textStyle: {
            color: '#a8aab0',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          }
        },
        xAxis: [
          {
            type: 'category',
            //	boundaryGap: true,//坐标轴两边留白
            data: this.yjtzData[0],
            axisLabel: { //坐标轴刻度标签的相关设置。
              //		interval: 0,//设置为 1，表示『隔一个标签显示一个标签』
              //	margin:15,
              textStyle: {
                color: '#078ceb',
                fontStyle: 'normal',
                fontFamily: '微软雅黑',
                fontSize: 12,
              },
              rotate:0,
            },
            axisTick:{//坐标轴刻度相关设置。
              show: false,
            },
            axisLine:{//坐标轴轴线相关设置
              lineStyle:{
                color:'#fff',
                opacity:0.2
              }
            },
            splitLine: { //坐标轴在 grid 区域中的分隔线。
              show: false,
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: '#a8aab0',
                fontStyle: 'normal',
                fontFamily: '微软雅黑',
                fontSize: 12,
              }
            },
            axisLine:{
              show: false
            },
            axisTick:{
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#fff'],
                opacity:0.06
              }
            }

          }
        ],
        series : [
          {
            name:'温湿度预警',
            type:'bar',
            data:this.yjtzData[1][0],
            barWidth: 10,
            barGap:0,//柱间距离
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#5768EF'
                }, {
                  offset: 1,
                  color: '#5768EF'
                }]),
                barBorderRadius: 50,
                borderWidth: 0,
              }
            },
          },
          {
            name:'特殊复方超量销售预警',
            type:'bar',
            data:this.yjtzData[1][1],
            barWidth: 10,
            barGap:0,//柱间距离
            // label: {//图形上的文本标签
            //     normal: {
            //       show: true,
            //       position: 'top',
            //       textStyle: {
            //           color: '#a8aab0',
            //           fontStyle: 'normal',
            //           fontFamily: '微软雅黑',
            //           fontSize: 12,
            //       },
            //     },
            // },
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#69CBF2'
                }, {
                  offset: 1,
                  color: '#69CBF2'
                }]),
                barBorderRadius: 50,
                borderWidth: 0,
              }
            },
          },
          {
            name:'未凭处方销售预警',
            type:'bar',
            data:this.yjtzData[1][2],
            barWidth: 10,
            barGap:0,//柱间距离
            // label: {//图形上的文本标签
            //     normal: {
            //       show: true,
            //       position: 'top',
            //       textStyle: {
            //           color: '#a8aab0',
            //           fontStyle: 'normal',
            //           fontFamily: '微软雅黑',
            //           fontSize: 12,
            //       },
            //     },
            // },
            itemStyle: {
              normal: {
                show: true,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#69CBF2'
                }, {
                  offset: 1,
                  color: '#69CBF2'
                }]),
                barBorderRadius: 50,
                borderWidth: 0,
              }
            },
          }
        ]
      };
      myChart.setOption(option);
    },
    drawWsdjk(){
      let myChart = echarts.init(document.getElementById('myChartWsdjk'))
     let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          }
        },
       color:['#5BFC9F', '#0B2BB6', '#3AACFB','#5BFCF4'],
        series: [
          {
            label: {
              fontSize: 12,
              color: '#7AE1FF',
              formatter: (params) => {
                  return params.name+params.value+"家";

              },
            },
            name: '温湿度监控',
            type: 'pie',
            radius: '50%',
            data: [
              {value: this.wsdData.excerpt, name: '超标'},
              {value: this.wsdData.normal, name: '正常'},
              {value: this.wsdData.fault, name: '故障'},
              {value: this.wsdData.stop, name: '报停'},
              ],
            emphasis: {
              itemStyle: {


              }
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    drawZyys(){

     var option = {
        backgroundColor: '',
        "textStyle": {
          "color": "#c0c3cd",
          "fontSize": 14
        },
        "toolbox": {
          "show": false,
          "feature": {
            "saveAsImage": {
              "backgroundColor": "#031245"
            },
            "restore": {}
          },
          "iconStyle": {
            "borderColor": "#c0c3cd"
          }
        },
        "legend": {
          "top": 10,
          "itemWidth": 8,
          "itemHeight": 8,
          "icon": "circle",
          "left": "center",
          "padding": 0,
          "textStyle": {
            "color": "#c0c3cd",
            "fontSize": 14,
            "padding": [2, 0, 0, 0]
          }
        },
        "color": ["#63caff", "#49beff", "#edab31", "#fff8df",],
        "grid": {
          "containLabel": true,
          "left": 20,
          "right": 20,
          "bottom": 10,
          "top": 40
        },
        "xAxis": {
          "nameTextStyle": {
            "color": "#c0c3cd",
            "padding": [0, 0, -10, 0],
            "fontSize": 14
          },
          "axisLabel": {
            "color": "#c0c3cd",
            "fontSize": 14,
            "interval": 0
          },
          "axisTick": {
            "lineStyle": {
              "color": "#384267",
              "width": 1
            },
            "show": true
          },
          "splitLine": {
            "show": false
          },
          "axisLine": {
            "lineStyle": {
              "color": "#384267",
              "width": 1,
              "type": "dashed"
            },
            "show": true
          },
          "data": this.zyysData[0],
          "type": "category"
        },
        "yAxis": {
          "nameTextStyle": {
            "color": "#c0c3cd",
            "padding": [0, 0, -10, 0],
            "fontSize": 14
          },
          "axisLabel": {
            "color": "#c0c3cd",
            "fontSize": 14
          },
          "axisTick": {
            "lineStyle": {
              "color": "#384267",
              "width": 1
            },
            "show": true
          },
          "splitLine": {
            "show": true,
            "lineStyle": {
              "color": "#384267",
              "type": "dashed"
            }
          },
          "axisLine": {
            "lineStyle": {
              "color": "#384267",
              "width": 1,
              "type": "dashed"
            },
            "show": true
          },
          "name": ""
        },
        "series": [{
          name:"药师在岗",
          "data": this.zyysData[1][0],
          "type": "bar",
          "barMaxWidth": "auto",
          "barWidth": 30,
          "itemStyle": {
            "color": {
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "type": "linear",
              "global": false,
              "colorStops": [{
                "offset": 0,
                "color": "#0b9eff"
              }, {
                "offset": 1,
                "color": "#63caff"
              }]
            }
          },
          "label": {
            "show": false,
            "position": "top",
            "distance": 10,
            "color": "#fff"
          }
        },{
          "data": [1, 1, 1, 1],
          "type": "pictorialBar",
          "barMaxWidth": "20",
          "symbol": "diamond",
          "symbolOffset": ["-65%", "50%"],
          "symbolSize": [30, 15]
        }, {
          "data": this.zyysData[1][0],
          "type": "pictorialBar",
          "barMaxWidth": "20",
          "symbolPosition": "end",
          "symbol": "diamond",
          "symbolOffset": ["-65%", "-50%"],
          "symbolSize": [30, 12],
          "zlevel": 2
        }, {
          name:"药师迟到",
          "data": this.zyysData[1][1],
          "type": "bar",
          "barMaxWidth": "auto",
          "barWidth": 30,
          "itemStyle": {
            "color": {
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "type": "linear",
              "global": false,
              "colorStops": [{
                "offset": 0,
                "color": "#feeeb7"
              }, {
                "offset": 1,
                "color": "#edab31"
              }]
            }
          },
          "label": {
            "show": true,
            "position": "top",
            "distance": 10,
            "color": "#fff"
          }
        }, {
          "data": [1, 1, 1, 1],
          "type": "pictorialBar",
          "barMaxWidth": "20",
          "symbol": "diamond",
          "symbolOffset": ["65%", "50%"],
          "symbolSize": [30, 15]
        }, {
          "data": this.zyysData[1][1],
          "type": "pictorialBar",
          "barMaxWidth": "20",
          "symbolPosition": "end",
          "symbol": "diamond",
          "symbolOffset": ["65%", "-50%"],
          "symbolSize": [30, 12],
          "zlevel": 3
        },],
        "tooltip": {
          "trigger": "axis",
          "show": true,
          backgroundColor:'rgba(0,0,0,0.8)',
          borderWidth:0,
          textStyle: { fontSize: '100%' },
          formatter:function(item){
           var returnData1 =
                '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:' +
                item[1].color +
                '"></span>';
          var  returnData2=
                '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:' +
                item[4].color +
                '"></span>';
            //console.log(item);
            return '<div style="color:#FFF">'+item[0].axisValue+"<br/>"+returnData1+item[0].seriesName+":"+item[0].value+'人<br/>'+returnData2+item[3].seriesName+":"+item[3].value+'人</div>';
          }
        }
      }

      let myChart = echarts.init(document.getElementById('myChartZyys'))
      myChart.setOption(option);
    },
    drawZjyj(){
      let myChart = echarts.init(document.getElementById('myChartZjyj'))

      let xLabel = ['药品经营证','食品经营证','药业药师证','健康证']
      let ydata=[this.zjyjData.licence.expire, this.zjyjData.food.expire, this.zjyjData.register.expire, this.zjyjData.health.expire]
      let ydata2=[this.zjyjData.licence.all, this.zjyjData.food.all, this.zjyjData.register.all, this.zjyjData.health.all]
     let option = {
       backgroundColor: '',
       grid: {
         top: '10%',
         left: '5%',
         right: '2%',
         bottom: '14%',
       },
       tooltip: {
         show: false,
       },
       xAxis: {
         data: xLabel,
         axisLine: {
           lineStyle: {
             color: 'transparent', //底部边框颜色
           },
         },
         axisLabel: {
           textStyle: {
             color: '#fff', //底部文字颜色
             fontSize: 12,
                   },
         },
       },
       yAxis: [
         {
           type: 'value',
           splitLine: {
             show: false,//网格线隐藏
             lineStyle: {
               color: 'rgba(255,255,255,0.2)', //网格线的颜色
               width: 1,
               type: 'solid',
             },
           },
           axisLine: {
             show: false,
             lineStyle: {
               color: 'transparent', //左边框颜色
             },
           },
           axisLabel: {
             show: false,
             fontSize: 12,
             textStyle: {
               color: '#ADD6FF', //左文字颜色
             },
           },
         },
       ],
       series: [
         {
           name: '证件预警',
           type: 'bar',
           barWidth: 30,
           showBackground: true,
           backgroundStyle: {
             color: 'rgba(21,136,209,0.1)',
           },
           itemStyle: {
             normal: {
               color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                 {
                   offset: 0,
                   // color: '#00FFE3',//渐变1
                   color: 'rgba(21,136,209,1)', //渐变1
                 },
                 {
                   offset: 1,
                   // color: '#4693EC',//渐变2
                   color: 'rgba(21,136,209,1)', //渐变2
                 },
               ]),
             },
           },
           data: ydata,
           z: 0,
           zlevel: 0,
           // label: {
           //     show: true,
           //     position: 'top',
           //     fontSize: 14,
           //     color: '#fff', //柱状顶部文字颜色
           //     formatter: function (params) {
           //         return '20%';
           //     },
           // },
         },
         {
           type: 'pictorialBar',
           barWidth: 30,
           itemStyle: {
             normal: {
               color: 'rgba(0,63,119,1)', //数据的间隔颜色
             },
           },
           symbolRepeat: 'fixed',
           symbolMargin: 3,
           symbol: 'rect',
           symbolSize: [30, 4],
           // symbolPosition: 'end',
           // symbolOffset: [0, 0],
           data: [50, 50, 50, 50],
           z: 1,
           zlevel: 0,
         },
         {
           type: 'pictorialBar',
           barWidth: 30,
           itemStyle: {
             normal: {
               color: 'transparent', //数据的间隔颜色
             },
           },
           // symbolRepeat: 'fixed',
           symbolMargin: 3,
           symbol: 'rect',
           symbolSize: [30, 4],

           // symbolPosition: 'start',
           // symbolOffset: [0, 0],
           data:ydata,
           z: 1,
           zlevel: 0,
           label: {
             show: true,
             position: 'top',
             fontSize: 14,
             color: '#fff', //柱状顶部文字颜色
             formatter: function (params) {
               return params.data+"家";
             },
           },
         }, {
           type: 'pictorialBar',
           barWidth: 30,
           itemStyle: {
             normal: {
               color: 'transparent', //数据的间隔颜色
             },
           },
           // symbolRepeat: 'fixed',
           symbolMargin: 3,
           symbol: 'rect',
           symbolSize: [30, 4],

           // symbolPosition: 'start',
           // symbolOffset: [0, 0],
           data:[100,100,100,100],
           z: 1,
           zlevel: 0,
           label: {
             show: true,
             position: 'top',
             fontSize: 14,
             color: '#fff', //柱状顶部文字颜色
             formatter: function (params) {
               //console.log(params)
let num=ydata2[params.dataIndex]
               return num+"家";
             },
           },
         },
       ],
     };

      myChart.setOption(option);
    },
    //传入的参数应为数字
    getRiqi(time){
  var myDate = new Date(); //获取今天日期
  myDate.setDate(myDate.getDate() - time);
  var dateArray = [];
  var dateTemp;
  var flag = 1;
  for (var i = 0; i < time; i++) {
    let month = 0;
    if (myDate.getMonth() + 1 < 10) {
      month = '0' + (myDate.getMonth() + 1);
    } else {
      month = myDate.getMonth() + 1;
    }
    let day = 0;
    if (myDate.getDate() < 10) {
      day = '0' + myDate.getDate();
    } else {
      day = myDate.getDate();
    }
    dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
    dateArray.push(dateTemp);
    myDate.setDate(myDate.getDate() + flag);
  }
  return dateArray
}
}
}
</script>
<style>
.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png")}
.kuang_m{
  background: url("../assets/kuang_m.png") no-repeat center;
  height: 320px;
}
.kuang_s{
  background: url("../assets/kuang_s.png") no-repeat center;
  height: 280px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 23px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 820px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}



</style>
