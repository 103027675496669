<template>
  <div id="main" style="width: 1920px;overflow: hidden">
    <div class="dialog">
    <el-dialog title="" :visible.sync="dialogVisible" width="1000px" top="5vh">
      <img src="../assets/d.png" width="100%" @click="dialogVisible=false"/>
    </el-dialog>
      </div>
      <div class="content">
        <div class="toper">
          <div style="text-align: center">智能评</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px;position: relative">
            <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px" @click="go('/smartHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
            <div class="findleft">
              <div class="titleBox">
                   <div class="title">企业动态评级趋势</div>
              </div>
              <div class="chart abs" style="top:50px">
                <div id="myChart_dtpjqs" :style="{width: '600px', height: '250px'}"></div>
              </div>
              <div style="height: 272px"></div>
<div class="lan14" style="padding-left: 27px">评级数据分析</div>
              <div class="abs bai14" style="top:386px;left:134px">加分分析</div>
              <div class="abs bai14" style="top:386px;left:441px">减分分析</div>
              <div class="chart abs" style="top:404px">
                <div id="myChart_jia" :style="{width: '300px', height: '250px'}"></div>
              </div>
              <div class="chart abs" style="top:404px;left:300px">
                <div id="myChart_jian" :style="{width: '300px', height: '250px'}"></div>
              </div>
            </div>
            <div class="findleft2" style="margin-top: 20px">
              <div class="titleBox">
                <div class="title">结果排行数据分析</div>
              </div>
              <div class="chart " style="top:50px">
                <div id="myChart_jgph" :style="{width: '600px', height: '230px'}"></div>
              </div>
            </div>
          </div>
          <div style="width: 671px;margin-left: 18px" class="centerblock">
            <div class="fenji" style="position: relative">
              <div class="titleBox" style="padding-top: 1px;padding-left: 10px">
                <div class="title" style="cursor:pointer;">分级管理</div>
              </div>
              <div  class="fenbox">
                <div class="item" style="color: #2BDC74; ">
                  <div class="item1">A级</div>
                  <div class="item2">95.2%</div>
                  <div class="item3">160家</div>
                </div>
                <div class="item" style="color: #1AC9FF ">
                  <div class="item1">B级</div>
                  <div class="item2">0%</div>
                  <div class="item3">0家</div>
                </div>
                <div class="item" style="color:#D89A1F ">
                  <div class="item1">C级</div>
                  <div class="item2">3.6%</div>
                  <div class="item3">6家</div>
                </div>
                <div class="item" style="color: #ED460C ">
                  <div class="item1">D级</div>
                  <div class="item2 hand" @click="dialogVisible=true">1.2%</div>
                  <div class="item3">2家</div>
                </div>
              </div>
            </div>

            <div class="ditu" style="height: 512px">
<baidumap :height="512" :type="4"/>
            </div>
<div style="position: relative;margin-top: 20px" class="block_yjcz">
  <div class="titleBox" style="">
    <div class="title">预警处置结果</div>
  </div>

  <div  class="zdyj" style="margin-top: 10px">
    <table width="100%" cellspacing="0" cellpadding="0">
      <tr style="">
        <td>序号</td>
        <td>企业名称</td>
        <td>预警名称</td>
        <td>预警时间</td>
        <td>预警次数</td>
      </tr>
      <tr v-for="x in 0">
        <td>{{x}}</td>
        <td>亚太大道</td>
        <td>2022-02-04</td>
        <td>类型</td>
        <td>待处置</td>
      </tr>


    </table>
  </div>

</div>


          </div>
          <div style="width: 606px" class="rightblock">
            <div class="block_right">
              <div class="titleBox">
                <div class="title">处置结果趋势</div>
              </div>
                <div class="chart abs" style="top:50px">
                  <div id="myChart_czjgqs" :style="{width: '600px', height: '250px'}"></div>
                </div>
              <div style="height: 270px;"></div>
              <div style="position: relative;padding-left: 27px">
                <div class="lan14" style="">动态监控频次</div>
                <div class="chart abs" style="top:50px">
                  <div id="myChart_dtjkpc" :style="{width: '550px', height: '250px'}"></div>
                </div>
              </div>


              <div style="height: 293px;"></div>

              <div style="position: relative;padding-left: 27px">
<div class="abs bai14" style="top:113px;left:102px">重点监管对象问题分析</div>
<div class="abs bai14" style="top:113px;left:387px">督促纠正率</div>
                <div class="chart abs" style="top:123px">
                  <div id="myChart_cyxy" :style="{width: '300px', height: '200px'}"></div>
                </div>
                <div class="crystalBox" style="left:279px;top:153px">
                  <div id="chartCrystal_znyx" :style="{width: '300px', height: '150px'}"></div>
                </div>
              </div>



            </div>

          </div>
        </div>

      </div>



  </div>
</template>
<style scoped>
/*el-dialog样式------------------------------------start*/
.dialog >>>.el-dialog{background-color: transparent}
.dialog >>>.el-dialog__header{display: none}
.dialog >>>.el-dialog__wrapper .el-dialog .el-dialog__body{padding: 0;}



/*el-dialog样式------------------------------------end*/
.zdyj table{font-size: 12px}
.zdyj tr td{text-align: center;color: white;padding: 5px;line-height: 150%}
.block_center{
  position: relative;
  background-image: url("../assets/smart/dynamic/center.png");
  width: 646px;
  height: 320px;
}
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.hand{cursor: pointer}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/smart/dynamic/right2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 320px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.findright1{
  position: relative;
  background-image: url("../assets/home/findright1.png");
  width: 601px;
  height: 539px;
}
.findright2{
  position: relative;
  background-image: url("../assets/home/findright2.png");
  width: 601px;
  height: 416px;
}
.keymap{
  position: relative;
  background-image: url("../assets/home/findcenter.png");
  width: 646px;
  height: 634px;
}
.block_right{
  position: relative;
  background-image: url("../assets/smart/grading/right.png");
  width: 602px;
  height: 990px;
}
.findleft{
  position: relative;
  background-image: url("../assets/smart/grading/left1.png");
  width: 602px;
  height: 656px;
}
.findleft2{
  position: relative;
  background-image: url("../assets/smart/dynamic/left2.png");
  width: 602px;
  height:320px;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
.fenbox{display: flex}
.fenbox .item{width: 25%;text-align: center;line-height: 220%}
.fenbox .item2{font-size: 30px}
.fenji{
  background-image: url("../assets/smart/grading/center1.png");
  background-size: 100% 100%;
  width: 646px;
  height: 137px;

}
.block_yjcz{
  background-image: url("../assets/smart/grading/center2.png");
  background-size: 100% 100%;
  width: 646px;
  height: 320px;

}
</style>
<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import 'echarts-wordcloud'
import axios from "axios";
import {mul} from "@/utils/jsj";
import baidumap from "./components/baidumap"
import vueSeamlessScroll from 'vue-seamless-scroll'
import moment from 'moment'
export default {
  name: 'zhuye',
  components: {baidumap,vueSeamlessScroll},
  data(){
    return {
      dialogVisible:false,
      cyxyData:[
        {
          name: '进销存问题',
          value: 16,
          per: 16,
          background:"rgba(91, 143, 249, 0.85)"
        }, {
          name: '客户投诉',
          value: 3,
          per: 3,
          background:"rgba(90, 216, 166, 0.85)"
        }, {
          name: '其它问题',
          value: 3,
          per: 3,
          background:"rgba(93, 112, 146, 0.85)"
        }, {
          name: '处方药问题',
          value:30,
          per: 30,
          background:"#E8684A"
        }
        , {
          name: '温湿度指标',
          value:33,
          per: 33,
          background:"#fc1b6d"
        }
        , {
          name: '执业药师问题',
          value:5,
          per: 5,
          background:"#6DC8EC"
        }
        , {
          name: '防疫问题',
          value:10,
          per: 10,
          background:"#9270CA"
        }

      ],
      jiaData:[
        {
          name: '公益活动',
          value: 2,
          per: 2,
          background:"rgba(91, 143, 249, 0.85)"
        }, {
          name: '云药房服务',
          value: 14,
          per: 14,
          background:"rgba(90, 216, 166, 0.85)"
        }, {
          name: '药事服务',
          value: 66,
          per: 66,
          background:"rgba(93, 112, 146, 0.85)"
        }, {
          name: '志愿者服务',
          value:17,
          per: 17,
          background:"#E8684A"
        }
        , {
          name: '其它',
          value:1,
          per: 1,
          background:"#5300c4"
        }

        ],
      jianData:[
        {
          name: '督办检查',
          value: 10,
          per: 10,
          background:"rgba(91, 143, 249, 0.85)"
        }, {
          name: '联动处置',
          value: 11,
          per: 11,
          background:"rgba(90, 216, 166, 0.85)"
        }, {
          name: '防疫',
          value: 66,
          per: 66,
          background:"rgba(93, 112, 146, 0.85)"
        }        , {
          name: '其它',
          value:13,
          per: 13,
          background:"#E8684A"
        }

      ],
      keyword:"",
      region:330726,
      ypxsData:[],
      storeAge:[]
    }
  },
  created() {
    for(let i=0;i<7;i++){
      this.riqi.push(moment().add(-i,'day').format('MM-DD'))
    }
    this.riqi.sort()
  },
  mounted() {
    this.getData()
    },
  methods:{
go(url){
  this.$router.push(url)
},
    drawChart_jgph(){
      let myChart = echarts.init(document.getElementById('myChart_jgph'))
      var datas = [
        {
          "value": 70,
          "name": "停业整顿"
        },
        {
          "value": 60,
          "name": "取消医保"
        },
        {
          "value": 50,
          "name": "暂停医保"
        },
        {
          "value": 40,
          "name": "降低信用"
        },{
          "value": 30,
          "name": "暂停云药房"
        },{
          "value": 20,
          "name": "行政处罚"
        },
      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let option = {
        backgroundColor: "",
        legend: {
          show:false,
          borderColor:'#d73622',
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 10,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          },
        },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChart_sjzy(){
      let myChart = echarts.init(document.getElementById('myChart_dtjkpc'))
      var datas = [
        {
          "value": 3,
          "name": "A级"
        },
        {
          "value": 5,
          "name": "B级"
        },
        {
          "value": 10,
          "name": "C级"
        },
        {
          "value": 2,
          "name": "D级"
        }
      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let option = {
        backgroundColor: "",
        legend: {
          show:false,
          borderColor:'#d73622',
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 10,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          },
        },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            color:'#fff',
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          label:{show:true,position:"right",color:'#fff'},
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChart_wzd(id,title){//三级未找到药品推荐情况分析
      let option = {
        title: {
          text: title,
          // subtext: 'Fake Data',
          left: 'center',
          top: '85%',
          textStyle:{
            color:'#fff',
            fontSize:14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            label:{
              normal: {
                show: true, // 是否显示标签[ default: false ]
                color: '#fff',
                fontSize: 12,
                position: 'inside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: '{c}' // 标签内容
              }
            },
            name: title,
            type: 'pie',
            radius: '60%',
            data: [
              { value: 1048, name: '0～3岁' },
              { value: 735, name: '4～18岁' },
              { value: 580, name: '19～45岁' },
              { value: 484, name: '45～60岁' },
              { value: 300, name: '60岁以上' }
            ],
          }
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_ydxy(){
      let myChart = echarts.init(document.getElementById('myChart_ydxy'))
      var datas = [
        {
          "value": 40,
          "name": "亚太大道店"
        },
        {
          "value": 30,
          "name": "亚太大道店"
        },
        {
          "value": 20,
          "name": "亚太大道店"
        },
        {
          "value": 20,
          "name": "亚太大道店"
        },
      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let option = {
        backgroundColor: "",
        legend: {
          show:false,
          borderColor:'#d73622',
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 10,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          },
        },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChartCrystal(id,arrTitle,v=[0.5,0.8]){
      var value = v[0];
      var value2 = v[1];
      var data = [value, value, value];
      var data2 = [value2, value2, value2];
      var option = {
        title: [
          {
            text: arrTitle[1],
            x: '50%',
            y: 120,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: (value2 * 100).toFixed(0) + '%',
            left: '50%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#02456d',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['50%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(0, 155, 219, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(0, 155, 219, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(0, 155, 219, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data2,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外蓝边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#07a2e3'

                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
        ],
      };

      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChartKey(){
      let myChart = echarts.init(document.getElementById('myChartKey'))
      const list = [
        {
          value: '50',
          name: '防疫',
          textStyle: {
            shadowBlur: 4,
            shadowColor: '#ECEFFF',
            shadowOffsetY: 14,
            color: '#73DDFF',
          },
        }, // 50
        { value: '30', name: '维生素C' },
        { value: '29', name: '恒生药房' },
        { value: '28', name: '兴奋剂药品' },
        { value: '27', name: '花桥乡' },
        { value: '26', name: '特殊药品' },
        { value: '25', name: '未找到' },
        { value: '30', name: '中医' },
        { value: '23', name: '利福昔明片' },
        { value: '22', name: '高血压' },
        { value: '21', name: '疫情管控' },
        { value: '20', name: '配送费' },
        { value: '16', name: '老年人' },
        { value: '15', name: '中药' },
        { value: '14', name: '慢病' },
        { value: '13', name: '医保' },
        { value: '12', name: '康柏家' },
      ]
     let option = {
        tooltip: {
          show: true,
          borderColor: '#fe9a8bb3',
          borderWidth: 1,
          padding: [10, 15, 10, 15],
          confine: true,
          backgroundColor: 'rgba(255, 255, 255, .9)',
          textStyle: {
            color: 'hotpink',
            lineHeight: 22
          },
          extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },
        series: [
          {
            type: 'wordCloud',
            // The shape of the "cloud" to draw. Can be any polar equation represented as a
            // callback function, or a keyword present. Available presents are circle (default),
            // cardioid (apple or heart shape curve, the most known polar equation), diamond (
            // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

            shape: 'pentagon',

            // A silhouette image which the white area will be excluded from drawing texts.
            // The shape option will continue to apply as the shape of the cloud to grow.

            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.

            left: 'center',
            top: 'center',
            width: '100%',
            height: '100%',
            right: null,
            bottom: null,

            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.

            sizeRange: [14, 50],

            // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

            rotationRange: [0, 0],
            rotationStep: 0,

            // size of the grid in pixels for marking the availability of the canvas
            // the larger the grid size, the bigger the gap between words.

            gridSize: 25,

            // set to true to allow word being draw partly outside of the canvas.
            // Allow word bigger than the size of the canvas to be drawn
            drawOutOfBound: false,

            // If perform layout animation.
            // NOTE disable it will lead to UI blocking when there is lots of words.
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'PingFangSC-Semibold',
              fontWeight: 600,
              color: function (params) {
                let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3', '#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3', '#fe9a8bb3', '#73DDFF', '#58D5FF']
                return colors[parseInt(Math.random() * 10)];
              },
            },
            emphasis: {
              focus: 'none',
            },

            // Data is an array. Each array item must have name and value property.
            data: list,
          },
        ],
      };

      myChart.setOption(option);
    },
    drawChart(id){
      let myChart = echarts.init(document.getElementById(id))
      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '响应率', '找到率'],
            ['04-26', 43.3, 85.8],
            ['04-27', 83.1, 73.4],
            ['04-28', 86.4, 65.2],
            ['04-29', 72.4, 53.9],
            ['04-30', 72.4, 53.9],
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option);
    },
    drawChart_wzd3(id){
      let myChart = echarts.init(document.getElementById(id))
      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '响应率', '找到率'],
            ['04-26', 43.3, 85.8],
            ['04-27', 83.1, 73.4],
            ['04-28', 86.4, 65.2],
            ['04-29', 72.4, 53.9],
            ['04-30', 72.4, 53.9],
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option);
    },
    drawChart_jia(id,arr){//重点监管对象问题分析
      let color = [];
      arr.forEach(x=>{
        color.push(x.background)
      })
// 圆环图各环节的名称和值(系列中各数据项的名称和值)
      let data = arr
// 指定图表的配置项和数据
      let  option = {
        tooltip: {},
        legend: [{
          orient: 'vertical',
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: false,
          top: 'center',
          right: '0%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        }],
        backgroundColor : '',
        title: [{
          text: '',
          bottom: '2%',
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        }],
        center: ['55%', '60%'],
        // 系列列表
        series: [{
          name: '药店参与响应报价情况分析', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['10%', '45%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              color:'#fff',
              show: true, // 是否显示标签[ default: false ]
              fontSize: 12,
              position: 'outside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: (b) => {
                return b.data.name + '\n'+b.data.per+"%"; // 标签内容
              }
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 10, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }, {
          name: '名称指示圆环', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['25%', '25%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: false, // 是否显示标签[ default: false ]
              color: '#fff',
              fontSize: 12,
              position: 'outside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: '{b}' // 标签内容
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_cyxy(id){//重点监管对象问题分析
      let color = [];
      this.cyxyData.forEach(x=>{
        color.push(x.background)
      })
// 圆环图各环节的名称和值(系列中各数据项的名称和值)
      let data = this.cyxyData
// 指定图表的配置项和数据
      let  option = {
        tooltip: {},
        legend: [{
          orient: 'vertical',
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: false,
          top: 'center',
          right: '0%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        }],
        backgroundColor : '',
        title: [{
          text: '',
          bottom: '2%',
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        }],
        center: ['55%', '60%'],
        // 系列列表
        series: [{
          name: '药店参与响应报价情况分析', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['10%', '45%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              color:'#fff',
              show: true, // 是否显示标签[ default: false ]
              fontSize: 12,
              position: 'outside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: (b) => {
                return b.data.name + '\n'+b.data.per+"%"; // 标签内容
              }
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 10, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }, {
          name: '名称指示圆环', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['25%', '25%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: false, // 是否显示标签[ default: false ]
              color: '#fff',
              fontSize: 12,
              position: 'outside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: '{b}' // 标签内容
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_dtpjqs(id){//企业动态评级趋势
      let xLabel =this.riqi
      let data = ["79", "60", "22", "75", "50", "40",'33']
      let data2 = ["40", "50", "12", "55", "30", "38",'76']
      let data3 = ["52", "40", "32", "45", "30", "46",'56']
      let data4 = ["48", "70", "32", "85", "60", "75",'46']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: 'A级',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: 'B级',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: 'C级',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },
          {
            name: 'D级',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[3],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[3], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[3],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data4
          },
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_czjgqs(id,arr){//处置结果趋势
      let xLabel = ['1月', '2月', '3月', '4月', '5月']
      let data = ["20", "15", "11", "8", "1"]
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          show:false,
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '处置结果',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },

        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_xzzy(){//各乡镇（街道）找药响应情况分析
     let option = {
       tooltip: {
         trigger: 'axis'
       },
       legend: {
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 2,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         }
       },
       grid: {
         left: '3%',
         right: '4%',
         bottom: '3%',
         containLabel: true
       },
          xAxis: {
         axisLabel: {   // X轴线 标签修改
           textStyle: {
             color: '#fff', //坐标值得具体的颜色
           }
         },
         type: 'category',
         data: ['浦阳街道', '仙华街道', '黄宅镇', '白马镇', '大畈乡', '中余乡']
       },
       yAxis: {
         splitLine:{
           show:true,
           lineStyle: {color: ['#01233e', '#00223d']
    }
         },
         type: 'value'
       },
       series: [
         {
           name: '需求率',
           type: 'line',
           step: 'start',
           data: [120, 132, 101, 134, 90, 230]
         },
         {
           name: '响应率',
           type: 'line',
           step: 'middle',
           data: [220, 282, 201, 234, 290, 430]
         }
       ]
     };
      let myChart = echarts.init(document.getElementById("myChart_xzzy"))
      myChart.setOption(option);
    },
    drawChart2(){
      let data=this.storeAge
      let data2=['青年', '老年', '幼儿', '青少年', '儿童', '中年']
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        grid: {
          top: '0%',
          left: '10%',
          bottom: '0%',
          right: '20%',
          containLabel: true,
        },
        title: {
          text: '',
        },
        //backgroundColor: 'rgb(24, 3, 98)',
        yAxis: {
          type: 'category',
          inverse: true,
          offset: 10,
          axisLabel: {
            show: true,
            interval: 'auto',
            color: 'rgb(255,255,255)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: data2,
        },
        xAxis: {
          show: false,
          type: 'value',
          offset: 30,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(106, 148, 255)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            inside: true,
            length: 10,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%',
          },
        },
        series: [
          {
            //下半截柱子
            name: '人群',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,
              formatter: '{c}%'
            },
            itemStyle: {
              //lenged文本
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0, 111, 255)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(29, 227, 255)', // 100% 处的颜色
                      },
                    ],
                    false
                );
              },
            },
            data: data,
          },
          {
            //数据圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [6, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(27, 140, 255)'
            },
            symbolPosition: 'end',
            data: data,

          },
          {
            //最左圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [-5, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(0, 111, 255)'
              //color: '#000'
            },
            symbolPosition: 'end',
            data: [1, 1, 1, 1, 1,1],
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      this.ypxsData[0]=["中成药","西药","处方药","OTC","进口药","中药饮片","其它"]
      this.ypxsData[1]=[25,63,3,97,5,12,20]
      let myChart = echarts.init(document.getElementById('myChart3'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 16
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              },
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart5(){
      let xLabel = ['03-14', '03-15', '03-16', '03-17', '03-18', '03-19','03-20']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '已发布',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '已找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },

        ]
      };
      let myChart = echarts.init(document.getElementById('myChart5'))
      myChart.setOption(option);
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    getData(){
  this.drawChart_jgph()
      let arr2=["",""]
      let varr2=['','0.99']
      this.drawChartCrystal('chartCrystal_znyx',arr2,varr2);
      // this.drawChart("myChart");//库存历年平均
      // this.drawChart("myChart_pzfx");//滞销/近效期/过期药品品种分析

      let arr=[

      ]
  this.drawChart_dtpjqs("myChart_dtpjqs")//企业动态评级趋势
      this.drawChart_jia("myChart_jia",this.jiaData)//加分
      this.drawChart_jia("myChart_jian",this.jianData)//减分
  //this.drawChart_sjzy("myChart_czjgqs")//处置结果趋势
      this.drawChart_czjgqs("myChart_czjgqs")//处置结果趋势
 this.drawChart_sjzy("myChart_dtjkpc")//动态监控频次

  this.drawChart_cyxy("myChart_cyxy")//重点监管对象问题分析
  this.drawChart_ydxy()
  this.drawChart_xzzy()//各乡镇（街道）找药响应情况分析
  let that=this
      //年龄段销售数据
      axios.get('/drug/store/age?region='+this.region, {})
          .then(function (response) {
            let age=response.data.data
            that.storeAge[0]=mul(parseInt((age.young/age.total)*1000),0.1)
            that.storeAge[1]=mul(parseInt((age.elderly/age.total)*1000),0.1)
            that.storeAge[2]=mul(parseInt((age.infant/age.total)*1000),0.1)
            that.storeAge[3]=mul(parseInt((age.youngster/age.total)*1000),0.1)
            that.storeAge[4]=mul(parseInt((age.child/age.total)*1000),0.1)
            that.storeAge[5]=mul(parseInt((age.middleAged/age.total)*1000),0.1)
          //  that.drawChart();
           // that.drawChart2();
          })


          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
