import request from '@/utils/request'
// 获取店铺库存
export function storeInventory(params) {
    return request({ url: `/screen/lot/num`, params, method: 'get' })
}
//获取线下销售总量
export function saleTotal() {
    return request({ url: `/screen/v3/sale/total`, params: {}, method: 'get' })
}
//获取药店从业人员数量 /store/person
export function practitionerLsit(params) {
    return request({ url: `/store/person/page`, params, method: 'get' })
}
//异常人数 /store/person
export function abnormalNumber(params) {
    return request({ url: `/visitor/page`, params, method: 'get' })
}

//处方销量
export function prescriptionTotal(params) {
    return request({ url: `/screen/prescription/total`, params, method: 'get' })
}
//药店防疫管理从业人员
export function epidemic(params) {
    return request({ url: `/store/person/page`, params, method: 'get' })
}
//药店防疫管理
export function visitor(params) {
    return request({ url: `/visitor/page`, params, method: 'get' })
}