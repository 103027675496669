<template>
  <div :style="{padding: '0 10px'}">
    <div class="pop1 overflow" :title="form.name || form.title">{{ form.name || form.title }}</div>
    <div class="pop2">{{ form.address || form.storeName }}</div>
    <div class="pop3"><el-button type="primary" plain size="mini" style="font-size: 12px;border-radius: 4px;" @click="handleStoreDetail()">企业详情</el-button></div>
    <store :dialog-show="dialogShow" />
  </div>
</template>
<style scoped>
.pop1{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9C6E;
  line-height: 34px;
  width: 100%;
  height: 34px;
  background: #FFF2E8;
  border-radius: 4px;
  text-align: center;
}
.pop2{
  width: 265px;

  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

  text-align: center;
}
.pop3{display: flex;justify-content: center;}
.overflow{

  width:267px;

  overflow:hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  -o-text-overflow:ellipsis;

}

</style>
<script>
import Store from './Store'
export default {
  components: { Store },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    backgroundColor: {
      type: String,
      default: '#000'
    }
  },
  data() {
    return {
      dialogShow: {
        show: false
      }
    }
  },
  methods: {
    handleStoreDetail() {
      let url=this.$route.path

      if(this.form.storeId){
        this.form.name=this.form.storeName
        this.form.id=this.form.storeId
      }
          this.$router.push({path: '/yunStore?url='+url, query: this.form})
      //this.dialogShow.show = true
    }
  }
}
</script>

<style>

</style>
