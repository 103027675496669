<template>
  <div class="daping">
    <div :class="isAll?'map':'map2'">
      <bd-map ref="children"/>
    </div>
    <div class="lanBg" v-if="isAll"></div>
  <div   v-if="isAll">
    <div class="quan">
    <div style="height: 29px"></div>
    <div class="header" >
      <div class="you_btn" @click="back">
        返  回
      </div>
      <div style="height: 19px"></div>
      <div class="dabiaoti">一店一档</div>
    </div>
    <div class="main_content" v-if="isAll">
<div class="main_content_right">
  <div class="kuang_s">
    <div class="kuang_tit">信用等级</div>
    <div style="width: 425px;height:192px;padding-left: 41px;padding-top: 33px">
      <div style="float: left;width: 50%">
        <div class="xy_btn" v-if="store.id!='1380465370459328513'&&store.id!='1380465380655681537'">A</div>
        <div class="xy_btn" v-else>D</div>
      <div class="xy_wz">当前信用等级</div>
      </div>
<div style="float: left;width: 50%">
  <div class="xy_btn" v-if="store.id!='1380465370459328513'&&store.id!='1380465380655681537'">98.5%</div>
  <div class="xy_btn" v-if="store.id=='1380465370459328513'">67.3%</div>
  <div class="xy_btn" v-if="store.id=='1380465380655681537'">75.5%</div>

  <div class="xy_wz">用户好评率</div>
</div>
    </div>
  </div>
  <div class="kuang_b">
    <div class="kuang_tit">企业信息</div>
    <div class="qyxx_list">
      <div class="qyxx_list_item">
        <div class="nei">
         <div class="zuo">
           企业<br>执照
         </div>
        <div class="you" >
<div class="piclist">
<!--  <img :src="qyzz.business" width="67"/>-->
<!--  <img v-for="(x,i) in licence" :key="i" :src="x" width="67" />-->

      <el-image
          fit="scale-down"
          v-for="(x,i) in licence" :key="i"
          style="height: 67px;"
          :src="x"
          :preview-src-list="licence">
      </el-image>


</div>
</div>
          </div>
      </div>
      <div class="qyxx_list_item">
        <div class="nei">
          <div class="zuo">
            执业<br>药师
          </div>
          <div class="you">
            <div class="piclist">
              <el-scrollbar style="height:67px">
                <div :style="'width: '+zyys.length*69+'px'">
<!--                  <img v-for="(x,i) in zyys" :key="i" :src="x.image" width="67"/>-->
                  <el-image
                      v-for="(x,i) in zyys" :key="i"
                      style="height: 67px;width: 67px"
                      :src="x"
                      :preview-src-list="zyys">
                  </el-image>
                </div></el-scrollbar>

            </div>
          </div>
        </div>

      </div>
      <div class="qyxx_list_item">
        <div class="nei">
        <div class="zuo">
          药师
        </div>
        <div class="you">
          <div class="piclist">
<!--            <img v-for="(x,i) in yszz" :key="i" :src="x.image" width="67"/>-->
            <el-image
                v-for="(x,i) in yszz" :key="i"
                style="height: 67px;width: 67px"
                :src="x"
                :preview-src-list="yszz">
            </el-image>
          </div>
        </div>  </div>
      </div>
      <div class="qyxx_list_item">
        <div class="nei">
        <div class="zuo">
          员工
        </div>
        <div class="you">
          <div class="piclist">
<!--            <img v-for="(x,i) in ygzz" :key="i" :src="x.image" width="67"/>-->
            <el-image
                fit="scale-down"
                v-for="(x,i) in ygzz" :key="i"
                style="height: 67px;width: 67px"
                :src="x"
                :preview-src-list="ygzz">
            </el-image>
          </div>
        </div></div>
      </div>
    </div>
  </div>
</div>
<div class="main_content_left">
  <div class="kuang_m">
    <div class="kuang_tit">疫情管控药品销售</div>
    <div style="width: 400px; margin: 0 auto" >
<!--      <div :style="ypxsData[1][3]==0?'display: none':''">-->
        <div id="myChart" :style="{width: '400px', height: '248px'}"></div>
<!--      </div>-->

    </div>
  </div>
  <div class="kuang_m">
    <div class="kuang_tit">温湿度监控</div>
    <div style="display: flex; width: 435px; margin: 0 auto">
      <div style="width: 200px; margin: 0 auto">
        <div id="myChartWsdjk" :style="{width: '250px', height: '248px'}"></div>
      </div>
      <div style="width: 200px; margin: 0 auto">
        <div id="myChartWsdjk2" :style="{width: '250px', height: '248px'}"></div>
      </div>
    </div>

  </div>
  <div class="kuang_m">
    <div class="kuang_tit">特殊药品复方制剂销售</div>
    <div style="width: 400px; margin: 0 auto">
      <div id="myChartFfxs" :style="{width: '400px', height: '250px'}"></div>
    </div>
  </div>
</div>
<div class="main_content_center">
  <div style="height: 22px"></div>
  <div class="search">
    <div style="padding-left: 20px; padding-top: 8px;"><div style="position: absolute;margin-left: 241px;margin-top: 5px;font-size: 30px;color: #0895ee" v-if="zoom"><img src="../assets/zoom.png"/>搜索企业名称</div>
      <el-input
          :readonly="true"
          class="search_input"
          v-model="keyword"
              ></el-input>
    </div>
  </div>
  <div style="height: 525px;margin-top: 24px">
    <div><span v-for="(x,i) in video" class="circle" @click="changeTong(i)">{{i+1}}</span></div>
    <iframe
        id="ship"
        :src="ship"
        width="100%"
        height="450"
        allowfullscreen
        v-if="videoType==1"
    />
    <div v-show="videoType==2">
      <div v-show="i==tongdao" v-for="(x,i) in video">
        <video
            :id="'myVideo' + i"
            class="video-js vjs-default-skin vjs-big-play-centered"
            controls
            preload="auto"
            style="width: 100%;height: 450px"
        />
      </div>
    </div>

  </div>
  <div>
    <div class="yjtz">告警信息</div>
    <div class="gjxx_list" style="height: 268px;overflow: auto">
      <div class="gjxx_list_item" v-for="(x,i) in gjxxData" :key="i">
        <div style="float: right;overflow: hidden" class="gj_btn">
<!--          <span class="gj3" v-if="x.status==10">{{zt(x.status)}}</span>-->
<!--          <span  v-if="x.status!=10">{{zt(x.status)}}</span>-->
          <!-- {{["","无执业药师","销售预警","特殊药品","处方预警","药品禁售","跨店销售","超售预警","温湿度超标","温湿度离线"][x.type]}} -->
          {{x.title}}
        </div>
        <span :class="x.status==10?'gj3':'gj1'">{{x.storeName}}</span>
<!--        <span class="gj2">湿度</span>-->
<!--        <span class="gj3">【85%】超过规定范围值。</span>-->
      </div>

<!--      <div class="gjxx_list_item">-->
<!--        <div style="float: right" class="gj_btn">未处理</div>-->
<!--        <span class="gj1">当前告警： 2021年3月4日10:42系统发现</span>-->
<!--        <span class="gj2">湿度</span>-->
<!--        <span class="gj3">【85%】超过规定范围值。</span>-->
<!--      </div>-->


    </div>
  </div>

</div>
    </div>
  </div>
  </div>
  </div>
</template>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import axios from "axios";

import 'video.js/dist/video-js.css'
import videojs from 'video.js';
import 'videojs-contrib-hls'

export default {
  name: 'szjg',
  components: { BdMap},
  data () {
    return {
      playList: [],
      videoType:1,
      singlePlayer:"",
      tongdao:0,
      voption:[],
      ship:'',
      keyword:"",
      ypxsData:[],
      ffzjData:[],
      yjtzData:[],
      gjxxData:[],
      licence:[],
      wsdData:{},
      zjyjData:{},
      isAll: true,
      zoom: false,
      store:{},
      video:[],
      qyzz:{business:''},
      zyys:[],
      yszz:[],
      ygzz:[],
      lai:0,
      url:"",
    }
  },
  mounted(){
    this.url=this.$route.query.url
    this.lai=this.$route.query.new
    if(!this.$Cookies.get("AuthorizationToken")&&this.$login){
      window.location.href='/#/login'
    }
    this.store=this.$route.query
    if(this.store){
      this.keyword=this.store.name
    }
    this.getData()
    this.loadAll()
    this.getWsd()
    this.getFfzj()
    this.getVideo()
    this.getQyzz()
    this.getGjxx()

  },
  methods: {
    zt(t){
      let a=""
      if(t==10){
        a="待处理"
      }
      if(t==20){
        a="处理中"
      }
      if(t==30){
        a="已处理"
      }
      if(t==40){
        a="处理完成"
      }
      return a
    },
    async getGjxx(){
      let that=this
      await axios.get('/screen/v3/store/message?page=1&count=7&storeId='+this.store.id, {})
          .then(function (response) {
            that.gjxxData=response.data.data
          })
   },
    async getQyzz(){
      let that=this
      await axios.get('/screen/store/image?storeId='+this.store.id, {})
          .then(function (response) {
            that.qyzz=response.data.data
            if(that.qyzz.licence){
              that.licence=that.qyzz.licence.split(",")
            }
            that.licence.push(that.qyzz.business)

          })

      await axios.get('/screen/store/pharmacist/image?storeId='+this.store.id, {})
          .then(function (response) {
            let zyys=response.data.data
            for(let i in zyys){
              that.zyys.push(zyys[i].image)
            }

          })
      await axios.get('/screen/store/junior?storeId='+this.store.id, {})
          .then(function (response) {
             let yszz=response.data.data
            for(let i in yszz){
              that.yszz.push(yszz[i].image)
            }
          })
      await axios.get('/screen/store/clerk?storeId='+this.store.id, {})
          .then(function (response) {
            let ygzz=response.data.data
            for(let i in ygzz){
              that.ygzz.push(ygzz[i].image)
            }
          })

    },
    back(){
     //  console.log(this.lai)
     //  if(this.lai==1){
     // this.$router.push("/cloudHome")
     //  }else{
     //    this.$router.push({path: '/szjg', query: {act:"back"}})
     //  }
   this.$router.push(this.url)
    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.$id, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })

    },
   // handleSelect(item) {
      // console.log(item);
      //this.isAll=false
      //this.$refs.children.handleSelect(item)
     // this.$router.push({path: '/szjg', query: item})
   // },
    jujiao(){
      this.zoom=false
    },
    quanju(){
      this.isAll=true
      this.getData();
    },
    today() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
},
changeTong(i){
      this.tongdao=i
      let that=this
  that.ship='https://open.ys7.com/ezopen/h5/iframe?url=' + that.video[i].url + '&autoplay=1&accessToken=' + that.video[i].accessToken + '&begin=' + that.video[i].begin + '&end' + that.video[i].end+''
},
//搜素代码----------------------------------------end
    async getVideo(){
      //获取视频---
      let that=this
      await axios.get('/store/monitor', {params:{ id: this.$route.query.id }})
          .then(function (response) {
            that.video=response.data.data
            if(that.video[0].type==2){
              that.videoType=2
              setTimeout(() => {
                that.loadPlay()
              }, 1000)

            }
            if(that.video){
              that.ship='https://open.ys7.com/ezopen/h5/iframe?url=' + that.video[0].url + '&autoplay=1&accessToken=' + that.video[0].accessToken + '&begin=' + that.video[0].begin + '&end' + that.video[0].end+''
            }
            let obj={}
            for(let i in that.video){
              let j=i++
              let aa="通道"+j
              obj={value:i,label:aa}
              that.voption.push(obj)
            }

          })
      //获取视频---
    },
    loadPlay() {
      let index = 0
      this.video.forEach(item => {
        if (item.type === 2) {
          var newUrl = item.url
          const myPlayer = videojs('myVideo' + index, {
            bigPlayButton: true, // 是否显示播放按钮（这个播放按钮默认会再左上方，需用CSS设置居中）
            textTrackDisplay: true,
            posterImage: true,
            errorDisplay: true,
            controlBar: true,
            autoplay: 'muted', // 这个说是自动播放
            controls: true,
            width: '800px',
            height: '400px'
          }, function() {
            this.play()
          })
          this.playList.push(myPlayer)
          myPlayer.src(newUrl)
          myPlayer.load(newUrl)
        }
        index++
      })
    },
    kkk(url,index){
      this.singlePlayer = videojs("singleVideo"+index, {
        autoplay: true,
        controls: false,
      });
      let res = url
      if(res){
        this.singlePlayer.src({ src: res, type: "application/x-mpegURL" });
        this.singlePlayer.play();
      }

    },
    async getWsd(){

      let that=this
     await axios.get('screen/store/monitor/latest?storeId='+this.$route.query.id, {params:{ id: this.$route.query.id }})
          .then(function (response) {
            that.wsdData=response.data.data
            that.drawWsdjk("湿度",that.wsdData.hum,"myChartWsdjk")
            that.drawWsdjk("温度",that.wsdData.tem,"myChartWsdjk2")
          })

    },
    async getFfzj(){
      console.log(this.today())
      //特殊药品复方制剂销售------start
       let otime=this.getRiqi(4);
       let that=this
      that.ffzjData[0]=[]
      that.ffzjData[1]=[]
      await axios.get('/screen/store/special/sale?storeId='+this.$route.query.id+'&startTime='+otime[1]+'&endTime='+that.today()+'', {})
          .then(function (response) {

            let obj=response.data.data
            for(let key in obj){
              that.ffzjData[0].push(key)
              that.ffzjData[1].push(obj[key])

            }
            console.log("ffz",that.ffzjData)
            that.drawFfzj()
          })
      //特殊药品复方制剂销售------end
    },
    async getData(){
         let that=this
      //疫情管控药品销售------start
      let otime=this.getRiqi(4);
       that.ypxsData[0]=[]
      that.ypxsData[1]=[]
      let tt=that.today()
     await axios.get('/screen/store/epidemic/sale?storeId='+that.store.id+'&startTime='+otime[1]+'&endTime='+tt+'&region='+this.$id, {})
          .then(function (response) {
            let obj=response.data.data
            for(let key in obj){
              that.ypxsData[0].push(key)
              that.ypxsData[1].push(obj[key])
            }
            // if(that.store.id==1){
            //   that.ypxsData[1]=[1,2,1,0]
            // }

            console.log("yy",that.ypxsData)
          that.drawLine();
          })
      //疫情管控药品销售------end



    },
    go(url){
      //this.$router.push(url)
      window.location.href=url
    },
    drawLine2(){
      //疫情管控药品销售
      let myChart = echarts.init(document.getElementById('myChart'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '10%',
          left: '-5%',
          bottom: '5%',
          right: '5%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 30,
            },
            interval: 1,
          },
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, 7],
            z: 12,
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
          {
            name: '内层波浪',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [50, 15],
            symbolOffset: [0, 12],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: '#5BFCF4',
                borderType: 'solid',
                borderWidth: 8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '外层波浪',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [70, 20],
            symbolOffset: [0, 18],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: 'rgba(91,252,244,0.5)',
                borderType: 'solid',
                borderWidth: 5,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '疫情管控药品销售',
            type: 'bar',
            barWidth: '40',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: 'rgba(210,210,210,0.3)',
                  },
                  {
                    offset: 1,
                    color: '#5BFCF4',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },

          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [40, 10],
            symbolOffset: [0, 7],
            z: 12,
            color: '#5BFCF4',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawLine(){//替代方案
     var xData = this.ypxsData[0];
    var  yData = this.ypxsData[1];

    for(let i in xData){
      xData[i]=xData[i].substring(5,10)
    }

    var  option = {
      backgroundColor: '',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: '15%',
        right: '3%',
        left: '5%',
        bottom: '18%'
      },
      xAxis: [{
           type: 'category',
        data:xData,
        axisLine: {
          lineStyle: {
            color: ''
          }
        },
        axisLabel: {
          margin: 10,
          color: '#e2e9ff',
          textStyle: {
            fontSize: 14
          },
        },
        axisTick: {
          show: false
        }
      }],
      yAxis: [{
        show:false,
        axisLabel: {
          formatter: '{value}',
          color: '#e2e9ff',
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: 'rgba(0,186,255,.6)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.12)'
          }
        }
      }],
      series: [{
        type: 'bar',
        data: yData,
        barWidth: '28px',
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,244,255,1)' // 0% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(0,77,167,1)' // 100% 处的颜色
            }], false),
            shadowColor: 'rgba(0,160,221,1)',
            shadowBlur: 4,
          }
        },
        label: {
          normal: {
            show: true,
            lineHeight: 30,
            formatter: '{c}',
            position: 'top',
            textStyle: {
              color: '#00D6F9',
              fontSize: 20
            }

          }
        }
      }]
    };
      let myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption(option);
    },
    drawWsdjk(name,num,id){//温湿度
      let myChart = echarts.init(document.getElementById(id))
      var dataArr = num;
      var colorSet = {
        color: '#468EFD'
      };

      let pointColor=""
      if(id=="myChartWsdjk2"){//如果是温度，指针颜色
        pointColor="#00cdff"
      }else{//如果是湿度，指针颜色
        pointColor="#f54400"
      }
     let option = {
        backgroundColor: '',
        tooltip: {
          formatter: "{b} : {c}%"
        },

        series: [{
          name: "仪表盘内部",
          type: "gauge",
           center: ['40%', '50%'],
          radius: '40%',

          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [dataArr / 100, pointColor],//当前刻度弧线
                [1, "#111F42"]
              ],
              width: 2
            }
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,

          },
          splitLine: {
            show: false,
          },
          itemStyle: {//仪表盘指针颜色样式
            color:pointColor
          },
          detail: {
            formatter: function(value) {
              if (value !== 0) {
                var num = Math.round(value ) ;
                let unit="%"
                if(id=="myChartWsdjk2"){
                  unit="℃"
                }
                return parseInt(num).toFixed(0)+unit;
              } else {
                return 0;
              }
            },
            offsetCenter: [0, 82],
            textStyle: {
              padding: [0, 0, 0, 0],
              fontSize: 18,
              fontWeight: '700',
              color: colorSet.color
            }
          },
          title: { //标题
            show: true,
            offsetCenter: [0, 46], // x, y，单位px
            textStyle: {
              color: "#fff",
              fontSize: 14, //表盘上的标题文字大小
              fontWeight: 400,
              fontFamily: 'PingFangSC'
            }
          },
          data: [{
            name: name,
            value: dataArr,
          }],
          pointer: {
            show: true,
            length: '75%',
            radius: '20%',
            width: 10, //指针粗细
          },
          animationDuration: 4000,
        },
          {
            name: '外部刻度',
            type: 'gauge',
            center: ['40%', '50%'],//仪表盘位置
            radius: '50%',
            min: 0, //最小刻度
            max: 100, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: [
                  [1, 'rgba(0,0,0,0)']
                ]
              }
            }, //仪表盘轴线
            axisLabel: {
              show: true,
              color: '#468EFD',//刻度值 样式
              distance: 25,
              formatter: function(v) {
                switch (v + '') {
                  case '0':
                    return '0';
                  case '10':
                    return '10';
                  case '20':
                    return '20';
                  case '30':
                    return '30';
                  case '40':
                    return '40';
                  case '50':
                    return '50';
                  case '60':
                    return '60';
                  case '70':
                    return '70';
                  case '80':
                    return '80';
                  case '90':
                    return '90';
                  case '100':
                    return '100';
                }
              }
            }, //刻度标签。
            axisTick: {
              show: true,
              splitNumber: 7,
              lineStyle: {
                color: colorSet.color, //用颜色渐变函数不起作用
                width: 1,
              },
              length: -8
            }, //刻度样式
            splitLine: {
              show: true,
              length: -20,
              lineStyle: {
                color: colorSet.color, //用颜色渐变函数不起作用
              }
            }, //分隔线样式
            detail: {
              show: false
            },
            pointer: {
              show: false
            }
          },
        ]
      };
      myChart.setOption(option);
    },
    drawFfzj(){
      var xdata = this.ffzjData[0];
      var data = this.ffzjData[1];
      var maxArr = [Math.max(...this.ffzjData[1]), Math.max(...this.ffzjData[1]), Math.max(...this.ffzjData[1])];

      let myChart = echarts.init(document.getElementById('myChartFfxs'))
      var option = {
        backgroundColor: "", //背景色
        tooltip: {
          trigger: "axis",
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function(params) { // params[0].data.nameCode
            return (
                params[0].name +
                "<br>" +
                "特殊药品复方制剂销售 ：" +
                params[0].value
            );
          }
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "10%",
          top: "15%",
          containLabel: true
        },
        xAxis: {
          data: xdata,
          triggerEvent: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            rotate: 0,
            interval: 0,
            textStyle: {
              padding: [3,0,0,0],
              fontSize: 14,
              color: "rgba(255,255,255,1)"
            }
          }
        },
        yAxis: {
          show:false,
          name: "单位：个数",
          triggerEvent: true,
          nameTextStyle: {
            color: "rgba(255,255,255,1)",
            fontSize: 16,
            padding: [0,0,10,-20]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgb(148,159,167)",
              fontSize: 16
            }
          }
        },
        // color: ["#e54035"],
        series: [{
          name: "数量",
          barMinHeight: 10,
          type: "pictorialBar",
          barCategoryGap: "60%",
          // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
          symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
          itemStyle: {
            normal: {
              //barBorderRadius: 5,
              //渐变色
              color: new echarts.graphic.LinearGradient(0, 0, 0, .5, [{
                offset: 0,
                color: "#01EAED"//塔尖
              },
                {
                  offset: 0.5,//塔中
                  color: "#02C4DD"
                },
                {
                  offset: 1,//塔底
                  color: "#029ED9",
                }
              ])
            }
          },
          label: {
            normal: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize:20
              }
            }
          },
          data: data,
          z: 10
        },
          {//矩形样式
            name: "hill",
            type: "bar",
            barWidth: '20%',
            symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              normal: {
                color: "rgba(11,47,68,.2)"
              }
            },
            data: maxArr,
            z: 9
          }
        ]
      }
      myChart.setOption(option);
    },
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    }
  }
}
</script>
<style>
.circle {
  border-radius: 50%;
  width: 30px;
  height: 20px;
  border: 1px solid #ccc;
  color: white;
  margin-right: 10px;
  padding: 5px;
  cursor: pointer;
  /* 宽度和高度需要相等 */
}
.el-scrollbar__wrap{
  overflow-x: auto;
  height: calc(100% + 20px);
}

.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
.piclist{display: flex;}
.piclist .el-image{margin-right: 2px}
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.gj_btn{
  width: 100px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  background-color: #0E6B83;
  text-align: center;
  margin: 0 auto;
  border-radius:15px;
  color: white;
}
.hui{color: #A6A5A5}
.gj1{color: white}
.gj2{color: #EC7B1F}
.gj3{color: #D12929}
.gjxx_list_item{
  padding-left: 58px;
  background: url("../assets/gjxx.png") no-repeat left;
  height: 23px;
  margin-top: 10px;
}
.qyxx_list{color: white;padding-left: 50px}
.qyxx_list_item{border-bottom: 1px dashed #163f9d;height: 115px;}
.qyxx_list_item .nei{padding-top: 36px;display: flex}
.piclist{width: 307px;padding-left: 23px}
.piclist img{height: 43px;width: 67px}
.xy_wz{ text-align: center;font-size: 18px; color: #ffffff;margin-top: 43px}
.xy_btn{
  width: 132px;
  height: 49px;
  line-height: 49px;
  font-size: 30px;
  background-color: #093C6A;
  text-align: center;
  margin: 0 auto;
  border-radius:15px;
  color: #5BFCF4;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png")}
.kuang_m{
  background: url("../assets/kuang_m.png") no-repeat center;
  height: 320px;
}
.kuang_s{
  background: url("../assets/kuang_s.png") no-repeat center;
  height: 280px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 23px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 820px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}
.header{
  background: url("../assets/head.png") no-repeat center;
  height: 77px;
}

</style>
