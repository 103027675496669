<template>
<div class="dialog">
  <el-dialog title="" :visible.sync="dialogTableVisible" width="1754px" top="5vh">
    <div class="box">
      <div style="padding: 50px">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 10px"><img src="../../assets/home/4.png" ></div>
          <div class="title">从业人员健康证</div>
          <div style="margin-left: auto"><img src="../../assets/home/cha.png" @click="dialogTableVisible=false" class="hand"></div>
        </div>
        <div class="search">
          <div style="margin-right: 10px">关键字</div>
          <div><el-input v-model="post.keyword" clearable></el-input></div>
          <div style="margin-left: 20px;margin-right: 10px">药店</div>
          <div>
            <el-select v-model="post.storeId" filterable placeholder="请选择" clearable>
              <el-option
                  v-for="item in storelist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select></div>
          <div class="btnSearch hand" style="margin-left: 10px" @click="search">查询</div>
        </div>
        <div class="content" >
               <div class="biao" style="margin-top: 20px">
            <el-table :data="list" height="660">
              <el-table-column type="index" label="序号" width="50"></el-table-column>
              <el-table-column property="storeName" label="所在店铺" width="200"></el-table-column>
              <el-table-column property="name" label="姓名">
              </el-table-column>
              <el-table-column property="sex" label="性别"></el-table-column>
              <el-table-column property="number" label="编号" ></el-table-column>
              <el-table-column property="types" label="岗位">
              </el-table-column>
              <el-table-column property="phone" label="手机号"></el-table-column>
              <el-table-column property="address" label="现地址"></el-table-column>
              <el-table-column property="idcard" label="身份证号"></el-table-column>
              <el-table-column property="image" label="照片">
                <template slot-scope="scope">
                  <div v-if="scope.row.image">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="scope.row.image"
                        :preview-src-list="[scope.row.image]"
                        @click.stop="handleClickItem(scope.$index)"
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline" />
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="validDate" label="有效期">
                <template slot-scope="scope">
                  {{ scope.row.validDate.replace(' 00:00:00', '') }}
                </template>
              </el-table-column>
              <el-table-column property="status" label="操作">
                <template slot-scope="scope">
                  <el-button type="text">编辑</el-button>
                  <el-button type="text">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: center">
              <el-pagination @current-change="currentChange"  layout="total,prev, pager, next" :total="total"></el-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>

  </el-dialog>
</div>
</template>
<style scoped>
.btnSearch{
  width: 100px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #1AC9FF;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
}
.search{
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #1AC9FF;
  font-size: 18px;
}
.search >>>.el-input__inner{
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #1AC9FF;
  border-radius: 6px;
}
.content{ padding:0px 20px}
.box{
  background-image: url('../../assets/home/dialog.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 931px;
}
.title{
  font-size: 24px;
  font-weight: 500;
  color: #1AC9FF;
}
.hand{cursor: pointer}

>>>.el-pager li{background-color: transparent !important;}
>>>.el-pagination .btn-next, >>>.el-pagination .btn-prev{
  background-color: transparent !important;
}
/*el-table样式------------------------------------start*/
.biao>>> .el-table,
.biao >>> .el-table__expanded-cell {
  background-color: transparent !important;
}

.biao >>> .el-table th,>>>.el-table tr {
  border: 0 !important;
  background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>>.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row>td{
  color: #F5FDFF;
  border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
  height: 0px;
}

/*el-table样式------------------------------------end*/

/*el-dialog样式------------------------------------start*/
.dialog >>>.el-dialog{background-color: transparent}
.dialog >>>.el-dialog__header{display: none}
.dialog >>>.el-dialog__wrapper .el-dialog .el-dialog__body{padding: 0;}



/*el-dialog样式------------------------------------end*/

</style>
<script>
import {storeList,healthList} from "@/api/yaojian";
export default {
  name: "disposal",
  data(){
    return {
      storelist: [],
      value: '',
      total:0,
      post:{
        storeId:null,
        keyword:"",
        page:1,
        count:10,
      },
      list: [],
      dialogTableVisible: false,
    }
  },
  created() {
         this.getStore()
    },
  methods:{
    async getStore(){
      let rs=await storeList({})
      if(rs.code==200){
        this.storelist=rs.data
      }
    },
    search(){
      this.post.page=1
      this.getList()
    },
    currentChange(p){
      this.post.page=p
      this.getList()
    },
    async getList(){
      let rs=await healthList(this.post)
      if(rs.code==200){
        this.list=rs.data.list
        this.total=rs.data.total
      }
    },
    changeType(t){
      this.post.status=t
      this.getList()
    },
    open(){
      this.getList()
      this.dialogTableVisible=true
    }
  }
}
</script>

<style scoped>

</style>
