<template>
  <div class="daping">
    <div :class="isAll?'map':'map2'">
      <bd-map ref="children"/>
    </div>
    <div class="lanBg" v-if="isAll"></div>
    <div>
      <div style="height: 29px"></div>
      <div class="header">
        <div class="you_btn" @click="open('/')" v-if="isAll">
          返回首页
        </div>
        <div class="you_btn" @click="quanju" v-if="!isAll">
          返回全局
        </div>
        <div style="height: 19px"></div>
        <div class="dabiaoti">疫情药品销售</div>
      </div>
      <div class="main_content">

         <div class="main_content_yqyp">

          <div style="">
            <div class="kuang_center1">
              <div class="kuang_tit">疫情药品销售</div>
              <div style="width: 1830px; margin: 0 auto">
                <div style="height: 50px"></div>
                <div id="myChartYaofang" :style="{width: '1830px', height: '710px'}"></div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import axios from "axios";
export default {
  name: 'szjg',
  components: { BdMap},
  data () {
    return {
      region:0,//地区
      keyword:"",
      isAll: true,
      zoom: true,
      restaurants: [],
      selectData: {},
      jkjt: [],
      ypxsData:[],
    }
  },
  mounted(){
    if(!this.$Cookies.get("AuthorizationToken")&&this.$login){
      window.location.href='/#/login'
    }
   // this.loadAll()
    this.region=localStorage.id
    this.getYpxs()

  },
  methods: {
    open(url){
      //this.$router.push(url)
      window.location.href="/#/?region="+localStorage.id
    },
    getYpxs(){
      //疫情管控药品销售------start
      let otime=this.getRiqi(10);
      var that=this
      that.ypxsData[0]=[]
      that.ypxsData[1]=[]
      axios.get('/screen/epidemic/sale?startDate='+otime[1]+'&endDate='+that.today()+'&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data.data
            for(let key in obj){
              that.ypxsData[0].push(key)
              that.ypxsData[1].push(obj[key])
            }
            that.drawYaofang();
          })
      //疫情管控药品销售------end

    },

    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })

    },
    handleSelect(item) {
      // console.log(item);
      this.isAll=false
      this.$refs.children.handleSelect(item)
    },
    jujiao(){
      this.zoom=false
    },
    quanju(){
      this.isAll=true
      this.getData();
    },
    //搜素代码----------------------------------------end
    drawYaofang(){

      let label = this.ypxsData[0];
      let value = this.ypxsData[1];

   let   option = {
        backgroundColor: '',
        grid: {
          top: 100,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              formatter: '{value}',
              fontSize: 14,
              margin: 20,
              textStyle: {
                color: '#7ec7ff',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#243753',
              },
            },
            axisTick: {
              show: false,
            },
            data: label,
          },
        ],
        yAxis: [
          {
            boundaryGap: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#7ec7ff',
              },
            },
            nameTextStyle: {
              color: '#fff',
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#283352',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '疫情药品销售',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            itemStyle: {
              color: '#19a3df',
              borderColor: '#a3c8d8',
            },
            lineStyle: {
              normal: {
                width: 6,
                color: '#19a3df',
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(88,255,255,0.2)',
                      },
                      {
                        offset: 0.8,
                        color: 'rgba(88,255,255,0)',
                      },
                    ],
                    false
                ),
              },
            },
            data: value,
          },
        ],
      };
      let myChart = echarts.init(document.getElementById('myChartYaofang'))
      myChart.setOption(option);
    },
    go(url){
      //this.$router.push(url)
      window.location.href=url
    },
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

  }
}
</script>
<style>
.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
.jsq_top{font-size: 20px;color: white;margin-bottom: 14px}
.jsq{display: flex;flex-direction: row;font-size: 40px;color: #07DBFF}
.jsq span{
  background: url('../assets/szms/jsq.png') no-repeat;
  text-align: center;
  width: 49px;
  height: 64px;
  line-height: 64px;
  display: block;
  margin-right: 6px;
}
.jkjt_list{
  width: 410px; margin: 0 auto;
  color: white;
  font-size: 20px;
}
.jkjt_list_item{
  border-radius: 25px;
  background-color: #123E6B;
  height: 50px;
  margin-top: 18px;
  line-height: 50px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.cer_sz{
  font-size: 38px;
  color: white;
  padding-top: 143px;
}
.cer_wz{
  font-size: 18px;
  color: white;
}
.cercle2{
  width: 370px;height: 370px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle2.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.cercle{
  width: 362px;height: 362px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png"); margin: 0 auto}
.kuang_left1{
  background: url("../assets/szms/left1.png") no-repeat center;
  height: 212px;
}
.kuang_left2{
  background: url("../assets/szms/left2.png") no-repeat center;
  height: 342px;
}
.kuang_left3{
  background: url("../assets/szms/left3.png") no-repeat center;
  height: 396px;
}

.kuang_you{
  background: url("../assets/szms/right.png") no-repeat center;
  height: 950px;
}
.kuang_center1{
  background: url("../assets/yqyp.png") no-repeat center;
  width: 1890px;
  height: 974px;
  margin-top: 0px;

}
.kuang_center2{
  background: url("../assets/szqy/center2.png") no-repeat center;
  height: 502px;

}
.kuang_you2{
  background: url("../assets/szqy/right2.png") no-repeat center;
  height: 503px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 31px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 923px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}

</style>
