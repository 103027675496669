// bmpgl.js
export function BMPGL(ak) {
  return new Promise(function(resolve, reject) {
    window.init = function() {
      // eslint-disable-next-line
      resolve(BMapGL)
    }
    let isBai=document.getElementsByTagName("head")[0].innerHTML.indexOf("api.map.baidu.com")
     if(isBai>0){location.reload()}
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://api.map.baidu.com/api?v=1.0&type=webgl&ak=${ak}&callback=init`
    script.onerror = reject
    document.head.appendChild(script)

  })
}
