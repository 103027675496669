<template>
  <div id="main" style="width: 1920px;overflow: hidden">
      <div class="content">
        <disposal ref="disposal"></disposal>
        <health ref="health"></health>
        <business ref="business"></business>
        <pharmacist ref="pharmacist"></pharmacist>
        <controlAdd ref="controlAdd"></controlAdd>
        <div class="toper">
          <div style="text-align: center">智控云药房</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px">
            <div class="boxleft">
            <div class="crystalBox" style="left:305px;top:20px">
              <div id="chartCrystal" :style="{width: '262px', height: '100px'}"></div>
              </div>
             <div class="title" @click="go('/cloudFind')" style="cursor:pointer;">找好药</div>
              <div style="padding-left: 27px">
                <div  style="margin-top: 17px" class="flex"><span class="bai16">累计找到数: </span>
                  <div class="nums">
                    <span class="num" style="font-size: 22px;line-height:25px;width: 25px;height: 25px" v-for="(x,i) in findAll" :key="i">{{x}}</span>
                  </div>

                </div>
                <div style="margin-top: 17px" class="flex"><span class="bai16">今日发布：</span>
                  <div class="nums">
                    <span class="num" style="font-size: 22px;line-height:25px;width: 25px;height: 25px" v-for="(x,i) in fabu" :key="i">{{x}}</span>
                  </div>
                </div>
              </div>

              <div class="chart" style="margin-top: 20px">
                <div id="myChart" :style="{width: '600px', height: '121px'}"></div>
              </div>
              <div class="chart" style="margin-top: 20px">
                <div id="myChart2" :style="{width: '300px', height: '200px'}"></div>
              </div>
              <div class="chart" style="position: absolute;left:295px;top:287px">
 <div id="myChart3" :style="{width: '300px', height: '200px'}"></div>
              </div>

            </div>
            <div class="boxleft">
              <div class="crystalBox" style="left:305px;top:47px">
                <div id="chartCrystal2" :style="{width: '262px', height: '100px'}"></div>
              </div>
<!--              <div class="crystalBox" style="left:305px;top:42px">-->
<!--                <div class="crystal" >-->
<!--                  <div class="item">95%</div>-->
<!--                </div>-->
<!--                <div class="c bai14">在线药房成交率</div>-->
<!--              </div>-->
<!--              <div class="crystalBox" style="left:437px;top:42px">-->
<!--                <div class="crystal2" >-->
<!--                  <div class="item">92%</div>-->
<!--                </div>-->
<!--                <div class="c bai14">药品智能匹配率</div>-->
<!--              </div>-->
              <div class="title" @click="go('/cloudBuy')" style="cursor:pointer;">买好药</div>
              <div style="padding-left: 27px">
                <div  style="margin-top: 17px" class="flex"><span class="bai16">好药推荐数: </span>
                  <div class="nums">
                    <span class="num" style="font-size: 22px;line-height:25px;width: 25px;height: 25px" v-for="(x,i) in okYao" :key="i">{{x}}</span>
                  </div>
                </div>
              </div>

              <div class="chart abs" style="top:40px;left:-30px">
                <div id="chart3d_1" :style="{width: '300px', height: '300px'}"></div>
              </div>
              <div class="legend3d_1 abs" style="left:212px;top:150px;font-size: 12px">
                <div class="flex_vc" style="color: white">
                  <div class="yuan" style="background-color: rgba(0,127,244,.9)"></div>
                  <div>好评85%</div>
                </div>
                <div class="flex_vc" style="color: white">
                  <div class="yuan" style="background-color: rgba(209,126,23,.9)"></div>
                  <div>中评12%</div>
                </div>
                <div class="flex_vc" style="color: white">
                  <div class="yuan" style="background-color: rgba(20,101,0,0.9)"></div>
                  <div>差评3%</div>
                </div>
              </div>
              <div class="chart abs" style="bottom:30px">
                <div id="myChart5" :style="{width: '600px', height: '250px'}"></div>
              </div>
            </div>
          </div>
          <div style="width: 671px;margin-left: 18px" class="centerblock">
            <div style="position: relative">
              <div class="total">
                <div class="lan24" style="padding-left: 64px;padding-top: 23px">
                  <div>今日药品销售总量</div>
                  <div style="margin-top: 37px">库存药品总量</div>
                </div>
              </div>
              <div class="totalnum">
                  <div class="nums">
                    <span class="num" v-for="(x,i) in saleToday" :key="i">{{x}}</span>
                  </div>
                  <div class="nums" style="margin-top: 30px">
                    <span class="num" v-for="(x,i) in stock" :key="i">{{x}}</span>
                  </div>
                </div>
            </div>
            <div class="numrate">
              <div class="itemRateBox">
                <div class="itemRate"><div >91%</div></div>
                <div class="itemRateName">精准找药</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >85%</div></div>
                <div class="itemRateName">药房参与率</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >1.55%</div></div>
                <div class="itemRateName">处方流转率</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >73.5%</div></div>
                <div class="itemRateName">推荐成交率</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >2.99%</div></div>
                <div class="itemRateName">管控药品占比</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >35%</div></div>
                <div class="itemRateName">药箱活跃度</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >13%</div></div>
                <div class="itemRateName">库存提醒率</div>
              </div>
              <div class="itemRateBox">
                <div class="itemRate"><div >17%</div></div>
                <div class="itemRateName">药事服务率</div>
              </div>
            </div>
            <div class="mapBlock">
              <div class="hand abs btn_yjdb" style="bottom: 0px;right:0px;z-index: 5;" @click="open('http://www.yaojian.xusos.com/#/disposal/list?type=1')">预警督办</div>
             <div class="search flex">
             <div class="onekey flex">
              <div style="width: 81px;height:24px;padding-left: 20px" class="hand" @click="go('/szjg?new=1')"></div>
              <div style="width: 81px;height:24px;padding-left: 20px" class="hand" @click="open('http://www.yaojian.xusos.com/#/system/control')"></div>
             </div>
               <div class="searchBar">
                 <el-autocomplete
                     class="skey"
                     v-model="keyword"
                     :fetch-suggestions="querySearch"
                     @select="handleSelect"
                     placeholder="请输入企业名称"
                 >
                   <i slot="prefix" class="el-input__icon el-icon-search"></i>
                 </el-autocomplete>
               </div>
             </div>
             <div class="baidumap map2">
               <div class="abs" style="right: 10px;z-index: 5;color: white;font-size: 12px;top:90px;line-height: 150%">
                 <div class="flex_vc"><img src="../assets/home/m4.png"/>找药人群分布</div>
                 <div class="flex_vc"><img src="../assets/home/m3.png"/>药店区域分布</div>
                 <div class="flex_vc"><img src="../assets/home/m2.png"/>预警通知信息</div>
                 <div class="flex_vc"><img src="../assets/home/m5.png"/>下发责令整改</div>
                 <div class="flex_vc"><img src="../assets/home/m1.png"/>督办完成</div>
               </div>

               <bd-map ref="children" :znz="1"/>
             </div>
            </div>
            <div class="stock" style="position: relative">
              <div>全局库存药品溢货缺货提醒次数: <span class="lan16">24232352</span></div>
              <div class="chart" style="margin-top: 20px">
                <div id="myChart6" :style="{width: '700px', height: '128px'}"></div>
              </div>
<div class="flex abs stocksale" style="bottom: 119px;left:48px;font-size: 12px">
  <div class="item">库存</div>
  <div class="item" style="margin-right: 40px">销售</div>
  <div class="item">库存</div>
  <div class="item" style="margin-right: 40px">销售</div>
  <div class="item">库存</div>
  <div class="item" style="margin-right: 40px">销售</div>
  <div class="item" >库存</div>
  <div class="item" style="margin-right: 40px">销售</div>
  <div class="item">库存</div>
  <div class="item">销售</div>
</div>
            </div>
          </div>
          <div style="width: 606px" class="rightblock">
            <div class="rightblock1">
              <div class="abs lan14" style="left:386px;top:64px;">智能药箱数据分析</div>
              <div class="chart abs" style="top:75px;left:269px">
                <div id="myChart4_2" :style="{width: '350px', height: '150px'}"></div>
              </div>
              <div class="abs lan14" style="left:58px;top:207px">一周药事服务</div>
              <div class="chart abs" style="bottom:0px">
                <div id="myChart5_2" :style="{width: '600px', height: '150px'}"></div>
              </div>
              <div class="crystalBox" style="left:14px;top:92px">
                <div id="chartCrystal3" :style="{width: '262px', height: '100px'}"></div>
              </div>
<!--              <div class="crystalBox" style="left:305px;top:42px">-->
<!--                <div class="crystal" >-->
<!--                  <div class="item">95%</div>-->
<!--                </div>-->
<!--                <div class="c bai14">在线药房成交率</div>-->
<!--              </div>-->
<!--              <div class="crystalBox" style="left:437px;top:42px">-->
<!--                <div class="crystal2" >-->
<!--                  <div class="item">92%</div>-->
<!--                </div>-->
<!--                <div class="c bai14">药品智能匹配率</div>-->
<!--              </div>-->
              <div class="title" @click="go('/cloudUse')" style="cursor:pointer;">用好药</div>
              <div style="padding-left: 27px">
                <div  style="margin-top: 17px" class="flex"><span class="bai16">好药推荐数: </span>
                  <div class="nums">
                    <span class="num" style="font-size: 20px;line-height:25px;width: 22px;height: 22px" v-for="(x,i) in okYao" :key="i">{{x}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightblock2">
              <div class="abs lan14" style="top:44px;left:30px">一周管控药品销售趋势</div>
              <div class="chart abs" style="top:200px">
<!--                企业动态评级-->
                <div id="myChart4_3" :style="{width: '340px', height: '150px'}"></div>
              </div>
              <div class="chart abs" style="top:40px;left:20px">
<!--           一周管控药品销售趋势     药品类型数据-->
                <div id="myChart5_3" :style="{width: '600px', height: '160px'}"></div>
              </div>
              <div class="abs lan14" style="top:202px;left:30px">企业动态评级</div>

              <div class="abs flex" style="right:20px;top:218px;font-size: 12px">
                <div class="flex_vc" style="color: white">
                  <div class="yuan" style="background-color: #00B7FF"></div>
                  <div>限售</div>
                </div>
                <div class="flex_vc" style="color: white;margin-left: 10px">
                  <div class="yuan" style="background-color: #FF001E"></div>
                  <div>禁售</div>
                </div>
              </div>
              <div class="chart abs" style="right:20px;top:207px">
<!--                限售 禁售-->
                <div id="myChart_2" :style="{width: '300px', height: '130px'}"></div>
              </div>
              <div class="title" style="padding-top: 10px;cursor:pointer;" @click="go('/cloudWit')">智慧管</div>
            </div>
            <div class="rightblock3">
              <div class="title" style="padding-top: 17px">预警分析督办</div>
              <div class="chart abs" style="top:30px;left:360px">
                <div id="myChart_3" :style="{width: '250px', height: '150px'}"></div>
              </div>
              <div class="dbyj">
                <el-row class="dbyjHeader">
                  <el-col :span="1">序号</el-col>
                  <el-col :span="5">预警名称</el-col>
                  <el-col :span="3">时间</el-col>
                  <el-col :span="3">督办单位</el-col>
                  <el-col :span="3">当前状态</el-col>
                </el-row>
                <div ref="gunlist" style="height:120px;overflow: hidden">
                  <div ref="infobord1">
                  <el-row class="dbyjBody" v-for="(x,i) in message">
                    <el-col :span="1">{{i+1}}</el-col>
                    <el-col :span="5" class="huang lue"><div class="lue" style="width: 123px">{{x.title.split("-")[0]}}</div></el-col>
                    <el-col :span="3">{{x.updateDate.split(" ")[0]}}</el-col>
                    <el-col :span="3" ><div class="lue" style="width: 88px">市场监督局</div></el-col>
                    <el-col :span="3" class="lan14">已下发</el-col>
                  </el-row>

                </div>
                </div>
              </div>
<!--              <div class="flex alertRow" >-->
<!--                <div><img src="../assets/home/3.png"/> </div>-->
<!--                <div style="color: #C3F4FE">今日预警通知</div>-->
<!--                <div class="btnAlert">{{warningNotice}}</div>-->
<!--              </div>-->
<!--              <div class="flex alertRow" >-->
<!--                <div><img src="../assets/home/3.png"/> </div>-->
<!--                <div style="color: #C3F4FE">今日下发整改</div>-->
<!--                <div class="btnAlert">{{drugDisposal}}</div>-->
<!--              </div>-->
<!--              <div class="flex alertRow" >-->
<!--                <div><img src="../assets/home/3.png"/> </div>-->
<!--                <div style="color: #C3F4FE">今日闭环检查</div>-->
<!--                <div class="btnAlert">1243</div>-->
<!--              </div>-->
            </div>
          </div>
        </div>

      </div>



  </div>
</template>
<style scoped>
.btn_yjdb{
  color: #ffffff;
  border: 1px solid #506b99;
  background-color: rgba(126,199,255,0.5);
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 12px;
  text-shadow: 0px 0px 5px #000000;
}
.stocksale .item{width: 40px;text-align: center}
.ww{width: 33px}
.lue{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.yuan {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  display: block;
  /* 宽度和高度需要相等 */
}
.hand{cursor: pointer}
.dbyj{
  color: white;
  padding-left: 20px;
  padding-top: 6px;
  text-align: center;
}
.huang{color: #FEC024}
.ztlan{color: #FEC024}
.dbyjHeader{font-size: 12px}
.dbyjBody{font-size: 14px;line-height: 200%}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px;margin-top: 5px}
>>>.el-input__icon{line-height: 30px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 351px;
  color: #28C0EA;
  font-size: 16px;
  opacity: 0.7;
  height: 30px;
  line-height: 30px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 464px;
  width: 646px;
  position: absolute;
  z-index: 0;

}
.mapBlock{width: 646px;height: 464px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.flex_vc{display: flex;align-items: center;}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.title{
  padding-top: 20px;
  padding-left: 55px;
font-size: 24px;
  color: #1AC9FF;
  line-height: 31px;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import controlAdd from './components/controlAdd'
import disposal from './components/disposal'
import health from './components/health'
import business from './components/business'
import pharmacist from './components/pharmacist'
import {mul} from '../utils/jsj'
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import 'echarts-liquidfill'//水晶图组件
import 'echarts-gl'  //立体图组件
import axios from "axios";
export default {
  name: 'zhuye',
  components: { BdMap,disposal,health,business,pharmacist,controlAdd},
  data(){
    return {
      findAll:[2,4,2,3,2],
      fabu:[5,7,3],
      okYao:[2,4,2,3,2,3,5],
      comment:[3,5,1],
      keyword:"",
      region:330726,
      ypxsData:[],
      gjxxData:[],
      stock:0,
      sale:0,
      saleToday:0,
      drugRate:[],
      drugRateStock:[],
        drugCategory: {},
      drugDisposal:0,
      saleCount:0,
      warningNotice:0,
      smartType: {},
      storeAge: [],
      message: [],
    }
  },
  created() {
    console.log(process.env.VUE_APP_BASE_API,"api")
    if(!this.$Cookies.get("AuthorizationToken")){
     // window.location.href='/#/login?url=/cloudHome'
    }

  },
  mounted() {
    this.getData()
    this.getData2()
    this.rollText()
let that=this
    var timer = setInterval(()=>{
    that.getData2()
    }, 30000); //设置总量定时器更新

    },
  methods:{
      rollText() {
      var speed = 20;
      var that = this;
      function Marquee() {
            if (
            //这里控制滚动到底回顶
            that.$refs.gunlist.scrollTop >=
            that.$refs.infobord1.offsetHeight - that.$refs.gunlist.clientHeight
        ) {
          that.$refs.gunlist.scrollTop = 0;
        } else {
          that.$refs.gunlist.scrollTop++;
          // console.log(111);
          // console.log(that.$refs.gunlist.scrollTop);
          // console.log(that.infobord1.offsetTop);
        }
      }
      var MyMar = setInterval(Marquee, speed); //设置定时器
      //鼠标移上时清除定时器达到滚动停止的目的

      this.$refs.gunlist.addEventListener("mouseover", function () {
        clearInterval(MyMar);
      });
      //鼠标移开时重设定时器
      this.$refs.gunlist.addEventListener("mouseout", function () {
        MyMar = setInterval(Marquee, speed);
      });
    },

    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })

    },
    handleSelect(item) {
      this.$router.push(`/szjgdd?id=${item.id}&name=${item.name}&new=1`)
      //this.$refs.children.handleSelect(item)
    },
    //搜素代码----------------------------------------end
    go(url){
      this.$router.push(url)
    },
    open(url){
      window.location.href=url
    },
    drawChart(){
      this.ypxsData[0]=["中成药","西药","处方药","OTC","进口药","中药饮片","其它"]
      this.ypxsData[1]=[25,63,3,97,5,12,20]
      let myChart = echarts.init(document.getElementById('myChart'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            show: false,
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
            ],
      };
      // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
      let selectedIndex = '';
      let hoveredIndex = '';

// 监听点击事件，实现选中效果（单选）
      myChart.on('click', function(params) {
        // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
        let isSelected = !option.series[params.seriesIndex].pieStatus.selected;
        let isHovered = option.series[params.seriesIndex].pieStatus.hovered;
        let k = option.series[params.seriesIndex].pieStatus.k;
        let startRatio = option.series[params.seriesIndex].pieData.startRatio;
        let endRatio = option.series[params.seriesIndex].pieData.endRatio;


        // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
        if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
          option.series[selectedIndex].parametricEquation = getParametricEquation(option.series[selectedIndex].pieData.startRatio, option.series[selectedIndex].pieData.endRatio, false, false, k, option.series[selectedIndex].pieData.value);
          option.series[selectedIndex].pieStatus.selected = false;
        }

        // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
        option.series[params.seriesIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, option.series[selectedIndex].pieData.value);
        option.series[params.seriesIndex].pieStatus.selected = isSelected;

        // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
        isSelected ? selectedIndex = params.seriesIndex : null;

        // 使用更新后的 option，渲染图表
        myChart.setOption(option);
      });

// 监听 mouseover，近似实现高亮（放大）效果
      myChart.on('mouseover', function(params) {

        // 准备重新渲染扇形所需的参数
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;

        // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        if (hoveredIndex === params.seriesIndex) {
          return;

          // 否则进行高亮及必要的取消高亮操作
        } else {

          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== '') {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = option.series[hoveredIndex].pieData.startRatio;
            endRatio = option.series[hoveredIndex].pieData.endRatio;
            k = option.series[hoveredIndex].pieStatus.k;

            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            option.series[hoveredIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, option.series[hoveredIndex].pieData.value);
            option.series[hoveredIndex].pieStatus.hovered = isHovered;

            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = '';
          }

          // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
          if (params.seriesName !== 'mouseoutSeries') {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected = option.series[params.seriesIndex].pieStatus.selected;
            isHovered = true;
            startRatio = option.series[params.seriesIndex].pieData.startRatio;
            endRatio = option.series[params.seriesIndex].pieData.endRatio;
            k = option.series[params.seriesIndex].pieStatus.k;

            // 对当前点击的扇形，执行高亮操作（对 option 更新）
            option.series[params.seriesIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, option.series[params.seriesIndex].pieData.value + 5);
            option.series[params.seriesIndex].pieStatus.hovered = isHovered;

            // 记录上次高亮的扇形对应的系列号 seriesIndex
            hoveredIndex = params.seriesIndex;
          }

          // 使用更新后的 option，渲染图表
          myChart.setOption(option);
        }
      });

// 修正取消高亮失败的 bug
      myChart.on('globalout', function() {


        if (hoveredIndex !== '') {
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
         let isSelected = option.series[hoveredIndex].pieStatus.selected;
          let  isHovered = false;
          let  k = option.series[hoveredIndex].pieStatus.k;
          let  startRatio = option.series[hoveredIndex].pieData.startRatio;
          let   endRatio = option.series[hoveredIndex].pieData.endRatio;

          // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
          option.series[hoveredIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, option.series[hoveredIndex].pieData.value);
          option.series[hoveredIndex].pieStatus.hovered = isHovered;

          // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
          hoveredIndex = '';
        }

        // 使用更新后的 option，渲染图表
        myChart.setOption(option);
      });
      myChart.setOption(option);
    },
    drawChart_2(){
      let dataX=["特殊药品","疫情药品","不合格药品"]
      let dataY=[this.smartType.special,this.smartType.epidemic,this.smartType.failed]
      let myChart = echarts.init(document.getElementById('myChart_2'))
      let option = {
        color:['#00B7FF','#c90119','#00B7FF'],
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '0%',
          bottom: '0%',
          right: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: dataX,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
                fontSize:'12px'
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            show: false,
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            colorBy: dataX,
            data: dataY
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '1%',
            colorBy: dataX,
            // itemStyle: {
            //   normal: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
            //       {
            //         offset: 0,
            //         color: '#00B7FF',
            //       },
            //       {
            //         offset: 1,
            //         color: 'rgba(34,68,172,0.35)',
            //       },
            //     ]),
            //     opacity: 0.8,
            //   },
            // },
            data: dataY
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            colorBy: dataX,
            data: dataY
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart_3(){
      let dataX=["今日预警","下发督办","督办完成"]
      let dataY=[20,20,100]
      let myChart = echarts.init(document.getElementById('myChart_3'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '0%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: dataX,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
                fontSize:'12px'
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            show: false,
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: dataY
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: dataY
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: dataY
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart2(){
      let data=this.storeAge
      let data2=[50, 50, 50, 50, 50,50]
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        grid: {
          top: '0%',
          left: '10%',
          bottom: '0%',
          right: '20%',
          containLabel: true,
        },
        title: {
          text: '',
        },
        //backgroundColor: 'rgb(24, 3, 98)',
        yAxis: {
          type: 'category',
          inverse: true,
          offset: 20,
          axisLabel: {
            show: true,
            interval: 'auto',
            color: 'rgb(255,255,255)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: ['青年', '老年', '幼儿', '青少年', '儿童', '中年'],
        },
        xAxis: {
          show: false,
          type: 'value',
          offset: 30,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(106, 148, 255)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            inside: true,
            length: 10,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%',
          },
        },
        series: [
           {
            //下半截柱子
            name: '2020',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
             label: {
               color:"#ffffff",
               show: true,
               position: 'right',
               distance: 10,
               formatter: '{c}%'
             },
            itemStyle: {
              //lenged文本
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0, 111, 255)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(29, 227, 255)', // 100% 处的颜色
                      },
                    ],
                    false
                );
              },
            },
            data: data,
          },
          {
            //数据圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [5, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(27, 140, 255)'
            },
            symbolPosition: 'end',
            data: data,

          },
          {
            //最左圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [0, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(0, 111, 255)'
              //color: '#000'
            },
            symbolPosition: 'end',
            data: [1, 1, 1, 1, 1,1],
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      let color = ['#16ba13', '#03b0bf', '#0468b4', '#d41e1e', '#3F8AEF', '#F6CAA3'];
// 圆环图各环节的名称和值(系列中各数据项的名称和值)
      let data = [{
        name: '一级找药',
        value: 50,
        per: 50
      }, {
        name: '二级找药',
        value: 48,
        per: 48
      }, {
        name: '三级找药',
        value: 12,
        per: 12
      }, {
        name: '未找到',
        value: 2,
        per: 2
      }];
// 指定图表的配置项和数据
    let  option = {
        backgroundColor : '',
        title: [{
          text: '',
          bottom: '2%',
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 18
          }
        }],
        center: ['55%', '60%'],
        // 系列列表
        series: [{
          name: '圆环图系列名称', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['30%', '65%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              fontSize: 16,
              position: 'inside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: (b) => {
                return b.data.per + '%'; // 标签内容
              }
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }, {
          name: '圆环图系列名称', // 系列名称
          type: 'pie',
          center: ['50%', '50%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ['45%', '45%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
          color: color, // 圆环图的颜色
          label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              color: '#fff',
              fontSize: 12,
              position: 'outside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: '{b}' // 标签内容
            }
          },
          labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
            normal: {
              show: true, // 是否显示视觉引导线。
              length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10 // 视觉引导项第二段的长度。
            }
          },
          data: data // 系列中的数据内容数组。
        }]
      };
      let myChart = echarts.init(document.getElementById('myChart3'))
      myChart.setOption(option);
    },
    drawChart4(){
      var datas = [
        {
          name: '好评',
          value: 20,
        },
        {
          name: '中评',
          value: 10,
        },
        {
          name: '差评',
          value: 30,
        },
      ];
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

     let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 16,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };

      let myChart = echarts.init(document.getElementById('myChart4'))
      myChart.setOption(option);
    },
    drawChart4_2(){
      var datas = [
        {
          name: '正常',
          value: 81,
        },
        {
          name: '过期',
          value: 4,
        },
        {
          name: '近效期',
          value: 15,
        },
      ];
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };

      let myChart = echarts.init(document.getElementById('myChart4_2'))
      myChart.setOption(option);
    },
    drawChart4_3(){
      var datas = [
        {
          name: 'A',
          value: 20,
        },
        {
          name: 'B',
          value: 10,
        },
        {
          name: 'C',
          value: 30,
        },
        {
          name: 'D',
          value: 30,
        },
      ];
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13', '#fe5613'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }
let chartData=[{name:"A",value:20},{name:"B",value:10},{name:"C",value:30},{name:"D",value:30}]
      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: true,
          top: 'center',
          right: '0%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 15,
          formatter: (name) => {
            const item = chartData.find((i) => {
              return i.name === name;
            });
           // const p = ((item.value / sum) * 100).toFixed(0);
            return name+item.value;
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: true,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}家',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };

      let myChart = echarts.init(document.getElementById('myChart4_3'))
      myChart.setOption(option);
    },
    drawChart4_4(){
      var datas = [
        {
          name: '处方药品',
          value: 20,
        },
        {
          name: '特殊药品',
          value: 10,
        },
        {
          name: '疫情药品',
          value: 30,
        },
        {
          name: '温湿度',
          value: 30,
        },
        {
          name: '不合格药品',
          value: 30,
        },
      ];
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: true,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 16,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };

      let myChart = echarts.init(document.getElementById('myChart4_4'))
      myChart.setOption(option);
    },
    drawChart5(){
      let xLabel = ['03-14', '03-15', '03-16', '03-17', '03-18', '03-19','03-20']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']
      let data3 = ["30", "40", "22", "45", "50", "40",'56']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
           // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '处方流转量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fd5800',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '在线交易量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#15EE76',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '在线问诊量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'rgba(10,219,250,1)',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "rgba(10,219,250,1)", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "rgba(10,219,250,1)",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          }
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart5'))
      myChart.setOption(option);
    },
    drawChart5_2(){
      let data=[251.55,281.55, 398.35, 214.02, 179.55, 289.57, 356.14]
      let dataX=['03-14','03-15', '03-16', '03-17', '03-18', '03-19', '03-20']
      let option = {
        backgroundColor: '',
        title: {
          text: '',
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20,
          },
          top: '5%',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0, 255, 233,0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 255, 233,0)',
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          show:false,
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '15%',
          right: '5%',
          bottom: '20%',
          // containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            splitArea: {
              // show: false,
              color: '#f00',
              lineStyle: {
                color: '#f00',
              },
            },
            axisLabel: {
              color: '#fff',
            },
            splitLine: {
              show: false,
            },
            "axisTick":{       //y轴刻度线
              "show":false
            },
            boundaryGap: true,
            data: dataX,
          },
        ],

        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            axisLabel: {
              show: true,
              margin: 0,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '在线交易量',
            type: 'line',
            smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: 'circle',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#00ca95',
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#00ca95',
              },
            },

            itemStyle: {
              color: '#00ca95',
              borderColor: '#fff',
              borderWidth: 3,
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0,202,149,0.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,202,149,0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20,
              },
            },
            data: data,
          },
        ],
      };
      let myChart = echarts.init(document.getElementById('myChart5_2'))
      myChart.setOption(option);
    },
    drawChart5_3(){ //一周管控药品销售趋势
      let xLabel =this.drugCategory[0]
      let data = this.drugCategory[1]
      let data2 =this.drugCategory[2]
      let data3 = this.drugCategory[3]

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '12px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '25%',
          left: '10%',
          right: '10%',
          bottom: '20%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 5,
              fontSize: 12
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 12,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '特殊药品',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fd5800',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '处方药品',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#15EE76',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '疫情药品',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'rgba(10,219,250,1)',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "rgba(10,219,250,1)", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "rgba(10,219,250,1)",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          }
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart5_3'))
      myChart.setOption(option);
    },
    //药品种类占比
    drawChart6(){
      var newchartName = ['处方药占比', '近效期占比', '进口药占比', '滞销药占比', '过期药占比'],
          newchartValue1 =this.drugRateStock,
          newchartValue2 = this.drugRate;
      var path = 'path://M1390,595h79l-39,22Z';
      var barWidth = 40;
      var data =  [
        {
          name: '',
          value: 100,
          symbolPosition: 'end',
        },
        {
          name: '',
          value: 100,
          symbolPosition: 'end',
        },
        {
          name: '',
          value: 100,
          symbolPosition: 'end',
        },
        {
          name: '',
          value: '100',
          symbolPosition: 'end',
        },
        {
          name: '',
          value: '100',
          symbolPosition: 'end',
        },
      ];
      var dataBottom =  [
        {
          name: '',
          value: '100',
        },
        {
          name: '',
          value: '100',
        },
        {
          name: '',
          value: '100',
        },
        {
          name: '',
          value: '100',
        },
        {
          name: '',
          value: '100',
        },
      ];
      var color1 = {
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        type: 'linear',
        global: false,
        colorStops: [
          {
            //第一节下面
            offset: 0,
            color: '#1C98CD',
          },
          {
            offset: 1,

            color: 'rgba(61,187,255,.16)',
          },
        ],
      };
      var color2 = {
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        type: 'linear',
        global: false,
        colorStops: [
          {
            //第一节下面
            offset: 0,
            color: '#E7AB47',
          },
          {
            offset: 1,
            color: 'rgba(255,164,41,.16)',
          },
        ],
      };
      let option = {
        legend: {
          data: ['库存', '销售'],
          icon: 'rect',
          top: 5,
          right: 10,
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 12,
          },
        },
        //提示框
        tooltip: {
          show:false,
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
          formatter: function (param) {
            var resultTooltip =
                "<div style='background:rgba(13,5,30,.3);border:1px solid rgba(255,255,255,.2);padding:5px 10px;border-radius:4px;'>" +
                "<div style='text-align:center;'>" +
                param[0].name +
                '</div>' +
                "<div style='padding-top:5px;'>" +
                "<span style='display:inline-block;border-radius:4px;width:20px;height:10px;background-color:rgba(61,187,255,.3);border: 2px solid #3eb6f5;'></span>" +
                "<span style=''> " +
                param[0].seriesName +
                ': </span>' +
                "<span style=''>" +
                param[0].value +
                '</span><span>%</span>' +
                '</div>' +
                "<div style='padding-top:5px;'>" +
                "<span style='display:inline-block;border-radius:4px;width:20px;height:10px;background-color:rgba(255,164,41,.3);border: 2px solid #ffc241;'></span>" +
                "<span style=''> " +
                param[1].seriesName +
                ': </span>' +
                "<span style=''>" +
                param[1].value +
                '</span><span>%</span>' +
                '</div>' +
                "<div style='padding-top:5px;'>" +
                "<span style='display:inline-block;border-radius:4px;width:20px;height:10px;background-color:rgba(61,187,255,.16);'></span>" +
                "<span style=''> " +
                '已开工未完成' +
                ': </span>' +
                "<span style=''>" +
                (100 - param[0].value) +
                '</span><span>%</span>' +
                '</div>' +
                "<div style='padding-top:5px;'>" +
                "<span style='display:inline-block;border-radius:4px;width:20px;height:10px;background-color:rgba(255,164,41,.16);'></span>" +
                "<span style=''> " +
                '已完成未合格' +
                ': </span>' +
                "<span style=''>" +
                (100 - param[1].value) +
                '</span><span>%</span>' +
                '</div>' +
                '</div>';
            return resultTooltip;
          },
        },
        grid: {
          top: '15%',
          left: '0%',
          bottom: '15%',
          right: '10%',
          containLabel: true,
        },
        animation: false,
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.8)',
              },
            },
            axisLabel: {
              inside: true,
              textStyle: {
                color: '#fff',
                fontWeight: 'normal',
                fontSize: 12,
              },
              margin: -20, //刻度标签与轴线之间的距离。
            },
            data: newchartName,
          },
          {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            data: newchartName,
          },
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#000',
              },
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.8)',
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,.8)',
              },
            },
          },
        ],
        series: [
          {

            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 10],
            symbolOffset: ['-55%', -5],
            symbolPosition: 'end',
            z: 15,
            color: '#3eb6f5',
            zlevel: 2,
            data: newchartValue1,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 10],
            symbolOffset: ['55%', -5],
            symbolPosition: 'end',
            z: 15,
            color: '#ffc241',
            zlevel: 2,
            data: newchartValue2,
          },
          {
            name: '',
            type: 'bar',
            barGap: '10%',
            barWidth: barWidth,
            itemStyle: {
              color: color1,
              borderColor: color1,
              borderWidth: 1,
              borderType: 'solid',
            },
            label: {
              show: true,
              formatter: '{c}' + '%',
              position: 'top',
              color: 'rgba(119,167,255,1)',
              fontSize: 12,
              textAlign: 'center',
            },
            zlevel: 2,
            data: newchartValue1,
          },
          {
            name: '',
            type: 'bar',
            barGap: '10%',
            barWidth: barWidth,
            itemStyle: {
              // color: 'rgba(255,164,41,.16)',
              color: color2,
              borderColor: color2,
              borderWidth: 1,
              borderType: 'solid',
            },
            label: {
              show: true,
              formatter: '{c}' + '%',
              position: 'top',
              color: 'rgba(255,228,59,1)',
              fontSize: 12,
              textAlign: 'center',
            },
            zlevel: 2,
            data: newchartValue2,
          },
          {
            name: '',
            type: 'bar',
            xAxisIndex: 1,
            barGap: '10%',
            data: [100, 100, 100,100,100],
            zlevel: 1,
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: 'rgba(61,187,255,.16)',
              },
            },
          },
          {
            name: '',
            type: 'bar',
            xAxisIndex: 1,
            barGap: '10%',
            data: [100, 100, 100,100,100],
            zlevel: 1,
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: 'rgba(255,164,41,.16)',
              },
            },
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 10],
            symbolOffset: ['-55%', 5],
            z: 12,
            color: '#3eb6f5',

            data:dataBottom,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 10],
            symbolOffset: ['55%', 5],
            z: 12,
            color: '#ffc241',

            data: dataBottom,
          },
          {
            name: '',
            type: 'effectScatter',
            rippleEffect: {
              period: 1,
              scale: 5,
              brushType: 'fill',
            },
            z: 20,
            zlevel: 3,
            symbolPosition: 'end',
            symbolSize: [10, 4],
            symbolOffset: [-22, 0],
            itemStyle: {
              normal: {
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 5,
                shadowOffsetY: 3,
                shadowOffsetX: 0,
                color: 'rgba(119,167,255,1)',
              },
            },
            data: data,
          },
          {
            name: '',
            type: 'effectScatter',
            rippleEffect: {
              period: 1,
              scale: 5,
              brushType: 'fill',
            },
            z: 20,
            zlevel: 2,
            symbolPosition: 'end',
            symbolSize: [10, 4],
            symbolOffset: ['22', 0],
            itemStyle: {
              normal: {
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 5,
                shadowOffsetY: 3,
                shadowOffsetX: 0,
                color: 'rgba(255,164,41,0.5)',
              },
            },
            data:data,
          },
        ],
      };

      let myChart = echarts.init(document.getElementById('myChart6'))
      myChart.setOption(option);
    },
    drawChart7(){
      let list = [
        {
          value: 13,
          name: 'A',
          label: {
            color: '#666',
          },
        },
        {
          value: 12,
          name: 'B',
          label: {
            color: '#666',
          },
        },
        {
          value: 12,
          name: 'C',
          label: {
            color: '#666',
          },
        },
        {
          value: 12,
          name: 'D',
          label: {
            color: '#666',
          },
        },
      ];
      let all = 0;
      list.forEach(function(item){
        all += item.value;
      })

      let colorList = ['#FFC67E', '#5DB7D2', '#BF8AF2', '#FF945E', '#1773DD', '#61D79B'];
      let option = {
        backgroundColor: '',

        tooltip: {
          show:false,
          trigger: 'item',
          formatter: '{b} <br/> {c}' + '人',
          backgroundColor: 'rgba(67,100,247,0.8)',
          textStyle: {
            color: '#fff',
          },
          padding: [10, 10],
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: '#fff',
            },
          },
        },

        legend: {
          orient: 'vertical',
          right: '5%',
          align: 'left',
          top: 10,
          itemWidth: 13,
          itemHeight: 13,
          icon: 'circle',
          itemGap: 5,
          data: list,
          formatter: function (name) {
            console.log('name--', name);
            let data = 0
            var res = '';
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                data = ((list[i].value/all)*100).toFixed(2)+'%';
                //res += '{a|' + name + '}' + '| ' +'{b|'+data+'}' + '{b|' + list[i].value + '}';
                res += '{a|' + name + '}'  + '{b|' + list[i].value + '}';
              }
            }
            return res;
          },
          textStyle: {
            color: '#000',
            rich: {
              a: {
                fontSize: 14,
                color: '#666',
                padding: [0, 10, 0, 6],
              },
              b: {
                fontSize: 14,
                color: '#666',
                padding: [0, 6, 0, 6],
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            zlevel: 3,
            radius: ['20', '100'],
            center: ['15%', '10%'],
            color: colorList,
            itemStyle: {
              normal: {
                borderWidth: 10,
                borderColor: '#fff',
              },
            },
            data: list,
            labelLine: {
              normal: {
                show: false,
              },
            },
            label: {
              show: false,
            },
          },
        ],
      };


      let myChart = echarts.init(document.getElementById('myChart7'))
      myChart.setOption(option);
    },
    drawChartCrystal(id,arrTitle,v=[0.5,0.5]){
      let value=0
let v1=v[0]
let v2=v[1]
      var data = [v1,v1];
      var data2 = [v2,v2];
      var option = {
        title: [
          {
            text: arrTitle[0],
            x: '25%',
            y: 80,
            textAlign: 'center',
            textStyle: {
              color: '#fff',
              fontSize: 14,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: arrTitle[1],
            x: '75%',
            y: 80,
            textAlign: 'center',
            textStyle: {
              color: '#fff',
              fontSize: 14,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: (v1 * 100).toFixed(0) + '%',
            left: '25%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#a06a0a',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
          {
            text: (v2 * 100).toFixed(0) + '%',
            left: '75%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#02456d',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['25%', '50%'],
            // color:[ {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{
            //         offset: 1,
            //         color: 'rgba(2, 159, 200, 1)'
            //     }, {
            //         offset: 0.5,
            //         color: 'rgba(0, 186, 211, .5)'
            //     }, {
            //         offset: 0,
            //         color: 'rgba(0, 230, 220, 1)'
            //     }],
            //     globalCoord: false
            // }],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(251, 173, 23, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(251, 173, 23, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(251, 173, 23, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外黄边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#fdc56e'
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['75%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(0, 155, 219, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(0, 155, 219, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(0, 155, 219, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data2,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外蓝边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#07a2e3'

                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
        ],
      };

      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart3d(){
      let myChart = echarts.init(document.getElementById('chart3d_1'))
      let arr=[3,5,1]
      let  option = this.getPie3D([{
        name: '好评',
        value: arr[0],
        left:'100px',
        itemStyle: {
          opacity: 0.5,
          color:'rgba(209,126,23,.9)',
        }
      }
        , {
          name: '中评',
          value: arr[1],
          itemStyle: {
            opacity: 0.5,
            color:'rgba(0,127,244,.9)',
          }
        }
        , {
          name: '差评',
          value: arr[2],
          itemStyle: {
            opacity: 0.5,
            color:'rgba(20,101,0,0.9)',
          }
        }

      ],2);

      myChart.setOption(option);
    },
    shortDay(d){
      let a=d.slice(-5)
      return a
    },
    getData2(){
      //获取库存药品数量
      let that=this
      axios.get('/drug/store/stock/count?region='+this.region, {})
          .then(function (response) {
            that.stock=response.data.data.total
            that.stock=parseInt(that.stock)
            that.stock=that.stock.toString().split("")
          })
      //获取今日销售总量
      axios.get('/drug/store/today/sale/number?region='+this.region, {})
          .then(function (response) {
            that.saleToday=response.data.data.total
            that.saleToday=parseInt(that.saleToday)
            that.saleToday=that.saleToday.toString().split("")
          })
    },
    getData(){
      let that=this
      //预警列表数据（今日）
      axios.get('/drug/store/message?region='+this.region, {})
          .then(function (response) {
            that.message=response.data.data
          })

      //药品种类占比
      axios.get('/drug/store/drug/rate?region='+this.region, {})
          .then(function (response) {
            let drugRate=response.data.data
            that.drugRate[0]=mul(parseInt((drugRate.prescriptionCount/drugRate.totalCount)*1000),0.1)
            that.drugRate[1]=mul(parseInt((drugRate.validateCount/drugRate.totalCount)*1000),0.1)
            that.drugRate[2]=mul(parseInt((drugRate.entranceCount/drugRate.totalCount)*1000),0.1)
            that.drugRate[3]=0
            that.drugRate[4]=0

            that.drugRateStock[0]=mul(parseInt((drugRate.prescriptionStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[1]=mul(parseInt((drugRate.validateStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[2]=mul(parseInt((drugRate.entranceStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[3]=mul(parseInt((drugRate.expiredStock/drugRate.totalStock)*1000),0.1)
            that.drugRateStock[4]=mul(parseInt((drugRate.expiredStock/drugRate.totalStock)*1000),0.1)
            that.drawChart6();//药品种类占比

          })
      //药品类型数据
      let startTime=this.getRiqi(6)[0];
      axios.get('/drug/store/category/count?endTime='+that.today()+' 23:59:59&startTime='+startTime+' 00:00:00&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.drugCategory[0]=Object.keys(obj.special).map(x=>that.shortDay(x)) //日期
            that.drugCategory[1]=Object.values(obj.special)//特殊药品
            that.drugCategory[2]=Object.values(obj.prescription)//处方药品
            that.drugCategory[3]=Object.values(obj.epidemic)//疫情药品
            that.drawChart5_3();
          })

      //今日下发整改
      axios.get('/drug/store/disposal/count?region='+this.region, {})
          .then(function (response) {
            that.drugDisposal=response.data.data
          })

      //交易量（订单数）
      axios.get('/drug/store/sale/count?startTime=2022-03-01 00:00:00&endTime=2022-03-15 23:59:59&region='+this.region, {})
          .then(function (response) {
            that.saleCount=response.data.data
          })
      //今日预警通知数量
      axios.get('/drug/store/warning/notice?&region='+this.region, {})
          .then(function (response) {
            that.warningNotice=response.data.data
          })
      //首页-智慧管-右下角 分类品种统计
      axios.get('/drug/store/smart/tube/type?&region='+this.region, {})
          .then(function (response) {
            that.smartType=response.data.data
            that.drawChart_2();
          })
      //年龄段销售数据
      axios.get('/drug/store/age?region='+this.region, {})
          .then(function (response) {
            let age=response.data.data
            that.storeAge[0]=mul(parseInt((age.young/age.total)*1000),0.1)
            that.storeAge[1]=mul(parseInt((age.elderly/age.total)*1000),0.1)
            that.storeAge[2]=mul(parseInt((age.infant/age.total)*1000),0.1)
            that.storeAge[3]=mul(parseInt((age.youngster/age.total)*1000),0.1)
            that.storeAge[4]=mul(parseInt((age.child/age.total)*1000),0.1)
            that.storeAge[5]=mul(parseInt((age.middleAged/age.total)*1000),0.1)
            that.drawChart2();
          })

    //获取所有店铺
      this.loadAll()

      this.drawChart3d()
      this.drawChart();
      this.drawChart_3();

      this.drawChart3();
     // this.drawChart4();
      this.drawChart4_2();
      this.drawChart4_3();
      //this.drawChart4_4();
      this.drawChart5();
      this.drawChart5_2();
      let arr=["找到率","精准推荐率"]
      let varr=['0.98','0.85']
      this.drawChartCrystal('chartCrystal',arr,varr);
      let arr2=["在线药房成交率","药品智能匹配率"]
      let varr2=['0.13','0.87']
      this.drawChartCrystal('chartCrystal2',arr2,varr2);
      let arr3=["执业药师活跃度","智能药箱提醒率"]
      let varr3=['0.90','0.75']
      this.drawChartCrystal('chartCrystal3',arr3,varr3);
    },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

// 生成模拟 3D 饼图的配置项
getPie3D(pieData, internalDiameterRatio) {

  let series = [];
  let sumValue = 0;
  let startValue = 0;
  let endValue = 0;
  let legendData = [];
  let k = typeof internalDiameterRatio !== 'undefined' ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio): 1 / 3;

  // 为每一个饼图数据，生成一个 series-surface 配置
  for (let i = 0; i < pieData.length; i++) {

    sumValue += pieData[i].value;

    let seriesItem = {
      name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
      type: 'surface',
      parametric: true,
      wireframe: {
        show: false
      },
      pieData: pieData[i],
      pieStatus: {
        selected: false,
        hovered: false,
        k: k
      }
    };

    if (typeof pieData[i].itemStyle != 'undefined') {

      let itemStyle = {};

      typeof pieData[i].itemStyle.color != 'undefined' ? itemStyle.color = pieData[i].itemStyle.color : null;
      typeof pieData[i].itemStyle.opacity != 'undefined' ? itemStyle.opacity = pieData[i].itemStyle.opacity : null;

      seriesItem.itemStyle = itemStyle;
    }
    series.push(seriesItem);
  }

  // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
  // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
  for (let i = 0; i < series.length; i++) {
    endValue = startValue + series[i].pieData.value;
    //console.log(series[i]);
    series[i].pieData.startRatio = startValue / sumValue;
    series[i].pieData.endRatio = endValue / sumValue;
    series[i].parametricEquation = this.getParametricEquation(series[i].pieData.startRatio, series[i].pieData.endRatio, false, false, k,series[i].pieData.value);

    startValue = endValue;

    legendData.push(series[i].name);
  }



  // 准备待返回的配置项，把准备好的 legendData、series 传入。
  let option = {
    tooltip: {
      formatter: params => {
        if (params.seriesName !== 'mouseoutSeries') {
          return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
        }
      }
    },
    // legend: {
    //   data: legendData,
    //   textStyle:{
    //     color:'#fff',
    //     fontSize:26
    //   }
    // },
    labelLine: {
      show: true,
      lineStyle: {
        color: '#7BC0CB'
      }
    },
    label: {
      show: true,
      position: 'outside',
      formatter: '%',

    },
    xAxis3D: {
      min: -1,
      max: 1
    },
    yAxis3D: {
      min: -1,
      max: 1
    },
    zAxis3D: {
      min: -1,
      max: 1
    },
    grid3D: {
      show: false,
      boxHeight: 20,
      //top: '30%',
      bottom: '50%',
      environment: '',
      viewControl:{
        distance:300,
        alpha:25,
        beta:130,
      },

    },
    series: series
  };
  return option;
}
  },

}
</script>
