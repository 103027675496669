<template>
    <div id="assess" style="width: 1920px; height:1080px; overflow: hidden">
        <div class="assess-content">
            <!--            <div class="assess-header">-->
            <!--                <span style="line-height:38px">考核评级</span>-->
            <!--            </div>-->
            <yun-header />
            <div class="assess-body">
                <div class="left">
                    <!-- 部门考核评价 -->
                    <div class="evaluate">
                        <!-- 头部标题 -->
                        <div class="evaluate-header commont">
                            <div class="evaluate-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >部门考核评价</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="evaluate-content">
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                style="border-collapse:collapse"
                                width="100%"
                            >
                                <tr class="level">
                                    <td>联动单位</td>
                                    <td>处置任务数</td>
                                    <td>处置完成数</td>
                                    <td>按时完成率</td>
                                    <td>评价</td>
                                </tr>
                                <tr class="level-two" v-for="item in tableList" :key="item" @click="open(item)">
                                    <td>{{ item.unit }}</td>
                                    <td>{{ item.taskNumber }}</td>
                                    <td>{{ item.finshedNuber }}</td>
                                    <td>{{ item.ratio }}</td>
                                    <td>
                                        <div v-if="item.taskNumber == 0 && item.finshedNuber == 0">
                                            <span>—</span>
                                        </div>
                                        <div v-else>{{ item.evaluate }}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- 签约医生响应情况 -->
                    <div class="Signed">
                        <!-- 头部标题 -->
                        <div class="Signed-header commont">
                            <div class="Signed-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >签约医生考核</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="Signed-content">
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                style="border-collapse:collapse"
                                width="100%"
                            >
                                <tr class="level">
                                    <td style="width: 60px;text-align: center">序号</td>
                                    <td style="width: 200px;text-align: center">签约医生姓名</td>
                                    <td>本年服务次数</td>
                                </tr>
                            </table>

                            <div style="height:350px;overflow: hidden;">
                                <vue-seamless-scroll
                                    :data="SignedList"
                                    :class-option="defaultOption"
                                >
                                    <table
                                        cellspacing="0"
                                        cellpadding="0"
                                        border="0"
                                        style="border-collapse:collapse"
                                        width="100%"
                                    >
                                        <tr
                                            class="level-two"
                                            v-for="(item, index) in SignedList"
                                            :key="item"
                                        >
                                            <td style="width: 60px;text-align: center">
                                                {{ index + 1 }}
                                            </td>
                                            <td style="width: 200px;text-align: center">
                                                {{ item.name }}
                                            </td>
                                            <td>{{ item.ren }}</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center">
                    <!-- 评级结果运用 -->
                    <div class="rating-using">
                        <!-- 头部标题 -->
                        <div class="rating-using-header commont">
                            <div class="rating-using-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >评级结果运用</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div>
                            <div id="pjjgyy" :style="{ width: '572px', height: '400px' }"></div>
                        </div>
                    </div>

                    <!-- 药店评级赋分 -->
                    <div class="assignment">
                        <!-- 头部标题 -->
                        <div class="assignment-header commont">
                            <div class="assignment-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >药店评级赋分</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 加分动态 -->
                        <div class="awardedmarks">
                            <span>加分动态</span>
                            <img
                                style="display:block;width:23px;height:20px"
                                src="../assets/kaohe/双箭头.png"
                                alt=""
                            />
                        </div>
                        <!-- 加分表格 -->
                        <div class="awaedermarks-content">
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                style="border-collapse:collapse"
                                width="100%"
                            >
                                <tr class="first">
                                    <td style="text-align: left;padding-left: 8px;width: 200px">
                                        药店名称
                                    </td>
                                    <td style="width: 130px;">时间</td>
                                    <td style="width: 169px">加分原因</td>
                                    <td>加分值</td>
                                    <!--                                    <td><img src="../assets/kaohe/下箭头.png" alt="" /></td>-->
                                </tr>
                            </table>

                            <div style="height:100px;overflow: hidden;">
                                <vue-seamless-scroll :data="jiafen" :class-option="defaultOption">
                                    <table cellspacing="0" cellpadding="0" width="100%">
                                        <tr class="second" v-for="x in jiafen">
                                            <td style="width: 200px;padding-left: 8px;">
                                                <div class="overone" style="text-align: left">
                                                    {{ x.a1 }}
                                                </div>
                                            </td>
                                            <td style="width: 130px;">{{ x.a2 }}</td>
                                            <td style="width: 169px">{{ x.a3 }}</td>
                                            <td>{{ x.a4 }}</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                        <!-- 减分 -->
                        <div class="awardedmarks">
                            <span>减分动态</span>
                            <img
                                style="display:block;width:23px;height:20px"
                                src="../assets/kaohe/双箭头.png"
                                alt=""
                            />
                        </div>
                        <!-- 减分表格 -->
                        <div class="awaedermarks-content">
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                style="border-collapse:collapse"
                                width="100%"
                            >
                                <tr class="first">
                                    <td style="text-align: left;padding-left: 8px;width: 200px">
                                        药店名称
                                    </td>
                                    <td style="width: 130px;">时间</td>
                                    <td style="width: 169px">减分原因</td>
                                    <td>减分值</td>
                                    <!--                                    <td><img src="../assets/kaohe/下箭头.png" alt="" /></td>-->
                                </tr>
                            </table>

                            <div style="height:100px;overflow: hidden;">
                                <vue-seamless-scroll
                                    :data="warningList"
                                    :class-option="defaultOption"
                                >
                                    <table cellspacing="0" cellpadding="0" width="100%">
                                        <tr class="second" v-for="x in warningList">
                                            <td style="width: 200px;padding-left: 8px;">
                                                <div class="overone" style="text-align: left">
                                                    {{ x.storeName }}
                                                </div>
                                            </td>
                                            <td style="width: 130px;">
                                                {{ x.updateDate.split(" ")[0] }}
                                            </td>
                                            <td style="width: 169px">
                                                {{ x.title.split("-")[0] }}
                                            </td>
                                            <td>1</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- 药店动态评级趋势图 -->
                    <div class="dynamicrating">
                        <!-- 头部标题 -->
                        <div class="dynamicrating-header commont">
                            <div class="dynamicrating-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >药店动态评级趋势图</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 折线图 -->
                        <div class="dynamicrating-echart" ref="dynamicratingechart"></div>
                        <!-- <div class="dynamicrating-time">
                            <span class="active">本月</span>
                            <span>本季</span>
                            <span>本年</span>
                        </div> -->
                    </div>
                    <!-- 执业药师响应情况 -->
                    <div class="pharmacist">
                        <!-- 头部标题 -->
                        <div class="pharmacist-header commont">
                            <div class="pharmacist-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >执业药师考核</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="pharmacist-content">
                          <table
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                              style="border-collapse:collapse"
                              width="100%"
                          >
                            <tr class="level">
                              <td style="width: 40px">序号</td>
                              <td>执业药师姓名</td>
                              <td style="width: 108px">审方次数</td>
                              <td style="width: 108px">学时</td>
                            </tr>

                          </table>
                          <div style="height:350px;overflow: hidden;">
                            <vue-seamless-scroll
                                :data="SignedList"
                                :class-option="defaultOption"
                            >
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                style="border-collapse:collapse"
                                width="100%"
                            >

                                <tr
                                    class="level-two"
                                    v-for="(item, index) in pharmacistList"
                                    :key="item"
                                >
                                    <td  style="width: 40px">{{ item.a1 }}</td>
                                    <td>{{ item.a2 }}</td>
                                    <td style="width: 108px">{{ item.a3 }}</td>
                                    <td style="width: 108px">{{ item.a4 }}</td>
                                </tr>
                            </table>
                            </vue-seamless-scroll>
                          </div>
                        </div>
                    </div>
                    <!-- 评级结果运用 -->
                    <!-- <div class="rating-using"> -->
                    <!-- 头部标题 -->
                    <!-- <div class="rating-using-header commont">
                            <div class="rating-using-header-title commont-title ">
                                <span style="margin-right:15px" class="commont-txt"
                                    >评级结果运用</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                        </div> -->
                    <!-- 动态监管频次分析 -->
                    <!-- <div class="barTitle">
                            <span class="barTitle-left">动态监管频次分析</span>
                            <div class="dynamicrating-time">
                                <span class="active">本月</span>
                                <span>本季</span>
                                <span>本年</span>
                            </div>
                        </div> -->
                    <!-- <div class="rating-echart" ref="ratingechart"></div>
                        <div class="rating-content">
                            <div class="rating-content-title">
                                <div class="left">获得好商风贷</div>
                                <div class="right">
                                    <span>92</span>
                                    <img src="../assets/kaohe/箭头.png" alt="" />
                                </div>
                            </div>
                            <div class="rating-content-title">
                                <div class="left">暂停/取消医保</div>
                                <div class="right">
                                    <span>2</span>
                                    <img src="../assets/kaohe/箭头.png" alt="" />
                                </div>
                            </div>

                            <div class="rating-content-title">
                                <div class="left">暂停云药房</div>
                                <div class="right">
                                    <span>11</span>
                                    <img src="../assets/kaohe/箭头.png" alt="" />
                                </div>
                            </div>
                        </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <yun-dialog ref="childDialog" />
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import * as echarts from "echarts";
import yunHeader from "@/views/yunHeader";
import jiafen from "@/assets/data/jiafen.json";
import doctor from "@/assets/data/doctor.json";
import shangfengdai from "@/assets/data/shangfengdai.json";
import yskh from "@/assets/data/yskh.json";
import { messagePage, signTime } from "@/api/yaojian";
import moment from "moment";
import yunDialog from "@/views/yunDialog";
export default {
    components: {
        vueSeamlessScroll,
        yunHeader,
        yunDialog,
    },
    data() {
        return {
            shangfengdai: shangfengdai,
            yaoshiList: [],
            warningList: [],
            jiafen: jiafen,
            severList: [
                {
                    name: "后郎村丰收驿站",
                    time: "2022-6-5  11:00:00",
                    content: "代买药",
                    serveObject: "张*丰",
                },
            ],
            strokeCountlist: [
                {
                    id: 1,
                    name: "浦江县中余乡普丰村卫生室",
                    serveObject: "张*丰",
                },
                { id: 2, name: "测试丰收驿站", serveObject: "张*丰" },
                { id: 3, name: "浦江县中余乡普丰村卫生室", serveObject: "张*丰" },
                {
                    id: 4,
                    name: "测试丰收驿站",
                    serveObject: "张*丰",
                },
            ],
            //部门考核评价数据
            tableList: [
                {
                    unit: "医保",
                    taskNumber: 20,
                    finshedNuber: 20,
                    evaluate: "合格",
                  ratio: "75.8%",
                },
                {
                    unit: "卫健",
                    taskNumber: 2017,
                    finshedNuber: 2017,
                    evaluate: "优",
                  ratio: "96.7%",
                },
                {
                    unit: "发改",
                    taskNumber: 181,
                    finshedNuber: 181,
                    evaluate: "优",
                    ratio: "98.3%",
                },
                {
                    unit: "公安",
                    taskNumber: 2,
                    finshedNuber: 2,
                    evaluate: "良",
                  ratio: "85.6%",
                },

                {
                    unit: "政法委",
                    taskNumber: 1056,
                    finshedNuber: 1056,
                    evaluate: "优",
                  ratio: "97.8%",
                },
                {
                    unit: "市监",
                    taskNumber: 1706,
                    finshedNuber: 1706,
                    evaluate: "优",
                  ratio: "98.3%",
                },
                {
                    unit: "人社",
                    taskNumber: 490,
                    finshedNuber: 490,
                    evaluate: "优",
                  ratio: "96.8%",
                },
                {
                    unit: "农商行",
                    taskNumber: 91,
                    finshedNuber: 91,
                    evaluate: "合格",
                  ratio: "77.5%",
                },
            ],
            // 签约医生数据
            SignedList: [...doctor],
            // 执业药师响应情况数据
            pharmacistList: [],
            dynamicrating: null,
            rating: null,
        };
    },
    computed: {
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
    mounted() {
        this.dynamicrat();
        this.getWarning();
        this.getYaoshi();
        this.drawPjjgyy();
    },
    methods: {
      open(o){
        console.log(o)
        if(o.unit=='公安'){
          this.$refs.childDialog.gayjDialog=true
        }

      },
        getYaoshi() {
            // signTime({ page: 1, count: 100, year: "2022" }).then((rs) => {
            //     this.pharmacistList = rs.data.list;
            // });
            this.pharmacistList = yskh;
        },
        getWarning() {
            messagePage({
                count: 100,
                start: moment()
                    .add(-3, "day")
                    .format("yyyy-MM-DD 00:00:00"),
                end: moment().format("yyyy-MM-DD 23:59:59"),
            }).then((rs) => {
                this.warningList = rs.data.list;
            });
        },
        //评级结果运用
        drawPjjgyy() {
            // let option = {
            //     legend: {
            //         show: true,
            //         bottom: "1%",
            //         textStyle: {
            //             color: "#fff",
            //             fontSize: 16,
            //         },
            //     },
            //     series: [
            //         {
            //             name: "评级结果运用",
            //             type: "pie",
            //             radius: ["30%", "60%"],
            //             center: ["50%", "50%"],
            //             avoidLabelOverlap: true,
            //             itemStyle: { borderColor: "#fff", borderWidth: 0 },
            //             color: ["#0635E6", "#22F906", "#FEE610", "#F90606"],
            //             label: {
            //                 // alignTo: 'labelLine', // ! 文字对齐方式
            //                 formatter: function(e) {
            //                     let {
            //                         data: { value, name, remark ,proportion},
            //                     } = e;
            //                     if(name=="B级药店"){
            //                         return `{a|${proportion}%}`
            //                     }
            //                     return `{a|${proportion}%}\n{b|${value}家}{b|${remark}}`;
            //                 },
            //                 minMargin: 5,
            //                 lineHeight: 15,
            //                 rich: {
            //                     a: { fontSize: 18, color: "#fff", padding: [0, 20, 0, 8] },
            //                     b: {
            //                         fontSize: 18,
            //                         align: "left",
            //                         color: "#fff",
            //                         padding: [20, 0, 0, 0],
            //                     },
            //                 },
            //             },
            //             data: [
            //                 {
            //                     value: 91,
            //                     name: "A级药店",
            //                     remark: "好商风贷授信",
            //                     proportion:71.02,
            //                     labelLine: { show: true, length: 10, length2: 50 },
            //                 },
            //                 {
            //                     value: 20,
            //                     name: "B级药店",
            //                     remark: "",
            //                      proportion:24.43,
            //                     label: { show: true },
            //                     labelLine: { show: true },
            //                 },
            //                 {
            //                     value: 6,
            //                     name: "C级药店",
            //                     remark: "暂停云药房",
            //                      proportion:3.41,
            //                     labelLine: { show: true, length: 30, length2: 20 },
            //                 },
            //                 {
            //                     value: c,
            //                     name: "D级药店",
            //                     remark: "暂停医保",
            //                      proportion:1.14,
            //                     labelLine: { show: true, length: 30, length2: 20 },
            //                 },
            //             ],
            //         },
            //     ],
            // };

      var datas = [
        {
          name: 'A级药店',
          value: 91,
          remark: "已获好商风贷授信",
          proportion:71.02,
        },
        {
          name: 'B级药店',
          value: 20,
           remark: "",
          proportion:24.43,
        },
        {
           name: 'C级药店',
           value: 6,
           remark: "暂停云药房",
          proportion:3.41,
        },
        {
          name: 'D级药店',
          value: 3,
           remark: "暂停医保和云药房",
          proportion:1.14,
        },

      ];
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ["#0635E6", "#22F906", "#FEE610", "#F90606"]
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              remark: datas[i].remark,
              proportion:datas[i].proportion,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }
      let option = {
        backgroundColor: '',
        color: color,
       legend: {   orient: 'vertical',
                    show: true,
                    bottom: "1%",
                    textStyle: {
                        color: "#fff",
                        fontSize: 16,
                    },
                },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['30%', '55%'],
            hoverAnimation: true,
            center: ['50%', '50%'],
            itemStyle: {},
              label: {
                            // alignTo: 'labelLine', // ! 文字对齐方式
                            formatter: function(e) {
                                let {
                                    data: { value, name, remark ,proportion},
                                } = e;
                                if(name=="B级药店"){
                                    return `{a|${proportion}%}`
                                }
                                if(name=="A级药店"){
                                    return `{a|${proportion}%}\n{b|${remark}}\n{c|${value}家}`
                                }
                                return `{a|${proportion}%}\n{b|${value}家}{b|${remark}}`;
                            },
                            minMargin: 5,
                            lineHeight: 15,
                            rich: {
                                a: { fontSize: 18, color: "#fff", padding: [0, 20, 0, 8] },
                                b: {
                                    fontSize: 18,
                                    align: "left",
                                    color: "#fff",
                                    padding: [18, 0, 0, 0],
                                },
                                c: { fontSize: 19, color: "#fff",padding: [10, 0, -20, 0], },
                            },
                        },
            data: data,
          },
        ],
      };
            let myChart = echarts.init(document.getElementById("pjjgyy"));
            myChart.setOption(option);

            let that = this;
            myChart.on("click", function(params) {
                //此处的value值为饼状图里 data的name 值
                var value = params.name;

                if (value == "A级药店") {
                    that.$refs.childDialog.dialogShangfengdai = true;
                }
                if (value == "C级药店") {
                    that.$refs.childDialog.openDtpj("C级");
                }
                if (value == "D级药店") {
                    that.$refs.childDialog.openDtpj("D级");
                }
            });
        },
        // 趋势动态评级趋势图
        dynamicrat() {
          let monthArr=[]
          let nowMonth=this.$moment().format("MM")
          for(let i=nowMonth;i>=nowMonth-8;i--){
            monthArr.unshift(i+"月")
          }
            this.dynamicrating = echarts.init(this.$refs.dynamicratingechart);
            const dynamicratOption = {
                color: ["#0635E6", "#22F906", "#FEE610", "#F90606"],
                tooltip: {
                    show: true,
                },
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },
                    itemGap: 28,
                    icon: "rect",
                    left: "3%",
                },
                xAxis: {
                    type: "category",
                    data: monthArr,
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        name: "A级",
                        data: [102, 108, 116, 119, 121, 125, 120, 119, 123],
                        type: "line",
                        smooth: true,
                        itemStyle: {
                            color: "#0635E6",
                            normal: {
                                label: {
                                    show: true,
                                },
                            },
                        },
                        lineStyle: {
                            color: "#0635E6",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#0635E6",
                            },
                        },
                    },
                    {
                        name: "B级",
                        data: [61, 58, 51, 48, 42, 43, 47, 41, 43],
                        type: "line",
                        smooth: true,
                        itemStyle: {
                            color: "#22F906",
                            normal: {
                                label: {
                                    show: true,
                                },
                            },
                        },
                        lineStyle: {
                            color: " #22F906",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#22F906",
                            },
                        },
                    },
                    {
                        name: "C级",
                        data: [10, 8, 6, 6, 6, 6, 6, 6, 6],
                        type: "line",
                        smooth: true,
                        itemStyle: {
                            color: "#FEE610",
                            normal: {
                                label: {
                                    show: true,
                                },
                            },
                        },
                        lineStyle: {
                            color: "#FEE610",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 1, color: "#00D7FE " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#FEE610",
                            },
                        },
                    },
                    {
                        name: "D级别",
                        data: [1, 1, 2, 2, 2, 2, 2, 2, 3],
                        type: "line",
                        smooth: true,
                        itemStyle: {
                            color: "#F90606",
                            normal: {
                                label: {
                                    show: true,
                                },
                            },
                        },
                        lineStyle: {
                            color: "#F90606",
                        },
                        // areaStyle: {
                        //     normal: {
                        //         // 设置折线图颜色渐变
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 0, color: "#00D7FE  " },
                        //             { offset: 0, color: "#07244a" },
                        //         ]),
                        //     },
                        // },
                        labelLine: {
                            lineStyle: {
                                color: "#F90606",
                            },
                        },
                    },
                ],
            };
            this.dynamicrating.setOption(dynamicratOption);
        },
        ratingehart() {
            this.rating = echarts.init(this.$refs.ratingechart);
            const option = {
                grid: {
                    height: "100%",
                    left: "5%",
                    right: "5%",
                    bottom: 0,
                    top: 0,
                    containLabel: true,
                },
                tooltip: {
                    axisPointer: {
                        type: "none",
                    },
                },

                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisLabel: {
                            show: true,
                            align: "right",
                            interval: 0,
                            textStyle: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: ["A级", "B级", "C级", "D级"],
                    },
                    {
                        type: "category",
                        inverse: true,
                        axisTick: "none",
                        axisLine: "none",
                        show: true,
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: "#fff",
                                fontSize: "20",
                            },
                        },
                        data: [3, 5, 10, 2],
                    },
                ],
                series: [
                    {
                        name: "值",
                        type: "bar",

                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 15,
                                color: "#0277FA",
                            },
                        },
                        barWidth: 15,
                        data: [3, 5, 10, 2, 8],
                    },
                    {
                        name: "背景",
                        type: "bar",
                        barWidth: 15,
                        barGap: "-100%",
                        data: [100, 100, 100, 100, 100],
                        itemStyle: {
                            normal: {
                                color: "#071c4c",
                                barBorderRadius: 15,
                            },
                        },
                    },
                ],
            };
            this.rating.setOption(option);
        },
    },
};
</script>

<style scoped>
.hand {
    cursor: pointer;
}
.overtwo {
    width: 200px;

    word-break: break-all;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2; /* 这里是超出几行省略 */

    overflow: hidden;
}
.overone {
    width: 200px;

    word-break: break-all;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1; /* 这里是超出几行省略 */

    overflow: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#assess {
    background: url("../assets/medicine/bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#assess .assess-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.assess-content .assess-header {
    height: 51px;
    width: 100%;
    background: url("../assets/medicine/bt.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    line-height: 26px;
}
.assess-content .assess-body {
    flex: 1;
    display: flex;
    gap: 15px;
    margin-top: 10px;
    padding: 0 20px;
}
.assess-content .assess-body .commont-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.assess-content .assess-body .commont {
    display: flex;
    align-items: center;
}
.assess-content .assess-body .commont-txt {
    line-height: 28px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 6px;
}
.assess-content .assess-body .left {
    flex: 1;
}
.assess-content .assess-body .right {
    flex: 1;
}
.assess-content .assess-body .center {
    flex: 1;
}
/* .assess-content .assess-body .right .pharmacist {
    height: 473px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 14px;
} */
.assess-content .assess-body .right .pharmacist {
    margin-top: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 473px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .right .pharmacist .pharmacist-content {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.assess-content .assess-body .right .pharmacist .pharmacist-content table .level {
    height: 39px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .right .pharmacist .pharmacist-content table .level td {
    line-height: 39px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}

.assess-content .assess-body .right .pharmacist .pharmacist-content table .level-two {
    height: 59px;
    /* background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
}
.assess-content
    .assess-body
    .right
    .pharmacist
    .pharmacist-content
    table
    .level-two:nth-child(2n + 1) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}
.assess-content .assess-body .right .pharmacist .pharmacist-content .level-two td {
    line-height: 59px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}
.assess-content .assess-body .center .rating-using {
    padding: 20px;
    height: 482px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .center .rating-using .rating-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.assess-content .assess-body .center .rating-using .rating-content .rating-content-title {
    height: 56px;
    background: url("../assets/kaohe/右二.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    margin-top: 10px;
}
.assess-content .assess-body .center .rating-using .rating-content .rating-content-title .left {
    line-height: 23px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}
.assess-content .assess-body .center .rating-using .rating-content .rating-content-title .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.assess-content
    .assess-body
    .center
    .rating-using
    .rating-content
    .rating-content-title
    .right
    span {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fbc902;
    line-height: 68px;
    margin-right: 10px;
}
.assess-content
    .assess-body
    .center
    .rating-using
    .rating-content
    .rating-content-title
    .right
    img {
    width: 8px;
    height: 14px;
}
.assess-content .assess-body .center .rating-using .rating-echart {
    height: 130px;
    width: 100%;
    margin-top: 18px;
}
.assess-content .assess-body .center .rating-using .barTitle {
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    height: 39px;
    background: url("../assets/medicine/jb.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.assess-content .assess-body .center .rating-using .barTitle .dynamicrating-time {
    right: 0;
    top: 20px;
    width: 153px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #0277fa;
    display: flex;
    align-items: center;
}
.assess-content .assess-body .center .rating-using .barTitle .dynamicrating-time span {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0096ff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    border-right: 1px solid #0277fa;
}
.assess-content .assess-body .center .rating-using .barTitle .dynamicrating-time .active {
    background: #0277fa;
    color: #eeeeee;
}
.assess-content .assess-body .center .rating-using .barTitle .dynamicrating-time span:last-child {
    border: none;
}
.assess-content .assess-body .center .rating-using .barTitle .barTitle-left {
    line-height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}
.assess-content .assess-body .left .evaluate {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 482px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .evaluate .evaluate-content {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.assess-content .assess-body .left .evaluate .evaluate-content table .level {
    height: 39px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .evaluate .evaluate-content table .level td {
    line-height: 39px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}

.assess-content .assess-body .left .evaluate .evaluate-content table .level-two {
    height: 44px;
    /* background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
}
.assess-content .assess-body .left .evaluate .evaluate-content table .level-two:nth-child(2n + 1) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .evaluate .evaluate-content table .level-two td {
    line-height: 44px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}
/* 签约医生 start */
.assess-content .assess-body .left .Signed {
    margin-top: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 473px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .Signed .Signed-content {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.assess-content .assess-body .left .Signed .Signed-content table .level {
    height: 39px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .Signed .Signed-content table .level td {
    line-height: 39px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}

.assess-content .assess-body .left .Signed .Signed-content table .level-two {
    height: 59px;
    /* background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
}
.assess-content .assess-body .left .Signed .Signed-content table .level-two:nth-child(2n + 1) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .left .Signed .Signed-content .level-two td {
    line-height: 59px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
}
/* 签约医生end */
.assess-content .assess-body .center .assignment {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 15px;
    height: 473px;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .center .assignment .awaedermarks-content {
    width: 100%;
    height: 140px;
}
.assess-content .assess-body .center .assignment .awaedermarks-content table .second td {
    line-height: 35px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    vertical-align: middle;
}
.assess-content .assess-body .center .assignment .awaedermarks-content table .second {
    height: 35px;
    text-align: center;
}
.assess-content
    .assess-body
    .center
    .assignment
    .awaedermarks-content
    table
    .second:nth-child(2n + 1) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .center .assignment .awaedermarks-content table .first {
    text-align: center;
    height: 35px;
    background: url("../assets/medicine/表头.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.assess-content .assess-body .center .assignment .awaedermarks-content table .first td {
    text-align: center;
    line-height: 35px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    vertical-align: middle;
}

.assess-content .assess-body .center .assignment .awardedmarks {
    margin-top: 15px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}
.assess-content .assess-body .center .assignment .awardedmarks span {
    line-height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    margin-right: 7px;
}
.assess-content .assess-body .right .dynamicrating {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    height: 482px;
}
.assess-content .assess-body .right .dynamicrating .dynamicrating-echart {
    flex: 1;
    margin-top: 23px;
}
.assess-content .assess-body .right .dynamicrating .dynamicrating-time {
    position: absolute;
    right: 20px;
    top: 15%;
    width: 153px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #0277fa;
    display: flex;
    align-items: center;
}
.assess-content .assess-body .right .dynamicrating .dynamicrating-time span {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0096ff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    border-right: 1px solid #0277fa;
}
.assess-content .assess-body .right .dynamicrating .dynamicrating-time .active {
    background: #0277fa;
    color: #eeeeee;
}
.assess-content .assess-body .right .dynamicrating .dynamicrating-time span:last-child {
    border: none;
}
</style>
