<template>
  <div class="daping" style="width: 1920px;">
    <div :class="isAll?'map':'map2'" >
      <bd-map ref="children"/>
    </div>
    <div class="lanBg" v-if="isAll"></div>
    <div>
      <div style="height: 29px"></div>
      <div class="header">
        <div class="you_btn" @click="go('/#/zhuye?region='+region)" v-if="isAll">
          返回首页
        </div>
        <div class="you_btn" @click="quanju" v-if="!isAll">
          返回全局
        </div>
        <div style="height: 19px"></div>
        <div class="dabiaoti">数字企业</div>
      </div>
      <div class="main_content">
        <div class="main_content_right">
          <div class="kuang_you1">
            <div class="kuang_tit">一键办事</div>
            <div style="width: 400px; margin: 0 auto">
              <div id="myChartYjbs" :style="{width: '400px', height: '389px'}"></div>
            </div>
          </div>
          <div class="kuang_you2">
            <div class="kuang_tit">培训情况</div>
            <div style="width: 400px; margin: 0 auto">
              <div id="myChartPxqk" :style="{width: '400px', height: '389px'}"></div>
            </div>
          </div>
        </div>
        <div class="main_content_left">
          <div class="kuang_left">
            <div class="kuang_tit">企业信息</div>
            <div class="cercle">
              <div>
                <div class="cer_sz">{{szqy.store}}</div>
                <div class="cer_wz">企业总数量</div>
  </div>
            </div>
            <div class="cercle2">
              <div>
                <div class="cer_sz">{{szqy.pharmacist}}</div>
                <div class="cer_wz">执业药师</div>
              </div>
            </div>
          </div>

        </div>
        <div class="main_content_center">
          <div style="height: 22px"></div>
          <div class="search">
            <div style="padding-left: 20px; padding-top: 8px;"><div style="position: absolute;margin-left: 241px;margin-top: 5px;font-size: 30px;color: #0895ee" v-if="zoom"><img src="../assets/zoom.png"/>搜索企业名称</div>
              <el-autocomplete
                  @focus="jujiao()"
                  class="search_input"
                  v-model="keyword"
                  :fetch-suggestions="querySearch"
                  @select="handleSelect"
              ></el-autocomplete>
            </div>
          </div>
          <div style="">
            <div class="kuang_center1">
              <div class="kuang_tit">药师情况</div>
              <div style="width: 832px; margin: 0 auto">
                <div id="myChartZzqk" :style="{width: '832px', height: '248px'}"></div>
              </div>
            </div>
            <div class="kuang_center2">
              <div class="kuang_tit">企业信用等级排行</div>
              <div >
                <div style="height: 20px">
                      </div>
                   <div class="djph_list">
                     <div style="display: flex;flex-direction: row;color: white;padding-left: 30px;">
                       <div  style="width: 340px;text-align: left">名称</div>
                       <div  style="width: 266px;text-align: center">日期</div>
                       <div  style="width: 100px;text-align: center">级别</div>
                     </div>
                     <div style="height: 363px" class="scroll">
                       <div class="djph_list_item" v-for="(x,i) in restaurants" >
                         <div class="left" style="width: 340px;height:50px;overflow: hidden">{{x.name}}</div>
                         <div class="left" style="width: 266px;text-align: center">{{x.createDate.substring(0,10)}}</div>
                         <div class="left" style="width: 100px;text-align: center">A 级</div>
                       </div>
                     </div>


                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<style scoped >

 .scroll{
             overflow: auto;
   padding-right: 10px;
         }

         .scroll::-webkit-scrollbar {

             width: 10px;

            height: 10px;

         }

         /*正常情况下滑块的样式*/

         .scroll::-webkit-scrollbar-thumb {

             background-color: #123E6B;

             border-radius: 10px;

         }

         /*鼠标悬浮在该类指向的控件上时滑块的样式*/

         .scroll:hover::-webkit-scrollbar-thumb {

            background-color: rgba(0,0,0,.2);

             border-radius: 10px;

         }

         /*鼠标悬浮在滑块上时滑块的样式*/

         .scroll::-webkit-scrollbar-thumb:hover {
             background-color: rgba(0,0,0,.8);

         }

         /*正常时候的主干部分*/

         .scroll::-webkit-scrollbar-track {

             border-radius: 10px;

            background-color: #00797d;

         }

         /*鼠标悬浮在滚动条上的主干部分*/

         .scroll::-webkit-scrollbar-track:hover {
             background-color: #00797d;

         }


</style>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import axios from "axios";
export default {
  name: 'szqy',
  components: { BdMap},
  data () {
    return {
      region:0,
      keyword:"",
      isAll: true,
      zoom: true,
      restaurants: [],
      selectData: {},
      szqy:{},
      zyysSign:0,
      zyysZgl:0,
      zyysNum:0
    }
  },
  mounted(){
    if(!this.$Cookies.get("AuthorizationToken")&&this.$login){
      window.location.href='/#/login'
    }
    this.region=localStorage.id
    this.loadAll()
    this.drawPxqk()
    this.drawlineYjbs()
    this.getSzqy()
    this.getSign()


  },
  methods: {
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    getYsrs(){
      let that=this
      axios.get('/screen/index?region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data
            that.zyysNum=obj.pharmacist
            that.zyysZgl=Math.round(that.zyysSign*100/that.zyysNum)
            that.drawZzqk()
          })
    },
    getSign(){
      let that=this
        axios.get('/screen/pharmacist/duty?startTime='+that.today()+'&endTime='+that.today()+'&region='+this.region, {})
            .then(function (response) {
              let obj=response.data.data
              let arr = Object.keys(obj).map(function(i){return obj[i]});
              that.zyysSign=arr[0].sign
              that.getYsrs()
            })
    },
    getSzqy(){
      let that=this
      axios.get('/screen/count?region=330726', {})
          .then(function (response) {
            let obj=response.data.data
            that.szqy=obj
          })

    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })

    },
    handleSelect(item) {
      // console.log(item);
      this.isAll=false
      this.$refs.children.handleSelect(item)
    },
    jujiao(){
      this.zoom=false
    },
    quanju(){
      this.isAll=true
      this.getData();
    },
    //搜素代码----------------------------------------end
    go(url){
     // this.$router.push(url)
      window.location.href=url
    },
    drawZzqk(){
      let myChart = echarts.init(document.getElementById('myChartZzqk'))
      let total=this.zyysNum
     let  data = [{
        name: '总人数',
        value: 100,
        sum: 100
      },
        {
          name: '执业药师在岗率',
          value: this.zyysZgl,
          sum: 100
        },
        {
          name: '存在违规',
          value: 2,
          sum: 100
        }
      ];
      var getArrByKey = (data, k) => {
        let key = k || "value";
        let res = [];
        if (data) {
          data.forEach(function(t) {
            res.push(t[key]);
          });
        }
        return res;
      };
      var getSymbolData = (data) => {
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          arr.push({
            value: data[i].value,
            symbolPosition: 'end'
          })
        }
        return arr;
      }
      // var data = data.sort((a, b) => {//排序
      //   return b.value - a.value
      // });
      var option = {
        backgroundColor: '',
        grid: {
          top: '2%',
          bottom: -15,
          right: 30,
          left: 30,
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          triggerEvent: true,
          show: false,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            interval: 0,
            color: '#fff',
            align: 'left',
            margin: 80,
            fontSize: 13,
            formatter: function(value, index) {
              //console.log(index)
              return '{title|' + value + '}'
            },
            rich: {
              title: {
                width: 165
              }
            }
          },
        }, {
          triggerEvent: true,
          show: true,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            shadowOffsetX: '-20px',
            color: '#fff',
            align: 'right',
            verticalAlign: 'bottom',
            lineHeight: 30,
            fontSize: 13,
            formatter: function(value, index) {
              //console.log(index)
              let str=""
              if(index>0){str="%"
                return data[index].value+str
              }else{
                return total
              }

            },
          }
        }],
        series: [
             {
          name: '条',
          type: 'bar',
          showBackground: true,
          barBorderRadius: 30,
          yAxisIndex: 0,
          data: data,
          barWidth: 10,
          // align: left,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [{
                    offset: 0,
                    color: '#80FFC5'
                  },
                    {
                      offset: 0.7,
                      color: '#0ACB89'
                    },
                    {
                      offset: 1,
                      color: '#0ACB89'
                    }
                  ],
                  false
              ),
              barBorderRadius: 10
            },
            // color: '#A71A2B',
            barBorderRadius: 4,
          },
          label: {
            normal: {
              color: '#fff',
              show: true,
              position: [0, '-20px'],
              textStyle: {
                fontSize: 16
              },
              formatter: function(a, b) {
                //console.log(b)
                return a.name
              }
            }
          }
        }]
      };
      myChart.setOption(option);
    },
    drawPxqk(){
      let data = [{
        name: '一期培训率',
        value: 82,
        sum: 100
      },
        {
          name: '二期培训率',
          value: 91,
          sum: 100
        },
        {
          name: '三期培训率',
          value: 97,
          sum: 100
        },
        {
          name: '四期培训率',
          value: 63,
          sum: 100
        }
      ];
      var getArrByKey = (data, k) => {
        let key = k || "value";
        let res = [];
        if (data) {
          data.forEach(function(t) {
            res.push(t[key]);
          });
        }
        return res;
      };
      var getSymbolData = (data) => {
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          arr.push({
            value: data[i].value,
            symbolPosition: 'end'
          })
        }
        return arr;
      }

      // var data = data.sort((a, b) => {
      //   return b.value - a.value
      // });
      var option = {
        backgroundColor: '',
        grid: {
          top: '2%',
          bottom: -15,
          right: 30,
          left: 30,
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: [{
          triggerEvent: true,
          show: false,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            interval: 0,
            color: '#fff',
            align: 'left',
            margin: 80,
            fontSize: 13,
            formatter: function(value, index) {
              //console.log(index)
              return '{title|' + value + '}'
            },
            rich: {
              title: {
                width: 165
              }
            }
          },
        }, {
          triggerEvent: true,
          show: true,
          inverse: true,
          data: getArrByKey(data, 'name'),
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            shadowOffsetX: '-20px',
            color: '#fff',
            align: 'right',
            verticalAlign: 'bottom',
            lineHeight: 30,
            fontSize: 13,
            formatter: function(value, index) {
              //console.log(index)
             // return (data[index].value / data[index].sum) * 100
              return data[index].value+"%"
            },
          }
        }],
        series: [{
          name: 'XXX',
          type: 'pictorialBar',
          symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
          symbolSize: [50, 50],
          symbolOffset: [20, 0],
          z: 12,
          itemStyle: {
            normal: {
              color: '#ffffff'
            }
          },
          data: getSymbolData(data)
        }, {
          name: '条',
          type: 'bar',
          showBackground: true,
          barBorderRadius: 30,
          yAxisIndex: 0,
          data: data,
          barWidth: 10,
          // align: left,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [{
                    offset: 0,
                    color: '#01A2F8'
                  },
                    {
                      offset: 0.7,
                      color: '#00EAD7'
                    },
                    {
                      offset: 1,
                      color: '#00EAD7'
                    }
                  ],
                  false
              ),
              barBorderRadius: 10
            },
            // color: '#A71A2B',
            barBorderRadius: 4,
          },
          label: {
            normal: {
              color: '#fff',
              show: true,
              position: [0, '-20px'],
              textStyle: {
                fontSize: 16
              },
              formatter: function(a, b) {
                //console.log(b)
                return a.name
              }
            }
          }
        }]
      };

      let myChart = echarts.init(document.getElementById('myChartPxqk'))
      myChart.setOption(option);
    },
    //一件办事图表相关代码开始------------start
    drawlineYjbs(){
      var   data = [{
        name: "一键审核",
        value: 100
      },
        {
          name: "一键监测",
          value: 75
        },
        {
          name: "一键导出",
          value: 50
        },
        {
          name: "一键回复",
          value: 25
        }
      ];
    var  arrName = this.getArrayValue(data, "name");
      var arrValue = this.getArrayValue(data, "value");
      var  sumValue = eval(arrValue.join('+'));
      var objData = this.array2obj(data, "name");
      var optionData = this.getDatayj(data,sumValue)
     var option = {
        backgroundColor: '',
        title : {
          text : ''
        },
        legend: {
          show: true,
          icon: "circle",
          top: '18%',
          bottom: '53%',
          left: "30%",
          data: arrName,
          width: 40,
          padding: [0, 16],
          itemGap: 8,
          formatter: function(name) {
            return "{title|" + name + "} {value|" + (objData[name].value) + "}  {title|%}"
          },

          textStyle: {
            rich: {
              title: {
                fontSize: 20,
                lineHeight: 30,
                color: "rgb(0, 178, 246)"
              },
              value: {
                fontSize: 18,
                lineHeight: 20,
                color: "#fff"
              }
            }
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a}<br>{b}:{c}({d}%)"
        },
        color: ['rgb(9,187,247)', 'rgb(184,254,165)', 'rgb(253,218,23)', 'rgb(252,152,12)'],
        xAxis: [{
          show: false
        }],
        series: optionData.series
      };
      let myChart = echarts.init(document.getElementById('myChartYjbs'))
      myChart.setOption(option);

    },
    getArrayValue(array, key){
   key = key || "value";
  var res = [];
  if (array) {
    array.forEach(function(t) {
      res.push(t[key]);
    });
  }
  return res;
},
    array2obj(array, key){
  var resObj = {};
  for (var i = 0; i < array.length; i++) {
    resObj[array[i][key]] = array[i];
  }
  return resObj;
},
     getDatayj(data,sumValue){
  var res = {
    series: [],
    yAxis: []
  };
  for (let i = 0; i < data.length; i++) {
    res.series.push({
      name: '',
      type: 'pie',
      clockWise: false, //顺时加载
      hoverAnimation: false, //鼠标移入变大
      radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
      center: [143, "55%"],
      label: {
        show: false
      },
      itemStyle: {
        label: {
          show: false,
        },
        labelLine: {
          show: false
        },
        borderWidth: 5,
      },
      data: [{
        value: data[i].value,
        name: data[i].name
      }, {
        value: sumValue - data[i].value,
        name: '',
        itemStyle: {
          color: "rgba(0,0,0,0)",
          borderWidth: 0
        },
        tooltip: {
          show: false
        },
        hoverAnimation: false
      }]
    });
    res.series.push({
      name: '',
      type: 'pie',
      silent: true,
      z: 1,
      clockWise: false, //顺时加载
      hoverAnimation: false, //鼠标移入变大
      radius: [73 - i * 15 + '%', 68 - i * 15 + '%'],
      center: [143, "55%"],
      label: {
        show: false
      },
      itemStyle: {
        label: {
          show: false,
        },
        labelLine: {
          show: false
        },
        borderWidth: 5,
      },
      data: [{
        value: 7.5,
        itemStyle: {
          color: "rgb(3, 31, 62)",
          borderWidth: 0
        },
        tooltip: {
          show: false
        },
        hoverAnimation: false
      }, {
        value: 2.5,
        name: '',
        itemStyle: {
          color: "rgba(0,0,0,0)",
          borderWidth: 0
        },
        tooltip: {
          show: false
        },
        hoverAnimation: false
      }]
    });
    res.yAxis.push((data[i].value / sumValue * 100).toFixed(2) + "%");
  }
  return res;
},
    //一件办事图表相关代码------------end

  }
}
</script>
<style>
.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
/*.daping{margin: 0 auto;*/
/*  height: 1080px;*/
/*  width: 1920px;*/

/*}*/
.djph_list{
  width: 803px; margin: 0 auto;
  color: white;
  font-size: 20px;
}
.djph_list_item{
  border-radius: 25px;
  background-color: #123E6B;
  height: 55px;
  margin-top: 18px;
  line-height: 55px;
  padding-left: 30px;
}

.cer_sz{
  font-size: 38px;
  color: white;
  padding-top: 143px;
}
.cer_wz{
  font-size: 18px;
  color: white;
}
.cercle2{
  width: 370px;height: 370px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle2.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.cercle{
  width: 362px;height: 362px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png"); margin: 0 auto}
.kuang_left{
  background: url("../assets/szqy/left.png") no-repeat center;
  height: 942px;
}
.kuang_you1{
  background: url("../assets/szqy/right1.png") no-repeat center;
  height: 448px;
}
.kuang_center1{
  background: url("../assets/szqy/center1.png") no-repeat center;
  height: 348px;
  margin-top: 16px;

}
.kuang_center2{
  background: url("../assets/szqy/center2.png") no-repeat center;
  height: 502px;

}
.kuang_you2{
  background: url("../assets/szqy/right2.png") no-repeat center;
  height: 503px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 23px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 923px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}

</style>
