<template>
  <div style="width: 100%; height: 100%">
    <!-- <div style="width: 100%;height: 5%;text-align: center;font-size: 20px;font-family: FZZYJW--GB1-0, FZZYJW--GB1;font-weight: normal;color: #FFFFFF;line-height: 5%;letter-spacing: 10px;">数字监控</div> </div>-->
    <div style="height:100%;width:100%;">
      <div id="container" style="height:100%;width:100%;" />
    </div>
    <div style="display: none;">
      <info-windows ref="infowindow" :form="selectData" :background-color="backgroundColor" />
    </div>
  </div>
</template>

<script>
import { BMPGL } from '@/assets/bmpgl.js'
import { storeList, disposalList,storeNotice } from '@/api/screen'
import customMap from '@/assets/custom_map_config.json'
import InfoWindows from './components/InfoWindows'
import { get_message } from '@/assets/message'
import moment from "moment";
export default {
  components: { InfoWindows },
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      url:"",
      region:localStorage.id,
      backShow: false,
      ak: 'SR4XOvuAhNfufh25pCumRCgMU8sbVM2z',
      myMap: null,
      bmpBL: null,
      shawMarker: null,
      tempData: [],
      name: '',
      restaurants: [],
      selectData: {
        id: '',
        name: '',
        address: ''
      },
      backgroundColor: '#fff',
      dialogShow: {
        show: false
      }
    }
  },
  mounted() {
    let url=this.$route.path
    if (location.href.indexOf("#reloaded") == -1) {
      // location.href = location.href + "#reloaded";
      // location.reload();
    }
    // 传入密钥获取地图回调。
    const _this = this
    let city=this.$city.find(item => item.code ==localStorage.id);
    BMPGL(this.ak).then((BMapGL) => {
      _this.bmpBL = BMapGL
      // 创建地图实例
      const map = new BMapGL.Map('container')
     let initPoint=[119.656028,29.089564,10]  //金华坐标
/*      if(this.region=='330700'){
        initPoint=[119.656028,29.089564,10]  //金华坐标
      }
      if(this.region=='330726'){
        initPoint=[119.908, 29.466,11]//浦江坐标
      }*/
      initPoint=[city.longitude, city.latitude,11]//坐标
      map.centerAndZoom(new BMapGL.Point(initPoint[0], initPoint[1]), initPoint[2])

      map.enableScrollWheelZoom()
      map.setMapType(BMAP_EARTH_MAP);
      // map.setHeading(64.5)
     map.setMapStyleV2({ styleJson: customMap })
      var navi3DCtrl = new BMapGL.NavigationControl3D({ offset: new this.bmpBL.Size(0, 0) }) // 添加3D控件
      map.addControl(navi3DCtrl)



      this.loadAll()
      this.loadDisposal()
      // map.setMapStyle2({ style: 'midnight' })
      // map.setTilt(73)
      // map.setDisplayOptions({
      //   skyColors: ['rgba(186, 0, 255, 0)', 'rgba(186, 0, 255, 0.2)']
      // })
      // map.setStyle({ fontSize: '64px' })
      // console.log('map', map)

      //画框开始=-------------------------------------start
      new BMapGL.Boundary().get(city.name, (rs) => {
        var count = rs.boundaries.length // 行政区域的点有多少个
        for (var i = 0; i < count; i++) {
          var ply= new BMapGL.Polygon(rs.boundaries[i],

              {strokeWeight:2, //设置多边形边线线粗

                strokeOpacity:1, //设置多边形边线透明度0-1
                StrokeStyle: "solid", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed
                strokeColor:"#FF8C33", //设置多边形边线颜色
              fillColor: "#00ffff", //设置多边形填充颜色
                fillOpacity:0 //设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下

              });//建立多边形覆盖物

          map.addOverlay(ply); //添加覆盖物
          map.setViewport(ply.getPath()); //调整视野
        }
      })
      // new BMapGL.Boundary().get('浦江县', (rs) => {
      //   var count = rs.boundaries.length // 行政区域的点有多少个
      //   for (var i = 0; i < count; i++) {
      //     var path = []
      //     const str = rs.boundaries[i].replace(' ', '')
      //     const points = str.split(';')
      //     for (var j = 0; j < points.length; j++) {
      //       var lng = points[j].split(',')[0]
      //       var lat = points[j].split(',')[1]
      //       path.push(new BMapGL.Point(lng, lat))
      //     }
      //     var prism = new BMapGL.Prism(path, 500, {
      //       topFillColor: '#09216d',//填充色
      //       topFillOpacity: 0.6,
      //       sideFillColor: '#000000',//边线色
      //       sideFillOpacity: 1
      //
      //     })
      //     map.addOverlay(prism)
      //   }
      // })
      //画框=-------------------------------------end

      map.setDisplayOptions({
        poiIcon: false
        // poiText: false
      })
      // var label = new BMapGL.Label('温湿度超标', { offset: new BMapGL.Size(0, -40) })
      // label.setStyle({ color: 'red' })
      // marker.setLabel(label)
      // 保存数据
      this.myMap = map
      // this.showDisposalMarker({ lng: 119.90803105992, lat: 29.466875606848, title: '温湿度超标整改', storeName: '浦江县履信太和堂医药零售有限公司' })
      // this.myMap.addOverlay(new BMapGL.Marker(new BMapGL.Point(119.903937, 29.520086)))
      // this.showWarnMarker({ lng: 119.90803105992, lat: 29.466875606848, title: '特殊药品复方制剂跨店销售预警', storeName: '浦江永济堂药店' })
      this.loadStoreList()
    })

    get_message((res) => {
      const msgData = JSON.parse(res.data)
      if (msgData.lng != null && msgData.lng !== '') {
        // this.tempData.push(msgData)
        if (msgData.type === 1) { // 预警消息
          this.tempData.push(msgData)
        } else { // 警示
          this.showDisposalMarker(msgData)
        }
      }
    })

    setInterval(() => {
      if (this.tempData.length > 0) {
        _this.showWarnMarker(this.tempData[0])
      }
      // this.showWarnMarker({ lng: 119.90803105992, lat: 29.466875606848, title: '健康证到期提醒', storeName: '浦江春来药房' })
    }, 3000)
  },
  methods: {
    icoAni(){
      // let divElement = document.createElement('div');
      // divElement.innerHTML="<div style='font-size: 50px'>hello</div>"
      // document.getElementsByClassName("BMap_Marker").appendChild(divElement)

    },
zoom(point={lng:119.92528,lat:29.454017},num){
      this.myMap.centerAndZoom(new BMapGL.Point(point.lng, point.lat), num)
    },
    loadAll() {
      return
      storeList({region:localStorage.id,lai:"shopZuo"}).then(res => {
        this.restaurants = res.data.map(x => {
          return {
            value: x.name + (x.lng != null && x.lng !== '' ? '' : '（无坐标）'),
            address: x.address,
            name: x.name,
            lng: x.lng,
            lat: x.lat,
            id: x.id
          }
        })
      })
    },
    loadDisposal() {
      // disposalList().then(res => {
      //   res.data.forEach(item => {
      //     this.showDisposalMarker(item)
      //   })
      // })
      let post={
        status:"10,20",//5,待审核，10，待下发，20，待检查，30，已完成，40，已取消
        startTime:moment()
        .add(-1, "day")
            .format("yyyy-MM-DD 12:00:00"),
        endTime:moment()
            .format("yyyy-MM-DD 23:59:59"),
      }
      storeNotice(post).then(res => {
        res.data.forEach(item => {
          this.showDisposalMarker(item)
        })
      })
    },
    querySearchAsync(queryString, cb) {
      if (queryString === '') {
        cb([])
      } else {
        var restaurants = this.restaurants
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
        console.log('results', results)
        cb(results)
      }
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.indexOf(queryString) !== -1)
      }
    },
    handleSelect(item) {
      console.log(item,"选中")
      this.selectData = item
      const point = new this.bmpBL.Point(item.lng, item.lat)
      this.myMap.centerAndZoom(point, 22)
      console.log(item,"map")
      const _this = this
      const marker = new this.bmpBL.Marker(point)
      this.myMap.addOverlay(marker)
      marker.setTop(true)
      marker.setTitle(item.name)
      // 创建信息窗口
      var opts = {
        width: 600,
        height: 150,
        offset: new this.bmpBL.Size(0, -20)
      }
      // console.log('infowindow', _this.$refs.infowindow.$el)
      var infoWindow = new this.bmpBL.InfoWindow(_this.$refs.infowindow.$el, opts)
      this.myMap.openInfoWindow(infoWindow, point) // 开启信息窗口
    },
    showWarnMarker(item) {
      console.log('showWarnMarker')
      const position = new this.bmpBL.Point(item.lng, item.lat)
      const marker1 = new this.bmpBL.Marker(position)
      this.myMap.addOverlay(marker1)
      // const dotIconSize = new this.bmpBL.Size(50, 50)
      // var dotIcon = new this.bmpBL.Icon('http://pic.yaojian.xusos.com/yaojian/file/2021-07-23/2021_7_23_1893135695.png', dotIconSize)
      // dotIcon.setImageSize(dotIconSize)
      // marker1.setIcon(dotIcon)
      let width = 380
      if (item.storeName.length > 12) {
        width = width + ((item.storeName.length - 12) * 23)
      }
      var label = new this.bmpBL.Label('<div style="min-width: 300px;"><p>' + item.storeName + '</p><p style="text-align: center;">' + item.title + '</p></div>',
        { offset: new this.bmpBL.Size(-width / 6, -250) })
      label.setStyle({ width: width + 'px', height: '250px', color: '#ffffff', border: 'unset', backgroundColor: 'rgb(254,174,33, 0.9)', borderRadius: 3,
        lineHeight: '32px', padding: '10px 10px 10px 10px', fontSize: '24px',
        background: 'url(http://pic.yaojian.xusos.com/yaojian/file/2021-07-25/2021_7_25_555334984.png) no-repeat', 'background-size': '100% 100%' })
      label.setPosition(position)
      console.log('label', label)
      // marker1.setTop(true)
      marker1.setLabel(label)
      setTimeout(() => {
        // infoWindow.close()
        this.myMap.removeOverlay(marker1)
        this.tempData.splice(0, 1)
      }, 3000)
    },
    showDisposalMarker(item,type=0) {//预警标注，颜色等级（1、黄色，2、绿，3、橘色，4、红，5，灰）  type:1 警报
if(this.type==2||this.type==3){return}
      if (item.lng != null && item.lng !== '') {
        const position = new this.bmpBL.Point(item.lng, item.lat)
        const iconSize = new this.bmpBL.Size(25, 25)
        // 创建旗子图标
        let iconUrl=''
        if(item.level==1){//预警通知
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1828051103.png"//黄色
        }
        if(item.level==2){//自主整改
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_359700814.png"//绿
        }
        if(item.level==3){//责令整改
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1828051103.png"//橙色
        }
        if(item.level==4){//现场检查
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_943563961.png"//红
        }
        if(item.level==5){//联动处置
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1363424593.png"//黑
        }
        if(item.level==6){//未处置
          iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1910063012.png"//紫
        }
        // if(item.level==99){//警报
        //   iconUrl="http://pic.yaojian.xusos.com/yaojian/file/2022-05-28/2022_5_28_766650638.gif"//警报
        // }

        // 创建Marker图片标注-------------------------start
      //  var myIcon = new this.bmpBL.Icon(iconUrl, iconSize)
       // myIcon.setImageSize(iconSize)
        // myIcon.setImageOffset(new this.bmpBL.Size(10, 10))
        // var marker = new this.bmpBL.Marker(position, {
        //   icon: myIcon,
        //   offset: new this.bmpBL.Size(6, -5)
        // })
        // marker.setZIndex(19000001)
        // 创建Marker图片标注---------------------------------end

        //加动画(加html标签)------------------------------start
        var label = new this.bmpBL.Label(`<img width="25" height="25" src="${iconUrl}"/>`, { offset: new this.bmpBL.Size(-17, -32) })
        label.setStyle({ color: '#ffffff',backgroundColor: 'transparent',border:0})
        label.setPosition(position)
        //加动画------------------------------end
        this.myMap.addOverlay(label)

        // var label = new this.bmpBL.Label('<p>' + item.title + '</p><p>' + item.storeName + '</p>', { offset: new this.bmpBL.Size(-140, -120) })
        // label.setStyle({ color: '#ffffff', border: 'unset', backgroundColor: 'rgb(254,174,33, 0.9)', borderRadius: 3,
        //   lineHeight: '16px', padding: '5px', fontSize: '20px' })
        // label.setPosition(position)
        // marker.setLabel(label)
        // 创建信息窗口
        var opts = {
          width: 300,
          height: 140
        }
        // item['name'] = item.title
        this.selectData = item
        this.backgroundColor = 'red'
        var infoWindow = new this.bmpBL.InfoWindow(this.$refs.infowindow.$el, opts)
        // marker.openInfoWindow(infoWindow) // 开启信息窗口
        label.addEventListener('click', () => {
          //console.log('disposal', item)
          //点击放大地图
          this.zoom({lng:item.lng,lat:item.lat},22)
          this.selectData = item
          this.myMap.openInfoWindow(infoWindow, position) // 开启信息窗口
        })
      }
    },
    loadStoreList() {//标注店铺红点
      storeList({region:localStorage.id}).then(res => {
        this.restaurants = res.data.map(x => {
          return {
            value: x.name + (x.lng != null && x.lng !== '' ? '' : '（无坐标）'),
            address: x.address,
            name: x.name,
            lng: x.lng,
            lat: x.lat,
            id: x.id
          }
        })
        res.data.forEach(item => {
          //加载警报图
          // if(item.id=="1380465380655681537"||item.id=="1380465370459328513"){
          //   item.level=99
          //   this.showDisposalMarker(item)
          // }
          //加载小红点
          if (item.lng) {
            // console.log('item.lng', item.lng)
            const point = new this.bmpBL.Point(item.lng, item.lat)
            const iconSize = new this.bmpBL.Size(12, 12)//红点标注图片大小
            var myIcon = new this.bmpBL.Icon('http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1296310526.png', iconSize)
            const marker = new this.bmpBL.Marker(point, { icon: myIcon })
            this.myMap.addOverlay(marker)
            marker.setTitle(item.name)
            // marker.setZIndex(10)
            // 创建信息窗口
            var opts = {
              width: 300,
              height: 140
            }
            marker.setTop(true)
            var infoWindow = new this.bmpBL.InfoWindow(this.$refs.infowindow.$el, opts)
            // marker.openInfoWindow(infoWindow) // 开启信息窗口
            marker.addEventListener('click', () => {
              //console.log(item,"点击店铺红点")
              this.zoom({lng:item.lng,lat:item.lat},22)
              this.selectData = item
              this.myMap.openInfoWindow(infoWindow, point) // 开启信息窗口
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-message__content {
  font-size: 46px;
}
/deep/ .anchorBL{
  display:none;
}

/deep/ .BMap_bubble_buttons div:last-child {
  width: 40px !important;
  height: 40px !important;
}

/deep/ .BMap_bubble_buttons div:last-child div {
  font-size: 32px !important;
  top: 7px !important;
  right: 5px !important;
}

.dp-search /deep/ .el-input__inner {
    width: 100%;
    height: 100%;
    line-height: 100%;
    border-radius: 100px;
    background-color: transparent;
    border: 8px solid #4de1ff93;
    font-size: 32px;
    text-indent: 50px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 3px;
  }

  .dp-search /deep/ .el-input__icon {
    height: 100%;
    width: 100%;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    line-height: 100%;
    font-size: 32px;
    margin: 5px 0 0 20px
  }

  .dp-search /deep/ .el-autocomplete-suggestion {
    background-color: #1c386be0;
    border-radius: 30px;
    color: #fff;
  }

  .dp-search /deep/ .el-autocomplete-suggestion__wrap {
    max-height: 1200px
  }

  .dp-search /deep/ .el-autocomplete-suggestion li {
    line-height: 32px;
    font-size: 32px;
    text-align: left;
    padding: 10px 40px;
    color: #dcdada;
  }

  .dp-search /deep/ .el-autocomplete-suggestion li:hover {
    color: #005aca;
  }


</style>
<style>
.BMapLabel img{
  -webkit-user-drag: none;
}

/*.BMap_Marker{*/
/*  background-image: url('http://pic.yaojian.xusos.com/yaojian/file/2022-05-25/2022_5_25_19148019.gif');*/
/*  background-size: 100% 100%;*/
/*  background-repeat: no-repeat;*/
/*  !*animation: animate 1s linear infinite;*!*/
/*}*/
/*@keyframes animate {*/
/*  0% {*/
/*    transform: translateY(20px);*/
/*  }*/
/*  50% {*/
/*    transform: translateY(0px);*/
/*  }*/
/*  100% {*/
/*    transform: translateY(20px);*/
/*  }*/
/*}*/
</style>
