<template>
    <div class="risk">
        <div class="risk-content">
          <yun-header />
            <div class="risk-body">
                <div class="risk-body-content">
                    <!-- 标题 -->
                    <div class="rick-center-header">
                        <span> 风险处置</span>
                        <img src="../assets/regulation/yd.png" alt="" />
                    </div>
                    <!-- 今日指令  今日处置 今日处置-->
                    <div class="risk-type">
                        <div class="risk-left commont">
                            <div class="commont-echart" ref="leftEchart"></div>
                            <span>今日指令</span>
                        </div>
                        <div class="risk-center commont">
                            <div class="commont-echart" ref="centerEchart"></div>
                            <span>今日处置</span>
                        </div>
                        <div class="risk-right commont">
                            <div class="commont-echart" ref="rightEchart"></div>
                            <span>处置分析</span>
                        </div>
                    </div>
                    <!-- 今日处置列表 -->
                    <div class="disposal-list">
                        <!-- 标题 -->
                        <div class="disposal-title">
                            今日处置列表
                        </div>
                        <!-- 表格 -->
                        <div class="disposal-table">
                          <table>
                            <tr class="first">
                              <td style="width: 68px">序号</td>
                              <td style="width:220px">药店名称</td>
                              <td style="width: 170px">发现时间</td>
                              <td style="width:194px">风险名称</td>
                              <td>发现方式</td>
                              <td  style="width:150px">当前环节</td>
                              <td  style="width:150px">处置时间</td>
                            </tr>

                          </table>
                          <div style="height:433px;overflow: hidden;">
                            <vue-seamless-scroll
                                :data="tableList"
                                :class-option="defaultOption"
                            >
                            <table>
                                <tr class="second" v-for="(item, index) in tableList" :key="index">
                                    <td style="width: 68px">{{ item.id}}</td>
                                    <td style="width:220px">{{ item.name }}</td>
                                    <td  style="width:170px"> {{fxsj }}</td>
                                    <td  style="width:194px">{{ item.warringName }}</td>
                                    <td>{{ item.fundType }}</td>
                                    <td  style="width:150px">
                                      {{item.present}}
                                    </td>
                                    <td style="width:150px">
                                        {{czsj}}
                                    </td>

                                </tr>
                            </table>
                            </vue-seamless-scroll>
                          </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import Pager from "./components/pager";
import {task} from "@/api/yaojian"
import moment from "moment";
import vueSeamlessScroll from "vue-seamless-scroll";
import yunHeader from "@/views/yunHeader";
import afxcz from "@/assets/data/afxcz.json"
export default {
    components: {
        Pager,vueSeamlessScroll,yunHeader
    },
    data() {
        return {
          fxsj:moment()
              .add(-1, "day")
              .format("yyyy-MM-DD 21:57:54"),
          czsj:moment()
              .format("yyyy-MM-DD 08:52:26"),
          ywcData:{},
          wwcData:{},
          today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
            risktype: null,
            tableList: afxcz,
        };
    },
  computed:{
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum:5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
    mounted() {
        this.riskTypeLeft();
        this.riskTypeCenter();
        this.riskTypeRight();
        this.getTaskAll()
        this.getTask()
    },
    methods: {
      zflx(id){
        let lxm=[]
        lxm[10]="有因检查";
        lxm[20]="专项检查";
        lxm[30]="飞行检查";
        return lxm[id];
      },
      getTaskAll(){
        Promise.all([task({
          page:1,
          count:100,
          status:30,
          startTime:this.today[0],
          endTime:this.today[1],
        }), task({
          page:1,
          count:100,
          status:20,
          startTime:this.today[0],
          endTime:this.today[1],
        })]).then(rs=>{
          console.log(rs,"--------")
          this.ywcData=rs[0].data
          this.wwcData=rs[1].data
          this.riskTypeCenter()
        })
      },
         getTask(){
           let post={
             page:1,
             count:5,
             statusText:"20,30",
             startTime:this.today[0],
             endTime:this.today[1],
           }
           task(post).then(rs=>{
            //  this.tableList=rs.data.list
           })
         },
      getTaskNo(){
        let post={
          status:20,
          startTime:this.today[0],
          endTime:this.today[1],
        }
        task(post).then(rs=>{
          this.wwcData=rs.data
        })
      },
        riskTypeLeft() {
            this.risktype = echarts.init(this.$refs.leftEchart);
            var value = {
                今日新增: 4,
                前日指令: 96,
            };
            const option = {
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    data: ["今日新增", "前日指令"],
                    right: "5%",
                    top: "center",
                    orient: "vertical",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 100,
                                fontSize: 20,
                                color: "#fff",
                            },
                            value: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        return `{title|${name}}{value|${value[name]}%}`;
                    },
                },
                series: [
                    {
                        name: "今日指令",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "50%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                                name: "今日新增",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 40,
                                name: "前日指令",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                    {
                        name: "今日指令",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["60%", "90%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                                name: "今日新增",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 40,
                                name: "前日指令",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                ],
            };
            this.risktype.setOption(option);
        },
        riskTypeCenter() {
        let wc=[this.ywcData.total,this.wwcData.total]
            this.risktype = echarts.init(this.$refs.centerEchart);
            var value = {
                今日完成: wc[0],
                未完成:wc[1],
            };
            const option = {
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    data: ["今日完成", "未完成"],
                    right: "5%",
                    top: "center",
                    orient: "vertical",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 100,
                                fontSize: 20,
                                color: "#fff",
                            },
                            value: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        return `{title|${name}}{value|${value[name]}}`;
                    },
                },
                series: [
                    {
                        name: "今日处置",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "50%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: wc[0],
                                name: "今日完成",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: wc[1],
                                name: "未完成",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                    {
                        name: "今日处置",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["60%", "90%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: wc[0],
                                name: "今日完成",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: wc[1],
                                name: "未完成",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                ],
            };
            this.risktype.setOption(option);
        },
        riskTypeRight() {
            this.risktype = echarts.init(this.$refs.rightEchart);
            var value = {
                部门督办: 93,
                联动处置: 6,
                未处置: 1,
            };
            const option = {
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    right: "5%",
                    top: "center",
                    orient: "vertical",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 100,
                                fontSize: 20,
                                color: "#fff",
                            },
                            value: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        return `{title|${name}}{value|${value[name]}%}`;
                    },
                },
                series: [
                    {
                        name: "处置分析",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "50%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                                name: "部门督办",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 40,
                                name: "联动处置",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                            {
                                value: 40,
                                name: "未处置",
                                itemStyle: {
                                    color: "#00d7fe",
                                },
                            },
                        ],
                    },
                    {
                        name: "处置分析",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["60%", "90%"],
                        center: ["35%", "50%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                                name: "部门督办",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 40,
                                name: "联动处置",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                            {
                                value: 40,
                                name: "未处置",
                                itemStyle: {
                                    color: "#00d7fe",
                                },
                            },
                        ],
                    },
                ],
            };
            this.risktype.setOption(option);
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.risk {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .risk-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .risk-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .risk-header-back {
                cursor: pointer;
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .risk-header-title {
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .risk-body {
            flex: 1;
            padding: 0 260px;
            .risk-body-content {
                display: flex;
                flex-direction: column;
                margin-top: 35px;
                width: 1400px;
                height: 904px;
                background-image: url("../assets/regulation/背景-无圆点.png");
                .rick-center-header {
                    display: flex;
                    align-items: center;
                    padding: 20px 0 0 20px;
                    span {
                        font-size: 28px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #00d7fe;
                        margin-right: 20px;
                        letter-spacing: 6px;
                    }
                    img {
                        width: 95px;
                        height: 10px;
                    }
                }
                .risk-type {
                    margin-top: 43px;
                    display: flex;
                    .commont {
                        flex: 1;
                        height: 185px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        .commont-echart {
                            height: 160px;
                            width: 100%;
                        }
                        span {
                            font-size: 26px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #00d7fe;
                            line-height: 26px;
                        }
                    }
                }
                .disposal-list {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 30px 35px 0 35px;
                    overflow-y: auto;
                    .disposal-title {
                        height: 39px;
                        width: 100%;
                        padding: 0 20px;
                        background-image: url("../assets/regulation/渐变.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                    }
                    .disposal-table {
                        flex: 1;
                        margin-top: 30px;
                        overflow-y: auto;
                        table {
                            //height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 55px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    line-height: 55px;
                                    font-size: 22px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 80px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 80px;
                                    font-size: 22px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                                .operation {
                                    span {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 115px;
                                        height: 45px;
                                        background: #0277fa;
                                        border-radius: 4px;
                                    }
                                }
                            }
                        }
                    }
                    .footer-page {
                        position: relative;
                        margin-top: 25px;
                        bottom: 25px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}
</style>
