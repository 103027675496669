<template>
  <div id="main" style="width: 1920px;overflow: hidden">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">找好药</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px;position: relative">
            <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px" @click="go('/smartHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
            <div class="findleft">
              <div class="titleBox">
                   <div class="title">响应率及找到率分析</div>
              </div>
              <div class="chart abs" style="top:50px">
                <div id="myChart" :style="{width: '600px', height: '400px'}"></div>
              </div>

            </div>
            <div class="findleft" style="margin-top: 20px">
              <div class="titleBox">
                <div class="title">药店参与响应报价情况分析</div>
              </div>
              <div class="chart abs" style="top:28px;left:50px">
                <div id="myChart_cyxy" :style="{width: '400px', height: '200px'}"></div>
              </div>
              <div class="lan16" style="margin-top: 190px;padding-left: 27px">药店响应排行</div>
              <div id="myChart_ydxy" :style="{width: '600px', height: '230px'}"></div>
            </div>
          </div>
          <div style="width: 671px;margin-left: 18px" class="centerblock">
            <div style="position: relative">
              <div class="total">
                <div class="lan24" style="padding-left: 64px;padding-top: 23px">
                  <div>今日找药发布数</div>
                  <div style="margin-top: 37px">找到药品数量</div>
                </div>
              </div>
              <div class="totalnum">

                  <div class="nums">
                    <span class="num">0</span><span class="num">7</span><span class="num">3</span>
                  </div>
                  <div class="nums" style="margin-top: 30px">

                    <span class="num">1</span><span class="num">3</span><span class="num">5</span><span class="num">7</span>
                  </div>
                </div>
            </div>
            <div class="ditu" style="height: 450px;margin-top: 10px">
              <baidumap :type="2"/>
            </div>
<div style="position: relative;margin-top: 20px">
  <div class="titleBox" style="padding-left: 0px">
    <div class="title">各乡镇（街道）找药响应情况分析</div>
  </div>

    <div class="chart " >
      <div id="myChart_xzzy" :style="{width: '600px', height: '250px'}"></div>
    </div>

</div>


          </div>
          <div style="width: 606px" class="rightblock">
            <div class="findleft">
              <div class="titleBox">
                <div class="title">三级找药趋势分析</div>
              </div>

              <div class="chart abs" style="top:50px">
                  <div id="myChart_sjzy" :style="{width: '600px', height: '400px'}"></div>
              </div>


            </div>
            <div class="findleft" style="margin-top: 20px">
              <div class="titleBox">
                <div class="title">三级未找到药品分析</div>
              </div>

              <div class="chart abs" style="top:30px;left:15px">
                <div id="myChart_wzd1" :style="{width: '300px', height: '250px'}"></div>
              </div>
              <div class="chart abs" style="top:30px;left:251px">
                <div id="myChart_wzd2" :style="{width: '300px', height: '250px'}"></div>
              </div>

              <div class="lan14" style="margin-top: 232px;padding-left: 27px">三级未找到药品品种分析</div>
              <div class="chart " style="">
                <div id="myChart_sjwzd" :style="{width: '600px', height: '230px'}"></div>
              </div>
            </div>
          </div>
        </div>

      </div>



  </div>
</template>
<style scoped>
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.hand{cursor: pointer}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.findright1{
  position: relative;
  background-image: url("../assets/home/findright1.png");
  width: 601px;
  height: 539px;
}
.findright2{
  position: relative;
  background-image: url("../assets/home/findright2.png");
  width: 601px;
  height: 416px;
}
.keymap{
  position: relative;
  background-image: url("../assets/home/findcenter.png");
  width: 646px;
  height: 634px;
}
.findleft{
  position: relative;
  background-image: url("../assets/smart/find/left.png");
  width: 602px;
  height: 486px;
}
.findleft2{
  position: relative;
  background-image: url("../assets/home/findleft2.png");
  width: 594px;
  height: 639px;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import 'echarts-wordcloud'
import axios from "axios";
import {mul} from "@/utils/jsj";
import baidumap from "@/views/components/baidumap";
import moment from 'moment'
export default {
  name: 'zhuye',
  components: {baidumap},
  data(){
    return {
      riqi:[],
      cyxyData:[
        {
          name: '5分钟内',
          value: 390,
          per: 390,
          background:"rgba(91, 143, 249, 0.85)"
        }, {
          name: '5-15分钟',
          value: 815,
          per: 815,
          background:"rgba(90, 216, 166, 0.85)"
        }, {
          name: '15-30分钟',
          value: 861,
          per: 861,
          background:"rgba(93, 112, 146, 0.85)"
        }, {
          name: '30分钟后',
          value:315,
          per: 315,
          background:"rgba(246, 189, 22, 0.85)"
        }],
      keyword:"",
      region:330726,
      ypxsData:[],
      storeAge:[]
    }
  },
  created() {
    for(let i=0;i<7;i++){
      this.riqi.push(moment().add(-i,'day').format('MM-DD'))
    }
    this.riqi.sort()
  },
  mounted() {
    this.getData()
    },
  methods:{
go(url){
  this.$router.push(url)
},
    drawChart_wzd(id,title,arr){//三级未找到药品推荐情况分析
      let option = {
        title: {
          text: title,
          // subtext: 'Fake Data',
          left: 'center',
          top: '85%',
          textStyle:{
            color:'#fff',
            fontSize:14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          itemHeight:7,
          icon:"circle",
          textStyle:{color:"#fff"},
          show:true,
          orient: 'vertical',
          left: 'right',
          top:"center"
        },
        series: [
          {
            label:{
              normal: {
                show: true, // 是否显示标签[ default: false ]
                color: '#fff',
                fontSize: 12,
                position: 'inside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: '{c}%' // 标签内容
              }
            },
            name: title,
            type: 'pie',
            radius: '60%',
            data: arr,
          }
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_ydxy(){
      let myChart = echarts.init(document.getElementById('myChart_ydxy'))
      var datas = [
        {
          "value": 40,
          "name": "浦江县恒生药房连锁有限公司亚太大道店"
        },
        {
          "value": 30,
          "name": "浙江康佰家大药房有限公司浦江亚太路店"
        },
        {
          "value": 20,
          "name": "金华市江南药都医药连锁有限公司浦江亚太路药店"
        },
        {
          "value": 15,
          "name": "金华瑞人堂保济堂医药连锁有限公司浦江亚太大道"
        },
        {
          "value": 8,
          "name": "浦江丰安药品销售有限公司"
        }
      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let option = {
        backgroundColor: "",
        legend: {
          show:false,
          borderColor:'#d73622',
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 10,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          },
        },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChartCrystal(id,arrTitle,v=[0.5,0.8]){
      var value = v[0];
      var value2 = v[1];
      var data = [value, value, value];
      var data2 = [value2, value2, value2];
      var option = {
        title: [
          {
            text: arrTitle[0],
            x: '25%',
            y: 120,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: arrTitle[1],
            x: '75%',
            y: 120,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {
            text: (value * 100).toFixed(0) + '%',
            left: '25%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#a06a0a',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
          {
            text: (value2 * 100).toFixed(0) + '%',
            left: '75%',
            top: '38%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: '#02456d',
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['25%', '50%'],
            // color:[ {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{
            //         offset: 1,
            //         color: 'rgba(2, 159, 200, 1)'
            //     }, {
            //         offset: 0.5,
            //         color: 'rgba(0, 186, 211, .5)'
            //     }, {
            //         offset: 0,
            //         color: 'rgba(0, 230, 220, 1)'
            //     }],
            //     globalCoord: false
            // }],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(251, 173, 23, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(251, 173, 23, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(251, 173, 23, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外黄边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['25%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#fdc56e'
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            type: 'liquidFill',
            radius: '50%',
            z: 6,
            center: ['75%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: 'rgba(0, 155, 219, 0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(0, 155, 219, .2)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(0, 155, 219, 1)',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: data2,
            backgroundStyle: {
              borderWidth: 1,
              color: 'transparent',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: true,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: '第二层白边',
            type: 'pie',
            z: 3,
            radius: ['0%', '55%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fefefe',
                      },
                      {
                        offset: 1,
                        color: '#e7e8ea',
                      },
                    ]),
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
          {
            name: '最外蓝边',
            type: 'pie',
            z: 1,
            radius: ['0%', '58%'],
            center: ['75%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color:'#07a2e3'

                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
        ],
      };

      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChartKey(){
      let myChart = echarts.init(document.getElementById('myChartKey'))
      const list = [
        {
          value: '50',
          name: '防疫',
          textStyle: {
            shadowBlur: 4,
            shadowColor: '#ECEFFF',
            shadowOffsetY: 14,
            color: '#73DDFF',
          },
        }, // 50
        { value: '30', name: '维生素C' },
        { value: '29', name: '恒生药房' },
        { value: '28', name: '兴奋剂药品' },
        { value: '27', name: '花桥乡' },
        { value: '26', name: '特殊药品' },
        { value: '25', name: '未找到' },
        { value: '30', name: '中医' },
        { value: '23', name: '利福昔明片' },
        { value: '22', name: '高血压' },
        { value: '21', name: '疫情管控' },
        { value: '20', name: '配送费' },
        { value: '16', name: '老年人' },
        { value: '15', name: '中药' },
        { value: '14', name: '慢病' },
        { value: '13', name: '医保' },
        { value: '12', name: '康柏家' },
      ]
     let option = {
        tooltip: {
          show: true,
          borderColor: '#fe9a8bb3',
          borderWidth: 1,
          padding: [10, 15, 10, 15],
          confine: true,
          backgroundColor: 'rgba(255, 255, 255, .9)',
          textStyle: {
            color: 'hotpink',
            lineHeight: 22
          },
          extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
        },
        series: [
          {
            type: 'wordCloud',
            // The shape of the "cloud" to draw. Can be any polar equation represented as a
            // callback function, or a keyword present. Available presents are circle (default),
            // cardioid (apple or heart shape curve, the most known polar equation), diamond (
            // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

            shape: 'pentagon',

            // A silhouette image which the white area will be excluded from drawing texts.
            // The shape option will continue to apply as the shape of the cloud to grow.

            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.

            left: 'center',
            top: 'center',
            width: '100%',
            height: '100%',
            right: null,
            bottom: null,

            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.

            sizeRange: [14, 50],

            // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

            rotationRange: [0, 0],
            rotationStep: 0,

            // size of the grid in pixels for marking the availability of the canvas
            // the larger the grid size, the bigger the gap between words.

            gridSize: 25,

            // set to true to allow word being draw partly outside of the canvas.
            // Allow word bigger than the size of the canvas to be drawn
            drawOutOfBound: false,

            // If perform layout animation.
            // NOTE disable it will lead to UI blocking when there is lots of words.
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'PingFangSC-Semibold',
              fontWeight: 600,
              color: function (params) {
                let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3', '#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3', '#fe9a8bb3', '#73DDFF', '#58D5FF']
                return colors[parseInt(Math.random() * 10)];
              },
            },
            emphasis: {
              focus: 'none',
            },

            // Data is an array. Each array item must have name and value property.
            data: list,
          },
        ],
      };

      myChart.setOption(option);
    },
    drawChart(){//响应率及找到率分析
      let myChart = echarts.init(document.getElementById('myChart'))
      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '响应率', '找到率'],
            [this.riqi[2], 43.3, 85.8],
            [this.riqi[3], 83.1, 73.4],
            [this.riqi[4], 86.4, 65.2],
            [this.riqi[5], 72.4, 53.9],
            [this.riqi[6], 72.4, 53.9]
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option);
    },
    drawChart_wzd3(id){
      let myChart = echarts.init(document.getElementById(id))
      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '响应率', '找到率'],
            ['04-26', 43.3, 85.8],
            ['04-27', 83.1, 73.4],
            ['04-28', 86.4, 65.2],
            ['04-29', 72.4, 53.9],
            ['04-30', 72.4, 53.9],
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option);
    },
    drawChart_sjwzd(id){
      let myChart = echarts.init(document.getElementById(id))
      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '找到量', '需求量', '未找到'],
            ['利福昔明片', 100, 95, 5],
            ['拉莫三嗪片', 140, 130, 10],
            ['丙戊酸钠片', 230, 210, 20],
            ['氯沙坦钾片', 100, 97, 3],
            ['利格列汀片', 130, 130, 0],
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option);
    },
    drawChart_cyxy(id){//购药人群疾病占比分析

      var m2R2Data= [
        {value:20, legendname:'5分钟内',name:"5分钟内  20%",itemStyle:{color:"#8d7fec"}},
        {value:42, legendname:'5-15分钟',name:"5-15分钟  42%",itemStyle:{color:"#5085f2"}},
        {value:23, legendname:'15-30分钟',name:"15-30分钟  23%",itemStyle:{color:"#e75fc3"}},
        {value:15, legendname:'30分钟后',name:"30分钟后  15%",itemStyle:{color:"#f87be2"}},

      ];


     let option = {
        tooltip: {
          trigger: 'item',
          formatter:function (parms){
            var str=  parms.seriesName+"</br>"+
                parms.marker+""+parms.data.legendname+"</br>"+
                "占比："+ parms.percent+"%";
            return  str ;
          }
        },
        legend: {
          type:"scroll",
          orient: 'vertical',
          left:'70%',
          align:'left',
          top:'middle',
          textStyle: {
            color:'#8C8C8C'
          },
          height:250
        },
        series: [
          {
            name:'情况分析',
            type:'pie',
            center: ['35%', '50%'],
            radius: ['40%', '65%'],
            clockwise: false, //饼图的扇区是否是顺时针排布
            avoidLabelOverlap: false,
            label: {
              normal: {
                color:"#fff",
                show: true,
                position: 'outter',
                formatter:function (parms){
                  return parms.data.legendname
                }
              }
            },
            labelLine: {
              normal: {
                length:5,
                length2:3,
                smooth:true,
              }
            },
            data:m2R2Data
          }
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_sjzy(id){//三级找药趋势分析
      let xLabel = this.riqi
      let data = ["70", "75", "81", "79", "76", "83",'85']
      let data2 = ["10", "12", "21", "15", "22", "23",'25']
      let data3 = ["5", "6", "8", "7", "9", "8",'10']
      let data4 = ["1", "1", "2", "1", "1", "2",'1']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '一级找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '二级找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '三级找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },
          {
            name: '未找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[3],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[3], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[3],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data4
          },
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_xzzy(){//各乡镇（街道）找药响应情况分析
     let option = {
       tooltip: {
         trigger: 'axis'
       },
       legend: {
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 2,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         }
       },
       grid: {
         left: '3%',
         right: '4%',
         bottom: '3%',
         containLabel: true
       },
          xAxis: {
         axisLabel: {   // X轴线 标签修改
           textStyle: {
             color: '#fff', //坐标值得具体的颜色
           }
         },
         type: 'category',
         data: ['浦阳街道', '仙华街道', '黄宅镇', '白马镇', '大畈乡', '中余乡']
       },
       yAxis: {
         splitLine:{
           show:true,
           lineStyle: {color: ['#01233e', '#00223d']
    }
         },
         type: 'value'
       },
       series: [
         {
           name: '需求率',
           type: 'line',
           step: 'start',
           data: [120, 132, 101, 134, 90, 230]
         },
         {
           name: '响应率',
           type: 'line',
           step: 'middle',
           data: [220, 282, 201, 234, 290, 430]
         }
       ]
     };
      let myChart = echarts.init(document.getElementById("myChart_xzzy"))
      myChart.setOption(option);
    },
    drawChart2(){
      let data=this.storeAge
      let data2=['青年', '老年', '幼儿', '青少年', '儿童', '中年']
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        grid: {
          top: '0%',
          left: '10%',
          bottom: '0%',
          right: '20%',
          containLabel: true,
        },
        title: {
          text: '',
        },
        //backgroundColor: 'rgb(24, 3, 98)',
        yAxis: {
          type: 'category',
          inverse: true,
          offset: 10,
          axisLabel: {
            show: true,
            interval: 'auto',
            color: 'rgb(255,255,255)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: data2,
        },
        xAxis: {
          show: false,
          type: 'value',
          offset: 30,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(106, 148, 255)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            inside: true,
            length: 10,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%',
          },
        },
        series: [
          {
            //下半截柱子
            name: '人群',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,
              formatter: '{c}%'
            },
            itemStyle: {
              //lenged文本
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0, 111, 255)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(29, 227, 255)', // 100% 处的颜色
                      },
                    ],
                    false
                );
              },
            },
            data: data,
          },
          {
            //数据圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [6, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(27, 140, 255)'
            },
            symbolPosition: 'end',
            data: data,

          },
          {
            //最左圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [10, 17],
            symbolOffset: [-5, 0],
            z: 3,
            itemStyle: {
              opacity: 1,
              color: 'rgb(0, 111, 255)'
              //color: '#000'
            },
            symbolPosition: 'end',
            data: [1, 1, 1, 1, 1,1],
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      this.ypxsData[0]=["中成药","西药","处方药","OTC","进口药","中药饮片","其它"]
      this.ypxsData[1]=[25,63,3,97,5,12,20]
      let myChart = echarts.init(document.getElementById('myChart3'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 16
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              },
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart5(){
      let xLabel = ['03-14', '03-15', '03-16', '03-17', '03-18', '03-19','03-20']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '已发布',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '已找到',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },

        ]
      };
      let myChart = echarts.init(document.getElementById('myChart5'))
      myChart.setOption(option);
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    getData(){
  //this.drawChart_wzd3("myChart_wzd3")
   this.drawChart_sjwzd("myChart_sjwzd")
  this.drawChart_sjzy("myChart_sjzy")
  this.drawChart_cyxy("myChart_cyxy")
      let arr=[
        { value: 99, name: '找到' },
        { value: 1, name: '未找到' },
      ]
  this.drawChart_wzd("myChart_wzd1","找到率药品占比",arr)
      arr=[
        { value: 95, name: '复诊' },
        { value: 5, name: '未复诊' },
      ]
  this.drawChart_wzd("myChart_wzd2","复诊率占比",arr)
  this.drawChart_ydxy()
  this.drawChart_xzzy()//各乡镇（街道）找药响应情况分析
  let that=this
      //年龄段销售数据
      axios.get('/drug/store/age?region='+this.region, {})
          .then(function (response) {
            let age=response.data.data
            that.storeAge[0]=mul(parseInt((age.young/age.total)*1000),0.1)
            that.storeAge[1]=mul(parseInt((age.elderly/age.total)*1000),0.1)
            that.storeAge[2]=mul(parseInt((age.infant/age.total)*1000),0.1)
            that.storeAge[3]=mul(parseInt((age.youngster/age.total)*1000),0.1)
            that.storeAge[4]=mul(parseInt((age.child/age.total)*1000),0.1)
            that.storeAge[5]=mul(parseInt((age.middleAged/age.total)*1000),0.1)
            that.drawChart();
           // that.drawChart2();
          })


          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
