<template>
  <div class="main" style="min-height: 500px;background-color: #F4F4F4">
    <div class="content" v-show="zt==1">
      <div><el-button type="primary" @click="zt=0">返回</el-button></div>
      <el-table
          :data="arrYaodian"
          style="width: 100%">
        <el-table-column prop="name" label="名称" />
        <el-table-column prop="address" label="地址" />
        <el-table-column prop="contact" label="联系人" />
      </el-table>
    </div>
    <div class="content" v-show="zt==2">
      <div><el-button type="primary" @click="zt=0">返回</el-button></div>
      <el-table
          :data="arrYaos"
          style="width: 100%">
        <el-table-column prop="name" label="姓名" />
        <el-table-column prop="phone" label="电话" />
        <el-table-column prop="storeName" label="药店" />
      </el-table>
    </div>
    <div class="content" v-show="zt==0" >
      <div style="text-align: center;font-size: 40px;position: relative" class="lanse tou">
        <div style="height: 20px"></div>
        <div style="padding: 0 15%"><img width="100%" style="vertical-align: middle;margin-left: 5px" src="../assets/pujiang2/1.png"/></div>
        <div style='cursor: pointer;text-align: right;margin-top: 0px;' @click="open()">
          <img style="vertical-align: middle;margin-left: 5px;width:21vw" src="../assets/pujiang2/2.png"/></div>
        <div style="height: 20px"></div>
      </div>
      <div class="block1 bai" >
        <div class="title_box">
          <div class="titlebiao">
            应用场景
          </div>
        </div>
        <div style="padding:0 1rem 20px 1rem;font-size: .875rem;line-height: 200%">
          <div><span class="txt-b">牵头单位：</span>浦江县市场监督管理局</div>
          <div><span class="txt-b">协同单位：</span>浦江县政法委、浦江县公安局、浦江县医保局、浦江县卫生健康局、浦江县大数据发展中心、浦江县农商行、浦江县人社局、8890市民服务中心等</div>
          <div>
            <span class="txt-b">场景简介：</span>
            针对药品安全监管基层力量不足、药店安全管理水平有待加强、群众看病难购药难等堵点难点问题，通过全链数据归集、部门多跨协同和信用分类监管，打造数字监管、数字企业、数字民生三大应用场景，
            实现全场景智慧监管、药店规范经营、群众在线医疗，切实提升药店规范化经营水平，保障广大人民群众用药安全。
          </div>
        </div>
      </div>
      <div class="jian"></div>
      <div id="echart" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              核心指数
            </div>
          </div>
        </div>
        <div style="width: 100vw;margin: 0 auto">
          <el-row>
            <el-col :span="24">
              <div>
                <div><div id="myChart" :style="{width: '100%', height: '200px'}"></div></div>
                <div style="text-align:center ">疫情管控药品监测指标</div>
              </div>
            </el-col>
            <el-col :span="24"><div>
              <div><div id="myChart2" :style="{width: '100%', height: '200px'}"></div></div>
              <div style="text-align:center ">易制毒类药品监测指标</div>
            </div></el-col>
              <el-col :span="24">
              <div>
                <div><div id="myChart3" :style="{width: '100%', height: '200px'}"></div></div>
                <div style="text-align:center ">浙里药店服务指标</div>
              </div>
            </el-col>
            <el-col :span="24"><div>
              <div><div id="myChart4" :style="{width: '100%', height: '200px'}"></div></div>
              <div style="text-align:center ">部门信息分享指标</div>
            </div></el-col>
          </el-row>

        </div>
        <div style="margin-top: 1rem">
          <el-row>
            <el-col :span="8">
              <div class="hxzs">
                <div class="hxzs1"><img src="../assets/pujiang2/t1.png" height="72"/> </div>
                <div class="hxzs2">{{o.num2}}</div>
                <div class="hxzs3">药品库存数量</div>
              </div>
            </el-col>
            <el-col :span="8">   <div class="hxzs" @click="go(1)" style="cursor: pointer">
              <div class="hxzs1"><img src="../assets/pujiang2/t2.png" height="72"/> </div>
              <div class="hxzs2">{{o.num1}}</div>
              <div class="hxzs3">药店数量</div>
            </div></el-col>
            <el-col :span="8">   <div class="hxzs" @click="go(2)" style="cursor: pointer">
              <div class="hxzs1"><img src="../assets/pujiang2/t3.png" height="72"/> </div>
              <div class="hxzs2">{{o.num6}}</div>
              <div class="hxzs3">执业药师数量</div>
            </div></el-col>
          </el-row>
        </div>
        <div style="height: 30px"></div>
      </div>
      <div class="jian"></div>
      <div id="ywlc" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              业务流程
            </div>
          </div>
        </div>
        <div  class="block_c">
          <div class="txt-c" style="margin-top: 30px"><img width="100%" src="../assets/pujiang2/b0.png" style="display: block"/> </div>
          <div class="txt-c" ><img width="100%" src="../assets/pujiang2/b1.png"/> </div>
          <div class="txt-c" style="margin-top: 20px"><img src="../assets/pujiang2/san.png"/> </div>
          <div class="txt-c" style="margin-top: 30px"><img width="100%" src="../assets/pujiang2/b2.png"/> </div>
          <div class="txt-c" style="margin-top: 20px"><img src="../assets/pujiang2/san.png"/> </div>
          <div class="txt-c" style="margin-top: 30px"><img width="100%" src="../assets/pujiang2/b3.png"/> </div>
          <div class="txt-c" style="margin-top: 20px"><img src="../assets/pujiang2/san.png"/> </div>
          <div class="txt-c" style="margin-top: 20px"> <img width="100%" src="../assets/pujiang2/b4.png"/></div>

        </div>

        <div style="height: 30px"></div>
      </div>
      <div class="jian"></div>
      <div id="yycj" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              应用成效
            </div>
          </div>
        </div>
        <div class="block_c">
          <div class="txt-c" style="margin-top: 10px">
            <div class="yycx_item1 " >
              <div class="yycx_item"> 完成浙里找药、网订店数{{sjs1}}笔</div>
            </div>
            <div class="yycx_item2" >
              <div class="yycx_item"> 完成数据上报{{sjs2}}笔</div>
            </div>
            <div class="yycx_item3" >
              <div class="yycx_item"> 完成自动预警下发责令改正通知{{sjs3}}次</div>
            </div>
          </div>
        </div>

        <div style="height: 10px"></div>
      </div>
      <div id="gxsj" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              共享数据
            </div>
          </div>
        </div>
        <div class="block_c">
          <div style="height: 10px;"></div>
          <div class="txt-c gxsj" >
            <div class="gxsj_item">
              <el-row>
                <el-col :span="12"><div class="shuju">{{o.num5}}</div></el-col>
                <el-col :span="12"><div class="shuju">{{o.num3}}</div></el-col>
              </el-row>
            </div>
            <div class="gxsj_item">
              <el-row>
                <el-col :span="12"><div class="shuju">{{o.num1}}</div></el-col>
                <el-col :span="12"><div class="shuju">{{o.num1}}家每日上报</div></el-col>
              </el-row>
            </div>
            <div class="gxsj_item">
              <el-row>
                <el-col :span="12"><div class="shuju">正常值</div></el-col>
                <el-col :span="12"><div class="shuju">3213</div></el-col>
              </el-row>
            </div>
            <div class="gxsj_item">
              <el-row>
                <el-col :span="12"><div class="shuju">753424</div></el-col>
                <el-col :span="12"><div class="shuju">{{o.num6}}</div></el-col>
              </el-row>
            </div>
          </div>
        </div>

        <div style="height: 10px"></div>
      </div>
      <div class="jian"></div>
      <div class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              改革突破
            </div>
          </div>
        </div>
        <div class="ggtp">
          <div class="txt-c" style="margin-top: 10px"><img src="../assets/pujiang2/fwgk.jpg"/> </div>
          <div class="txt-c" style="margin-top: 10px"><img style="width: 20px" src="../assets/pujiang2/san.png"/> </div>
          <div class="txt-c" style="margin-top: 11px"><img src="../assets/pujiang2/8.png"/> </div>
        </div>
        <div style="height: 10px"></div>
      </div>
      <div class="jian"></div>
      <div id="dkxt" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              多跨协同
            </div>
          </div>
        </div>
        <div class="">
          <div class="txt-c" style="margin-top: 10px">
            <div class="dkxt"><img src="../assets/pujiang2/c1.jpg"/></div>
            <div class="dkxt"><img src="../assets/pujiang2/c2.jpg"/></div>
          </div>
        </div>
        <div style="height: 30px"></div>
      </div>
      <div id="zctx" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              政策体系
            </div>
          </div>
        </div>
        <div class="">
          <div class="" style="margin:0 auto;margin-top: 10px;font-size: .875rem;width: 90%;padding: 10px 0px">
            1、浙江省药品经营企业药品质量安全风险分级管理实施办法。<br>
            2、浦江县医疗保障基金部门联动监管工作实施意见。 </div>

        </div>
        <div style="height: 10px"></div>
      </div>
      <div id="gzzb" class="block2 bai" style="">
        <div class="biaoti lanse">
          <div class="title_box">
            <div class="titlebiao">
              工作专班
            </div>
          </div>
        </div>
        <div class="">
          <div class="txt-c img" style="margin-top: 30px"><img src="../assets/pujiang2/10.png"/> </div>

        </div>
        <div style="height: 30px"></div>
      </div>
      </div>
  </div>

</template>
<style scoped>
.yycx_item{color: #FFFFFF;text-align: center;font-size: .45rem;text-align: right;bottom: 0;right: 0;position: absolute}
.yycx_item1{
  height: 130px;
  position: relative;
  background-image: url('../assets/pujiang2/a1.png');
  background-size:100% 100%;
  background-repeat: no-repeat;background-position: bottom}
.yycx_item1 .yycx_item{}
.yycx_item2{
  height: 130px;
  position: relative;
  background-image: url('../assets/pujiang2/a2.png');background-size:100%;background-repeat: no-repeat;background-position: bottom}
.yycx_item2 .yycx_item{}
.yycx_item3{
  height: 130px;
  position: relative;
  background-image: url('../assets/pujiang2/a3.png');background-size:100%;background-repeat: no-repeat;background-position: bottom}
.yycx_item3 .yycx_item{}
</style>
<script>
import axios from "axios";
import * as echarts from 'echarts'
export default {
  name:"pujiang",
  data(){
    return{
      kuan:"1100px",
      jxqData:[],
      ypxsData:[],
      ffzjData:[],
      zlzyData:[],
      gqypData:[],
      zt:0,//0,首页，1，药店列表，2.药师列表
      o:{
        num1:0,
        num2:0,
        num3:0,
        num4:0,
        num5:0,
        num6:0,
        num7:0,
        num8:2,
        num9:10,
        num10:588,
        num11:98178,
      },
      arrYaodian:[],
      arrYaos:[],
      region:"330726",
      sjs1:5123,
      sjs2:156213236,
      sjs3:1753,
    }},
  created() {
    //屏幕适应---start
    // let ww=document.body.clientWidth
    // let scale=(ww/1100).toFixed(2)
    // document.getElementsByName("viewport")[0].content="width=device-width,initial-scale="+scale
    //屏幕适应---end
    this.sjs()
    this.zlzyData[1]=[852,859,832,856,956,856]
    this.load()
    },
  methods:{
    sjs(b){
      let s=Math.random();
      let num=parseInt(s*10);
      if(localStorage.sjs1){
        this.sjs1=localStorage.sjs1*1+num
      }
      if(localStorage.sjs2){
        this.sjs2=localStorage.sjs2*1+num
      }
      if(localStorage.sjs3){
        this.sjs3=localStorage.sjs3*1+num
      }
      localStorage.sjs1=this.sjs1
      localStorage.sjs2=this.sjs2
      localStorage.sjs3=this.sjs3
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    search(){
      this.getYqgk()
      this.getTsyp()
      this.getJxq()

    },
    async getJxq(){ //近效期药品
      let otime=this.getRiqi(6);
      var that=this
      that.jxqData[0]=[]
      that.jxqData[1]=[]
      axios.get('/screen/lot/num?startTime='+otime[1]+'  00:00:00&endTime='+that.today()+'  23:59:59&region='+this.region, {})
          .then(function (response) {
            let arr=response.data.data
            for(let i in arr){
              let a=arr[i].date.substring(5,10)
              that.jxqData[0].push(a)
              that.jxqData[1].push(parseInt(arr[i].count))
            }
            that.drawLine('myChart4');
          })

    },
    async getYqgk(){
      //疫情管控药品销售------start
      let otime=this.getRiqi(6);
      var that=this
      that.ypxsData[0]=[]
      that.ypxsData[1]=[]
      axios.get('/screen/epidemic/sale?startDate='+otime[1]+'&endDate='+that.today()+'&region='+this.region, {})
          .then(function (response) {
            let obj=response.data.data.data
            for(let key in obj){
              let a=key.substring(5,10)
              that.ypxsData[0].push(a)
              that.ypxsData[1].push(obj[key])
            }
            that.drawLine('myChart');
          })
      //疫情管控药品销售------end


    },
    async getTsyp(){
      //特殊药品复方制剂销售------start
      let that=this
       let otime=this.getRiqi(6);
      that.ffzjData[0]=[]
      that.ffzjData[1]=[]
      axios.get('/screen/special/sale?startDate='+otime[1]+'&endDate='+that.today()+'&region='+this.region, {})
          .then(function (response) {
            // console.log(response)
            let obj=response.data.data.data
            for(let key in obj){
              let a=key.substring(5,10)
              that.ffzjData[0].push(a)
              that.ffzjData[1].push(obj[key])
            }
            that.drawLine('myChart2');
            that.drawLine('myChart3');
            that.drawLine('myChart4');
          })
      //特殊药品复方制剂销售------end


    },
    drawLine(id){
      let name="疫情管控药品销售数量"
      let arr=[]
      let color=""
      if(id=="myChart"){
        arr=this.ypxsData
        name="疫情管控药品销售数量"
        color="#ff0000"
      }
      if(id=="myChart2"){
        arr=this.ffzjData
        name="特殊药品销售数量"
        color="#F67A02"
      }
      if(id=="myChart3"){
        arr[0]=this.ffzjData[0]
        arr[1]=this.zlzyData[1]
        color="#9A6903"
        name="订单数量"
      }
      if(id=="myChart4"){
        arr=this.jxqData
        name="近效期药品数量"
        color="#4DC4C7"
      }
      //name=""
      let myChart = echarts.init(document.getElementById(id))
      let option = {
        title: {
          text: '',
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20,
          },
          top: '3%',
          left: '10%',
        },
        backgroundColor: '',
        grid: {
          left:"20%",
          top: "25%",
          bottom: "10%"//也可设置left和right设置距离来控制图表的大小
        },
        // tooltip: {
        //   trigger: "axis",
        //   formatter(params){
        //     for(let x in params){
        //       return name+params[x].name+":"+params[x].value;
        //     }
        //
        //   },
        //   axisPointer: {
        //     type: "shadow",
        //     label: {
        //       show: true
        //     }
        //   }
        // },
         xAxis: {
          data: arr[0],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#000000'
            }
          },
          axisTick: {
            show: true //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#787979" //X轴文字颜色
            }
          },

        },
        yAxis: [{
          type: "value",
          name: "",
          nameTextStyle: {
            color: "#787979"
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000000'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#787979"
            }
          },

        },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#ebf8ac"
            },
            position: "right",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              formatter: "", //右侧Y轴文字显示
              textStyle: {
                color: "#ebf8ac"
              }
            }
          },
          {
            type: "value",
            gridIndex: 0,
            min: 50,
            max: 100,
            splitNumber: 8,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"]
              }
            }
          }
        ],
        series: [{
          name: "销售数量",
          type: "line",
          label: { //每个元素顶部显示数据
            normal: {
              show: true,
              position: 'top',
              fontSize: 12,
              color: '#000',
              offset: [0, 0],
            },
          },
          yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
          smooth: true, //平滑曲线显示
          showAllSymbol: true, //显示所有图形。
          symbol: "circle", //标记的图形为实心圆
          symbolSize: 10, //标记的大小
          itemStyle: {
            //折线拐点标志的样式
            color: color
          },
          lineStyle: {
            color: color
          },
          areaStyle:{
            color: "rgba(5,140,255, 0.2)"
          },
          data: arr[1]
        },
          {
            name: "销售数量",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: color
                },
                  {
                    offset: 1,
                    color: color
                  }
                ])
              }
            },
            data:arr[1]
          }
        ]
      };
      myChart.setOption(option);
    },
    go(zt){
      window.scrollTo(0,0);
      this.zt=zt
    },
    open(){
      window.location.href='https://dp.yaojian.xusos.com/#/zhuye?act=nologin'
    },
    load(){
      this.getData()
      this.yaosList()
      this.search()
    },
    yaosList(){
      let that=this
      axios.get('/screen/pharmacist/list?region='+this.region, {})
          .then(function (response) {
            that.arrYaos=response.data.data
          })
    },
    getData(){
      let that=this

      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            that.arrYaodian=response.data.data
            that.o.num1=that.arrYaodian.length
                 })

      axios.get('/screen/stock?region='+this.region, {})
          .then(function (response) {
            that.o.num2=response.data.data
          })

      axios.get('/screen/index?region='+this.region, {})
          .then(function (response) {
            that.o.num3=response.data.data.special
            that.o.num4=response.data.data.warning
            that.o.num5=response.data.data.epidemic
            that.o.num6=response.data.data.pharmacist
            that.o.num7=response.data.data.tad
          })

    },
    //日期函数开始
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    }

  }
}
</script>
<style scoped>
.img img{width: 100%}
.dkxt img{width: 100%}
.ggtp{padding: 0px 10px}
.ggtp img{width: 100%;}
.content{font-size: .875rem}
.gxsj_item{height: 53px}
.shuju{color: #FF6327;}
.gxsj{background-image: url("../assets/pujiang2/7.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;height: 209px}
.block_c{padding: 0 1rem 0 1rem}
.hxzs{text-align: center}
.hxzs2{width: 33vw;
  height: 51px;
  line-height: 51px;
  background: #88c9e3;
  border-radius: 10px;

margin: 0 auto;
  text-align: center;
  color: #DF5934;
  font-size: .875rem;
}
.hxzs3{margin-top: 10px}
.title_box{padding-top: 1rem;padding-bottom: 1rem}
.titlebiao{
  width: 34vw;
  font-size: 1rem;
  line-height: 2rem;
  color: #FFFFFF;
   background-image: url("../assets/pujiang2/3.png");
   text-align: center;
  background-size:100%;
  background-repeat: no-repeat;

}
.sjgx{
  margin:0 auto;width: 1446px;height: 645px;background-image: url('../assets/pujiang/sjgx.png');margin-top: 50px;
}
.jian{height: 10px;background-color: #F4F4F4;}
.bai{background-color: #FFFFFF}
#app{
  /*background-color: #F6F6F6;*/

}
.hand{cursor: pointer}
.main{
  width: 100%;
  background-color: #F4F4F4;
}
.block-gg{
  color: #3A3939;

}
.content{font-size: .875rem;line-height: 180%;color: #191818}
.txt-c{ text-align: center}
.txt-b{font-weight: bolder}
.lanse{color: #88C9E3}
.block1{font-size: .875rem;line-height: 180%}

.block3 .zi1{
font-size: .875rem;
  background-color: #F6F6F6;
  border-radius:33px;
  width: 250px;
  margin: 0 auto;
  border: 3px solid #EBEBEB;
}
.block3 .zi2{
  font-size: .875rem;
}

.biaoti{line-height:50px;font-size: 30px}
.biaoti .kuai{width: 15px;height: 50px; background-color:#88C9E3;float: left;margin-right: 15px}
</style>
