<template>
<div class="dialog">
  <el-dialog title="" :visible.sync="dialogTableVisible" width="1754px" top="5vh">
    <div class="box">
      <div style="padding: 50px">
        <div style="text-align: right">
          <img src="../../assets/home/cha.png" @click="dialogTableVisible=false" class="hand">
        </div>
        <div class="content" >
          <div style="display: flex" class="title">
            <div class="item" :class="{active:post.status==10}" @click="changeType(10)">待整改</div>
            <div class="item" :class="{active:post.status==30}" @click="changeType(30)">待审核</div>
            <div class="item" :class="{active:post.status==40}" @click="changeType(40)">审核完成</div>
          </div>
          <div class="biao" style="margin-top: 20px">
            <el-table :data="list" height="660">
              <el-table-column property="id" label="id" width="150"></el-table-column>
              <el-table-column property="storeName" label="店铺" width="200"></el-table-column>
              <el-table-column property="type" label="通知类型">
                <template slot-scope="scope">
                  <div v-if="scope.row.type==2">现场检查</div>
                  <div v-else="scope.row.type==1">系统自动</div>
                </template>
              </el-table-column>
              <el-table-column property="title" label="标题"></el-table-column>
              <el-table-column property="content" label="内容" show-overflow-tooltip></el-table-column>
              <el-table-column property="status" label="状态">
                <template slot-scope="scope">
                <div v-if="scope.row.status === 10" type="info">待整改</div>
                <div v-if="scope.row.status === 20" type="warning">整改中</div>
                <div v-if="scope.row.status === 30" type="primary">已整改</div>
                <div v-if="scope.row.status === 40" type="success">审核完成</div>
                </template>
              </el-table-column>
              <el-table-column property="handleTime" label="处理时间"></el-table-column>
              <el-table-column property="handleName" label="处理人"></el-table-column>
              <el-table-column property="repayContent" label="回复内容"></el-table-column>
              <el-table-column property="repayPic" label="回复图片">
                <template slot-scope="scope">
                  <div v-if="scope.row.repayPic">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="scope.row.repayPic"
                        :preview-src-list="[scope.row.repayPic]"
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline" />
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="completeTime" label="完成时间"></el-table-column>
              <el-table-column property="completeName" label="完成人"></el-table-column>
              <el-table-column property="status" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button type="text" v-if="scope.row.status === 30">审核</el-button>
                  <el-button type="text" v-if="scope.row.status === 30">拒绝</el-button>
                  <el-button type="text">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: center">
              <el-pagination @current-change="currentChange"  layout="total,prev, pager, next" :total="total"></el-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>

  </el-dialog>
</div>
</template>

<script>
import {disposalList} from "@/api/yaojian";
export default {
  name: "disposal",
  data(){
    return {
      total:0,
      post:{
        page:1,
        count:10,
        status:10,
      },
      list: [],
      dialogTableVisible: false,
    }
  },
  created() {

    },
  methods:{
    currentChange(p){
      this.post.page=p
      this.getList()
    },
    async getList(){
      let rs=await disposalList(this.post)
      if(rs.code==200){
        this.list=rs.data.list
        this.list.map(x=>x.repayPic=JSON.parse(x.repayPic))
        this.total=rs.data.total
      }
    },
    changeType(t){
      this.post.status=t
      this.getList()
    },
    open(){
      this.getList()
      this.dialogTableVisible=true
    }
  }
}
</script>

<style scoped>
.content{ padding:0px 20px}
.box{
  background-image: url('../../assets/home/dialog.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 931px;
}
.title .active{color: #1AC9FF}
.title .item{margin-right: 48px;font-size: 24px;cursor: pointer}
.hand{cursor: pointer}

>>>.el-pager li{background-color: transparent !important;}
>>>.el-pagination .btn-next, >>>.el-pagination .btn-prev{
  background-color: transparent !important;
}
/*el-table样式------------------------------------start*/
.biao>>> .el-table,
.biao >>> .el-table__expanded-cell {
  background-color: transparent !important;
}

.biao >>> .el-table th,>>>.el-table tr {
  border: 0 !important;
  background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>>.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row>td{
  color: #F5FDFF;
  border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
  height: 0px;
}

/*el-table样式------------------------------------end*/

/*el-dialog样式------------------------------------start*/
.dialog >>>.el-dialog{background-color: transparent}
.dialog >>>.el-dialog__header{display: none}
.dialog >>>.el-dialog__wrapper .el-dialog .el-dialog__body{padding: 0;}



/*el-dialog样式------------------------------------end*/

</style>
