<template>
    <div id="regulation" style="width: 1920px; overflow: hidden">
        <div class="regulation-content">
            <!-- 头部 -->
            <!--            <div class="header">-->
            <!--                <span class="header-title">精 密 监 管</span>-->
            <!--                <div class="header-back" @click="$refs.dialog.open()">-->
            <!--                    <img src="../assets/smart/home/dialog2.png" alt="" />-->
            <!--                    <span>返回</span>-->
            <!--                </div>-->
            <!--            </div>-->
            <yun-header />
            <!-- 内容 -->
            <div class="body">
                <!-- 左边内容 -->
                <div class="left">
                    <!-- 风险 -->
                    <div class="risk">
                        <!-- 风险标题 -->
                        <div class="risk-header common-header">
                            <div class="risk-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >风险处置分析</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                            <div
                                class="risk-header-back common-back"
                                @click="$router.push('/yunRisk')"
                            >
                                <img
                                    style="width: 24px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 时间 -->
                        <div class="risk-time">
                            <span>2022-01-01 00:00:00 </span>
                            <span style="margin: 0 6px">至</span>
                            <span>{{ nowTime }}</span>
                        </div>
                        <!-- 饼图 -->
                        <div class="risk-echart" ref="riskEchart"></div>
                        <!-- <div class="risk-list">
                            <span>100</span>
                            <span>100</span>
                            <span>100</span>
                            <span>100</span>
                            <span>100</span>
                        </div> -->
                        <div class="panel-footer"></div>
                    </div>
                    <!-- 药店进销库管理 -->
                    <div class="sale">
                        <!-- 药店进销库管理标题 -->
                        <div class="sale-header common-header">
                            <div class="sale-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >药品进销存管理</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                            <div
                                class="sale-header-back common-back"
                                @click="$router.push('/yunStockSell')"
                            >
                                <img
                                    style="width: 24px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 药品总量 -->
                        <div class="total">
                            <!-- 库存总量 -->
                            <div class="drug-total store-total">
                                <span class="drug-total-title">药品库存总量</span>
                                <span class="drug-total-number">{{ total.stock }}</span>
                            </div>
                            <!-- 购进总量-->
                            <div class="drug-total buyin-total">
                                <span class="drug-total-title">药品购进总量</span>
                                <span class="drug-total-number">{{ total.caigou }}</span>
                            </div>
                            <!-- 销售总量 -->
                            <div class="drug-total sale-total">
                                <span class="drug-total-title">药品销售总量</span>
                                <div class="lineOn">
                                    <span class="drug-total-type" style="margin-right: 5px"
                                        >线上:</span
                                    >
                                    <span class="drug-total-number">2370563</span>
                                </div>
                                <div class="offLine">
                                    <span class="drug-total-type" style="margin-right: 5px"
                                        >线下:</span
                                    >
                                    <span class="drug-total-number">{{ total.sale }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 药品图表 -->
                        <div class="sale-echart">
                            <div class="unsalable">
                                <div class="unsalable-echart" ref="unsalable"></div>
                                <div class="proportion">滞销药品占比</div>
                            </div>
                            <div class="inventory">
                                <div class="inventory-echart" ref="inventory"></div>
                                <div class="proportion">库存药品占比分析</div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                </div>
                <div class="center">
                    <!-- 地图 -->
                    <div class="chinamap" style="padding: 10px">
                        <baidumap :height="540"></baidumap>
                        <div class="panel-footer"></div>
                    </div>
                    <!-- 防疫管理 -->
                    <div class="manage">
                        <!-- 防疫管理标题 -->
                        <div class="manage-header common-header" style="padding: 20px">
                            <div class="manage-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >智慧药房</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
<!--                            <div
                                class="manage-header-back common-back"
                                @click="$router.push('/yunEpidemic')"
                            >
                                <img
                                    style="width: 24px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div>-->
                        </div>
                        <div class="enterprice-table" style="margin-left: 20px;">
                            <table>
                                <tr class="first">
                                    <td style="width: 200px">序号</td>
                                    <td style="width: 600px;text-align: left">名称</td>
                                    <td style="width: 150px">订单数</td>
                                </tr>
                            </table>
                          <div style="height:760px;overflow: hidden;">
                         <template v-if="smartPharmcyStore.length>0">
                            <vue-seamless-scroll
                                :data="smartPharmcyStore"
                                :class-option="defaultOption"
                            >
                            <table>
                                <tr class="second" v-for="(item, index) in smartPharmcyStore" :key="index">
                                <td style="width:200px">{{ index + 1 }}</td>
                                <td  style="width:600px;text-align: left">{{ item.name }}</td>
                                <td  style="width:150px;">{{ item.number }}</td>
                                </tr>
                            </table>
                            </vue-seamless-scroll>
                            </template>
                          </div>
                        </div>
                        <!-- <div class="manage-total" style="padding: 0px 20px 0px 20px" >
                            <div class="noreport common-manage hand" @click="$refs.yunChild.open('zdqy')">
                                <span class="total-number">{{szyy.zdqy}}</span>
                                <span class="total-type">重点企业数量</span>
                            </div>
                            <div class="expire common-manage hand" @click="$refs.yunChild.open('xfj')">
                                <span class="total-number">{{ szyy.xfj }}</span>
                                <span class="total-type">含兴奋剂药品销售情况</span>
                            </div>
                        </div>
                      <div class="manage-total hand" style="padding: 0px 20px 0px 20px;margin-top: 30px">
                        <div class="noreport common-manage" @click="$refs.yunChild.open('zicha')">
                          <span class="total-number">{{szyy.zcqk}}</span>
                          <span class="total-type">自查情况</span>
                        </div>
                        <div class="expire common-manage hand" @click="$refs.yunChild.open('chengnuo')">
                          <span class="total-number">{{ szyy.cnqk }}</span>
                          <span class="total-type">承诺情况</span>
                        </div>
                      </div> -->
<!--                        <div class="people-counting" style="padding: 0 5px">
                            <div class="people-number" >
                                <div class="people-total">
                                    {{ SaleSum.today }}
                                </div>
                                <div class="people-type">今日成交量</div>
                            </div>
                            <div class="people-number" >
                                <div class="people-total">
                                    {{ SaleSum.week }}
                                </div>
                                <div class="people-type">本周成交量</div>
                            </div>
                            <div class="people-number" >
                                <div class="people-total">{{SaleSum.month }}</div>
                                <div class="people-type">本月成交量</div>
                            </div>

                        </div>-->
                        <div class="panel-footer"></div>
                    </div>
                </div>
                <div class="right">
                    <!-- 药品管控管理 -->
                    <div class="control">
                        <!-- 标题 -->
                        <div class="control-header common-header">
                            <div class="control-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >药品管控</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                            <div
                                class="control-header-back common-back"
                                @click="$router.push('/yunDrugControl')"
                            >
                                <img
                                    style="width: 2 4px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!-- 药品管控总量 -->
                        <div class="control-total">
                            <div class="gross">
                                <span class="gross-number">31</span>
                                <span class="gross-percent">0.0009%</span>
                                <span class="gross-type">禁售药品总量</span>
                                <div class="panel-footer"></div>
                            </div>
                            <div class="gross">
                                <span class="gross-number">2556</span>
                                <span class="gross-percent">0.076%</span>
                                <span class="gross-type">限售药品总量</span>
                                <div class="panel-footer"></div>
                            </div>
                            <div class="gross">
                                <span class="gross-number">159819</span>
                                <span class="gross-percent">4.759%</span>
                                <span class="gross-type">提示药品总量</span>
                                <div class="panel-footer"></div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                    <!-- 一周管控药品销量趋势 -->
                    <div class="weektrend" style="margin-top: 15px">
                        <!-- 标题 -->
                        <div class="weektrend-header common-header">
                            <div class="weektrend-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >一周管控药品销量趋势</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                            <!-- <div class="weektrend-header-back common-back">
                                <img
                                    style="width: 2 4px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div> -->
                        </div>
                        <!-- 折线图 -->
                        <div class="weektrendchart" ref="weektrendchart"></div>
                        <div class="panel-footer"></div>
                    </div>
                    <!-- 企业资质设备管理 -->
                    <div class="qualification">
                        <!-- 标题 -->
                        <div class="qualification-header common-header">
                            <div class="qualification-header-title common-title">
                                <span class="title commontxt" style="margin-right: 15px"
                                    >药店资质/设备管理</span
                                >
                                <img
                                    style="display: block; width: 95px; height: 10px"
                                    src="../assets/regulation/yd.png"
                                    alt=""
                                />
                            </div>
                            <div
                                class="qualification-header-back common-back"
                                @click="$router.push('/yunEnterprise')"
                            >
                                <img
                                    style="width: 24px; height: 24px"
                                    src="../assets/regulation/进入箭头小.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="qualificationechart">
                            <div class="left commont">
                                <div class="left-echart" ref="qualificationchart"></div>
                                <div class="condition">证照资质情况</div>
                            </div>
                            <div class="right commont">
                                <div class="right-echart">
                                    <div class="status">
                                        <span
                                            style="
                        color: #fbc902;
                        font-weight: bold;
                        font-family: Source Han Sans CN;
                        font-size: 26px;
                        line-height: 21px;
                      "
                                            >144</span
                                        >
                                        <span
                                            style="
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        line-height: 19px;
                      "
                                            >正常</span
                                        >
                                    </div>
                                    <div class="status">
                                        <span
                                            style="
                        font-size: 26px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 21px;
                      "
                                            >11</span
                                        >
                                        <span
                                            style="
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 19px;
                      "
                                            >不在线</span
                                        >
                                    </div>
                                    <div class="status">
                                        <span
                                            style="
                        font-size: 26px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #fefefe;
                        line-height: 20px;
                      "
                                            >7</span
                                        >
                                        <span
                                            style="
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 19px;
                      "
                                            >报停</span
                                        >
                                    </div>
                                    <div class="status">
                                        <span
                                            style="
                        font-size: 26px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 20px;
                      "
                                            >10</span
                                        >
                                        <span
                                            style="
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 20px;
                      "
                                            >损坏报修</span
                                        >
                                    </div>
                                </div>
                                <div class="condition">在线监测设备运营情况</div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </div>
        </div>
        <yunDialog ref="childDialog"></yunDialog>
      <yun-child ref="yunChild"/>
    </div>
</template>

<script>
import * as echarts from "echarts";
import baidumap from "./components/baidumap.vue";
import yunHeader from "@/views/yunHeader";
import yunDialog from "@/views/yunDialog";
import yunChild from "@/views/yunChild";
import vueSeamlessScroll from "vue-seamless-scroll";
import {
    storeInventory,
    saleTotal,
    practitionerLsit,
    abnormalNumber,
    prescriptionTotal,
} from "../api/daping";
import moment from "moment";
import {
  saleSum,
  disposalList,
  saleList,
  stockTotal,
  storePerson,
  storePersonList,
  sale,
  purchase,
  visitor,
  healthList, storeListPage, zichaList, zdqyList, mhjSaleNum, chengnuoList, smartPharmacyStoreListApi
} from "@/api/yaojian";

export default {
    name: "smartRegulation",
    components: {
        baidumap,
        yunHeader,
        yunDialog,yunChild,
        vueSeamlessScroll
    },
    omputed:{
        defaultOption() {
        return {
            step: 0.5, // 数值越大速度滚动越快
            limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: true, // 是否开启鼠标悬停stop
            direction: 1, // 0向下 1向上 2向左 3向右
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
        }
    },
    data() {
        return {
          offlineNum:0,
          SaleSum:{},
          sumTotal:0,//站点分布数量
            riqi: [],
            nowTime: moment().format("yyyy-MM-DD HH:mm:ss"),
            today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
            total: {stock:0},
            riskPie: null,
            sale: null,
            inventory: null,
            weektren: null,
            qualification: null,
            colors: ["#0f99df", "#df48ff"],
            dataList: [
                {
                    name: "正常",
                    value: 97.6,
                },
                {
                    name: "近效期",
                    value: 3.4,
                },
            ],
            startTime: moment()
                .subtract(1, "day")
                .format("yyyy-MM-DD 00:00:00"),
            endTime: moment()
                .subtract(1, "day")
                .format("yyyy-MM-DD 23:59:59"),
            storeInventoryList: 0, //药品库存总量
            offlineLsit: 0, // 线下总量
            abnormalNumber: 0, //异常人数
            practitionerList: 0, //从业人员总数
            orderList: [
                { value: 614, name: "自主整改" },
                { value: 450, name: "责令整改" },
                { value: 319, name: "部门处置" },
                { value: 2, name: "联动处置" },
            ],
            typeList: [
                { value: 87.6, name: "正常" },
                { value: 10.4, name: "近效期" },
                { value: 2, name: "过期" },
                { value: 0, name: "其他" },
            ],
            storePersonHsData: {},
            visitorData: {},
            visitorData2: {},
            storePersonHs: 0,
            vistorList:[],
          szyy:{
            zdqy:0,
            xfj:0,
            zcqk:0,
            cnqk:0,
          },
          smartPharmcyStore:[]
        };
    },
    mounted() {
        this.initChart();
        this.initSalechart();
        this.inventorychart();
        this.weektrendchart();
        this.qualificationchart();

        setInterval(() => {
            this.nowTime = moment().format("yyyy-MM-DD HH:mm:ss");
        }, 1000);
    },
    created() {
        this.initRequest();
        this.getData();
        this.getVistorList()
        for (let i = 0; i < 7; i++) {
            this.riqi.push(
                moment()
                    .add(-i, "day")
                    .format("MM-DD")
            );
        }
        this.riqi.sort();
        this.getZdqy()
        this.getMhj()
        this.getZicha()
        this.getChengnuo()
        this.loadSmartPharmacyStore()
    },
    computed:{
    },
    methods: {
        loadSmartPharmacyStore(){
            const start = moment().format("yyyy-01-01 00:00:00")
            const end =   moment().format("yyyy-MM-DD 23:59:59")
            smartPharmacyStoreListApi({ startTime: start, endTime: end }).then((rs) => {
                this.smartPharmcyStore = rs.data
            })
        },
      cint(i){
        return parseInt(i)
      },
      async getChengnuo(){
        let rs=await chengnuoList()
        if(rs.code==200) this.szyy.cnqk=rs.data.total
      },
      async getZicha(){
        let rs=await zichaList()
        if(rs.code==200) this.szyy.zcqk=rs.data.total
      },
      async getMhj(){
        let rs=await mhjSaleNum()
        if(rs.code==200) this.szyy.xfj=this.cint(rs.data.xifenji)
      },
       async getZdqy(){
            let rs=await zdqyList({type:4})
                this.szyy.zdqy=rs.data.total
       },
        //累积访客异常数
        getVistorList(){

            visitor({
                page: 1,
                count: 1000,
                level: "黄码",
                startTime: moment().startOf('year').format("yyyy-MM-DD HH:mm:ss"),
                endTime: moment().endOf('year').format("yyyy-MM-DD HH:mm:ss"),
            }).then((rs) => {
                 this.vistorList.push(rs.data)
            });
            //异常访客人数 红码
              visitor({
                page: 1,
                count: 1000,
                level: "红码",
                startTime: moment().startOf('year').format("yyyy-MM-DD HH:mm:ss"),
                endTime: moment().endOf('year').format("yyyy-MM-DD HH:mm:ss"),
            }).then((rs) => {
                 this.vistorList.push(rs.data)
            });
        },
        //今日访客数
          todayVisitor(){
                 // 访客人数
            visitor({
                page: 1,
                count: 200,
                startTime: this.today[0],
                endTime: this.today[1],
            }).then((rs) => {
                this.visitorData = rs.data;
            });
            this.$refs.childDialog.openJrfks()
        },
        getData() {
            //销售总数
            sale().then((rs) => {
                this.total.sale = parseInt(rs.data);
            });
            //总采购
            purchase().then((rs) => {
                this.total.caigou = parseInt(rs.data);
            });
            //总库存
            stockTotal({
                endTime: this.today[1],
                startTime: moment()
                    .add(-1, "day")
                    .format("yyyy-MM-DD 00:00:00"),
            }).then((rs) => {
              if(rs.data.length>0){
                this.total.stock = parseInt(rs.data[0].total);
              }
            });

  /*          // 访客人数
            visitor({
              count:10,
                page: 1,
                startTime: this.today[0],
                endTime: this.today[1],
            }).then((rs) => {
                this.visitorData = rs.data;
            });
            // 异常访客人数
            visitor({
                page: 1,
                level: "黄码",
                startTime: this.today[0],
                endTime: this.today[1],
            }).then((rs) => {
                this.visitorData2 = rs.data;
            });*/

            //从业人员核酸总
            let post = {
                startTime: moment()
                    .add(-7, "day")
                    .format("yyyy-MM-DD 00:00:00"),
                endTime: this.today[1],
            };
            storePerson(post).then((rs) => {
                this.storePersonHs = rs.data.size - rs.data.count;
            });
            //从业人员核酸列表异常
            post = {
                startTime: moment()
                    .add(-1, "day")
                    .format("yyyy-MM-DD 00:00:00"),
                endTime: this.today[1],
                page: 1,
                count: 10,
                health: "黄码",
            };
            storePersonList(post).then((rs) => {
                this.storePersonHsData = rs.data;
            });
        },
       initRequest() {
          //获取站点分布数量
        storeListPage({page:1,count:10,regionCode:localStorage.id,type:70}).then((rs) => {
          this.sumTotal = rs.data.total;
        });
         saleSum().then((rs) => {
           this.SaleSum = rs.data;
         });
            // 获取库存总量
            const getstoreInventory = new Promise(async (resolve, reject) => {
                const startTime = this.startTime;
                const endTime = this.endTime;
                const { data } = await storeInventory({ endTime, startTime });
                if(data.length>0){
                  resolve(parseInt(data[0].count));
                }

            });
            // 获取线下总量
            const getSaleTotal = new Promise(async (resolve, reject) => {
                const { data } = await saleTotal();
                resolve(parseInt(data));
            });

            // 获取黄码异常人数
            const abnormalLisi = new Promise(async (resolve, reject) => {
                const params = {
                    page: 1,
                    level: "黄码",
                    startTime: moment().format("yyyy-MM-DD 00:00:00"),
                    endTime: moment().format("yyyy-MM-DD 23:59:59"),
                };
                const { data } = await abnormalNumber(params);
                resolve(data.total);
            });

            //获取从业人员总数
            const getpractitioner = new Promise(async (resolve, reject) => {
                const params = {
                    page: 1,
                    count: 10,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    status: 20,
                };
                const { data } = await healthList(params);
                resolve(data.total);
            });

            //  处方销量
            // const prescription=new Promise(async(resolve,reject)=>{
            //   const params={
            //     endTime:this.endTime,
            //     startTime:this.startTime
            //   }
            //    const res =await prescriptionTotal(params)
            //    console.log(res,"处方销量");

            // })
            Promise.all([getstoreInventory, abnormalLisi, getpractitioner]).then(
                (res) => {
                    this.storeInventoryList = res[0];
                    this.abnormalNumber = res[1];
                    this.practitionerList = res[2];
                }
            );
        },
        //   风险图表
        initChart() {
            this.riskPie = echarts.init(this.$refs.riskEchart);
            const initOption = {
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "25%",
                    left: "55%",
                    itemGap: 16,
                    itemWidth: 15,
                    itemHeight: 15,
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 160,
                                fontSize: "22",
                                color: "#fff",
                                fontWeight: 400,
                            },
                            value: {
                                fontSize: "22",
                                color: "#fff",
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const index = this.orderList.findIndex((item) => item.name == name);
                        return `{title|${name}}{value|${this.orderList[index].value}}`;
                    },
                },
                series: [
                    {
                        name: "风险处置分析",
                        type: "pie",
                        radius: ["20%", "50%"],
                        center: ["25%", "47%"],
                        label: {
                            show: true,
                            position: "outside",
                            formatter: "{d}%",
                            color: "#fff",
                        },
                        labelLine: {
                            show: true,
                            length: 10,
                            length2: 10,
                            lineStyle: {
                                width: 2,
                            },
                        },
                        data: this.orderList,
                    },
                ],
            };
            this.riskPie.setOption(initOption);
        },
        //滞销药品占比
        initSalechart() {
            this.sale = echarts.init(this.$refs.unsalable);
            const saleOption = {
                series: [
                    {
                        name: "滞药销售占比",
                        type: "pie",
                        radius: ["65", "45"],
                        center: ["40%", "40%"],
                        label: {
                            position: "center",
                            color: "#fff",
                            fontSize: 24,
                            fontWeight: "bold",
                        },
                        data: [
                            {
                                value: 100,
                                name: "20.7%",
                                itemStyle: {
                                    color: "#0277fa",
                                },
                            },
                            {
                                value: 500,
                                name: "20.7%",
                                itemStyle: {
                                    color: "#07265f",
                                },
                            },
                        ],
                    },
                ],
            };
            this.sale.setOption(saleOption);
        },
        //  滞销药品占比分析图表
        inventorychart() {
            this.inventory = echarts.init(this.$refs.inventory);
            const inventoryOption = {
                color: ["#C41DF3", "#F78306", "#FBC902", "#0096FF", "#00D7FE"],
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "22%",
                    left: "45%",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 6,
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 100,
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            b: {
                                color: "#C41DF3",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            c: {
                                color: "#F78306",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            d: {
                                color: "#FBC902",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            e: {
                                color: "#0096FF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            f: {
                                color: "#00D7FE",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.typeList.findIndex((item) => item.name == name);
                        if (name == "正常") {
                            return `{a|${name}}{b|${this.typeList[currentIndex].value}%}`;
                        } else if (name == "近效期") {
                            return `{a|${name}}{c|${this.typeList[currentIndex].value}%}`;
                        } else if (name == "过期") {
                            return `{a|${name}}{d|${this.typeList[currentIndex].value}%}`;
                        } else if (name == "不合适") {
                            return `{a|${name}}{e|${this.typeList[currentIndex].value}%}`;
                        } else {
                            return `{a|${name}}{f|${this.typeList[currentIndex].value}%}`;
                        }
                    },
                },
                series: [
                    {
                        name: "风险处置分析",
                        type: "pie",
                        radius: ["40%", "70%"],
                        center: ["25%", "60%"],
                        label: {
                            show: false,
                            position: "center",
                        },
                        data: this.typeList,
                    },
                ],
            };
            this.inventory.setOption(inventoryOption);
        },

        // 一周管控药品销量趋势图表
        weektrendchart() {
            this.weektren = echarts.init(this.$refs.weektrendchart);
            const weektrenOption = {
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                    },
                },
                grid: {
                    left: "10%",
                    right: "4%",
                    bottom: "10%",
                },

                xAxis: [
                    {
                        type: "category",
                        data: this.riqi,
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#081d4b",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "易制毒类药品",
                        type: "bar",
                        itemStyle: {
                            barBorderRadius: [5, 5, 0, 0],
                            color: "#C41DF3",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                    {
                        name: "含兴奋剂药品",
                        type: "bar",
                        itemStyle: {
                            barBorderRadius: [5, 5, 0, 0],
                            color: "#FF6383",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [150, 232, 201, 154, 190, 330, 410],
                    },
                ],
            };
            this.weektren.setOption(weektrenOption);
        },
        //企业资质设备管理
        qualificationchart() {
            this.qualification = echarts.init(this.$refs.qualificationchart);
            const qualificationOption = {
                color: this.colors,
                //    tooltip: {
                //     trigger: 'item',
                // },
                legend: {
                    orient: "vertical",
                    icon: "rect",
                    top: "25%",
                    right: "35%",
                    left: "35%",
                    itemGap: 10,
                    itemWidth: 16,
                    itemHeight: 16,
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 60,
                                color: "#ffff",
                                fontSize: 16,
                                fontWeight: 400,
                            },
                            value: {
                                color: "#ffff",
                                fontSize: 18,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.dataList.findIndex((item) => item.name == name);
                        return `{title|${name}}{value|${this.dataList[currentIndex].value}%}`;
                    },
                },

                series: [
                    {
                        showEmptyCircle: true,
                        type: "pie",
                        center: ["18%", "40%"],
                        radius: ["45", "0"],
                        selectedOffset: 100, // 设置偏离距离

                        label: {
                            show: false,
                        },
                        labelLine: {
                            //血线
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                borderColor: "#09143e",
                                borderWidth: "5",
                            },
                        },
                        data: this.dataList,
                    },
                ],
            };
            this.qualification.setOption(qualificationOption);
        },
    },
};
</script>

<style lang="less" scoped>
.hand{cursor: pointer}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#regulation {
    margin: 0;
    padding: 0;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
}
.regulation-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.regulation-content .header {
    margin: 10px;
    z-index: 1;
    background-image: url("../assets/regulation/bt.png");
    background-size: cover;
    height: 51px;
}
.regulation-content .header .header-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 40px;
    font-weight: Bold;
    line-height: 26px;
    font-family: Source Han Sans CN;
}
.regulation-content .header .header-back {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 63%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.regulation-content .header .header-back img {
    width: 25px;
    height: 25px;
    vertical-align: center;
}
.regulation-content .header .header-back span {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 26px;
    margin: 0 30px 0 10px;
}

.body {
    flex: 1;
    width: 100%;

    display: flex;
    gap: 15px;
    padding: 0 20px;
}
.body .left {
    flex: 1;
}
.body .center {
    flex: 1;
}
.body .right {
    flex: 1;
}
.body .common-back {
    cursor: pointer;
}
.body .left .risk {
    position: relative;
    width: 100%;
    height: 408px;
    /* border: 1px solid #00AEFE;
       background-color: #081A32;
       opacity: 0.8; */
    background: url("../assets/regulation/bg.png");
    background-size: 100% 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
}
.body .left .risk::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .left .risk::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .left .risk .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .left .risk .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}

.body .left .risk .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .left .risk .risk-header,
.common-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
}
.body .left .risk .risk-header-title,
.common-title {
    display: flex;
    align-items: center;
}
.body .left .risk .risk-header .title,
.commontxt {
    line-height: 28px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 4px;
}
.body .left .risk .risk-time {
    padding-left: 20px;
    background: url("../assets/regulation/jb.png");
    background-size: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #eeeeee;
}
.body .left .risk .risk-echart {
    width: 100%;
    flex: 1;
    position: relative;
}
.body .left .risk .risk-list {
    display: flex;
    flex-direction: column;
    color: #fff;
    position: absolute;
    right: 26px;
    top: 40%;
}
.body .left .risk .risk-list span {
    padding-bottom: 10px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}
.body .left .sale {
    position: relative;
    margin-top: 15px;
    width: 100%;
    height: 560px;
    background: url("../assets/regulation/左2.png");
    background-size: 100% 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
}
.body .left .sale::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .left .sale::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .left .sale .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .left .sale .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .left .sale .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .left .sale .total {
    height: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    margin-top: 8px;
}
.body .left .sale .total .drug-total {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px 0 10px;
}
.body .left .sale .total .drug-total .drug-total-title {
    font-size: 24px;
    font-weight: 400;
    color: #00d7fe;
    font-family: Source Han Sans CN;
}
.body .left .sale .total .drug-total .drug-total-number {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}
.body .left .sale .total .drug-total .drug-total-type {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}
.body .left .sale .sale-echart {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}
.body .left .sale .sale-echart .inventory {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.body .left .sale .sale-echart .inventory .inventory-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 22px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    top: 15%;
}
.body .left .sale .sale-echart .inventory .inventory-list span {
    display: flex;
    margin-bottom: 6px;
}
.body .left .sale .sale-echart .inventory .inventory-echart {
    position: relative;
    width: 100%;
    height: 70%;
}
.body .left .sale .sale-echart .inventory .proportion {
    margin-top: 20px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}
.body .left .sale .unsalable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    width: 180px;
}
.body .left .sale .unsalable .proportion {
    bottom: 28px;
    left: 0;
    position: absolute;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-left: 10px;
}
.body .left .sale .sale-echart .unsalable-echart {
    width: 100%;
    height: 160px;
}
.body .center .chinamap {
    width: 100%;
    height: 562px;
    background-color: pink;
    background: url("../assets/medicine/公共背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
/* .body .center .chinamap .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
} */
/* .body .center .chinamap::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .center .chinamap::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .center .chinamap .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .center .chinamap .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
} */
.body .center .manage {
    position: relative;
    margin-top: 15px;
    width: 100%;
    height: 406px;
    background: url("../assets/regulation/中2.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
}
.body .center .manage .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .center .manage .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .center .manage .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .center .manage::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .center .manage::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .center .manage .manage-total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.body .center .manage .people-counting {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.body .center .manage .people-counting .people-number {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
}
.body .center .manage .people-counting .people-total {
    background: url("../assets/regulation/中2-背景.png");

    height: 110px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}
.body .center .manage .people-number .people-type {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-top: 10px;
}
.body .center .manage .common-manage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 130px;
    background: url("../assets/regulation/中2方框.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.body .center .manage .common-manage .total-number {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fbc902;
}
.body .center .manage .common-manage .total-type {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
}
.body .right .control {
    position: relative;
    width: 100%;
    height: 256px;
    background: url("../assets/regulation/右1.png");
    background-size: 100% 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
}
.body .right .control::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .control::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .control .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .right .control .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .right .control .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .right .control .control-total {
    display: flex;
    gap: 13px;
}
.body .right .control .control-total .gross {
    padding: 12px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 150px;
    background: url("../assets/regulation/右1方框.png");
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
}
.body .right .control .control-total .gross .gross-number {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fbc902;
}
.body .right .control .control-total .gross .gross-percent {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}
.body .right .control .control-total .gross .gross-type {
    width: 138px;
    height: 22px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-bottom: 10px;
}
.body .right .weektrend {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    background: url("../assets/regulation/中2.png");
    background-size: 100% 100%;
    padding: 20px 20px 0 20px;
}
.body .right .weektrend .weektrendchart {
    flex: 1;
    width: 100%;
}
.body .right .weektrend::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .weektrend::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .qualification {
    position: relative;
    margin-top: 15px;
    width: 100%;
    height: 297px;
    display: flex;
    flex-direction: column;
    /* background: url("../assets/regulation/bg.png"); */
    background: url("../assets/regulation/右3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    padding: 20px 20px 0 20px;
}
.body .right .qualification .qualificationechart {
    flex: 1;
    display: flex;
}

.body .right .qualification .qualificationechart .commont {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.body .right .qualification .qualificationechart .commont .left-echart {
    width: 100%;
    height: 70%;
}
.body .right .qualification .qualificationechart .commont .condition {
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 33px;
}

.body .right .qualification .qualificationechart .commont .right-echart {
    display: flex;
    justify-content: center;
    /* position: relative;
    left: 0px;
    top: -7px; */
    flex-wrap: wrap;
    width: 100%;
    height: 69%;
    margin-right: 25px;
    margin-bottom: 3px;
    padding: 10px 0;
}
.body .right .qualification .qualificationechart .commont .right-echart .status {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.body .right .qualification .qualificationechart .commont .right-echart .status:first-child {
    position: relative;
    left: 0;
    top: 0%;
}
.body .right .qualification .qualificationechart .commont .right-echart .status:nth-child(2) {
    position: relative;
    left: 13px;
    top: -1px;
}
.body .right .qualification .qualificationechart .commont .right-echart .status:nth-child(3) {
    position: relative;
    left: -1px;
    top: -5%;
}
.body .right .qualification .qualificationechart .commont .right-echart .status:nth-child(4) {
    position: relative;
    left: 15px;
    top: -4%;
}
.body .right .qualification .qualificationechart .commont .right-echart .status span {
    font-size: 18px;
    color: #fff;
}

.body .right .weektrend .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .right .weektrend .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .right .weektrend .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .right .qualification::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .qualification::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-top: 2px solid #00aefe;
    content: "";
}
.body .right .qualification .panel-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.body .right .qualification .panel-footer::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border-left: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.body .right .qualification .panel-footer::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-right: 2px solid #00aefe;
    border-bottom: 2px solid #00aefe;
    content: "";
}
.xs-table {
    margin-left: 10px;
    background: transparent;
    font-size: 20px;
}
.xs-table /deep/ .el-table thead{
    background: #071033 !important;
    color: #fff;
}
.el-table th.el-table__cell {
    background-color: #071033 !important;
    color: #fff;
}
.enterprice-table {
    flex: 1;
    overflow-y: hidden;
    table {
        width: 100%;
        border-collapse: collapse;
        .first {
            height: 40px;
            background-image: url("../assets/regulation/表头.png");
            text-align: center;
            td {
                line-height: 40px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                vertical-align: middle;
            }
        }
        .second {
            text-align: center;
            height: 60px;
            &:nth-child(2n + 1) {
                background: url("../assets/regulation/表单.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            td {
                height: 60px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                vertical-align: middle;
            }
        }
    }
}
</style>
