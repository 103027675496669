<template>
  <div id="main" style="width: 1920px;overflow: hidden">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">买好药</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px">
            <div class="boxleft1">
              <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px" @click="go('/smartHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
                   <div class="titleBox">
                     <div class="title">购药总量</div>
                   </div>
              <div class="chart abs" style="top:70px">
                <div id="myChart" :style="{width: '600px', height: '260px'}"></div>
              </div>

            </div>
            <div class="boxleft1" style="margin-top: 14px">

              <div class="titleBox"> <div class="title hand" @click="$refs.childPrescription.openPrescription('处方药')">处方药销售情况</div> </div>
                      <div class="chart abs" style="bottom:30px">
                        <div id="myChart3" :style="{width: '600px', height: '230px'}"></div>
              </div>
            </div>
            <div class="boxleft1" style="margin-top: 14px">
              <div class="titleBox"><div class="title">处方流转趋势图</div></div>
              <div class="chart abs" style="bottom:30px">
                <div id="myChart_2" :style="{width: '600px', height: '230px'}"></div>
              </div>
            </div>
          </div>
          <div style="width: 671px;margin-left: 18px" class="centerblock">
            <div style="position: relative">
              <div class="total">
                <div class="lan24" style="padding-left: 64px;padding-top: 23px">
                  <div>累积交易总量</div>
                  <div style="margin-top: 37px">今日交易总量</div>
                </div>
              </div>
              <div class="totalnum">

                  <div class="nums">
                    <span class="num">1</span><span class="num">3</span><span class="num">2</span><span class="num">1</span><span class="num">4</span>
                  </div>
                  <div class="nums" style="margin-top: 30px">

                    <span class="num">2</span><span class="num">7</span><span class="num">3</span>
                  </div>
                </div>
            </div>

            <div class="mapBlock" style="margin-top: 13px">
        <baidumap :type="3"/>
            </div>

            <div class="tsrq" >
              <div class="titleBox" style="padding-left: 0px"><div class="title">特殊人群购药情况</div></div>
              <div class="chart " style="">
                <div id="myChart_tsrq" :style="{width: '700px', height: '360px'}"></div>
              </div>
            </div>

          </div>
          <div style="width: 606px" class="rightblock">
            <div class="boxleft1">
              <div class="titleBox"> <div class="title">易感疾病趋势监测分析</div></div>
              <div class="chart abs" style="top:70px;left:20px">
                <div id="myChart_ygjb" :style="{width: '600px', height: '230px'}"></div>
              </div>

            </div>
            <div class="boxleft1" style="margin-top: 14px">
              <div class="titleBox">
                <div class="riqi abs flex bai14" style="width: 262px;right:10px;top:20px">
                  <div class="item" :class="{lan14:gyrqZt==1}" @click="changeYj(1)">今日</div>
                  <div class="item" :class="{lan14:gyrqZt==2}" @click="changeYj(2)">本周</div>
                  <div class="item" :class="{lan14:gyrqZt==3}" @click="changeYj(3)">本月</div>
                  <div class="item " :class="{lan14:gyrqZt==4}" @click="changeYj(4)">本季</div>
                  <div class="item" :class="{lan14:gyrqZt==5}" @click="changeYj(5)">本年</div>
                </div>
                <div class="title">购药人群疾病占比分析</div></div>
              <div class="chart abs" style="top:70px">
                                <div id="myChart_gyrq" :style="{width: '600px', height: '300px'}"></div>
              </div>

            </div>
            <div class="boxleft1" style="margin-top: 14px">
              <div class="titleBox"> <div class="title">购药/慢病人群年龄分布</div></div>
              <div class="chart abs" style="top:50px;left:15px">
                                <div id="myChart_gynl" :style="{width: '300px', height: '250px'}"></div>
              </div>
              <div class="chart abs" style="top:50px;left: 288px">
                <div id="myChart_mbnl" :style="{width: '300px', height: '250px'}"></div>
              </div>

            </div>
          </div>
        </div>

      </div>
<chuan ref="childPrescription"/>
  </div>
</template>
<style scoped>
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.hand{cursor: pointer}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}
.boxleft1{
  position: relative;
  background-image: url("../assets/smart/buy/left1.png");
  width: 602px;
  height: 327px;
}

.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
  width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
.riqi .item{ width: 70px; text-align: center;cursor: pointer}


/*el-dialog样式------------------------------------start*/
.dialog >>>.el-dialog{background-color: transparent}
.dialog >>>.el-dialog__header{display: none}
.dialog >>>.el-dialog__wrapper .el-dialog .el-dialog__body{padding: 0;}
/*el-dialog样式------------------------------------end*/
/*el-table样式------------------------------------start*/
.biao>>> .el-table,
.biao >>> .el-table__expanded-cell {
  background-color: transparent !important;
}

.biao >>> .el-table th,>>>.el-table tr {
  border: 0 !important;
  background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>>.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row>td{
  color: #F5FDFF;
  border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
  height: 0px;
}

/*el-table样式------------------------------------end*/
</style>
<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import axios from "axios";
import baidumap from "@/views/components/baidumap";
import moment from 'moment'
import {saleList} from "@/api/yaojian";
import chuan from "./components/chuan"
export default {
  name: 'zhuye',
  components: {baidumap,chuan},
  data(){
    return {
      riqi:[],
      gyrqZt:1,
      keyword:"",
      region:330726,
      ypxsData:[],
      dialogPrescription:false,
      prescriptionData:[],
      postPrescription:{
        page:1,
        count:10,
        isPrescription:1,
        hospital:"互联网医院",
        startTime:moment().format("yyyy-MM-DD 00:00:01"),
        endTime:moment().format("yyyy-MM-DD 23:59:59"),
        type:1,
      },
    }
  },
  created() {
    for(let i=0;i<7;i++){
      this.riqi.push(moment().add(-i,'day').format('MM-DD'))
    }
    this.riqi.sort()
  },
  mounted() {
    this.getData()
    },
  methods:{
    closed(){
      this.$set(this.postPrescription,"page",1)
    },
    openPrescription(){
      this.dialogPrescription=true
      this.getPrescription()
    },
    getPrescription(data){
      if(data){
        this.postPrescription.page=data.page
      }
      saleList(this.postPrescription).then(rs=>{
        this.prescriptionData=rs.data
      })
    },


    changeYj(t){
      this.gyrqZt=t
      this.drawChart_gyrq("myChart_gyrq",1)
      // this.getYujing(1)
    },
    go(url){
      this.$router.push(url)
    },
    open(url){
      window.location.href=url
    },
    drawChart(){//购药总量

     let option = {
       color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
       legend: {
         icon: "circle",
         itemHeight: 7, //修改icon图形大小
         itemWidth: 7, //修改icon图形大小
         show: true,
         top: '0%',
         right: '10%',
         textStyle: {
           color: '#fff',
         },
         itemGap: 5,
         formatter: (name) => {
           return name;
         },
       },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '云药房', '线下', '购买趋势'],
            [this.riqi[2], 51, 4845, 4896],
            [this.riqi[3], 65, 6175, 6240],
            [this.riqi[4], 62, 5890, 5952],
            [this.riqi[5], 37,3515, 3552],
            [this.riqi[6], 85, 8075, 8160]
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };
      let myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption(option);
    },
    drawChart_age(id,title,arr){//购药/慢病人群年龄分布
     let option = {
        title: {
          text: title,
         // subtext: 'Fake Data',
          left: 'center',
          top: 'bottom',
          textStyle:{
            color:'#fff',
            fontSize:14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            label:{
              color:"#ffffff",
              formatter: (o) => {
                return o.name+"\n"+o.value+"%";
              },
            },
            name: title,
            type: 'pie',
            radius: '40%',
            data: arr,
               }
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart_gyrq(id,t=0){//购药人群疾病占比分析
      let option = {
        color:["#5AD8A6"],
        xAxis: {
          axisLabel: {
            show: true,
            color:'#fff'
          },
          type: 'category',
          axisLine: {
            show: true,//显示坐标轴线
          },
          data: ['泌尿系统', '呼吸系统', '心血管', '综合', '消化系统']
        },
        tooltip: {},//加了这个空就能显示鼠标移上去提示
        yAxis: {
          splitLine:false,
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth:20
          }
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
      // if(t==1){
      //   option.series[0].data[0]=240
      //   myChart.setOption(option,true);
      // }else{
      //   myChart.setOption(option);
      // }
    },
    drawChart_tsrq(){//特殊人群购药情况

      let option = {
        color: ['#5B8FF9', '#5AD8A6', '#EA8A3A'],
        legend: {
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '0%',
          right: '10%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        },
        tooltip: {},
        dataset: {
          source: [
            ['类别', '代办点', '咨询量', '签约医生'],
            ['郑家坞', 43.3, 85.8, 93.7],
            ['黄宅', 83.1, 73.4, 55.1],
            ['檀溪', 86.4, 65.2, 82.5],
            ['普阳', 72.4, 53.9, 39.1],
            ['郑宅', 72.4, 53.9, 39.1],
            ['仙华', 72.4, 53.9, 39.1],
            ['中余', 72.4, 53.9, 39.1],
            ['大畈', 72.4, 53.9, 39.1],
            ['前吴', 72.4, 53.9, 39.1],
            ['花桥', 72.4, 53.9, 39.1],
            ['白马', 72.4, 53.9, 39.1],
          ]
        },
        xAxis: { type: 'category',
          axisLine: {
            show: true,//不显示坐标轴线
          }, },
        yAxis: {splitLine:false},
        series: [{ type: 'bar',
          //barWidth: 20,

        }, { type: 'bar' }, { type: 'bar' }]
      };
      let myChart = echarts.init(document.getElementById('myChart_tsrq'))
      myChart.setOption(option);
    },
    drawChart_2(){//处方流转趋势图
      let xLabel = this.riqi
      let data = ["2", "3", "5", "4", "2", "3",'1']
      let data2 = ["180", "270", "450", "360", "180", "270",'90']
      let data3 = ["3", "2", "4", "3", "3", "2",'3']
      let data4 = ["1", "1", "1", "1", "1", "1",'1']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '院内处方',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '互联网医院',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '签约医生',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },
          {
            name: '其它',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[3],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[3], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[3],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data4
          },
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_2'))
      myChart.setOption(option);
    },
    drawChart_ygjb(id){//易感疾病趋势监测分析
      let xLabel = this.riqi
      let data = ["50", "60", "22", "75", "50", "40",'33']
      let data2 = ["40", "50", "12", "55", "30", "60",'76']
      let data3 = ["30", "40", "32", "45", "30", "60",'76']
      let data4 = ["60", "70", "32", "85", "60", "50",'46']
      let color=["#fd5800","#15EE76","#ffb600","#0070d2"]
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '呼吸系统',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[0],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[0], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[0],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '血液血管',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[1],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[1], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[1],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '泌尿系统',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[2],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[2], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[2],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          },
          {
            name: '消化系统',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: color[3],
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: color[3], // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: color[3],
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data4
          },
        ]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart3(){
      let myChart = echarts.init(document.getElementById('myChart3'))
      var datas = [
        {
          "value": 90,
          "name": "消化系统类"
        },
        {
          "value": 80,
          "name": "呼吸系统类"
        },
        {
          "value": 70,
          "name": "心血管类"
        },
        {
          "value": 60,
          "name": "镇痛消炎类"
        },
        {
          "value": 50,
          "name": "抗感染类"
        },
        {
          "value": 30,
          "name": "感冒类"
        },


      ]
      var maxArr = [100,100,100,100,100,100,100,100];
     let option = {
        backgroundColor: "",
       legend: {
          show:false,
         borderColor:'#d73622',
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 10,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         },
          },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params.data.name + ' : ' + params.data.value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
            color:'#fff'
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#2B8CFF",
            barBorderRadius: 0,
          },
          barWidth: 10,
          data: datas
        }
        ]
      };
      myChart.setOption(option);
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    getData(){
      this.drawChart();
      this.drawChart_tsrq();//特殊人群购药情况
      this.drawChart_2();//处方流转趋势图
      this.drawChart_ygjb("myChart_ygjb");//易感疾病趋势监测分析
      this.drawChart_gyrq("myChart_gyrq");//购药人群疾病占比分析
      let arr=[
        { value: 20, name: '0～3岁' },
        { value: 15, name: '4～18岁' },
        { value: 5, name: '19～45岁' },
        { value: 15, name: '45～60岁' },
        { value: 45, name: '60岁以上' }
      ]
      this.drawChart_age("myChart_gynl","购药人群年龄结构占比",arr);//购药人群年龄结构占比
      arr=[
        { value: 5, name: '0～3岁' },
        { value: 5, name: '4～18岁' },
        { value: 10, name: '19～45岁' },
        { value: 25, name: '45～60岁' },
        { value: 55, name: '60岁以上' }
      ]
      this.drawChart_age("myChart_mbnl","慢病人群年龄结构占比",arr);//慢病人群年龄结构占比
      this.drawChart3();
      // let arrCake=[
      //   {
      //     name: '签约医生',
      //     value: 20,
      //   },
      //   {
      //     name: '线下处方',
      //     value: 10,
      //   },
      //   {
      //     name: '互联网医院',
      //     value: 70,
      //   },
      // ]
      // this.drawChartCake("myChartCake_1",arrCake);
      // let arrCake2=[
      //   {
      //     name: '好评',
      //     value: 85,
      //   },
      //   {
      //     name: '中评',
      //     value: 12,
      //   },
      //   {
      //     name: '差评',
      //     value: 3,
      //   },
      // ]
      // this.drawChartCake("myChartCake_2",arrCake2);
          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
