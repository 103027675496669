<template>
  <div id="main" style="width: 1920px">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">用好药</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px">
            <div class="boxleft">
              <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px;cursor:pointer;" @click="go('/cloudHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
                   <div class="title">一周药事服务趋势</div>
              <div class="chart abs" style="top:100px">
                <div id="myChart" :style="{width: '600px', height: '330px'}"></div>
              </div>

            </div>
            <div class="boxleft">

              <div class="title">家庭智能药箱分析</div>
                      <div class="chart abs" style="bottom:30px">
                        <div id="myChart2" :style="{width: '600px', height: '420px'}"></div>
              </div>
            </div>
          </div>
          <div class="youblcok">
            <div class="youblock1 flex">
              <div style="width: 671px;margin-left: 18px" class="centerblock">
                <div style="position: relative">
                  <div class="total">
                    <div class="lan24" style="padding-left: 64px;padding-top: 23px">
                      <div>今日增加量</div>
                      <div style="margin-top: 37px">家庭智能药箱总量</div>
                    </div>
                  </div>
                  <div class="totalnum">

                    <div class="nums">
                      <span class="num">3</span><span class="num">2</span><span class="num">1</span><span class="num">5</span>
                    </div>
                    <div class="nums" style="margin-top: 30px">

                      <span class="num">7</span><span class="num">5</span><span class="num">1</span><span class="num">4</span>
                      <span class="num">8</span><span class="num">6</span><span class="num">2</span>
                    </div>
                  </div>
                </div>
                <div style="position: relative;height: 150px">
                  <div class="crystalBox" style="left:150px;top:42px">
                    <div class="crystal" >
                      <div class="item">95%</div>
                    </div>
                    <div class="c bai14">药师服务活跃度</div>
                  </div>

                  <div class="crystalBox" style="left:445px;top:42px">
                    <div class="crystal2" >
                      <div class="item">92%</div>
                    </div>
                    <div class="c bai14">过期药品回收率</div>
                  </div>
                </div>
                <div class="chart" style="height: 226px;position: relative">
                  <div class="abs lan14" style="left:138px;top:149px">智能药箱药品分析</div>
                  <div class="abs lan14" style="left:460px;top:149px">执业药师评价</div>
                  <div class="chart abs"  style="bottom: 60px">
                    <div id="myChartCake_1" :style="{width: '400px', height: '150px'}"></div>
                  </div>
                  <div class="chart abs"  style="bottom: 60px;left:300px">
                    <div id="myChartCake_2" :style="{width: '400px', height: '150px'}"></div>
                  </div>
                </div>


              </div>
              <div style="width: 606px" class="rightblock">
                <div class="boxleft">
                  <div class="title">用药提醒分析</div>
                  <div class="chart abs" style="top:100px">
                    <div id="myChart_2" :style="{width: '600px', height: '330px'}"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="youblock2" style="padding-left: 20px">
<div class="useblock" style="position: relative" >
<div class="title" style="padding-left: 62px">智能药箱用药提醒</div>
  <div class="chart abs" style="top:40px;left:50px">
    <div id="myChart4" :style="{width: '1175px', height: '430px'}"></div>
  </div>
</div>

            </div>

          </div>


        </div>

      </div>



  </div>
</template>
<style scoped>
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.useblock{
  width: 1261px;
  height: 476px;
  background-image: url("../assets/home/usebox.png");
}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}
.title{
  padding-top: 20px;
  padding-left: 55px;
font-size: 24px;
  color: #1AC9FF;
}
.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import axios from "axios";
export default {
  name: 'zhuye',
  components: { BdMap},
  data(){
    return {
      keyword:"",
      region:330726,
      ypxsData:[],
    }
  },
  created() {
    if(!this.$Cookies.get("AuthorizationToken")){
     // window.location.href='/#/login?url=/cloudHome'
    }
  },
  mounted() {
    this.getData()
    },
  methods:{
    go(url){
      this.$router.push(url)
    },
    drawChart(){
      let data=[251,281, 398, 214, 179, 289, 356]
      let dataX=['03-14','03-15', '03-16', '03-17', '03-18', '03-19', '03-20']
      let option = {
        backgroundColor: '',
        title: {
          text: '',
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20,
          },
          top: '5%',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0, 255, 233,0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 255, 233,0)',
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          show:true,
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '15%',
          right: '5%',
          bottom: '20%',
          // containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            splitArea: {
              // show: false,
              color: '#f00',
              lineStyle: {
                color: '#f00',
              },
            },
            axisLabel: {
              color: '#fff',
            },
            splitLine: {
              show: false,
            },
            "axisTick":{       //y轴刻度线
              "show":false
            },
            boundaryGap: true,
            data: dataX,
          },
        ],

        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            axisLabel: {
              show: true,
              margin: 0,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '药事服务次数',
            type: 'line',
            smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: 'circle',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#00ca95',
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#00ca95',
              },
            },

            itemStyle: {
              color: '#00ca95',
              borderColor: '#fff',
              borderWidth: 3,
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0,202,149,0.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,202,149,0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20,
              },
            },
            data: data,
          },
        ],
      };
      let myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption(option);
    },
    drawChart_2(){
      let xLabel = ['03-14', '03-15', '03-16', '03-17', '03-18', '03-19','03-20']
      let data = ["50", "60", "22", "75", "50", "40",'33']
      let data2 = ["40", "50", "12", "55", "30", "60",'76']
      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '有效提醒',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fd5800',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '复购提醒',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#15EE76',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_2'))
      myChart.setOption(option);
    },
    drawChart2(){
      this.ypxsData[0]=["心血管类","肝肠类","男科","妇科","儿科","呼吸道类 ","慢病 "]
      this.ypxsData[1]=[5,15,17,26,23,35,31]
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        backgroundColor: '',
        grid: {
          top: '23%',
          left: '5%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: true,
        xAxis: [//x轴
          {
            type: 'category',
            data: this.ypxsData[0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#ddd',
              },
              margin: 20,
            },
            interval: 1,
          },
        ],
        yAxis: [//y轴
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 16
              },
              formatter: function(value) {
                if (value === 0) {
                  return value
                }
                return value
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#192a44'
              },
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"%";
                },
                show: true,
                position: 'top',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#5BFCF4',
              },
            },
            color: '#17E0FE',
            data: this.ypxsData[1]
          },
          {
            name: '智慧云药房',
            type: 'bar',
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#00B7FF',
                  },
                  {
                    offset: 1,
                    color: 'rgba(34,68,172,0.35)',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.ypxsData[1]
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [20, 8],
            symbolOffset: [0, 7],
            z: 12,
            color: '#133287',
            data: this.ypxsData[1]
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      let myChart = echarts.init(document.getElementById('myChart3'))
      var datas = [{
        "value": 60,
        "name": "泌尿系统类"
      },
        {
          "value": 50,
          "name": "内分泌系统类"
        },
        {
          "value": 40,
          "name": "消化系统类"
        },
        {
          "value": 30,
          "name": "呼吸系统类"
        },
        {
          "value": 20,
          "name": "心血管类"
        },
        {
          "value": 20,
          "name": "镇痛消炎类"
        },
        {
          "value": 20,
          "name": "抗感染类"
        },
        {
          "value": 20,
          "name": "感冒类"
        },


      ]
      var maxArr = [100,100,100,100,100,100,100,100];
     let option = {
        backgroundColor: "",
       legend: {
         borderColor:'#d73622',
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 10,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         },
          },
        grid: {
          left:40,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#fd5800",
            barBorderRadius: 30,
          },
          barWidth: 20,
          data: datas
        },
          {
            name: '当前',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: maxArr,
            itemStyle: {
              normal: {
                color: '#1153EA',
                barBorderRadius: 30,
              }
            },
          },
        ]
      };
      myChart.setOption(option);
    },
//药品品种分析
    drawChart4(){
      let myChart = echarts.init(document.getElementById('myChart4'))
      var attaData1 = [62, 52, 34, 61, 15, 25, 50, 60, 90, 100];
      var attaData2 = [162, 152, 134, 161, 115, 60, 90, 10, 30, 125];
      var attaData3 = [132, 112, 54, 71, 34, 77, 34, 56, 34, 60, 40];
      let dataY=['03-14', '03-15', '03-16', '03-17', '03-18', '03-19', '03-20']
     let option = {
       legend: {
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 2,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         }
       },
        backgroundColor: '',
        grid: {
          x: 65,
          y: 40,
          x2: 65,
          y2: 20,
        },
        xAxis: {
          show: true,
          type: 'value',
          name: '',
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.79)',
            },
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(255, 255, 255, 0.79)',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          data: dataY,
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.79)',
            },
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(255, 255, 255, 0.79)',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"";
                },
                show: true,
                position: 'right',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff',
              },
            },
            name: '服药提醒',
            type: 'bar',
            barWidth: 12,
            zlevel: 2,
            itemStyle: {
              barBorderRadius: [0, 20, 20, 0],
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,136,124, 0.5)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(235,40,86, 1)', // 100% 处的颜色
                  },
                ],
              },
              opacity: 0.8,
            },
            data: attaData1,
          },
          {
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"";
                },
                show: true,
                position: 'right',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff',
              },
            },
            name: '过期提醒',
            type: 'bar',
            barWidth: 12,
            zlevel: 2,
            itemStyle: {
              barBorderRadius: [0, 20, 20, 0],
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(247,203,107, 0.2)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(251,169,128, 1)', // 100% 处的颜色
                  },
                ],
                opacity: 0.8,
              },
            },
            data: attaData2,
          },
          {
            label: {
              normal: {
                formatter: (params) => {
                  return params.value+"";
                },
                show: true,
                position: 'right',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff',
              },
            },
            name: '近效期提醒',
            type: 'bar',
            barWidth: 12,
            zlevel: 2,
            itemStyle: {
              barBorderRadius: [0, 20, 20, 0],
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(1,94,234, 1)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,192,250, 0.5)', // 100% 处的颜色
                  },
                ],
                opacity: 0.8,
              },
            },
            data: attaData3,
          },

        ],
      };

      myChart.setOption(option);
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}%',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    getData(){
      this.drawChart();
      this.drawChart_2();
      this.drawChart2();
    //  this.drawChart3();
      this.drawChart4();
      let arrCake=[
        {
          name: '正常',
          value: 83.2,
        },
        {
          name: '近效期',
          value: 11.1,
        },
        {
          name: '过期',
          value: 5.7,
        },
      ]
      this.drawChartCake("myChartCake_1",arrCake);
      let arrCake2=[
        {
          name: '好评',
          value: 85,
        },
        {
          name: '中评',
          value: 12,
        },
        {
          name: '差评',
          value: 3,
        },
      ]
      this.drawChartCake("myChartCake_2",arrCake2);
          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
