<template>
  <div id="main" style="width: 1920px;overflow: hidden">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">日常风险分析预警</div>
        </div>
        <div class="body">
          <div style="padding-left: 18px" class="blockLeft">
            <div style="position: relative">
              <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px;cursor: pointer" @click="go('/smartWit')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
            </div>
            <div class="block" style="">
              <div class="titleBox">
                <div class="title">违规销售处方药预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px;padding: 0px 20px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>企业名称</td>
                    <td>预警时间</td>
                    <td>预警次数</td>
                  </tr>
                  <tr v-for="(x,i) in cfyList">
                    <td>{{i+1}}</td>
                    <td>{{x.storeName}}</td>
                    <td>{{x.createDate}}</td>
                    <td>{{x.count}}</td>
                  </tr>
                  <tr v-if="cfyList.length==0">
                    <td colspan="4" align="center">今日无预警</td>
                  </tr>

                </table>
              </div>
            </div>
            <div class="block" style="margin-top: 16px">
              <div class="titleBox">
                <div class="title">违规销售管控药预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>企业名称</td>
                    <td>药品名称</td>
                    <td>规格</td>
                    <td>单位</td>
                  </tr>
                  <tr v-for="(x,i) in gkyList">
                    <td>{{i+1}}</td>
                    <td>{{x.storeName}}</td>
                    <td>{{x.patientName}}</td>
                    <td>{{x.spec}}</td>
                    <td>{{x.unit}}</td>
                  </tr>
                  <tr v-if="gkyList.length==0">
                    <td colspan="5" align="center">今日无预警</td>
                  </tr>

                </table>
              </div>
            </div>
            <div class="block" style="margin-top: 16px">
              <div class="titleBox">
                <div class="title">违规销售/购买特殊药品复方制剂预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>企业名称/姓名</td>
                    <td>药品名称</td>
                    <td>规格</td>
                    <td>单位</td>
                    <td>数量</td>
                  </tr>
                  <tr v-for="(x,i) in tsyList">
                    <td>{{i+1}}</td>
                    <td>{{x.storeName}}</td>
                    <td>{{x.patientName}}</td>
                    <td>{{x.spec}}</td>
                    <td>{{x.unit}}</td>
                    <td>{{x.quantity}}</td>
                  </tr>
                  <tr v-if="tsyList.length==0">
                    <td colspan="6" align="center">今日无预警</td>
                  </tr>

                </table>
              </div>
            </div>

          </div>
<div class="blockCenter" style="padding-left: 18px">
  <div class="blockCenter1" style="height: 212px;position: relative">
    <div style="width: 100%;position: absolute;top:-50px">
      <div style="position: relative;">
        <div class="crystalBox" style="left:65px;top:50px">
          <div id="chartCrystal3_7" :style="{width: '152px', height: '100px'}"></div>
        </div>
        <div class="crystalBox" style="left:271px;top:50px">
          <div id="chartCrystal3_8" :style="{width: '152px', height: '100px'}"></div>
        </div>
        <div class="crystalBox" style="left:470px;top:50px">
          <div id="chartCrystal3_9" :style="{width: '152px', height: '100px'}"></div>
        </div>
        <div class="crystalBox" style="left:50px;top:42px">
          <!--        <div class="crystal" >-->
          <!--          <div class="item">{{businessLicense.bai}}%</div>-->
          <!--        </div>-->
          <div style="height: 72px"></div>
          <div class="witZong">
            <div class="c lan16">经营许可证</div>
            <div class="c zong">总数量：<span class="lan14">{{businessLicense.all}}家</span></div>
            <div class="c zong hand" @click="open('http://www.yaojian.xusos.com/#/license/list?type=10')">即将过期:<span class="lan14">{{businessLicense.expire}}家</span></div>
          </div>

        </div>

        <div class="crystalBox" style="left:250px;top:42px">
          <!--        <div class="crystal2" >-->
          <!--          <div class="item">{{pharmacistLicense.bai}}%</div>-->
          <!--        </div>--> <div style="height: 72px"></div>
          <div class="witZong">
            <div class="c lan16">执业药师注册证</div>
            <div class="c zong">总数量：<span class="lan14">{{pharmacistLicense.all}}家</span></div>
            <div class="c zong hand" @click="open('http://www.yaojian.xusos.com/#/pharmacist/register')">即将过期:<span class="lan14">{{pharmacistLicense.expire}}家</span></div>
          </div>
        </div>

        <div class="crystalBox" style="left:450px;top:42px">
          <!--        <div class="crystal2" >-->
          <!--          <div class="item">{{health.bai}}%</div>-->
          <!--        </div>-->
          <div style="height: 72px"></div>
          <div class="witZong">
            <div class="c lan16">从业人员健康证</div>
            <div class="c zong">总数量：<span class="lan14">{{health.all}}家</span></div>
            <div class="c zong hand" @click="open('http://www.yaojian.xusos.com/#/health')">即将过期:<span class="lan14">{{health.expire}}家</span></div>
          </div>

        </div>
      </div>
    </div>

    <div>
<!--<div class="abs" style="top:-10px;left:0px">视频监控情况分析</div>-->
<!--<div class="abs" style="top:-10px;left:200px">温湿度情况分析</div>-->
      <div class="chart abs" style="top:171px">
      <div id="myChart_jrcz2" :style="{width: '260px', height: '150px'}"></div>
    </div>
      <div class="chart abs" style="top:171px;left:300px">
        <div id="myChart_jrcz3" :style="{width: '300px', height: '150px'}"></div>
      </div>
    </div>
<div>
  <div class="chart abs" style="bottom:-60px;left:20px">
    <div id="myChart5" :style="{width: '375px', height: '425px'}"></div>
  </div>
</div>
  </div>
  <div style="height: 126px"></div>
  <div class="block2">
    <div class="titleBox">
      <div class="abs bai14" style="right:20px;top:20px">今日访客数：{{todayNum}}</div>
      <div class="title">全县动态访客信息列表</div>
    </div>
    <div  class="zdyj" style="margin-top: 10px">
      <table width="100%" cellspacing="0" cellpadding="0">
        <tr style="background-color:#001B3A">
          <td style="width: 40px">序号</td>
          <td style="width: 80px">访客姓名</td>
          <td style="width: 40px">性别</td>
          <td style="width: 145px">身份证号</td>
          <td style="width: 80px">健康码</td>
          <td >核酸</td>
          <td style="width: 80px">疫苗</td>
        </tr>
      </table>
      <div style="height:209px;overflow: hidden;">
        <vue-seamless-scroll :data="listData" :class-option="defaultOption" >
          <table width="100%" cellspacing="0" cellpadding="0" class="table">
            <tr v-for="(x,i) in listData" :key="x.id">
              <td style="width: 40px">{{i+1}}</td>
              <td style="width: 80px">{{x.name}}</td>
              <td style="width: 40px">{{x.sex}}</td>
              <td style="width: 145px">{{x.idcard}}</td>
              <td style="width: 80px">{{x.level}}</td>
              <td>{{x.detect.split(" ")[2]}}</td>
              <td style="width:80px">{{x.vaccine}}</td>
            </tr>
          </table>
        </vue-seamless-scroll>
        <div v-if="listData.length==0" style="line-height: 300%;text-align: center" class="bai14">
          今日无访客
        </div>
      </div>
    </div>
  </div>
  <div class="block2" style="margin-top: 16px">
    <div class="titleBox">
      <div class="title">动态处置信息列表</div>
    </div>
    <div  class="zdyj" style="margin-top: 10px">
      <table width="100%" cellspacing="0" cellpadding="0">
        <tr style="">
          <td>序号</td>
          <td>时间</td>
          <td>企业名称</td>
          <td>预警问题</td>
          <td>处置部门</td>
          <td>处置状态</td>
        </tr>
        <tr v-for="x in 0">
          <td>{{x}}</td>
          <td>亚太大道</td>
          <td>2022-02-04</td>
          <td>类型</td>
          <td>待处置</td>
          <td>待处置</td>
        </tr>
        <tr >
          <td colspan="6" align="center">今日无预警</td>
        </tr>

      </table>
    </div>
  </div>

</div>
          <div class="blockRight" style="padding-left: 18px">
            <div class="block" style="">
              <div class="titleBox">
                <div class="title">访客异常数据预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>姓名</td>
                    <td>性别</td>
                    <td>异常状态</td>
                    <td>处理状态</td>
                  </tr>
                  <tr v-for="(x,i) in fkycList">
                    <td>{{i+1}}</td>
                    <td>{{x.name}}</td>
                    <td>{{x.sex}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-if="fkycList.length==0">
                    <td colspan="5" align="center">今日无预警</td>
                  </tr>

                </table>
              </div>
            </div>
            <div class="block" style="margin-top: 16px">
              <div class="titleBox">
                <div class="title">药店从业人员异常预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>姓名</td>
                    <td>性别</td>
                    <td>异常状态</td>
                    <td>处理状态</td>
                  </tr>
                  <tr v-for="(x,i) in fkycList">
                    <td>{{i+1}}</td>
                    <td>{{x.name}}</td>
                    <td>{{x.sex}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-if="fkycList.length==0">
                    <td colspan="5" align="center">今日无预警</td>
                  </tr>

                </table>
              </div>
            </div>
            <div class="block" style="margin-top: 16px">
              <div class="titleBox">
                <div class="title">阴凉区温湿度预警列表</div>
              </div>
              <div  class="zdyj" style="margin-top: 10px">
                <table width="100%" cellspacing="0" cellpadding="0">
                  <tr style="">
                    <td>序号</td>
                    <td>企业名称</td>
                    <td>预警名称</td>
                    <td>预警时间</td>
                    <td>预警次数</td>
                  </tr>
                 </table>
                <div style="height:209px;overflow: hidden;">
                  <vue-seamless-scroll :data="wsdList" :class-option="defaultOption" >
                    <table width="100%" cellspacing="0" cellpadding="0" class="table">
                      <tr v-for="(x,i) in wsdList" :key="x.id">
                        <td style="width: 81px">{{i+1}}</td>
                        <td style="width: 141px;" ><div class="overflow">{{x.storeName}}</div></td>
                        <td style="width: 141px">温湿度超标</td>
                        <td style="width: 141px">{{x.count}}</td>
                      </tr>
                    </table>
                  </vue-seamless-scroll>
                  <div v-if="wsdList.length==0" style="line-height: 300%;text-align: center" class="bai14">
                   今日无预警
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



  </div>
</template>

<style scoped>
.overflow{

  width:140px;

  overflow:hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  -o-text-overflow:ellipsis;

}
.zdyj{padding-left: 20px;padding-right: 20px}
.zdyj table{font-size: 12px}
.zdyj tr td{text-align: center;color: white;padding: 5px;line-height: 150%}
.hand{cursor: pointer}
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}

.lue{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.parent {display: table;height: 30px; padding-top: 10px }
.controlAlert{
  vertical-align: middle;
  display: table-cell;
  width: 94px;
  height: 24px;
  line-height: 24px;
  background: rgba(209,255,231, 0.3);

    border-radius: 12px;
  color: #00D898;
}
.stockAlertHeader{font-size: 14px}
.stockAlertRow{line-height: 300%;font-size: 16px}
.stockAlert{color: white;padding-left: 20px;padding-right: 20px;text-align: center;
  margin-top: 30px}
.line{width: 1px;
  height: 84px;
  background: #283FEA;
  opacity: 0.5; }
.dynamic{}
.dynamic .item{text-align: center;width: 220px}
.dynamic .item1{padding-top: 20px}
.dynamic .item2{margin-top: 15px}
.witZong{line-height: 150%;margin-top: 10px}
.zong{color: white;font-size: 14px;opacity: 0.8;}
.blockRight2{
  position: relative;
  background-image: url("../assets/home/witright2.png");
  width: 601px;
  height: 289px;
  margin-top: 20px;
}
.blockRight1{
  position: relative;
  background-image: url("../assets/home/witright1.png");
  width: 601px;
  height: 487px;
}
.blockCenter4{
  position: relative;
  background-image: url("../assets/home/witcenter3.png");
  width: 646px;
  height: 284px;
  margin-top: 20px;
}
.blockCenter3{
  position: relative;
  background-image: url("../assets/home/witcenter2.png");
  width: 646px;
  height: 291px;
  margin-top: 20px;
}
.blockCenter2{
  position: relative;
  background-image: url("../assets/home/witcenter1.png");
  width: 646px;
  height: 150px;
}
.witleft2{
  position: relative;
  background-image: url("../assets/home/witleft2.png");
  width: 594px;
  height: 371px;
}
.block{
  position: relative;
  background-image: url("../assets/smart/risk/block.png");
  width: 602px;
  height: 320px;
}
.block2{
  position: relative;
  background-image: url("../assets/smart/risk/center.png");
  width: 646px;
  height: 320px;
}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.lan20{color: #5BFCF4;
  font-size: 20px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  left:262px

}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.bai12{
  color: white;
  font-size: 14px;
}
.lan12{
  color: #1AC9FF;
  font-size: 12px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}

.boxleft{
  position: relative;
  background-image: url("../assets/home/boxleft.png");
width: 599px;
  height: 494px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import axios from "axios";
import {mul} from "@/utils/jsj";
import vueSeamlessScroll from 'vue-seamless-scroll'
import {equipment,visitor} from "@/api/yaojian"
import moment from 'moment'
export default {
  name: 'zhuye',
  components: {vueSeamlessScroll},
  data(){
    return {
      todayNum:0,
      listData:[],//预警信息
      cfyList:[],
      gkyList:[],
      tsyList:[],
      dtyjList:[],
      fkycList:[],//访客异常
      cyycList:[],//从业人员异常
      wsdList:[],//温湿度
      keyword:"",
      region:330726,
      ypxsData:[],
      stock:0,
      saleToday:0,
      businessLicense:{},
      pharmacistLicense:{},
      health:{},
      drugCategory:[],
      drugRate:[],
      drugRateStock:[],
      message:[],
      smartType:{},
      wsdData:{},
      stockWarningData: [
        {
          index: 1,
          type: '抗感染类',
          count: 15,
          region: '浦阳街道',
          result: '缺货',
          num: '10%',
          status: '提醒控制'
        },
        {
          index: 2,
          type: '呼吸道类',
          count: 65,
          region: '全域',
          result: '缺货',
          num: '15%',
          status: '提醒控制'
        },
        {
          index: 3,
          type: '消化系统类',
          count: 53,
          region: '全域',
          result: '溢货',
          num: '5.8%',
          status: '提醒控制'
        },
        {
          index: 4,
          type: '心血管类',
          count: 20,
          region: '浦阳',
          result: '溢货',
          num: '7.9%',
          status: '提醒控制'
        },
        {
          index: 5,
          type: '肝胆类',
          count: 5,
          region: '黄宅',
          result: '缺货',
          num: '8.7%',
          status: '提醒控制'
        }
      ]
    }
  },
  created() {

  },
  mounted() {
    this.getData()
    },
  computed: {
    defaultOption () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }

  },
  methods:{
    drawChart_jrcz2(id,arr,title){//
      let color = ["rgba(91, 143, 249, 0.85)",
        "rgba(90, 216, 166, 0.85)",
        "rgba(158,61,0,0.85)",
        "rgba(112,6,172,0.85)",
        "rgba(149,143,0,0.85)"
      ];

// 圆环图各环节的名称和值(系列中各数据项的名称和值)
      let data = arr;
// 指定图表的配置项和数据
      let  option = {
        legend: [{
          orient: 'vertical',
          icon: "circle",
          itemHeight: 7, //修改icon图形大小
          itemWidth: 7, //修改icon图形大小
          show: true,
          top: '5%',
          right: '0%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 5,
          formatter: (name) => {
            return name;
          },
        }],
        backgroundColor : '',
        title: [{
          show:true,
          text: title,
          bottom: '5%',
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        }],
        //  center: ['60%', '50%'],
        // 系列列表
        series: [
          {
            name: '内圆和外圆', // 系列名称
            type: 'pie',
            center: ['50%', '30%'], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
            radius: ['20%', '55%'], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
            hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
            color: color, // 圆环图的颜色
            label: { // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
              normal: {
                show: true, // 是否显示标签[ default: false ]
                fontSize: 12,
                position: 'inside', // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: (b) => {
                  return b.data.per + ''; // 标签内容
                }
              }
            },
            labelLine: { // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
              normal: {
                show: true, // 是否显示视觉引导线。
                length: 30, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
                length2: 10 // 视觉引导项第二段的长度。
              }
            },
            data: data // 系列中的数据内容数组。
          }]
      };
      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    open(url){
      window.location.href=url
    },
    rollText() {
      var speed = 20;
      var that = this;
      function Marquee() {
        //    that.rollContainer.offsetHeight - that.rollContainer.scrollTop <=
        //   -(that.rollContainer.scrollTop / 2)
        // console.log(that.message);
        if (
            //这里控制滚动到底回顶
            that.$refs.gunlist.scrollTop >=
            that.$refs.infobord1.offsetHeight - that.$refs.gunlist.clientHeight
        ) {
          that.$refs.gunlist.scrollTop = 0;
        } else {
          that.$refs.gunlist.scrollTop++;
          // console.log(111);
          // console.log(that.$refs.gunlist.scrollTop);
          // console.log(that.infobord1.offsetTop);
        }
      }
      var MyMar = setInterval(Marquee, speed); //设置定时器
      //鼠标移上时清除定时器达到滚动停止的目的

      this.$refs.gunlist.addEventListener("mouseover", function () {
        clearInterval(MyMar);
      });
      //鼠标移开时重设定时器
      this.$refs.gunlist.addEventListener("mouseout", function () {
        MyMar = setInterval(Marquee, speed);
      });
    },
    rollText2() {
      var speed = 20;
      var that = this;
      function Marquee() {
        if (
            //这里控制滚动到底回顶
            that.$refs.gunlist2.scrollTop >=
            that.$refs.infobord2.offsetHeight - that.$refs.gunlist2.clientHeight
        ) {
          that.$refs.gunlist2.scrollTop = 0;
        } else {
          that.$refs.gunlist2.scrollTop++;
        }
      }
      var MyMar = setInterval(Marquee, speed); //设置定时器
      //鼠标移上时清除定时器达到滚动停止的目的

      this.$refs.gunlist2.addEventListener("mouseover", function () {
        clearInterval(MyMar);
      });
      //鼠标移开时重设定时器
      this.$refs.gunlist2.addEventListener("mouseout", function () {
        MyMar = setInterval(Marquee, speed);
      });
    },
    go(url){
      this.$router.push(url)
    },
    drawChartCake(id,arr){
      let myChart = echarts.init(document.getElementById(id))
      var datas = arr;
      var max = Math.max(...datas.map((item) => item.value));

      var data = [];
      var color = ['#2ca1ff', '#0adbfa', '#febe13', '#ad2300'];
      for (var i = 0; i < datas.length; i++) {
        data.push(
            {
              value: datas[i].value,
              name: datas[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowColor: color[i],
                },
              },
            },
            {
              value: max * 0.05,
              name: '',
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                  color: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 0,
                },
              },
              label: {
                show: false,
              },
              tooltip:{
                show:false
              }
            }
        );
      }

      let option = {
        backgroundColor: '',
        color: color,
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
          top: 'center',
          left: '60%',
          textStyle: {
            color: '#fff',
          },
          itemGap: 20,
          formatter: '{c}',
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: ['40%', '60%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {},
            label: {
              show: true,
              formatter: '{b}{c}家',
              textStyle: {
                fontSize: 12,
                color: '#fff',
              },
            },
            data: data,
          },
        ],
      };


      myChart.setOption(option);
    },
    drawChartCrystal(id,title,t=2,v){
      let wordColor=['#a06a0a','#a06a0a','#02456d']
      var value = v;
      var data = [value, value, value, value];
      let series1=[
        {
          type: 'liquidFill',
          radius: '50%',
          z: 6,
          center: ['25%', '35%'],
          color: [
            {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: 'rgba(251, 173, 23, 0)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(251, 173, 23, .2)',
                },
                {
                  offset: 0,
                  color: 'rgba(251, 173, 23, 1)',
                },
              ],
              globalCoord: false,
            },
          ],
          data: data,
          backgroundStyle: {
            borderWidth: 1,
            color: 'transparent',
          },
          label: {
            normal: {
              formatter: '',
            },
          },
          outline: {
            show: true,
            itemStyle: {
              borderWidth: 0,
            },
            borderDistance: 0,
          },
        },
        {
          name: '第二层白边',
          type: 'pie',
          z: 3,
          radius: ['0%', '55%'],
          center: ['25%', '35%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
            },
          },
          data: [
            {
              value: 100,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#fefefe',
                    },
                    {
                      offset: 1,
                      color: '#e7e8ea',
                    },
                  ]),
                },
              },
            },
            {
              value: 0,
              itemStyle: {
                normal: {
                  color: 'transparent',
                },
              },
            },
          ],
        },
        {
          name: '最外黄边',
          type: 'pie',
          z: 1,
          radius: ['0%', '58%'],
          center: ['25%', '35%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
            },
          },
          data: [
            {
              value: 100,
              itemStyle: {
                color:'#fdc56e'
              },
            },
            {
              value: 0,
              itemStyle: {
                normal: {
                  color: 'transparent',
                },
              },
            },
          ],
        },
      ]
      let series2=[
        {
          type: 'liquidFill',
          radius: '50%',
          z: 6,
          center: ['25%', '35%'],
          color: [
            {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: 'rgba(0, 155, 219, 0)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(0, 155, 219, .2)',
                },
                {
                  offset: 0,
                  color: 'rgba(0, 155, 219, 1)',
                },
              ],
              globalCoord: false,
            },
          ],
          data: data,
          backgroundStyle: {
            borderWidth: 1,
            color: 'transparent',
          },
          label: {
            normal: {
              formatter: '',
            },
          },
          outline: {
            show: true,
            itemStyle: {
              borderWidth: 0,
            },
            borderDistance: 0,
          },
        },
        {
          name: '第二层白边',
          type: 'pie',
          z: 3,
          radius: ['0%', '55%'],
          center: ['25%', '35%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
            },
          },
          data: [
            {
              value: 100,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#fefefe',
                    },
                    {
                      offset: 1,
                      color: '#e7e8ea',
                    },
                  ]),
                },
              },
            },
            {
              value: 0,
              itemStyle: {
                normal: {
                  color: 'transparent',
                },
              },
            },
          ],
        },
        {
          name: '最外蓝边',
          type: 'pie',
          z: 1,
          radius: ['0%', '58%'],
          center: ['25%', '35%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
            },
          },
          data: [
            {
              value: 100,
              itemStyle: {
                color:'#07a2e3'

              },
            },
            {
              value: 0,
              itemStyle: {
                normal: {
                  color: 'transparent',
                },
              },
            },
          ],
        },
      ]
      let series=[]
      if(t==1){
        series=series1
      }
      if(t==2){
        series=series2
      }
      var option = {
        title: [
          {
            text: title,
            x: '25%',
            y: 80,
            textAlign: 'center',
            textStyle: {
              color: '#5BFCF4',
              fontSize: 16,
              fontFamily: 'Microsoft Yahei',
              fontWeight: '100',
              textAlign: 'center',
            },
          },
          {//百分比数
            text: (value * 100).toFixed(0) + '%',
            left: '22%',
            top: '20%',
            textAlign: 'center',
            textStyle: {
              fontSize: '20',
              fontWeight: '300',
              color: wordColor[t],
              textAlign: 'center',
              textBorderColor: 'rgba(0, 0, 0, 0)',
              textShadowColor: '#fff',
              textShadowBlur: '0',
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },

        ],
        series: series
      };

      let myChart = echarts.init(document.getElementById(id))
      myChart.setOption(option);
    },
    drawChart(){
      let xLabel = ['03-01', '03-01', '03-01', '03-01', '03-01', '03-01','03-01']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']
      let data3 = ["30", "40", "22", "45", "50", "40",'56']

     let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
       legend: {
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 2,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         }
       },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
            {
          name: '处方流转量',
          type: 'line',
          symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
          showAllSymbol: true,
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            normal: {
              width: 2,
              color: "#fd5800", // 线条颜色
            },
            borderColor: 'rgba(0,0,0,.4)',
          },
          itemStyle: {
            color: "#fd5800",
            borderColor: "#646ace",
            borderWidth: 2

          },

          areaStyle: { //区域填充样式
            normal: {
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "rgba(25,163,223,.3)"
              },
                {
                  offset: 1,
                  color: "rgba(25,163,223, 0)"
                }
              ], false),
              shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
              shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            }
          },
          data: data
        },
          {
          name: '在线交易量',
          type: 'line',
          symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
          showAllSymbol: true,
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            normal: {
              width: 2,
              color: "#15EE76", // 线条颜色
            },
            borderColor: 'rgba(0,0,0,.4)',
          },
          itemStyle: {
            color: "#15EE76",
            borderColor: "#646ace",
            borderWidth: 2

          },

          areaStyle: { //区域填充样式
            normal: {
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "rgba(10,219,250,.3)"
              },
                {
                  offset: 1,
                  color: "rgba(10,219,250, 0)"
                }
              ], false),
              shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
              shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            }
          },
          data: data2
        },
          {
            name: '在线问诊量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "rgba(10,219,250,1)", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "rgba(10,219,250,1)",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          }
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption(option);
    },
    drawChart_2(){
      let xLabel = ['03-01', '03-01', '03-01', '03-01', '03-01', '03-01','03-01']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '在线率',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '接单率',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
        ]
      };
      let myChart = echarts.init(document.getElementById('myChart_2'))
      myChart.setOption(option);
    },
    //药品管控
    drawChart2(){
      let data=[this.smartType.epidemic, this.smartType.special, this.smartType.failed, this.smartType.cordial]
      let hui=this.smartType.epidemic+10000
      let data2=[hui,hui, hui,hui]
      let dataY=['疫情药品', '特殊药品', '不合格药品', '兴奋剂类药品']
      let dataRight=['禁售', '限售', '禁售', '提示']
      let myChart = echarts.init(document.getElementById('myChart2'))
      let option = {
        color:['#c90119','#00B7FF','#c90119','#00c19d'],
        grid: {
          top: '0%',
          left: '10%',
          bottom: '0%',
          right: '10%',
          containLabel: true,
        },
        title: {
          text: '',
        },
        //backgroundColor: 'rgb(24, 3, 98)',
        yAxis: {
          type: 'category',
          inverse: true,
          offset: 2,
          axisLabel: {
            show: true,
            interval: 'auto',
            color: '#28C2EC',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: dataY,
        },
        xAxis: {
          show: false,
          type: 'value',
          offset: 30,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(106, 148, 255)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            inside: true,
            length: 10,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}',
          },
        },
        series: [
          {
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,

            },
            //下半截柱子
            zlevel:2,
            name: '2022',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
            // itemStyle: {
            //   //lenged文本
            //   opacity: 1,
            //   color: function (params) {
            //     return new echarts.graphic.LinearGradient(
            //         0,
            //         0,
            //         1,
            //         0,
            //         [
            //           {
            //             offset: 0,
            //             color: '#0BBAFB', // 0% 处的颜色
            //           },
            //           {
            //             offset: 1,
            //             color: '#0BBAFB', // 100% 处的颜色
            //           },
            //         ],
            //         false
            //     );
            //   },
            // },
            colorBy: dataY,
            data: data,
          },
          {
            //灰色柱子
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,
              formatter: (params) => {
                return dataRight[params.dataIndex];
              },
            },
            name: '2020',
            type: 'bar',
            barWidth: 17,
            barGap: '-100%',
            itemStyle: {
              //lenged文本
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: '#2E5560', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#2E5560', // 100% 处的颜色
                      },
                    ],
                    false
                );
              },
            },
            data: data2,
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart3(){
      let myChart = echarts.init(document.getElementById('myChart3'))
      var datas = [{
        "value": 60,
        "name": "泌尿系统类"
      },
        {
          "value": 50,
          "name": "内分泌系统类"
        },
        {
          "value": 40,
          "name": "消化系统类"
        },
        {
          "value": 30,
          "name": "呼吸系统类"
        },
        {
          "value": 20,
          "name": "心血管类"
        },
        {
          "value": 20,
          "name": "镇痛消炎类"
        },
        {
          "value": 20,
          "name": "抗感染类"
        },
        {
          "value": 20,
          "name": "感冒类"
        },


      ]
      var maxArr = [100,100,100,100,100,100,100,100];
      let dataRight=['+1%', '+1%', '+1%', '-0.5%','+1%', '+1%', '+1%', '-0.5%']
     let option = {
        backgroundColor: "",
       legend: {
         borderColor:'#d73622',
         // data: ['示范', '一级', '二级'],
         orient: 'horizontal',   // 水平
         //type: 'plain',          // 普通图例
         icon: "circle",
         top: 0,
         right: 30,
         // right: '5%',
         // bottom: '15%',
         //width:280,              // 宽度
         itemGap: 10,            // 间隔
         itemWidth: 10,          // 图形宽度。
         itemHeight: 10,         // 图形高度。
         borderWidth: 0,
         textStyle: {
           fontSize: '14px',
           color: '#4ca9ff',
         },
          },
        grid: {
          left:40,
          right: 50,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'none'
          },
          formatter: function(params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        xAxis: {
          splitLine: {
            show: false
          },
          show: true,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            align: 'right',
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: datas.map(item => item.name)
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            show:false,
            textStyle: {
              color: '#fff',
              fontSize: '12'
            },
            formatter: '{value}%'
          },
          data: datas.map(item => item.value)
        }],
        series: [{
          name: '平均',
          type: 'bar',
          zlevel: 1,
          itemStyle: {
            color: "#fd5800",
            barBorderRadius: 30,
          },
          barWidth: 20,
          data: datas
        },
          {
            label: {
              color:"#ffffff",
              show: true,
              position: 'right',
              distance: 10,
              formatter: (params) => {
                return dataRight[params.dataIndex];
              },
            },
            name: '当前',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: maxArr,
            itemStyle: {
              normal: {
                color: '#1153EA',
                barBorderRadius: 30,
              }
            },
          },
        ]
      };
      myChart.setOption(option);
    },

    //本年药品销量
    drawChart4_2(){
      let myChart = echarts.init(document.getElementById('myChart4_2'))
      let xLabel = ['1月', '3月', '5月', '7月', '9月', '11月']
      let data = ["40", "60", "22", "85", "50", "40",'33']
      let data2 = ["20", "50", "12", "65", "30", "60",'76']
      let data3 = ["30", "40", "22", "45", "50", "40",'56']

      let option = {
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          backgroundColor:'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(126,199,255,0)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(126,199,255,1)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(126,199,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
          },
          formatter(res){
            // console.log(res)
            return  ''
          }
        },
        legend: {
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [{

          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: false,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '线上总销量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fd5800',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#fd5800", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fd5800",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(25,163,223,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(25,163,223, 0)"
                  }
                ], false),
                shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data
          },
          {
            name: '线下总销量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#15EE76',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#15EE76", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#15EE76",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data2
          },
          {
            name: '药品总销量',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'rgba(10,219,250,1)',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "rgba(10,219,250,1)", // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "rgba(10,219,250,1)",
              borderColor: "#646ace",
              borderWidth: 2

            },

            areaStyle: { //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "rgba(10,219,250,.3)"
                },
                  {
                    offset: 1,
                    color: "rgba(10,219,250, 0)"
                  }
                ], false),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: data3
          }
        ]
      };

      myChart.setOption(option);
    },

    getData(){
      let arr=[{
        name: '报停 2%',
        value: 2,
        per: 2
      }, {
        name: '在线 80%',
        value: 80,
        per: 80
      }, {
        name: '离线 15%',
        value: 15,
        per: 15
      }, {
        name: '未安装 3%',
        value: 3,
        per: 3
      }]
      this.drawChart_jrcz2("myChart_jrcz2",arr,"视频监控情况分析")


      // 温湿度监控
      equipment().then(response => {
        let data = response.data
        arr=[{
          name: '设备报停'+data.stop+'家',
          value: data.stop,
          per: data.stop
        }, {
          name: '温湿度超标'+data.excerpt+'家',
          value: data.excerpt,
          per: data.excerpt
        }, {
          name: '设备离线'+data.fault+'家',
          value: data.fault,
          per: data.fault
        }, {
          name: '正常'+data.normal+'家',
          value: data.normal,
          per: data.normal
        }]
        this.drawChart_jrcz2("myChart_jrcz3",arr,"温湿度情况分析")
      })

      let that=this
      //药店从业人员异常预警列表
      axios.get('/screen/v3/violation/person/list', {})
          .then(function (response) {
            let data=response.data
            that.cyycList=data.data
          })
      //访客异常数据预警列表
      axios.get('/screen/v3/violation/visitor/list', {})
          .then(function (response) {
            let data=response.data
            that.fkycList=data.data
          })
      //访客列表
      let today=moment().format("YYYY-MM-DD")
      visitor({startTime:today+" 00:00:00",endTime:today+" 23:59:59",count:50})
          .then(function (response) {
           that.listData=response.data.list
            that.todayNum=response.data.total
            that.listData.map(x=>{
              let a=x.idcard.substr(16,1);
              if(a%2==0){
                x.sex="女"
              }else{
                x.sex="男"
              }
            })
          }),
          //温湿度预警信息列表
          axios.get('/screen/v3/violation/message/list', {params:{type:8}})
              .then(function (response) {
                let data=response.data
                that.wsdList=data.data
              }),
      //违规销售处方药预警列表
      axios.get('/screen/v3/violation/prescription/list', {})
          .then(function (response) {
            let data=response.data
            that.cfyList=data.data
          })

      //违规销售管控药预警列表
      axios.get('/screen/v3/violation/control/list', {params:{type:1}})
          .then(function (response) {
            let data=response.data
            that.gkyList=data.data
          })
      //违规销售特殊药品预警列表
      axios.get('/screen/v3/violation/control/list', {params:{type:2}})
          .then(function (response) {
            let data=response.data
            that.tsyList=data.data
          })

      //获取今日销售总量
      axios.get('/drug/store/today/sale/number?region='+this.region, {})
          .then(function (response) {
            that.saleToday=response.data.data.total
            that.saleToday=parseInt(that.saleToday)
            that.saleToday=that.saleToday.toString().split("")
          })

      //经营许可证统计数量
      axios.get('/drug/store/business/license?region='+this.region, {})
          .then(function (response) {
            that.businessLicense=response.data.data
            that.businessLicense.bai=mul(parseInt((that.businessLicense.normal/that.businessLicense.all)*100),0.01)

            that.drawChartCrystal('chartCrystal3_7',"",2,that.businessLicense.bai);

          })
      //执业药师注册证 量
      axios.get('/drug/store/pharmacist/license?region='+this.region, {})
          .then(function (response) {
            that.pharmacistLicense=response.data.data
            that.pharmacistLicense.bai=mul(parseInt((that.pharmacistLicense.normal/that.pharmacistLicense.all)*100),0.01)
            that.drawChartCrystal('chartCrystal3_8',"",1,that.pharmacistLicense.bai);
          })
      //健康证
      axios.get('/drug/store/health/data?region='+this.region, {})
          .then(function (response) {
            that.health=response.data.data
            that.health.bai=mul(parseInt((that.health.normal/that.health.all)*100),0.01)
            that.drawChartCrystal('chartCrystal3_9',"",1,that.health.bai);
          })


      //预警列表数据（今日）
      axios.get('/drug/store/message?region='+this.region, {})
          .then(function (response) {
            that.message=response.data.data
          })

          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    shortDay(d){
      let a=d.slice(-5)
      return a
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
