<template>
  <div class="daping" style="width: 1920px">
    <div :class="isAll?'map':'map2'">
      <bd-map ref="children"/>
    </div>
    <div class="lanBg" v-if="isAll"></div>
    <div>
      <div style="height: 29px"></div>
      <div class="header">
        <div class="you_btn" @click="go('/#/zhuye?region='+region)" v-if="isAll">
          返回首页
        </div>
        <div class="you_btn" @click="quanju" v-if="!isAll">
          返回全局
        </div>
        <div style="height: 19px"></div>
        <div class="dabiaoti">数字民生</div>
      </div>
      <div class="main_content">
        <div class="main_content_right">
          <div class="kuang_you">
            <div class="kuang_tit">健康讲坛</div>
            <div class="jkjt_list">
              <div class="jkjt_list_item" v-for="x in jkjt">
                · {{x.title}}
              </div>

            </div>
          </div>

        </div>
        <div class="main_content_left">
          <div class="kuang_left1">
            <div class="kuang_tit">民生药事服务站</div>
            <div style="width: 386px;margin: 0 auto;">
              <div class="jsq_top">服务站数量</div>
              <div class="jsq" >
                <span>2</span>

              </div>
            </div>
         </div>
          <div class="kuang_left2">
            <div class="kuang_tit">家庭智能药箱</div>
            <div style="width: 386px;margin: 0 auto;">
              <div class="jsq_top">存量药品数量(盒）</div>
              <div class="jsq" >
                <span>9</span>
                <span>8</span>
                <span>1</span>
                <span>7</span>
                <span>8</span>
              </div>
            </div>
            <div style="width: 386px;margin: 0 auto;margin-top: 20px">
              <div class="jsq_top">过期药品回收数量(盒）   </div>
              <div class="jsq" >
                <span>2</span>
                <span>8</span>
                <span>5</span>
              </div>
            </div>
          </div>
          <div class="kuang_left3">
            <div class="kuang_tit">送药上山进岛便民服务点</div>
            <div style="height: 110px">
              <div style="width: 386px;margin: 0 auto;">
                <div class="jsq_top">服务点数量</div>
                <div class="jsq" >
                  <span>1</span>
                  <span>0</span>
                </div>
              </div>
            </div>
            <div class="kuang_tit" >寻医问药</div>
            <div style="width: 386px;margin: 0 auto;">
              <div class="jsq_top">今日问诊数量</div>
              <div class="jsq" >
                <span v-for="x in ask.arr">{{x}}</span>
              </div>
            </div>

          </div>

        </div>
        <div class="main_content_center">
          <div style="height: 22px"></div>
          <div class="search">
            <div style="padding-left: 20px; padding-top: 8px;"><div style="position: absolute;margin-left: 241px;margin-top: 5px;font-size: 30px;color: #0895ee" v-if="zoom"><img src="../assets/zoom.png"/>搜索企业名称</div>
              <el-autocomplete
                  @focus="jujiao()"
                  class="search_input"
                  v-model="keyword"
                  :fetch-suggestions="querySearch"
                  @select="handleSelect"
              ></el-autocomplete>
            </div>
          </div>
          <div style="">
            <div class="kuang_center1">
              <div class="kuang_tit">浙里办云药房</div>
              <div style="width: 830px; margin: 0 auto">
                <div id="myChartYaofang" :style="{width: '830px', height: '710px'}"></div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import axios from "axios";
export default {
  name: 'szjg',
  components: { BdMap},
  data () {
    return {
      ask:{num:128,arr:[]},
      timer:"",
      region:0,//地区
      keyword:"",
      isAll: true,
      zoom: true,
      restaurants: [],
      selectData: {},
      jkjt: [],
    }
  },
  mounted(){
    if(!this.$Cookies.get("AuthorizationToken")&&this.$login){
      window.location.href='/#/login'
    }
    console.log("dqid",this.region)
    this.region=localStorage.id
    this.loadAll()
    this.drawYaofang()
    this.getJkjt()

    //定时器
        this.changeNum()
        //this.clock()
  },
  methods: {
    changeNum(){
      this.ask.num++
      this.ask.arr=this.ask.num.toString().split("")
    },
    clock(){
      let that=this
      this.timer=setInterval(function(){
        that.changeNum()
      },20000)
    },
    getJkjt(){
      let that=this
      axios.get('https://portal-prepro.mall.xusos.com/lecture/hall/list?count=10&page=1&region='+this.region, {})
          .then(function (response) {
            that.jkjt=response.data.data.list
          })

    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
    loadAll() {
      let arr
      let that=this
      axios.get('/screen/store/list?region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })

    },
    handleSelect(item) {
      // console.log(item);
      this.isAll=false
      this.$refs.children.handleSelect(item)
    },
    jujiao(){
      this.zoom=false
    },
    quanju(){
      this.isAll=true
      this.getData();
    },
    //搜素代码----------------------------------------end
    drawYaofang(){
      let otime=this.getRiqi(5);
      otime.push(this.today())//推入当天
      let xLabel = otime
      let goToSchool = ["40", "60", "22", "85", "50", "40"]

      let option = {
        backgroundColor: '',
           grid: {
          top: '18%',
          left: '15%',
          right: '5%',
          bottom: '25%',
          // containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
              color: '#233653'
            },
          },
          axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
              color: '#7ec7ff',
              padding: 16,
              fontSize: 14
            },
            formatter: function(data) {
              return data
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisTick: {
            show: false,
          },
          data: xLabel
        }],
        yAxis: [{
          name: '人数',
          nameTextStyle: {
            color: "#7ec7ff",
            fontSize: 16,
            padding: 10
          },
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#192a44'
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#233653"
            }

          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7ec7ff',
              padding: 16
            },
            formatter: function(value) {
              if (value === 0) {
                return value
              }
              return value
            }
          },
          axisTick: {
            show: false,
          },
        }],
        series: [{
          name: '',
          type: 'line',
          symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
          showAllSymbol: true,
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            normal: {
              width: 5,
              color: "rgba(25,163,223,1)", // 线条颜色
            },
            borderColor: 'rgba(0,0,0,.4)',
          },
          itemStyle: {
            color: "rgba(25,163,223,1)",
            borderColor: "#646ace",
            borderWidth: 2

          },
          tooltip: {
            show: true
          },
          areaStyle: { //区域填充样式
            normal: {
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "rgba(25,163,223,.3)"


              },
                {
                  offset: 1,
                  color: "rgba(25,163,223, 0)"
                }
              ], false),
              shadowColor: 'rgba(25,163,223, 0.5)', //阴影颜色
              shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            }
          },
          data: goToSchool
        }]
      };
      let myChart = echarts.init(document.getElementById('myChartYaofang'))
      myChart.setOption(option);
    },
    go(url){
      //this.$router.push(url)
      window.location.href=url
    },
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

  }
}
</script>
<style>
.el-input__inner{
  background: transparent;
  border: 0;
  color: #0895ee;
  font-size: 30px;
  text-align: center;
}
</style>
<style scoped>
.jsq_top{font-size: 20px;color: white;margin-bottom: 14px}
.jsq{display: flex;flex-direction: row;font-size: 40px;color: #07DBFF}
.jsq span{
  background: url('../assets/szms/jsq.png') no-repeat;
  text-align: center;
  width: 49px;
  height: 64px;
  line-height: 64px;
  display: block;
  margin-right: 6px;
}
.jkjt_list{
  width: 410px; margin: 0 auto;
  color: white;
  font-size: 20px;
}
.jkjt_list_item{
  border-radius: 25px;
  background-color: #123E6B;
  height: 50px;
  margin-top: 18px;
  line-height: 50px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.cer_sz{
  font-size: 38px;
  color: white;
  padding-top: 143px;
}
.cer_wz{
  font-size: 18px;
  color: white;
}
.cercle2{
  width: 370px;height: 370px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle2.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.cercle{
  width: 362px;height: 362px;
  margin: 0 auto;
  background: url('../assets/szqy/cercle.png') no-repeat;
  text-align: center;
  margin-top: 34px;
}
.you_btn{
  background: url("../assets/btn.png") no-repeat left;
  height: 58px;
  width: 146px;
  color: #0895EE;
  text-align: center;
  position: absolute;
  line-height: 58px;
  right: 48px;
  cursor: pointer;
}
.yjtz{
  background: url("../assets/bq.png") no-repeat left;
  height: 40px;
  width: 342px;
  color: #5BFCF4;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
}
.search_input{height: 41px; width: 728px;background-color: transparent;border: 0;text-align: center;color: #0895EE;font-size: 28px}
.search{width: 773px;height: 60px; background: url("../assets/s.png"); margin: 0 auto}
.kuang_left1{
  background: url("../assets/szms/left1.png") no-repeat center;
  height: 212px;
}
.kuang_left2{
  background: url("../assets/szms/left2.png") no-repeat center;
  height: 342px;
}
.kuang_left3{
  background: url("../assets/szms/left3.png") no-repeat center;
  height: 396px;
}

.kuang_you{
  background: url("../assets/szms/right.png") no-repeat center;
  height: 950px;
}
.kuang_center1{
  background: url("../assets/szms/center.png") no-repeat center;
  height: 844px;
  margin-top: 16px;

}
.kuang_center2{
  background: url("../assets/szqy/center2.png") no-repeat center;
  height: 502px;

}
.kuang_you2{
  background: url("../assets/szqy/right2.png") no-repeat center;
  height: 503px;
}
.kuang_b{
  background: url("../assets/kuang_b.png") no-repeat center;
  height: 683px;
}
.kuang_tit{
  padding-top: 27px;
  padding-left: 50px;
  height: 40px;
  color: #5BFCF4;
  font-size: 20px;
}
.main_content_right{float: right;width: 502px}
.main_content_left{float: left;width: 502px}
.main_content_center{width: 923px;margin: 0 auto}
.dabiaoti{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}

</style>
