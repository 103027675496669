<template>
  <el-dialog title="企业详情" :visible.sync="dialogShow.show" width="100%" style="height: 100%;background: transparent; " append-to-body :close-on-click-modal="false" @open="handleOpen">
   水费水电费 <el-image style="width: 100%; height: 100%" :src="require('../../assets/20210725171354.png')" @click="handleClose()" />
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogShow: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleOpen() {
      // alert('打开弹窗')
    },
    handleClose() {
      this.dialogShow.show = false
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: transparent;
}
/deep/ .v-modal {
  opacity: .8 !important;
}
</style>
