<template>
    <div class="awardedMarks">
        <div class="awardedMarks-content">
            <div class="awardedMarks-header">
                <div class="awardedMarks-header-back">
                    <img src="../assets/smart/home/dialog2.png" alt="" />
                    <span>返回</span>
                </div>
                <span class="awardedMarks-header-title">药店加分管理</span>
            </div>
            <div class="awardedMarks-body">
                <!-- 头部 -->
                <div class="awardedMarks-body-content">
                    <!-- 头部 -->
                    <div class="awardendMarks-total">
                        <div class="awardendMarks-total-content">
                            <div>437次</div>
                            <span>累计申请加分次数</span>
                        </div>
                        <div class="awardendMarks-total-content">
                            <div>437次</div>
                            <span>今日申请加分数</span>
                        </div>
                        <div class="awardendMarks-total-content">
                            <div>213次</div>
                            <span>累计申请加分次数</span>
                        </div>
                        <div class="awardendMarks-total-content">
                            <div>145次</div>
                            <span>今日待审核数</span>
                        </div>
                        <div class="awardendMarks-total-content">
                            <div>453次</div>
                            <span>未审核数</span>
                        </div>
                    </div>
                    <!-- 表格 -->
                    <div class="awardendMarks">
                        <table>
                            <tr class="first">
                                <td>序号</td>
                                <td style="width:191px">企业名称</td>
                                <td>申请时间</td>
                                <td>申请加分事项</td>
                                <td>当前审核状态</td>
                                <td>分值范围</td>
                                <td>审核加分值</td>
                                <td>操作</td>
                            </tr>
                            <tr class="second" v-for="(item, index) in tableList" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td style="line-height:30px">{{ item.name }}</td>
                                <td>{{ item.foundTime }}</td>
                                <td>{{ item.add }}</td>
                                <td>
                                    <span v-if="item.stadus == 1" style="color: #FBC902;"
                                        >待审核</span
                                    >
                                    <span v-if="item.stadus == 2" style="color:#1DCE3A;"
                                        >已完成</span
                                    >
                                </td>
                                <td>{{ item.scope }}</td>
                                <td>{{ item.addmarks }}</td>
                                <td class="operation">
                                    <span class="unfinshed" v-if="item.stadus == 1">
                                        审核
                                    </span>
                                    <span class="finshed" v-if="item.stadus == 2">
                                        已完成
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer-page">
                        <Pager :total="tableList.length"></Pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pager from "./components/pager";
export default {
    components: {
        Pager,
    },
    data() {
        return {
            tableList: [
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    foundTime: "2022-05-24  12:12:12",
                    add: "参与百姓药事服务",
                    stadus: 1,
                    scope: "5-10分",
                    addmarks: "15分",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    foundTime: "2022-05-24  12:12:12",
                    add: "参与百姓药事服务",
                    stadus: 2,
                    scope: "5-10",
                    addmarks: "15分",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    foundTime: "2022-05-24  12:12:12",
                    add: "参与百姓药事服务",
                    stadus: 1,
                    scope: "5-10",
                    addmarks: "15分",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    foundTime: "2022-05-24  12:12:12",
                    add: "参与百姓药事服务",
                    stadus: 1,
                    scope: "5-10",
                    addmarks: "15分",
                },
                {
                    name: "恒生药房连锁有限公司亚太大道店",
                    foundTime: "2022-05-24  12:12:12",
                    add: "参与百姓药事服务",
                    stadus: 2,
                    scope: "5-10",
                    addmarks: "15分",
                },
            ],
        };
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.awardedMarks {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .awardedMarks-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .awardedMarks-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .awardedMarks-header-back {
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .awardedMarks-header-title {
                line-height: 38px;
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .awardedMarks-body {
            flex: 1;
            padding: 0 260px;
            .awardedMarks-body-content {
                position: relative;
                padding: 36px;
                display: flex;
                flex-direction: column;
                margin-top: 35px;
                width: 1400px;
                height: 904px;
                overflow: hidden;
                background-image: url("../assets/enterprice/背景.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .awardendMarks-total {
                    width: 100%;
                    height: 134px;
                    display: flex;
                    justify-content: space-evenly;
                    .awardendMarks-total-content {
                        width: 249px;
                        height: 100%;
                        background: url("../assets/enterprice/边框.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        div {
                            font-size: 36px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #fbc902;
                            line-height: 30px;
                            margin-bottom: 16px;
                        }
                        span {
                            line-height: 21px;
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }
                .awardendMarks {
                    margin-top: 30px;
                    overflow-y: auto;
                    table {
                        height: 100%;
                        width: 100%;
                        border-collapse: collapse;
                        .first {
                            height: 55px;
                            background-image: url("../assets/regulation/表头.png");
                            text-align: center;
                            td {
                                line-height: 55px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                                vertical-align: middle;
                            }
                        }
                        .second {
                            text-align: center;
                            height: 86px;
                            &:nth-child(2n + 1) {
                                background: url("../assets/regulation/表单.png");
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                            }
                            td {
                                height: 86px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                                vertical-align: middle;
                            }
                            .operation {
                                .unfinshed {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100px;
                                    height: 40px;
                                    background: #0277fa;
                                    border-radius: 4px;
                                }
                                .finshed {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100px;
                                    height: 40px;
                                    background: #1dce3a;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }
            .footer-page {
                position: absolute;
                margin-top: 25px;
                left: 50%;
                bottom: 40px;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
