<template>
  <div id="main" style="width: 1920px">
      <div class="content">
        <div class="toper">
          <div style="text-align: center">用好药</div>
        </div>
        <div class="body" style="margin-top: 16px">
          <div style="padding-left: 18px">
            <div class="boxleft">
              <div class="abs hand" style="color: #5BFCF4;font-size: 20px;top:-36px;cursor:pointer;" @click="go('/smartHome')"><img style="margin-right: 10px"  src="../assets/home/5.png"/>返回</div>
              <div class="titleBox">
                <div class="title">智能药箱</div>
              </div>
              <div class="jsq" style="padding: 0 27px">
                <table style="width: 100%">
                  <tr>
                    <td class="lan16" style="text-align: center;width: 50%;height: 36px">药箱提醒</td>
                    <td class="lan16" style="text-align: center;width: 50%">药箱示警</td>
                  </tr>
                  <tr>
                    <td>       <div class="flex">
                    <div class="numLabel">服药提醒</div>
                      <div class="nums">
                        <span class="num" v-for="x in num1">{{x}}</span>
                      </div>
                    </div></td>
                    <td><div class="flex">
                      <div class="numLabel">过期药提醒</div>
                      <div class="nums">
                        <span class="num" v-for="x in num2">{{x}}</span>
                      </div>
                    </div></td>
                  </tr>
                  <tr>
                    <td>       <div class="flex">
                      <div class="numLabel">禁忌提醒</div>
                      <div class="nums">
                        <span class="num" v-for="x in num3">{{x}}</span>
                      </div>
                    </div></td>
                    <td><div class="flex">
                      <div class="numLabel">复购提醒</div>
                      <div class="nums">
                        <span class="num" v-for="x in num4">{{x}}</span>
                      </div>
                    </div></td>
                  </tr>
                  <tr>
                    <td>       <div class="flex">
                      <div class="numLabel">近效期提醒</div>
                      <div class="nums">
                        <span class="num" v-for="x in num5">{{x}}</span>
                      </div>
                    </div></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="lan16" style="margin-top: 80px;padding-left: 20px"><span style="float: right;padding-right: 20px" class="bai14">药品回收点：23个</span>药品回收</div>
              <div class="chart " style="margin-top:100px">
                <div id="myChart" :style="{width: '880px', height: '430px'}"></div>
              </div>

            </div>
          </div>
          <div class="youblcok" style="padding-left: 18px">
            <div class="boxleft">
              <div class="titleBox" style="margin-bottom: 24px">
                <div class="title">药事服务</div>
              </div>
  <div style="padding: 0px 30px">
    <div class="block_ysfw">
       <div class="ysfw_tit">
         <div class="bang flex" >
           <div class="item1">红榜</div>
           <div class="item2">黑榜</div>
         </div>
         签约医生响应情况</div>
      <div class="ysfw">
        <table width="100%">
          <tr>
          <td>序号</td>
          <td>签约医生姓名</td>
          <td>响应率</td>
          <td>当月响应天数</td>
        </tr>
          <tr v-for=" (x,i) in doctor">
            <td>{{i+1}}</td>
            <td>{{x.name}}</td>
            <td>{{x.xyl}}%</td>
            <td>{{x.days}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="block_ysfw" style="margin-top: 50px">
       <div class="ysfw_tit">
         <div class="bang flex" >
           <div class="item1">红榜</div>
           <div class="item2">黑榜</div>
         </div>
         执业药师响应情况</div>
      <div class="ysfw">
        <table width="100%">
          <tr>
          <td>序号</td>
          <td>执业药师姓名</td>
          <td>响应率</td>
          <td>当月响应天数</td>
        </tr>
          <tr v-for="(x,i)  in yaoshi">
            <td>{{i+1}}</td>
            <td>{{x.name}}</td>
            <td>{{x.xyl}}%</td>
            <td>{{x.days}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

            </div>

          </div>


        </div>

      </div>



  </div>
</template>
<style scoped>
.bang{padding-top: 8px;padding-right: 10px;right: 5px;position: absolute}
.bang .item1{
  margin-right: 17px;
  width: 53px;
  height: 26px;
  line-height: 26px;
  background: #1AC9FF;
  border-radius: 13px;}
.bang .item2{
  color: #1AC9FF;
  width: 53px;
  height: 26px;
  line-height: 26px;
  background: #636578;
  border-radius: 13px;

}
.ysfw td{line-height: 35px;color: white;
text-align: center}
.ysfw_tit{
  position: relative;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #292B46;
  border-radius: 4px;
  }
.jsq td{height: 50px}
.numLabel{line-height: 36px;color: white;margin-right: 5px;font-size: 14px;width: 93px;
text-align: right}
.titleBox{padding-left: 25px;padding-top: 15px}
.title{
  padding-left: 20px;
  background-image: url("../assets/home/4.png");
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  color: #1AC9FF;
  line-height: 31px;
}
.lan14{
  color: #1AC9FF;
  font-size: 14px;
}
.useblock{
  width: 1261px;
  height: 476px;
  background-image: url("../assets/home/usebox.png");
}
.alertRow{
  align-items: center; padding-left: 20px
}
.btnAlert{
  color: #FFCA12;width: 73px;height: 38px;
  background-image: url("../assets/home/2.png");
  line-height: 38px;
  text-align: center;
}
.rightblock1{
  position: relative;
  background-image: url("../assets/home/boxright1.png");
  width: 606px;
  height: 381px;
}
.rightblock2{
  position: relative;
  background-image: url("../assets/home/boxright2.png");
  background-repeat: no-repeat;
  width: 606px;
  height: 346px;
  margin-top: 20px;
}
.rightblock3{
  margin-top: 20px;
  position: relative;
  background-image: url("../assets/home/boxright3.png");
  width: 606px;
  height: 217px;
}
.stock{
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bolder;
  color: #FFFFFF;
}
.searchBar{margin-left: 20px}
.skey>>>.el-input__inner{
   border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 400px;
  color: #28C0EA;
  font-size: 16px;
}
.onekey{
  background-image: url("../assets/home/onekey.png");
  background-repeat: no-repeat;
  width: 206px;
  height: 31px;
  z-index: 1;
  margin-top:5px;
}
.search{position: absolute;  left: 10px;top: 10px;z-index: 3}
.map2{
  height: 480px;
  width: 646px;
  position: absolute;
  z-index: 0;

  opacity: 0.7;
}
.mapBlock{width: 646px;height: 480px;position: relative}
.numrate{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 646px}
.itemRateName{
  font-size: 13px;
color: #1AC9FF;
  text-align: center;
}
.itemRateBox{
  width: 25%;
  margin-bottom: 20px;
}
.itemRate{
  background-image: url("../assets/home/quan.png");
  background-repeat: no-repeat;
  width: 108px;
  height: 58px;
  line-height: 58px;
  color: white;
  text-align: center;
  margin: 0 auto;
  font-weight: bolder;
  font-size: 24px;
}
.nums{display: flex}
.num{margin-right: 4px;font-size: 26px;color: white}
.abs{
  position: absolute;
}
.num{
  text-align: center;
  line-height: 36px;
  color: white;
  width: 36px;
  height: 36px;
  background: rgba(15,48,156,0.6);
  border: 1px solid #28C2EC;
  border-radius: 6px;
display: block}
.lan24{color: #5BFCF4;
font-size: 24px}
.total{
  background-image: url("../assets/home/1.png");
  background-repeat: no-repeat;
  height: 124px;

}
.totalnum{
  width: 336px;
position: absolute;
top: 20px;
  right: 10px;
}
.flex{display: flex}
.c{text-align: center}
.crystalBox{
  position: absolute;
}
.crystalBox .item{padding-top: 20px}
.crystal{
margin: 0 auto;
  background-image: url("../assets/home/crystal.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.crystal2{
  margin: 0 auto;
  background-image: url("../assets/home/crystal2.png");
  width: 69px;
  height: 70px;
  font-size: 20px;
  font-family: Agency FB;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}
.body{
  display: flex;
}
.bai16{
  color: white;
  font-size: 16px;
}
.bai14{
  color: white;
  font-size: 14px;
}
.lan16{
  color: #1AC9FF;
  font-size: 16px;
}

.boxleft{
  position: relative;
  background-image: url("../assets/smart/use/block.png");
width: 930px;
  height: 976px;
}
#main{
  background-image: url("../assets/home/bg.png");
  height: 1080px;
}
.toper{height: 59px;
  line-height: 59px;
font-size: 26px;

  color: #1AC9FF;
  background-image: url("../assets/home/top.png");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<script>
import BdMap from './BaiduMap'
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import axios from "axios";
import moment from 'moment'
export default {
  name: 'zhuye',
  components: { BdMap},
  data(){
    return {
      riqi:[],
      doctor:[
        {name:"陈夏男",xyl:95,days:15},
        {name:"项丹丹",xyl:93,days:12},
        {name:"李修楠",xyl:92,days:18},
        {name:"蔡婉婉",xyl:96,days:20},
        {name:"吴秋芳",xyl:85,days:23},
        {name:"张  晶",xyl:88,days:24},
        {name:"朱文君",xyl:99,days:27},
        {name:"陈倩倩",xyl:91,days:26},
        {name:"季于莞",xyl:95,days:10},
      ],
      yaoshi:[
        {name:"黄婷婷",xyl:88,days:21},
        {name:"毛旭芳",xyl:82,days:15},
        {name:"李军",xyl:95,days:14},
        {name:"裴晓",xyl:96,days:23},
        {name:"童燕",xyl:93,days:17},
        {name:"吴洪艳",xyl:94,days:16},
        {name:"吴超群",xyl:91,days:23},
        {name:"朱启医",xyl:88,days:25},
        {name:"丁丽珍",xyl:97,days:21},
      ],
      num1:[0,0,1,2,0,9],
      num2:[0,0,0,0,4,8],
      num3:[0,0,0,0,1,6],
      num4:[0,0,0,1,6,1],
      num5:[0,0,0,1,1,2],
      keyword:"",
      region:330726,
      ypxsData:[],
    }
  },
  created() {
    for(let i=0;i<7;i++){
      this.riqi.push(moment().add(-i,'day').format('MM-DD'))
    }
    this.riqi.sort()
  },
  mounted() {
    this.getData()
    },
  methods:{
    go(url){
      this.$router.push(url)
    },
    drawChart(){
      let data=[9,7, 12, 8, 4, 13, 11]
      let dataX=this.riqi
      let option = {
        backgroundColor: '',
        title: {
          text: '',
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: 20,
          },
          top: '5%',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0, 255, 233,0)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 255, 233,0)',
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          show:false,
          // data: ['示范', '一级', '二级'],
          orient: 'horizontal',   // 水平
          //type: 'plain',          // 普通图例
          icon: "circle",
          top: 0,
          right: 30,
          // right: '5%',
          // bottom: '15%',
          //width:280,              // 宽度
          itemGap: 2,            // 间隔
          itemWidth: 10,          // 图形宽度。
          itemHeight: 10,         // 图形高度。
          borderWidth: 0,
          textStyle: {
            fontSize: '14px',
            color: '#4ca9ff',
          }
        },
        grid: {
          top: '15%',
          left: '15%',
          right: '5%',
          bottom: '20%',
          // containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            splitArea: {
              // show: false,
              color: '#f00',
              lineStyle: {
                color: '#f00',
              },
            },
            axisLabel: {
              color: '#fff',
            },
            splitLine: {
              show: false,
            },
            "axisTick":{       //y轴刻度线
              "show":false
            },
            boundaryGap: true,
            data: dataX,
          },
        ],

        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#56a4cd',
              },
            },
            axisLabel: {
              show: true,
              margin: 0,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '药事服务次数',
            type: 'line',
            smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: 'circle',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#00ca95',
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#00ca95',
              },
            },

            itemStyle: {
              color: '#00ca95',
              borderColor: '#fff',
              borderWidth: 3,
              shadowColor: 'rgba(0, 0, 0, .3)',
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(0,202,149,0.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,202,149,0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20,
              },
            },
            data: data,
          },
        ],
      };
      let myChart = echarts.init(document.getElementById('myChart'))
      myChart.setOption(option);
    },
    getData(){
      this.drawChart();
      this.drawChart_2();
      this.drawChart2();
    //  this.drawChart3();
      this.drawChart4();
      let arrCake=[
        {
          name: '正常',
          value: 83.2,
        },
        {
          name: '近效期',
          value: 11.1,
        },
        {
          name: '过期',
          value: 5.7,
        },
      ]
      this.drawChartCake("myChartCake_1",arrCake);
      let arrCake2=[
        {
          name: '好评',
          value: 85,
        },
        {
          name: '中评',
          value: 12,
        },
        {
          name: '差评',
          value: 3,
        },
      ]
      this.drawChartCake("myChartCake_2",arrCake2);
          },
    today() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //传入的参数应为数字
    getRiqi(time){
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - time);
      var dateArray = [];
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < time; i++) {
        let month = 0;
        if (myDate.getMonth() + 1 < 10) {
          month = '0' + (myDate.getMonth() + 1);
        } else {
          month = myDate.getMonth() + 1;
        }
        let day = 0;
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate();
        } else {
          day = myDate.getDate();
        }
        dateTemp = myDate.getFullYear() + '-' + month + '-' + day;
        dateArray.push(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
      }
      return dateArray
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k,height) {

  // 计算
  let midRatio = (startRatio + endRatio) / 2;

  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3 ;

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;

  // 返回曲面参数方程
  return {

    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },

    x: function(u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    y: function(u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian ){
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },

    z: function(u, v) {
      if (u < - Math.PI * 0.5 ) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5 ){
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1*height : -1;
    }
  };
},

  },

}
</script>
