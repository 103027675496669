<template>
    <div id="warning" style="width:1920px;height:1080px;overflow: hidden">
        <div class="waring-content">
            <yun-header />
            <div class="waring-body">
                <div class="left">
                    <!-- 风险预警总数 -->
                    <div class="warning-total">
                        <div class="warning-total-number">
                            <span>
                                本年风险预警总数:
                            </span>
                            <span>
                                <!--                              {{numYjs+19}}-->
                                {{ messageTotal }}次 </span>
                        </div>
                        <div class="waring-total-content">
                            <div class="total-content-left">
                                <div class="left-data">
                                    <div class="left-title" @click="$refs.childDialog.openWarning('', 3)">
                                        <span>自动预警</span>
                                        <span> {{ todayWarning }}</span>
                                        <!--                                        <span> {{ numYjs }}</span>-->
                                    </div>
                                    <div class="left-title" @click="$refs.childDialog.openJiancha('检查发现')">
                                        <span>检查发现</span>
                                        <span>{{ taskNumber }}</span>
                                    </div>
                                    <div class="left-title" @click="$refs.childDialog.openJubao()">
                                        <span>群众举报</span>
                                        <span>0</span>
                                    </div>
                                    <div class="left-title">
                                        <span>其他</span>
                                        <span>0</span>
                                    </div>
                                </div>
                                <div class="left-footer">
                                    <span>风险数据来源分析</span>
                                </div>
                            </div>
                            <div class="total-content-center">
                                <div class="center-content">
                                    <div class="center-total">
                                        <div class="center-total-title">
                                            <span>
                                                <img style="width:100%;height:100%" src="../assets/warning/left1_bg.gif"
                                                    alt="" />
                                            </span>
                                            <div class="auto">
                                                <span>{{ autoTaskNumber }}</span>
                                                <span>自动下发数</span>
                                            </div>
                                        </div>
                                        <div class="center-total-title">
                                            <span><img style="width:100%;height:100%"
                                                    src="../assets/warning/left2-bg.png" alt="" /></span>
                                            <div class="auto">
                                                <span>{{ taskNumber - autoTaskNumber }}</span>
                                                <span>手动下发数</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="center-footer">下发检查</div>
                                </div>
                            </div>
                            <div class="total-content-right">
                                <div class="right-echart" ref="rectifyEchart"></div>
                                <div class="right-footer">自主整改率</div>
                            </div>
                        </div>
                    </div>
                    <!-- 风险预警类型分析 -->
                    <div class="warning-type" style="position:relative">
                        <div class="riqiYj">
                            <!-- <span @click="changeSj(1)" :class="{ active: fxSj == 1 }">当日</span
                            ><span @click="changeSj(2)" :class="{ active: fxSj == 2 }">本月</span
                            > -->
                            <span @click="changeSj(3)" :class="{ active: true }">本年</span>
                        </div>
                        <!-- 头部 -->
                        <div class="warning-type-header">
                            <span>风险预警类型汇总</span>
                            <img style="width:95px;height:10px;margin-left:19px" src="../assets/regulation/yd.png"
                                alt="" />
                        </div>
                        <div class="warning-echart" ref="warningechart">数据加载中...</div>
                    </div>
                </div>
                <div class="right">
                    <!-- 风险趋势分析 -->
                    <div class="warning-analyse">
                        <!--头部  -->
                        <div class="warning-analyse-header">
                            <span>风险趋势分析</span>
                            <img style="width:95px;height:10px;margin-left:19px" src="../assets/regulation/yd.png"
                                alt="" />
                        </div>
                        <!-- 本季 本月 本年 -->
                        <!-- <div class="warning-analyse-type">
                            <div class="warning-analyse-time">
                                <span class="active">本月</span>
                                <span>本季</span>
                                <span>本年</span>
                            </div>
                        </div> -->
                        <!-- 折线图 -->
                        <div class="warning-analyse-echart" ref="analyseline"></div>
                    </div>
                    <!-- 企业风险预警排行 -->
                    <div class="ranking-list">
                        <!-- 头部 -->
                        <div class="ranking-list-header">
                            <span>药店风险预警排行</span>
                            <img style="width:95px;height:10px;margin-left:19px" src="../assets/regulation/yd.png"
                                alt="" />
                        </div>
                        <!-- 表格 -->
                        <div class="ranking-list-table">
                            <table cellspacing="0" cellpadding="0" width="100%">
                                <tr class="first-level">
                                    <td style="width:133px;padding-left:20px">序号</td>
                                    <td style="width:437px">药店名称</td>
                                    <td style="width:179px">风险次数</td>
                                    <td>自主整改率</td>
                                </tr>
                            </table>

                            <div id="fxlist">
                                <vue-seamless-scroll :data="fxList" :class-option="defaultOption">
                                    <table cellspacing="0" cellpadding="0" width="100%">
                                        <tr class="second-level" v-for="(x, i) in fxList">
                                            <td style="width:128px;padding-left:30px">
                                                {{ i + 1 }}
                                            </td>
                                            <td style="width:457px">{{ x.storeName }}</td>
                                            <td style="width:188px">{{ x.count }}</td>
                                            <td>{{ sjs(90, 100) }}%</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                            <!-- <table cellspacing="0" cellpadding="0" width="100%">
                                <tr class="first-level">
                                    <td>序号</td>
                                    <td>药店名称</td>
                                    <td>风险次数</td>
                                    <td>自主整改率</td>
                                </tr>
                                <tr class="second-level" v-for="(x, i) in fxList">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ x.storeName }}</td>
                                    <td>{{ x.count }}</td>
                                    <td>{{ sjs(90, 100) }}%</td>
                                </tr>
                            </table> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <yun-dialog ref="childDialog" />
    </div>
</template>

<script>
import * as echarts from "echarts";
import yunHeader from "@/views/yunHeader";
import yunDialog from "@/views/yunDialog";
import { riskList, messagePage, disposalList, visitor, todayWarning, task } from "@/api/yaojian";
import vueSeamlessScroll from "vue-seamless-scroll";
import axios from "axios";
import moment from "moment";
export default {
    components: { yunHeader, yunDialog, vueSeamlessScroll },
    data() {
        return {
            visitorData: {},//异常人数
            today: [moment().format("yyyy-MM-DD 00:00:00"), moment().format("yyyy-MM-DD 23:59:59")],
            arrDate: [
                moment().format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("week")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("month")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("quarter")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("year")
                    .format("yyyy-MM-DD 00:00:00"),
            ],
            fxSj: 1,
            fxList: [],
            numYjs: 0,
            analyse: null,
            warningtype: null,
            rectify: null,
            total: [],
            autoSendNumber: 0,
            typeLoading: false,
            totalWsd: 0,
            totalWcf: 0,
            typeTotal: [],
            todayWarning: 0,
            prescriptionWarning: 0,
            wsdNumber: 0,
            wgmhjNumber: 0,
            taskNumber: 0,
            autoTaskNumber: 0,
            messageTotal: 0
        };
    },
    mounted() {
        this.yichang()
        this.analyseEhart();
        this.rectifybar();
        this.getData();
        this.MessagePage();
        this.autoNumber()
        this.loadTodayWarning()
        this.loadTaskData()
        this.loadAutoTaskNumber()
        this.loadAllMessageData()
    },
    methods: {
        loadAllMessageData() {
            disposalList({ page: 1, count: 10, startTime: moment().startOf('year').format("yyyy-01-01 00:00:00"), endTime: moment().endOf('year').format("yyyy-MM-DD 23:59:59") }).then(res => {
                this.messageTotal = res.data.total
            })
        },
        async loadTodayWarning() {
            let yearmonthStart4 = this.$moment().add(-0, 'month').startOf('month').format("yyyy-MM-DD")
            let yearmonthEnd4 = this.$moment().add(-0, 'month').endOf('month').format("yyyy-MM-DD")
            let rs7 = await todayWarning({ today: 1, startTime: yearmonthStart4, endTime: yearmonthEnd4 })
            this.todayWarning = rs7.data
        },
        loadTaskData() {
            task({ page: 1, count: 10, startTime: moment().startOf('year').format("yyyy-01-01 00:00:00"), endTime: moment().endOf('year').format("yyyy-MM-DD 23:59:59") }).then(res => {
                this.taskNumber = res.data.total
            })        
        },
        loadAutoTaskNumber() {
            task({ page: 1, count: 10, startTime: moment().startOf('year').format("yyyy-01-01 00:00:00"), endTime: moment().endOf('year').format("yyyy-MM-DD 23:59:59"), type: 10 }).then(res => {
                this.autoTaskNumber = res.data.total
            })
        },
        //异常人数
        yichang() {
            this.typeLoading = true
            let postWsd = {
                page: 1,
                count: 5,
                start: moment().format("yyyy-01-01 00:00:00"),
                end: moment().format("yyyy-MM-DD 23:59:59"),
                type: 8
            }
            let postWcf = {
                page: 1,
                count: 5,
                start: moment().format("yyyy-01-01 00:00:00"),
                end: moment().format("yyyy-MM-DD 23:59:59"),
                type: 4
            }
            let postYc = {
                page: 1,
                count: 1000,
                level: "黄码",
                startTime: moment().startOf('year').format("yyyy-MM-DD HH:mm:ss"),
                endTime: moment().endOf('year').format("yyyy-MM-DD HH:mm:ss"),
            }
            const start = moment().format("yyyy-01-01 00:00:00");
            const end = moment().format("yyyy-MM-DD 23:59:59");
            const wsdPost = {
                page: 1, count: 10, type: 1,  startTime: start, endTime: end
            }
            const wcfPost = {
                page: 1, count: 10, type: 61, startTime: start, endTime: end
            }
            Promise.all([messagePage(postWsd), messagePage(postWcf), visitor(postYc), disposalList(wsdPost), disposalList(wcfPost)]).then(rs => {
                this.typeLoading = false
                this.visitorData = rs[2].data
                //this.typeTotal=[rs[0].data.total,rs[1].data.total, rs[2].data.total, 3, 20]
                this.typeTotal = [rs[3].data.total, rs[1].data.total, 3, 20]
                this.wsdNumber = rs[3].data.total
                this.wgmhjNumber = rs[4].data.total
                this.typeEchart()
            })
        },
        //自动下发数
        autoNumber() {
            const start = moment().format("yyyy-01-01 00:00:00");
            const end = moment().format("yyyy-MM-DD 23:59:59");
            disposalList({ page: 1, count: 10, type: 1, status: 25, startTime: start, endTime: end }).then(res => {
                this.autoSendNumber = res.data.total
            })
        },
        changeSj(t) {
            this.fxSj = t;
            this.MessagePage();
        },
        MessagePage() {
            /* 1.没有药师在岗
             2.销售预警
             3.特殊药品复方制剂跨店购买
             4.处方预警
             5.销售药品禁售
             6.特殊药品复方制剂跨店销售
             7.销售药品超售
             8.温湿度超标
             9.温湿度离线*/
            let start = this.today[0];
            let end = this.today[1];
            if (this.fxSj == 2) {
                start = this.arrDate[2];
            }
            if (this.fxSj == 3) {
                start = this.arrDate[4];
            }
            let post1 = {//温湿度超标
                page: 1,
                count: 10,
                type: 8,
                start: start,
                end: end,
            };
            let post2 = {//处方预警
                page: 1,
                count: 10,
                type: 4,
                start: start,
                end: end,
            };
            let post3 = {//特殊药品复方制剂跨店销售
                page: 1,
                count: 10,
                type: 6,
                start: start,
                end: end,
            };
            Promise.all([messagePage(post1), messagePage(post2), messagePage(post3)]).then((res) => {
                this.total[0] = res[0].data.total;
                this.total[1] = res[1].data.total;
                this.total[2] = res[2].data.total;

            });
        },
        sjs(min, max) {
            let a = Math.floor(Math.random() * (max - min + 1)) + min;
            return a;
        },
        getData() {
            riskList().then((rs) => {
                this.fxList = rs.data;
            });
            let that = this;
            //预警数
            axios.get("/screen/v3/message/size", {}).then(function (response) {
                let data = response.data;
                that.numYjs = data.data;
            });
        },
        // 风险趋势分析折线图
        analyseEhart() {
            this.analyse = echarts.init(this.$refs.analyseline);
            //获取月份
            let monthArr = []
            let nowMonth = this.$moment().format("MM")
            for (let i = nowMonth; i >= nowMonth - 8; i--) {
                monthArr.unshift(i + "月")
            }
            const option = {
                xAxis: {
                    type: "category",
                    data: monthArr,
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        data: [30, 40, 50, 100, 50, 40, 80, 50, 60],
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: [30, 40, 50, 100, 50, 40, 80, 50, 60],
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: [30, 40, 50, 100, 50, 40, 80, 50, 60],
                        type: "line",
                        smooth: true,

                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 1, color: "#00D7FE " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        data: [30, 40, 50, 100, 50, 40, 80, 50, 60],
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: " #00D7FE",
                        },
                        areaStyle: {
                            normal: {
                                // 设置折线图颜色渐变
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#00D7FE  " },
                                    { offset: 0, color: "#07244a" },
                                ]),
                            },
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                ],
            };
            this.analyse.setOption(option);
        },
        //类型分析
        typeEchart() {
            this.warningtype = echarts.init(this.$refs.warningechart);
            const colorList = {
                温湿度超标: "#0277FA",
                未上传处方: "#00D7FE",
                //  防疫异常: "#0277FA",
                经营证件近效期: "#00D7FE",
                进销存不平衡: "#1DCE3A",
            };
            const othercolors = {
                违规销售含麻黄碱药品: "#C41DF3",
                药品储备异常: "#C41DF3",
                // 违规销售管控药品: "#1DCE3A",
                违规销售含兴奋剂药品: "#FBC902",
                盗刷医保嫌疑: "#FBC902",
            };
            const option = {
                grid: [
                    {
                        //左侧的柱状图grid
                        width: "46%",
                        left: "1%",
                        top: "15",
                        right: "0",
                        bottom: "0",
                    },
                    {
                        //右侧的柱状图grid
                        width: "46%",
                        left: "51%",
                        top: "15",
                        right: "0",
                        bottom: "0",
                    },
                ],
                xAxis: [
                    {
                        //左侧柱状图的X轴
                        gridIndex: 0, //x 轴所在的 grid 的索引
                        show: false,
                    },
                    {
                        //右侧柱状图的X轴
                        gridIndex: 1, //x 轴所在的 grid 的索引
                        show: false,
                    },
                ],
                yAxis: [
                    {
                        //左侧柱状图的Y轴
                        gridIndex: 0, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        axisLabel: {
                            verticalAlign: "bottom",
                            align: "left",
                            padding: [0, 0, 15, 15],
                            textStyle: {
                                color: "#befbff",
                                fontSize: "22",
                            },
                        },
                        data: [
                            "温湿度超标",
                            "未上传处方",
                            // "防疫异常",
                            "经营证件近效期",
                            "进销存不平衡",
                        ],
                        inverse: true,
                    },
                    {
                        //左侧柱状图的Y轴
                        gridIndex: 0, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        //this.total[0], this.total[1],
                        data: this.typeTotal,
                        inverse: true,
                        axisLabel: {
                            show: true,
                            verticalAlign: "bottom",
                            align: "right",
                            padding: [0, 20, 15, 0],
                            textStyle: {
                                color: "#fff",
                                fontSize: "26",
                            },
                        },
                    },
                    {
                        //左侧柱状图的Y轴
                        gridIndex: 0, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        data: [],
                    },

                    {
                        //右侧柱状图的Y轴
                        gridIndex: 1, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        axisLabel: {
                            verticalAlign: "bottom",
                            align: "left",
                            padding: [0, 0, 15, 15],
                            textStyle: {
                                color: "#befbff",
                                fontSize: "22",
                            },
                        },
                        data: [
                            "违规销售含麻黄碱药品",
                            "药品储备异常",
                            //"违规销售管控药品",
                            "违规销售含兴奋剂药品",
                            "涉嫌串换药品",
                        ],
                        inverse: true,
                    },
                    {
                        //右侧柱状图的Y轴
                        gridIndex: 1, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        data: [this.wgmhjNumber, 5, 57, 5],
                        inverse: true,
                        axisLabel: {
                            show: true,
                            verticalAlign: "bottom",
                            align: "right",
                            padding: [0, 20, 15, 0],
                            textStyle: {
                                color: "#fff",
                                fontSize: "26",
                            },
                        },
                    },
                    {
                        //右侧柱状图的Y轴
                        gridIndex: 1, //y轴所在的 grid 的索引
                        splitLine: "none",
                        axisTick: "none",
                        axisLine: "none",
                        data: [],
                    },
                ],
                series: [
                    {
                        name: "值",
                        type: "bar",
                        xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                        yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                        //this.total[0], this.total[1]
                        data: this.typeTotal,
                        barWidth: 8,
                        itemStyle: {
                            normal: {
                                color: (arg) => {
                                    return colorList[arg.name];
                                },
                                borderRadius: 10,
                            },
                        },
                        z: 2,
                    },
                    {
                        name: "外框",
                        type: "bar",
                        xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                        yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                        barGap: "-100%",
                        data: [1000, 1000, 1000, 1000, 1000],
                        barWidth: 8,
                        itemStyle: {
                            normal: {
                                color: "#0277FA",
                                opacity: 0.1,
                                barBorderRadius: 6,
                            },
                        },
                        z: 0,
                    },
                    {
                        // 分隔
                        type: "pictorialBar",
                        itemStyle: {
                            normal: {
                                color: "#022539",
                            },
                        },

                        symbolSize: [2, 6],
                        symbolPosition: "start",
                        symbolOffset: [-1, 0],

                        z: 66,
                        animationEasing: "elasticOut",
                    },

                    {
                        name: "值",
                        type: "bar",
                        xAxisIndex: 1, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                        yAxisIndex: 3, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                        barWidth: 8,
                        data: [1, 5, 1, 57, 5],
                        itemStyle: {
                            normal: {
                                color: (arg) => {
                                    return othercolors[arg.name];
                                },
                                borderRadius: 10,
                            },
                        },

                        z: 2,
                    },
                    {
                        name: "外框",
                        type: "bar",
                        xAxisIndex: 1, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                        yAxisIndex: 3, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                        barGap: "-100%",
                        data: [1000, 1000, 1000, 1000, 1000],
                        barWidth: 8,
                        itemStyle: {
                            normal: {
                                color: "#0277FA",
                                opacity: 0.1,
                                barBorderRadius: 6,
                            },
                        },
                        z: 2,
                    },
                    {
                        // 分隔
                        type: "pictorialBar",
                        xAxisIndex: 1, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
                        yAxisIndex: 3, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
                        itemStyle: {
                            normal: {
                                color: "#022539",
                            },
                        },

                        symbolSize: [2, 6],
                        symbolPosition: "start",
                        symbolOffset: [-1, 0],
                        // symbolBoundingData: [120, 100, 90, 60, 30],
                        z: 66,
                        animationEasing: "elasticOut",
                    },
                ],
            };
            this.warningtype.setOption(option);
            let that = this
            this.warningtype.on('click', function (param) {
                //此处的value值为饼状图里 data的name 值
                console.log(param.name)
                let value = param.name
                // if(param.offsetX<407&&param.offsetY<58){
                //   value='温湿度超标'
                // }
                // if(param.offsetX<407&&param.offsetY<131&&param.offsetY>58){
                //   value='未上传处方'
                // }
                // if(param.offsetX<407&&param.offsetY<200&&param.offsetY>148){
                //   value='防疫异常'
                // }
                // if(param.offsetX>444&&param.offsetY<58&&param.offsetY>0){
                //   value='违规销售含麻黄碱药品'
                // }
                // if(param.offsetX>444&&param.offsetY<200&&param.offsetY>151){
                //   value='违规销售管控药品'
                // }
                console.log('value', value)
                if (value == '温湿度超标' || value == '未上传处方') {
                    that.$refs.childDialog.openWarningType(value)
                }
                if (value == '防疫异常') {
                    that.$refs.childDialog.openFyyc(value)
                }
                if (value == '违规销售含麻黄碱药品') {
                    //   that.$refs.childDialog.ldczDialog=true
                    that.$refs.childDialog.openWarningType(value)
                }

            });
            this.warningtype.getZr().on("click", function (param) {
                return false
                //根据param填写你的跳转逻辑
                console.log(param)
                let value = ""
                if (param.offsetX < 407 && param.offsetY < 58) {
                    value = '温湿度超标'
                }
                if (param.offsetX < 407 && param.offsetY < 131 && param.offsetY > 58) {
                    value = '未上传处方'
                }
                if (param.offsetX < 407 && param.offsetY < 200 && param.offsetY > 148) {
                    value = '防疫异常'
                }
                if (param.offsetX > 444 && param.offsetY < 58 && param.offsetY > 0) {
                    value = '违规销售含麻黄碱药品'
                }
                if (param.offsetX > 444 && param.offsetY < 200 && param.offsetY > 151) {
                    value = '违规销售管控药品'
                }
                console.log('value', value)
                if (value == '温湿度超标' || value == '未上传处方') {
                    that.$refs.childDialog.openWarningType(value)
                }
                if (value == '防疫异常') {
                    that.$refs.childDialog.openFyyc(value)
                }
                if (value == '违规销售含麻黄碱药品' || value == '违规销售管控药品') {
                    that.$refs.childDialog.ldczDialog = true
                }
            });
        },
        //自主整改率
        rectifybar() {
            var colorList = {
                未整改: "#C41DF3",
                已完成: "#0277FA",
            };
            this.rectify = echarts.init(this.$refs.rectifyEchart);
            var data = [
                {
                    name: "整改中",
                    value: 5.4,
                    itemStyle: {
                        color: "#C41DF3",
                    },
                },
                {
                    name: "已完成",
                    value: 94.6,
                    itemStyle: {
                        color: "#0277FA",
                    },
                },
            ];

            const option = {
                grid: {},
                legend: {
                    top: "80%",
                    left: "10%",
                    textStyle: {
                        rich: {
                            title: {
                                display: "block",
                                width: 80,
                                color: "#FEFEFE",
                                fontSize: 18,
                            },
                            value: {
                                color: "#FEFEFE",
                                fontSize: 18,
                            },
                        },
                    },
                    itemWidth: 14,
                    itemHeight: 14,
                    data: data,
                    itemGap: 10,
                    orient: "vertical",
                    formatter: (name, value) => {
                        const current = data.findIndex((item) => item.name == name);
                        return `{title|${name}}{value|${data[current].value}%}`;
                    },
                },
                series: [
                    // 主要展示层的
                    {
                        radius: ["30%", "50%"],
                        center: ["50%", "35%"],
                        type: "pie",
                        label: {
                            normal: {
                                show: true,
                                formatter: "{c}%",
                                textStyle: {
                                    fontSize: 22,
                                    color: "#FEFEFE",
                                },
                                position: "outside",
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        itemStyle: {
                            color: (arg) => {
                                return colorList[arg.name];
                            },
                        },
                        labelLine: {
                            normal: {
                                show: true,
                                length: 6,
                                length2: 15,
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        name: "已完成",
                        data: data,
                    },
                ],
            };
            this.rectify.setOption(option);
        },
    },
    computed: {
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
    },
};
</script>
<style scoped>
.riqiYj {
    width: 51px;
    height: 24px;
    border: 1px solid #0277fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #ffffff;
    position: absolute;
    right: 20px;
}

.riqiYj .active {
    background: #0277fa;
    color: #eeeeee;
}

.riqiYj span {
    cursor: pointer;
    flex: 1;
    width: 36px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0096ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #0277fa;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#warning {
    background: url("../assets/regulation/bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.waring-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.waring-header {
    height: 51px;
    width: 100%;
    background: url("../assets/medicine/bt.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    line-height: 26px;
}

.waring-body {
    flex: 1;
    display: flex;
    gap: 20px;
    padding: 0 35px;
    margin-top: 10px;
}

.waring-body .left {
    flex: 1;
}

.waring-body .left .warning-type {
    padding: 20px;
    margin-top: 18px;
    height: 452px;
    background: url("../assets/warning/左2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

.waring-body .left .warning-type .warning-type-header span {
    line-height: 27px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 6px;
}

.waring-body .left .warning-type .warning-echart {
    flex: 1;
    margin-top: 20px;
}

.waring-body .right {
    flex: 1;
}

.warning-total {
    height: 480px;
    background: url("../assets/warning/左1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 36px 20px;
}

.warning-total .warning-total-number {
    height: 69px;
    background: url("../assets/warning/左1边框.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
}

.warning-total .warning-total-number span:first-child {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 67px;
}

.warning-total .warning-total-number span:last-child {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fbc902;
    line-height: 67px;
}

.warning-total .waring-total-content {
    margin-top: 30px;
    display: flex;
}

.warning-total .waring-total-content .total-content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.warning-total .waring-total-content .total-content-left .left-footer {
    line-height: 21px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-top: 12px;
}

.warning-total .waring-total-content .total-content-center .center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 53px;
}

.warning-total .waring-total-content .total-content-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.warning-total .waring-total-content .total-content-right .right-footer {
    margin-top: 12px;
    line-height: 21px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
}

.warning-total .waring-total-content .total-content-right .right-echart {
    height: 277px;
    width: 100%;
}

.warning-total .waring-total-content .total-content-center .center-content .center-footer {
    line-height: 21px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-top: 24px;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total {
    display: flex;
    flex-direction: column;
    height: 272px;
    justify-content: space-between;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total .center-total-title {
    width: 205px;
    height: 126px;
    background: url("../assets/warning/左1边框3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total .center-total-title .auto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total .center-total-title .auto span:nth-child(1) {
    line-height: 29px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total .center-total-title .auto span:nth-child(2) {
    height: 19px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    margin-top: 17px;
}

.warning-total .waring-total-content .total-content-center .center-content .center-total .center-total-title>span {
    width: 46px;
    height: 46px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warning-total .left-data .left-title {
    background: url("../assets/warning/左1边框2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 58px;
    width: 273px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 14px;
}

.warning-total .left-data .left-title span:first-child {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 19px;
}

.warning-total .left-data .left-title span:last-child {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
}

.waring-body .right .warning-analyse {
    height: 480px;
    background: url("../assets/warning/右1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    padding: 20px;
    flex-direction: column;
}

.waring-body .right .warning-analyse .warning-analyse-header {
    display: flex;
    align-items: center;
}

.waring-body .right .warning-analyse .warning-analyse-header span {
    line-height: 27px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 6px;
}

.waring-body .right .warning-analyse .warning-analyse-time {
    width: 153px;
    height: 24px;
    border: 1px solid #0277fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.waring-body .right .warning-analyse .warning-analyse-time span {
    flex: 1;
    width: 36px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0096ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #0277fa;
    height: 100%;
}

.waring-body .right .warning-analyse .warning-analyse-time .active {
    background: #0277fa;
    color: #eeeeee;
}

.waring-body .right .warning-analyse .warning-analyse-time span:last-child {
    border: none;
}

.waring-body .right .warning-analyse .warning-analyse-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.waring-body .right .warning-analyse .warning-analyse-echart {
    flex: 1;
}

.waring-body .right .ranking-list {
    height: 452px;
    background: url("../assets/warning/左2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.waring-body .right .ranking-list .ranking-list-header span {
    line-height: 27px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #00d7fe;
    letter-spacing: 6px;
}

.waring-body .right .ranking-list .ranking-list-table {
    margin-top: 20px;
    overflow: hidden;
    flex: 1;
}

.waring-body .right .ranking-list .ranking-list-table #fxlist {
    overflow-y: hidden;
}

.waring-body .right .ranking-list .ranking-list-table tr {
    height: 60px;
}

.waring-body .right .ranking-list .ranking-list-table .first-level {
    background: url("../assets/medicine/表头.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.waring-body .right .ranking-list .ranking-list-table .first-level td {
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    vertical-align: middle;
}

.waring-body .right .ranking-list .ranking-list-table .second-level:nth-child(2n + 1) {
    background: url("../assets/medicine/表单.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.waring-body .right .ranking-list .ranking-list-table .second-level td {
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    vertical-align: middle;
}
</style>
